import React from 'react';
import { Text } from 'components';
import { css } from '@emotion/css';
import { Close } from 'components/Icons';
import { theme } from 'theme';

export const LinkDialog: React.FC = () => {
  return (
    <dialog id="dialog" className="dialog">
      <div
        className={css`
          height: 40px;
          border-bottom: 1px solid #322d4e;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-top: -5px;
          /* padding: 0 20px; */
        `}
      >
        <Text.BodyXS marginBottom="4xs" marginTop="4xs">
          Add link
        </Text.BodyXS>

        <span
          className={css`
            background-color: #2d2a41;
            width: 24px;
            height: 24px;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          `}
          id="close_button"
        >
          <Close
            style={{ width: '12px', height: '12px' }}
            fill={theme.colors.text['text/blue/soft']}
          />
        </span>
      </div>
      <form method="dialog" id="form">
        <Text.BodyXS marginBottom="s">Text</Text.BodyXS>
        <input
          type="text"
          name="input_text"
          placeholder="Text to display"
          id="input_text"
          autoFocus
        />
        <Text.BodyXS marginTop="s" marginBottom="s">
          Link
        </Text.BodyXS>
        <input
          type="text"
          name="input_url"
          placeholder="The URL"
          id="input_url"
        />
        <div className="footer">
          <button type="button" className="cancel" id="cancel_button">
            Cancel
          </button>
          <button type="submit" className="submit">
            Ok
          </button>
        </div>
      </form>
    </dialog>
  );
};
