import React, { useEffect, useState } from 'react';
import { Box, Button, DropdownI, FilterDropdownItem, Input } from 'components';
import {
  BookmarkIcon,
  GoogleLogo,
  SlackLogo,
  TopicCreatedIcon,
  Comment,
  GoogleCommentIcon,
  FunnelSimpleIcon,
  PlusIcon,
  NotionLogo,
  LinearIcon,
  MicrosoftLogo,
} from 'components/Icons';
import { theme } from 'theme';
import { css } from '@emotion/css';
import { capitalizeString } from 'utils';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CREATE_GROUP, GROUP } from '../gql';
import { useAppDispatch, useAppSelector } from 'hooks';
import { RootState } from 'reducers';
import { addFilter, initializeFilter } from 'features/activityFilter';
import { EActivityType } from 'model';

type FilterProps = {
  stared: boolean;
  type: EActivityType[];
  topicId: string;
  selectedGroup: string[];
  // topicClosed?: boolean;
  //   createLoading?: boolean;
  clearFilter: VoidFunction;
  handleStaredClick: VoidFunction;
  handleTypeClick: (type: EActivityType) => void;
  handleGroupClick: (group: string) => void;
  //   onAddGroup: (newGroup: string) => void;
};
export const Filter: React.FC<FilterProps> = (props) => {
  const [newGroup, setNewGroup] = useState('');
  // const [open, setOpen] = useState(false);
  // const [group, setGroup] = useState<any[]>([]);

  // const topicBasics = useAppSelector((state: RootState) => state.topic);
  const group = useAppSelector((state: RootState) => state.activityFilter);
  const dispatch = useAppDispatch();

  const [getGroup] = useLazyQuery<any>(GROUP, {
    variables: {
      topicId: props.topicId,
    },
  });

  const [createGroup, { loading }] = useMutation(CREATE_GROUP);

  const onAddGroup = () => {
    createGroup({
      variables: {
        topicId: props.topicId,
        group: newGroup,
      },
    }).then((res) => {
      setNewGroup('');
      dispatch(addFilter(res.data.createGroup));
    });
  };

  useEffect(() => {
    getGroup().then((res) => {
      dispatch(initializeFilter(res.data?.groups || []));
      // setGroup(res.data.groups);
    });
  }, [dispatch, getGroup]);

  return (
    <Box noPadding width="100%" marginTop="xl">
      <div
        className={css`
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <div
          className={css`
            display: flex;
            flex-direction: row;
            align-items: center;
          `}
        >
          {/* <Button
            iconPosition="icon-only"
            Icon={Search}
            style={{ marginBottom: '8px', marginRight: '8px' }}
          /> */}
          <DropdownI.Root
            triggerComponent={
              <Button
                iconPosition="icon-only"
                Icon={FunnelSimpleIcon}
                style={{ marginBottom: '8px' }}
              />
            }
          >
            <Box noPadding>
              <DropdownI.Item>
                <FilterDropdownItem
                  text="All activity"
                  selected={
                    !props.stared &&
                    props.type.length === 0 &&
                    props.selectedGroup.length === 0
                  }
                  onClick={() => props.clearFilter()}
                />
              </DropdownI.Item>

              <DropdownI.Item>
                <FilterDropdownItem
                  text="Important"
                  Icon={TopicCreatedIcon}
                  selected={props.stared}
                  onClick={props.handleStaredClick}
                />
              </DropdownI.Item>

              <DropdownI.Item>
                <FilterDropdownItem
                  text="Google comment"
                  Icon={GoogleCommentIcon}
                  selected={props.type.includes(EActivityType.GOOGLE_COMMENT)}
                  onClick={() => {
                    props.handleTypeClick(EActivityType.GOOGLE_COMMENT);
                  }}
                />
              </DropdownI.Item>

              <DropdownI.Item>
                <FilterDropdownItem
                  text="Google"
                  Icon={GoogleLogo}
                  selected={props.type.includes(EActivityType.GOOGLE)}
                  onClick={() => props.handleTypeClick(EActivityType.GOOGLE)}
                />
              </DropdownI.Item>

              <DropdownI.Item>
                <FilterDropdownItem
                  text="Microsoft"
                  Icon={MicrosoftLogo}
                  selected={props.type.includes(EActivityType.MICROSOFT)}
                  onClick={() => props.handleTypeClick(EActivityType.MICROSOFT)}
                />
              </DropdownI.Item>

              <DropdownI.Item>
                <FilterDropdownItem
                  text=" Notion"
                  Icon={NotionLogo}
                  selected={props.type.includes(EActivityType.NOTION)}
                  onClick={() => props.handleTypeClick(EActivityType.NOTION)}
                />
              </DropdownI.Item>

              <DropdownI.Item>
                <FilterDropdownItem
                  text="Slack"
                  Icon={SlackLogo}
                  selected={props.type.includes(EActivityType.SLACK)}
                  onClick={() => props.handleTypeClick(EActivityType.SLACK)}
                />
              </DropdownI.Item>

              <DropdownI.Item>
                <FilterDropdownItem
                  text="Linear comment"
                  Icon={LinearIcon}
                  selected={props.type.includes(
                    EActivityType.LINEAR_ISSUE_COMMENT
                  )}
                  onClick={() => {
                    props.handleTypeClick(EActivityType.LINEAR_ISSUE_COMMENT);
                  }}
                />
              </DropdownI.Item>

              <DropdownI.Item>
                <FilterDropdownItem
                  text="Linear"
                  Icon={LinearIcon}
                  selected={props.type.includes(EActivityType.LINEAR_ISSUE)}
                  onClick={() => {
                    props.handleTypeClick(EActivityType.LINEAR_ISSUE);
                  }}
                />
              </DropdownI.Item>

              <DropdownI.Item>
                <FilterDropdownItem
                  text="Comment"
                  Icon={Comment}
                  selected={props.type.includes(EActivityType.COMMENT)}
                  onClick={() => props.handleTypeClick(EActivityType.COMMENT)}
                />
              </DropdownI.Item>

              <DropdownI.Sub
                triggerComponent={
                  <FilterDropdownItem
                    text="Custom"
                    Icon={BookmarkIcon}
                    selected={false}
                    onClick={() => {}}
                  />
                }
              >
                {group.map((item) => (
                  <DropdownI.Item key={item._id}>
                    <FilterDropdownItem
                      text={capitalizeString(item.name)}
                      Icon={BookmarkIcon}
                      selected={props.selectedGroup.includes(item._id)}
                      onClick={() => props.handleGroupClick(item._id)}
                    />
                  </DropdownI.Item>
                ))}

                <div
                  className={css`
                    margin-top: 16px;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                  `}
                >
                  <Input
                    width="200px"
                    small
                    placeholder="Create custom"
                    background={theme.colors.primary['primary/01']}
                    value={newGroup}
                    onChange={(e) => setNewGroup(e.target.value)}
                    style={{ marginRight: '8px' }}
                  />

                  <Button
                    Icon={PlusIcon}
                    iconPosition="icon-only"
                    onClick={onAddGroup}
                    disabled={loading}
                    size="small"
                  />
                </div>
              </DropdownI.Sub>
            </Box>
          </DropdownI.Root>

          {/* <Button
            iconPosition="icon-only"
            Icon={BookmarksSimpleIcon}
            style={{ marginBottom: '8px', marginLeft: '8px' }}
          /> */}
        </div>
        {/* <SortBy
          selectedLabel="Date created"
          body={
            <Box position="relative" noPadding>
              Hello man Hello manHello manHello manHello manHello man
            </Box>
          }
        /> */}
      </div>

      <div
        className={css`
          width: 100%;
          height: 1px;
          background-color: ${theme.colors.accent['purple/light']};
        `}
      />
    </Box>
  );
};
