import { gql, useQuery } from '@apollo/client';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { Box, Text } from 'components';
import { TopicCreatedIcon } from 'components/Icons';
import React from 'react';
import { ToolTipHelper } from './ToolTipHelper';

const MEETING_REPARTITION = gql`
  query MeetingDurationPerDay($userId: ID!) {
    meetingDurationPerHour(userId: $userId) {
      fri
      mon
      sat
      thu
      sun
      tue
      wed
    }
  }
`;

type MeetingDurationPerDay = {
  meetingDurationPerHour: {
    sun?: number[];
    mon?: number[];
    tue?: number[];
    wed?: number[];
    thu?: number[];
    fri?: number[];
    sat?: number[];
  };
};

const colors = [
  '#262438',
  '#2C2943',
  '#383456',
  '#443E6D',
  '#524B83',
  '#655BA6',
  '#7266BD',
  '#887AE7',
  '#6A53FE',
];

type Props = {
  userId: string;
  placeholder?: string;
};
export const MeetingHourRepartition: React.FC<Props> = ({
  userId,
  placeholder,
}) => {
  const { data, loading } = useQuery<MeetingDurationPerDay>(
    MEETING_REPARTITION,
    {
      variables: { userId },
    }
  );

  const stats =
    loading || !data?.meetingDurationPerHour
      ? placeholder
        ? JSON.parse(placeholder)
        : {}
      : data?.meetingDurationPerHour;

  const getTag = (values: number[]) => {
    let idx = 0;
    let isLastBusy = false;

    return values?.map((value, index) => {
      if (isLastBusy) {
        idx = 0;
        isLastBusy = false;
      }
      let color = colors[idx];
      idx++;
      if (value > 0) {
        color = colors[colors.length - 1];
        isLastBusy = true;
      }
      return <Tag key={index} color={color} />;
    });
  };
  return (
    <Box
      backgroundColor="primary/02"
      position="relative"
      // width="386px"
      width="856px"
      borderRadius="l"
      noPadding
      style={{ marginLeft: '15px' }}
    >
      <div
        className={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          padding: 36px;
        `}
      >
        <div
          className={css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            height: 300px;
            margin-right: 15px;
          `}
        >
          <div
            className={css`
              height: 26px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              border-radius: 13px;
              border: 1px solid #a99dcb;
              width: 250px;
              /* padding-inline: 8px; */
              margin-bottom: 34px;
              /* margin-top: -40px; */
            `}
          >
            <Text.BodySM
              style={{ color: '#A99DCB' }}
              marginBottom="4xs"
              marginTop="4xs"
            >
              Maker
            </Text.BodySM>
            <TopicCreatedIcon
              fill="#A99DCB"
              style={{
                width: '16px',
                height: '16px',
                marginLeft: '8px',
                marginRight: '8px',
              }}
            />
            <Text.BodySM
              style={{ color: '#A99DCB' }}
              marginBottom="4xs"
              marginTop="4xs"
            >
              Manager Schedule
            </Text.BodySM>
          </div>

          <div>
            <div
              className={css`
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
              `}
            >
              <div
                className={css`
                  width: 16px;
                  height: 16px;
                  border-radius: 2px;
                  background: #6a53fe;
                  margin-right: 8px;
                `}
              />
              <Text.BodySM
                style={{ color: '#A99DCB' }}
                marginBottom="4xs"
                marginTop="4xs"
              >
                Meeting
              </Text.BodySM>
            </div>

            <div
              className={css`
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                margin-top: 8px;
              `}
            >
              <div
                className={css`
                  width: 16px;
                  height: 16px;
                  border-radius: 2px;
                  background: linear-gradient(
                    180deg,
                    #221e39 0%,
                    #413a66 43.23%,
                    #564d83 100%
                  );
                  margin-right: 8px;
                `}
              />
              <Text.BodySM
                style={{ color: '#A99DCB' }}
                marginBottom="4xs"
                marginTop="4xs"
              >
                Focus time
              </Text.BodySM>
            </div>
          </div>

          <div className={css``}>
            <ToolTipHelper text="Are you a maker or a manager? Track your meetings vs focus time and see if they align with your goals." />
            <Text.BodySM style={{ color: '#A99DCB', width: '250px' }}>
              Are you a maker or a manager? Track your meetings vs focus time
              and see if they align with your goals.
            </Text.BodySM>
          </div>
        </div>
        <table
          className={css`
            width: 100%;
            border-collapse: collapse;
          `}
        >
          <thead>
            <Th>SUN</Th>
            <Th>MON</Th>
            <Th>TUE</Th>
            <Th>WED</Th>
            <Th>THU</Th>
            <Th>FRI</Th>
            <Th>Sat</Th>
          </thead>
          <tbody>
            {stats && (
              <Tr>
                <Td>
                  <Box noPadding display="flex" flexDirection="column">
                    {getTag(stats.sun!)}
                  </Box>
                </Td>
                <Td>
                  <Box noPadding display="flex" flexDirection="column">
                    {getTag(stats.mon!)}
                  </Box>
                </Td>
                <Td>
                  <Box noPadding display="flex" flexDirection="column">
                    {getTag(stats.tue!)}
                  </Box>
                </Td>
                <Td>
                  <Box noPadding display="flex" flexDirection="column">
                    {getTag(stats.wed!)}
                  </Box>
                </Td>
                <Td>
                  <Box noPadding display="flex" flexDirection="column">
                    {getTag(stats.thu!)}
                  </Box>
                </Td>
                <Td>
                  <Box noPadding display="flex" flexDirection="column">
                    {getTag(stats.fri!)}
                  </Box>
                </Td>
                <Td>
                  <Box noPadding display="flex" flexDirection="column">
                    {getTag(stats.sat!)}
                  </Box>
                </Td>
              </Tr>
            )}
          </tbody>
        </table>
      </div>
    </Box>
  );
};

const Th = styled.th`
  color: #a99dcb;
  font-family: 'Roobert';
  font-size: 9px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 74px;
  padding-bottom: 22px;
`;

const Tr = styled.tr`
  width: 100%;
`;
const Td = styled.td``;

type TagProps = {
  color: string;
};
export const Tag: React.FC<TagProps> = ({ color }) => {
  return (
    <div
      className={css`
        width: 64px;
        height: 34px;
        background-color: ${color};
      `}
    />
  );
};
