import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import styled from '@emotion/styled';
import { theme } from 'theme';
import {
  BellSimpleIcon,
  ChartBarIcon,
  HouseIcon,
  ListDashIcon,
  LogoIcon,
  UsersThreeIcon,
} from 'components/Icons';
import {
  UserDropdown,
  SearchDropdown,
  // PlayerOne,
  LogoutDialog,
} from 'components';
import { ERouteName } from 'pages';
import { displayUser, getFromLocalStorage } from 'utils';
import { UserContext } from 'context';
import { css } from '@emotion/css';
import { gql, useQuery } from '@apollo/client';
import { ENotificationKey } from 'shared';
import pubsub from 'sweet-pubsub';

export const UNREAD_NOTIFICATIONS = gql`
  query unreadNotificationCount {
    unreadNotificationCount
  }
`;

// const SYNC_TOPICS = gql`
//   mutation AutoBuildTopics {
//     autoBuildTopics
//   }
// `;

const Container = styled.div`
  max-width: 2000px;
  overflow: auto;
  padding-bottom: ${theme.spacing.l}px;
  border-radius: 0px 0px ${theme.borderRadius['2xl']}px
    ${theme.borderRadius['2xl']}px;
`;

const Header = styled.header`
  width: 90px;
  min-width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10;
  height: 100%;
  border-right: 1px solid #272539;
  padding: 60px 0px;
`;

const Body = styled.div`
  width: 100%;
  min-width: 1300px;
  max-width: 1800px;
  border-radius: ${theme.borderRadius.xl}px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: ${theme.spacing['xl']}px;
  padding-left: 125px;
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

type NavLinkProps = {
  selected?: boolean;
  to: string;
  label: string;
  Icon: React.FC<any>;
};

const NavLink: React.FC<NavLinkProps> = ({ to, Icon, label, selected }) => (
  <Link
    to={to}
    className={css`
      text-decoration: none;
      width: 100%;
    `}
  >
    <div
      className={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        position: relative;
        width: 100%;
        height: 40px;
        /* border-radius: 32px; */
        border-right: 3px solid #6a53fe;
        border-width: ${selected ? '3px' : '0px'};
        /* background-color: ${selected ? '#453E69' : ''}; */
      `}
    >
      <Icon
        fill={selected ? '#6a53fe' : 'white'}
        style={{
          height: '24px',
          width: '24px',
        }}
      />
    </div>
  </Link>
);

const excludedRoutes = [ERouteName.Topic];

type Params = {
  topicId?: string;
  userId?: string;
};

export const PageContainer: React.FC = () => {
  const { topicId } = useParams<Params>();
  const navigate = useNavigate();
  const location = useLocation();
  // const [show, setShow] = useState(true);
  const [showLogout, setShowLogout] = useState(false);

  const { state: userState } = useContext(UserContext);

  const { data, refetch } = useQuery<{
    unreadNotificationCount: number;
  }>(UNREAD_NOTIFICATIONS, { fetchPolicy: 'network-only' });

  // const [autoGenerate] = useMutation(SYNC_TOPICS);

  // useEffect(() => {
  //   autoGenerate().then();
  // }, [autoGenerate]);

  // useEffect(() => {
  //   const handleKeyPress = (event: KeyboardEvent) => {
  //     // console.log(`Key pressed: ${event.key}`);
  //     if (event.metaKey === true) {
  //       console.log(`Key pressed: ${event.key}`);
  //     }
  //     if (event.key.some((key) => event.key === key)) {
  //       callbackRef.current(event);
  //     }
  //   };
  //   // attach the event listener
  //   document.addEventListener('keydown', handleKeyPress);

  //   // remove the event listener
  //   return () => {
  //     document.removeEventListener('keydown', handleKeyPress);
  //   };
  // }, []);

  useEffect(() => {
    if (!getFromLocalStorage('djibril')) {
      navigate(ERouteName.Login);
    }
    if (!getFromLocalStorage('accessToken')) {
      if (
        !(
          topicId &&
          excludedRoutes.includes(
            location.pathname.split(topicId)[0] as ERouteName
          )
        )
      ) {
        navigate(ERouteName.Login);
      }
    }
    refetch();
  }, [location.pathname, navigate, refetch, topicId]);

  // useEffect(() => {
  //   const showCallback = (data: { value: boolean }) => {
  //     setShow(data.value);
  //   };
  //   pubsub.on(ENotificationKey.EDIT_TOPIC_OPEN, showCallback);
  //   return () => {
  //     pubsub.off(ENotificationKey.EDIT_TOPIC_OPEN, showCallback);
  //   };
  // }, []);

  useEffect(() => {
    const showCallback = (data: { value: boolean }) => {
      setShowLogout(data.value);
    };
    pubsub.on(ENotificationKey.SHOW_LOGOUT, showCallback);
    return () => {
      pubsub.off(ENotificationKey.SHOW_LOGOUT, showCallback);
    };
  }, []);

  const currentPage = window.location.pathname;

  return (
    <Container>
      <Header>
        {userState?.workspace && (
          <Nav>
            <a
              href={ERouteName.Home}
              className={css`
                margin-bottom: 80px;
                margin-top: 21px;
                @media (max-height: 900px) {
                  margin-bottom: 60px;
                }
                @media (max-height: 870px) {
                  margin-bottom: 50px;
                }
                @media (max-height: 850px) {
                  margin-bottom: 40px;
                }
                @media (max-height: 800px) {
                  margin-bottom: 10px;
                }
                @media (max-height: 750px) {
                  margin-bottom: 5px;
                }
              `}
            >
              <LogoIcon style={{ width: '40px', height: '32px' }} />
            </a>

            <div
              className={css`
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                /* background-color: ${theme.colors.primary['primary/02']};
                border-radius: 40px; */
                /* padding: 8px 0px; */
              `}
            >
              <NavLink
                to={ERouteName.Feed}
                Icon={HouseIcon}
                label="Feed"
                selected={
                  currentPage === ERouteName.Feed ||
                  currentPage === ERouteName.Home
                }
              />

              <NavLink
                to={ERouteName.Stats + userState?._id}
                Icon={ChartBarIcon}
                label="People"
                selected={currentPage.includes(ERouteName.Stats)}
              />

              <NavLink
                to={ERouteName.Workspace}
                Icon={ListDashIcon}
                label="Topics"
                selected={
                  currentPage === ERouteName.Workspace ||
                  currentPage.includes(ERouteName.Topic)
                }
              />
              <NavLink
                to={ERouteName.People}
                Icon={UsersThreeIcon}
                label="People"
                selected={currentPage === ERouteName.People}
              />

              <SearchDropdown />
            </div>

            <div
              className={css`
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                /* background-color: ${theme.colors.primary['primary/02']}; */
                /* border-radius: 40px; */
                padding-top: 8px;
                padding-bottom: 8px;
                margin-top: 100px;
                @media (max-height: 900px) {
                  margin-top: 80px;
                }
                @media (max-height: 870px) {
                  margin-top: 70px;
                }
                @media (max-height: 850px) {
                  margin-top: 60px;
                }
                @media (max-height: 800px) {
                  margin-top: 30px;
                }
                @media (max-height: 750px) {
                  margin-top: 10px;
                }
                @media (max-height: 700px) {
                  margin-top: 0px;
                }
              `}
            >
              <div
                className={css`
                  position: relative;
                  margin-bottom: 16px;
                  width: 100%;
                `}
              >
                <NavLink
                  to={ERouteName.NotificationsInbox}
                  Icon={BellSimpleIcon}
                  label="Feed"
                  selected={currentPage === ERouteName.NotificationsInbox}
                />
                {Boolean((data?.unreadNotificationCount || 0) > 0) && (
                  <div
                    className={css`
                      width: 8px;
                      height: 8px;
                      border-radius: 4px;
                      background-color: #8d86f8;
                      position: absolute;
                      top: 10px;
                      right: calc(100% / 2 - 10px);
                    `}
                  />
                )}
              </div>
              {userState?._id && (
                <div
                  className={css`
                    /* margin-top: 16px; */
                  `}
                >
                  <UserDropdown
                    user={{
                      id: userState?._id || '',
                      name: displayUser(userState) || '',
                      profileUrl: userState?.profileUrl,
                      role: userState?.role,
                    }}
                    workspace={[
                      {
                        id: 'jjjj',
                        name: 'Multiplayer',
                      },
                    ]}
                  />
                </div>
              )}
            </div>
          </Nav>
        )}
      </Header>

      <Body>
        <LogoutDialog open={showLogout} onOpenChange={setShowLogout} />
        <Outlet />
        {/* HARDCODE --- hide for moov for now */}
        {/* {currentPage.includes(ERouteName.Topic) &&
          show &&
          userState?.workspace &&
          userState?.workspace !== '6376cfa45a41c748c40af519' && <PlayerOne />} */}
      </Body>
    </Container>
  );
};

type PageProps = {
  children: React.ReactNode;
  title?: string;
};
export const Page: React.FC<PageProps> = ({ children, title }) => {
  return (
    <>
      <Helmet>
        <title>{title || 'Multiplayer App'}</title>
      </Helmet>
      {children}
    </>
  );
};
