import { css } from '@emotion/css';
import React, { useContext } from 'react';
import { Box, Text } from 'components';
import { TutorialSetting } from './TutorialSetting';
import { GET_PROGRESS } from './gql';
import { useQuery } from '@apollo/client';
import { UserContext } from 'context';
import { TutorialProgress } from 'model';
import { MagicWandIcon, PlayIcon } from 'components/Icons';
import { theme } from 'theme';

export const Tutorial: React.FC = () => {
  const { state: userState } = useContext(UserContext);
  const { data: tutorialResult } = useQuery<{
    tutorialProgress: TutorialProgress;
  }>(GET_PROGRESS, {
    variables: {
      userId: userState?._id,
    },
  });
  return (
    <div
      className={css`
        width: 80%;
      `}
    >
      <div
        className={css`
          width: 50%;
        `}
      >
        <Text.H2>Multiplayer tutorial</Text.H2>
        <TutorialSetting
          tutorialProgress={tutorialResult?.tutorialProgress}
          // onTutorialCompleted={() => {
          //   setIsTutorialCompleted(true);
          // }}
        />
      </div>

      <Box
        width="100%"
        height="110px"
        backgroundColor="primary/02"
        borderRadius="l"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        marginTop="3xl"
      >
        <Box
          noPadding
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          width="70%"
        >
          <MagicWandIcon
            fill={theme.colors.text['text/link']}
            style={{ width: '48px', height: '48px', marginRight: '32px' }}
          />
          <Box noPadding>
            <Text.Body marginBottom="4xs">Replay setup</Text.Body>
            <Text.TextSmall1 color="text/75%" marginTop="4xs">
              Create your topics and setup your workspace with the help of our
              AI helper.
            </Text.TextSmall1>
          </Box>
        </Box>

        <a
          href="/onboarding"
          className={css`
            text-decoration: none;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 44px;
            border-radius: 8px;
            background-color: ${theme.colors.primary['primary/03']};
            padding-inline: 16px;
          `}
        >
          <Text.Body marginBottom="4xs" marginTop="4xs">
            Start game
          </Text.Body>
          <PlayIcon
            style={{ width: '16px', height: '16px', marginLeft: '8px' }}
          />
        </a>
      </Box>
    </div>
  );
};
