import React, { useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { css } from '@emotion/css';
import { ActivityHolder, Box, Drawer, Text } from 'components';
import {
  ChevronRightIcon,
  GoogleColabIcon,
  GoogleDocIcon,
  GoogleLogo,
  GooglePresentationIcon,
  GoogleSheetIcon,
  LargeIcon,
  MediumIcon,
  SmallIcon,
  TeenyTinyIcon,
  TinyIcon,
} from 'components/Icons';
import { theme } from 'theme';
import { GoogleModel } from '../types';
import { DeltaDialog } from './ItemMenu/DeltaDialog';
import { EActivityType, GoogleDocType } from 'model';
import { ENotificationKey } from 'shared';
import pubsub from 'sweet-pubsub';

type DocProps = {
  doc: GoogleModel;
  id: string;
  topicId: string;
  createdBy: string;
  creatorId?: string;
  customRef: React.LegacyRef<HTMLDivElement>;
  focus?: boolean;
  isFirst?: boolean;
  stared: boolean;
  last?: boolean;
  subComponents?: JSX.Element[];
  inCollapse?: boolean;
  hasAccess: boolean | undefined;
  // isDrawerOpen?: boolean;
  // toggleDrawer?: VoidFunction;
};
export const DocUpdate: React.FC<DocProps> = ({
  doc,
  topicId,
  id,
  createdBy,
  customRef,
  creatorId,
  focus,
  isFirst,
  subComponents,
  stared,
  last,
  inCollapse,
  // hasAccess,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [openDelta, setOpenDelta] = useState(false);
  const hasAccess = true;

  const Icon = useMemo(() => {
    switch (doc.type) {
      case GoogleDocType.Document:
        return <GoogleDocIcon style={{ width: '16px', height: '16px' }} />;

      case GoogleDocType.Presentation:
        return (
          <GooglePresentationIcon style={{ width: '16px', height: '16px' }} />
        );

      case GoogleDocType.Sheets:
        return <GoogleSheetIcon style={{ width: '16px', height: '16px' }} />;

      case GoogleDocType.Colab:
        return <GoogleColabIcon style={{ width: '16px', height: '16px' }} />;

      default:
        return <GoogleLogo style={{ width: '16px', height: '16px' }} />;
    }
  }, [doc.type]);

  let updateType = '';
  let UpdateIcon;
  if (!doc.delta?.value || doc.delta?.value! <= 5) {
    updateType = 'Teeny tiny';
    UpdateIcon = TeenyTinyIcon;
  } else if (doc.delta?.value! <= 28) {
    updateType = 'Tiny';
    UpdateIcon = TinyIcon;
  } else if (doc.delta?.value! <= 400) {
    updateType = 'Small';
    UpdateIcon = SmallIcon;
  } else if (doc.delta?.value! <= 800) {
    updateType = 'Medium';
    UpdateIcon = MediumIcon;
  } else {
    updateType = 'Large';
    UpdateIcon = LargeIcon;
  }

  useEffect(() => {
    pubsub.emit(ENotificationKey.EDIT_TOPIC_OPEN, { value: !isDrawerOpen });
  }, [isDrawerOpen]);

  return (
    <>
      <ActivityHolder.Item
        topicId={topicId}
        type={EActivityType.GOOGLE}
        id={id}
        owner={creatorId}
        customRef={customRef}
        link={`${process.env.REACT_APP_WEB_URL}/topic/${topicId}#${id}`}
        Icon={Icon}
        title={`*<a href="${
          creatorId ? '/profile/' + creatorId : '#'
        }">${createdBy}</a>** added modification to *<a href="${
          isFirst
            ? doc?.lastVersionUrl || doc?.url
            : doc?.url === '#'
            ? doc?.lastVersionUrl
            : doc?.url || doc?.lastVersionUrl
        }" target="_blank" rel="noreferrer">${
          hasAccess === undefined
            ? 'A Google doc'
            : hasAccess
            ? doc?.title
            : 'a file you do not have access to'
        }</a>**`}
        subTitle={`${format(+doc?.modifiedDate, 'MMMM d, yyyy • h:mm aa')}`}
        stared={stared}
        last={
          last &&
          !Boolean(doc.delta?.value && doc.delta?.value > 0) &&
          !subComponents?.length
        }
        hasCollapse={last && subComponents && subComponents.length > 0}
      >
        {Boolean(hasAccess && doc.delta?.value && doc.delta?.value > 0) && (
          <>
            <Box
              padding="m"
              borderRadius="m"
              width="100%"
              backgroundColor={inCollapse ? 'primary/03' : 'primary/02'}
              display="flex"
              flexDirection="row"
              alignItems="center"
              // onClick={() => setOpenDelta(true)}
              className={css`
                cursor: pointer;
                border: 1px solid
                  ${focus ? theme.colors.accent['blue/main'] : 'transparent'} !important;
                transition: border-color 0.15s;
                &:hover {
                  border-color: ${theme.colors.accent['blue/main']} !important;
                }
              `}
            >
              <UpdateIcon />
              {/* {loading && <Text.BodySM>Loading diff...</Text.BodySM>} */}
              <Text.BodySM
                style={{ marginRight: '10px', marginLeft: '8px' }}
                marginBottom="4xs"
                marginTop="4xs"
              >
                {updateType} update
                {/* ({doc.delta?.value || 0}{' '}
                characters)*/}
              </Text.BodySM>
            </Box>
            <DeltaDialog
              open={openDelta}
              itemId={id}
              onOpenChange={setOpenDelta}
              type={EActivityType.GOOGLE}
            />
          </>
        )}

        {subComponents && subComponents.length > 0 && (
          <Box
            noPadding
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            marginTop="s"
            position="relative"
            style={{
              cursor: 'pointer',
            }}
            onClick={() => setIsDrawerOpen(!isDrawerOpen)}
          >
            <div
              className={css`
                position: absolute;
                bottom: 9.5px;
                left: -34px;
              `}
            >
              <div
                className={css`
                  height: 40px;
                  width: 33px;
                  border-bottom-left-radius: 15px;
                  border-bottom: 1px solid
                    ${theme.colors.accent['purple/light']};
                `}
              />
            </div>
            <div
              className={css`
                width: 20px;
                height: 1px;
                margin-left: -20px;
                background-color: ${theme.colors.accent['purple/light']};
              `}
            />
            <div
              className={css`
                width: 20px;
                height: 20px;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 7px;
                border: 1px solid ${theme.colors.accent['purple/light']};
                position: relative;
              `}
            >
              <div
                className={css`
                  height: 1px;
                  width: 11px;
                  background-color: ${theme.colors.accent['purple/light']};
                  position: absolute;
                  top: 8.5px;
                  left: -1px;
                `}
              />
              <ChevronRightIcon fill={theme.colors.accent['purple/main']} />
            </div>
            <Text.BodyXS color="text/link" marginBottom="4xs" marginTop="4xs">
              View {subComponents.length} more updates for{' '}
              {hasAccess ? doc?.title : 'this Google doc'}
            </Text.BodyXS>
          </Box>
        )}
      </ActivityHolder.Item>

      {subComponents && subComponents.length > 0 && (
        <Drawer
          isOpen={isDrawerOpen}
          toggleDrawer={() => setIsDrawerOpen(!isDrawerOpen)}
        >
          <Box noPadding width="100%">
            <ActivityHolder.Root>{subComponents}</ActivityHolder.Root>
          </Box>
        </Drawer>
      )}
    </>
  );
};
