import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import {
  Button,
  Page,
  Text,
  Box,
  PageLoading,
  ActivityHolder,
  DropdownI,
  TreemapChart,
  ItemTitle,
  FeedHolder,
  FeedSeparator,
  ProfileIcon,
} from 'components';
import { format } from 'date-fns';
import { theme } from 'theme';
import {
  AlienIcon,
  ArrowRight,
  BriefCaseIcon,
  CaretDownIcon,
  ChartBarIcon,
  ChevronDown,
  Comment,
  CrownSimpleIcon,
  EnvelopeIcon,
  FileTextIcon,
  FlyingSaucerIcon,
  GameControllerIcon,
  GhostIcon,
  GithubLogo,
  GithubLogoIcon,
  GoogleColabIcon,
  GoogleDocIcon,
  GoogleLogo,
  GooglePresentationIcon,
  GoogleSheetIcon,
  HandWavingIcon,
  HeartIcon,
  HorseIcon,
  LinkedinLogo,
  MapPinLineIcon,
  MaskHappyIcon,
  PuzzlePieceIcon,
  SlackLogo,
  SlackLogoSimple,
  SpinnerGapIcon,
  SwordIcon,
  WaveSineIcon,
} from 'components/Icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ERouteName } from 'pages';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { capitalizeString, displayUser, extractIdFromString } from 'utils';
import { UserContext } from 'context';

import {
  InputContainer,
  ImageInput,
  ProfileImageContainer,
  ProfileImage,
} from './components/primary';
import {
  GET_ACTIVITY,
  GET_COLLABORATORS,
  GET_DATA_REP,
  GET_PROFILE,
  GET_SLACK_PROFILE,
  GET_TOPICS,
  GET_TOP_DOC,
  UPDATE_PROFILE,
} from './gql';
import {
  Activity,
  Collaborator,
  DataRepartition,
  Params,
  TTopKDocument,
  TopicInfo,
  UserData,
} from './types';
import { EActivityType, EMomentumType, GoogleDocType } from 'model';
import { css } from '@emotion/css';
import './style.css';

// const limit = 4;

const ICON_LIST = [
  MaskHappyIcon,
  AlienIcon,
  GameControllerIcon,
  GhostIcon,
  HeartIcon,
  SwordIcon,
  CrownSimpleIcon,
  PuzzlePieceIcon,
  HorseIcon,
  FlyingSaucerIcon,
];

export const Profile = () => {
  const { userId: param } = useParams<Params>();
  const userId = extractIdFromString(param || '');
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    description: '',
    githubProfile: '',
    linkedinProfile: '',
    role: '',
  } as UserData);
  const [pictureLoading, setPictureLoading] = useState(false);
  const [page, setPage] = useState(0);
  // const [currentTopicPage, setCurrentTopicPage] = useState(0);
  const [activityList, setActivityList] = useState<Activity[]>([]);
  const [topics, setTopic] = useState<TopicInfo[]>([]);
  const [range, setRange] = React.useState<'day' | 'week' | 'month'>('month');

  const { state: userState } = useContext(UserContext);

  const [fetchUser, { loading }] = useLazyQuery<{ userById: UserData }>(
    GET_PROFILE
  );

  const { data: slackData } = useQuery(GET_SLACK_PROFILE, {
    variables: { userId },
  });

  const { data: collabData } = useQuery<Collaborator>(GET_COLLABORATORS, {
    fetchPolicy: 'cache-first',
  });

  const [updateUser] = useMutation<{
    updateUser: UserData;
  }>(UPDATE_PROFILE);

  const [loadTopic] = useLazyQuery<{
    mostInteractedTopics: TopicInfo[];
    // totalTopics: number;
  }>(GET_TOPICS);

  const { data: topData, loading: topKLoading } = useQuery<TTopKDocument>(
    GET_TOP_DOC,
    {
      variables: { userId },
    }
  );

  // const [loadMoreCurrentTopics] = useLazyQuery<{
  //   topics: TopicInfo[];
  // }>(GET_TOPICS);

  const [loadActivity, { data: activityData }] = useLazyQuery<{
    activityForUser: Activity[];
    totalActivity: number;
  }>(GET_ACTIVITY);

  const [
    fetchRepartition,
    { data: dataRepartition, loading: dataRepartitionLoading },
  ] = useLazyQuery<DataRepartition>(GET_DATA_REP);

  useEffect(() => {
    fetchRepartition({
      variables: {
        userId,
        range,
      },
    }).then((res) => {});
  }, [fetchRepartition, range, userId]);

  useEffect(() => {
    loadTopic({
      variables: {
        userId,
        limit: 4,
        page: 0,
      },
    }).then((res) => {
      if (res.data?.mostInteractedTopics) {
        setTopic(res.data.mostInteractedTopics);
      }
    });
  }, [loadTopic, userId]);

  useEffect(() => {
    loadActivity({
      variables: {
        workspaceId: userState?.workspace,
        userId,
        page: 0,
      },
    }).then((res) => {
      if (res.data?.activityForUser) {
        setActivityList(res.data.activityForUser);
      }
    });
  }, [loadActivity, userId, userState?.workspace]);

  const [loadMoreActivity, { loading: moreLoading }] = useLazyQuery<{
    activityForUser: Activity[];
  }>(GET_ACTIVITY);

  // const loadMoreCurrentTopic = useCallback(() => {
  //   loadMoreCurrentTopics({
  //     variables: {
  //       workspaceId: getFromLocalStorage('workspaceId'),
  //       userId,
  //       limit,
  //       page: currentTopicPage + 1,
  //       order: 'desc',
  //       filter: 'opened',
  //       participated: true,
  //     },
  //   }).then((result) => {
  //     if (result.data?.topics && result.data?.topics?.length > 0) {
  //       setCurrentTopicPage((p) => p + 1);
  //       setTopic((data) => data.concat(result.data?.topics!));
  //     }
  //   });
  // }, [loadMoreCurrentTopics, currentTopicPage, userId]);

  const loadMore = useCallback(() => {
    loadMoreActivity({
      variables: {
        workspaceId: userState?.workspace,
        userId,
        page: page + 1,
      },
    }).then((result) => {
      if (
        result.data?.activityForUser &&
        result.data?.activityForUser?.length > 0
      ) {
        setPage((p) => p + 1);
        setActivityList((data) => data.concat(result.data?.activityForUser!));
      }
    });
  }, [loadMoreActivity, page, userId, userState?.workspace]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchUser({
      variables: {
        id: userId,
      },
      fetchPolicy: 'network-only',
    }).then((result) => {
      if (result.data?.userById) {
        setUserData(result.data.userById);
        // TODO -- remove this later and change the links in other pages
        // if (!param?.split('-')?.[1]) {
        //   navigate(
        //     `${ERouteName.Profile}${userId}-${(
        //       displayUser(result.data?.userById as any) || ''
        //     )
        //       .toLowerCase()
        //       .split(' ')
        //       .join('-')}`,
        //     {
        //       replace: true,
        //     }
        //   );
        // }
      }
    });
  }, [fetchUser, navigate, param, userId]);

  const isMe = useMemo(
    () => userState?._id === userId,
    [userState?._id, userId]
  );

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setPictureLoading(true);
    try {
      const bodyContent = new FormData();
      bodyContent.append('image', event.target.files?.[0]!);
      const headersList = {
        Accept: '*/*',
        'User-Agent': 'Thunder Client (https://www.thunderclient.com)',
      };

      const res = await fetch(
        `${process.env.REACT_APP_API_URL?.split('graphql').join(
          'api'
        )}/profile-upload`,
        {
          method: 'POST',
          body: bodyContent,
          headers: headersList,
        }
      );

      await res
        .text()
        .then((response) => {
          updateUser({
            variables: {
              profileUrl: response,
            },
          }).then((result) => {
            setUserData({ ...userData, profileUrl: response });
            setPictureLoading(false);
            window?.location?.reload();
          });
        })
        .catch((e) => {
          setPictureLoading(false);
        });
    } catch (error) {
      setPictureLoading(false);
    }
  };

  const renderIcon = (data: string) => {
    switch (JSON.parse(data).type) {
      case 'comment':
        return (
          <Comment
            fill="#736DA0"
            style={{
              width: '14px',
              height: '14px',
            }}
          />
        );

      case 'slack':
        return (
          <SlackLogo fill="#736DA0" style={{ width: '14px', height: '14px' }} />
        );

      case 'google': {
        let Logo = GoogleLogo;
        switch (JSON.parse(data).payload.type) {
          case GoogleDocType.Document:
            Logo = GoogleDocIcon;
            break;

          case GoogleDocType.Presentation:
            Logo = GooglePresentationIcon;
            break;

          case GoogleDocType.Sheets:
            Logo = GoogleSheetIcon;
            break;

          case GoogleDocType.Colab:
            Logo = GoogleColabIcon;
            break;

          default:
            break;
        }
        return <Logo style={{ width: '12px', height: '12px' }} />;
      }

      case 'topic':
        return (
          <div
            style={{
              width: '5px',
              height: '5px',
              borderRadius: '3px',
              backgroundColor: '#736DA0',
            }}
          />
        );

      case 'github':
        return (
          <GithubLogoIcon
            fill="#736DA0"
            style={{
              width: '12px',
              height: '12px',
            }}
          />
        );

      default:
        return (
          <Comment
            fill="#736DA0"
            style={{
              width: '14px',
              height: '14px',
            }}
          />
        );
    }
  };

  const RandomIcon = ICON_LIST[Math.floor(Math.random() * ICON_LIST.length)];

  const goToStat = () => {
    navigate(ERouteName.Stats + userId);
  };

  const newUpdates = activityList.filter((fe) => {
    const date = new Date(fe.created);
    const today = new Date();
    return (
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate()
    );
  }).length;

  const previousDate = (date: number) =>
    new Date().getDate() === new Date(date).getDate() + 1
      ? 'Yesterday'
      : format(+date, 'MMMM d, yyyy');

  const RenderTree = useMemo(
    () => (
      <TreemapChart
        data={
          dataRepartition?.userActivityRepartition?.map((d) => ({
            id: d.id,
            label: d.topic,
            value: +d.percentage.toFixed(2),
          })) || []
        }
      />
    ),
    [dataRepartition?.userActivityRepartition]
  );

  if (loading) return <PageLoading />;

  return (
    <Page title={`${displayUser(userData as any) || 'Profile'} | Multiplayer`}>
      <div
        className={css`
          width: 100%;
          max-height: 100vh;
          display: grid;
          grid-template-columns: 30% auto;
          gap: 20px;
          padding-top: 24px;
        `}
      >
        <Box noPadding width="100%">
          <Box
            width="100%"
            // height="100%"
            backgroundColor="primary/02"
            borderRadius="l"
            padding="xs"
            position="relative"
          >
            <ProfileImageContainer>
              {userData.profileUrl ? (
                <ProfileImage
                  src={userData.profileUrl}
                  alt="User profile image"
                />
              ) : (
                <RandomIcon
                  fill={theme.colors.text['text/link']}
                  style={{ width: '32px', height: '32px' }}
                />
              )}
              {isMe && (
                <InputContainer>
                  {pictureLoading ? (
                    <Text.Body>Loading...</Text.Body>
                  ) : (
                    <ImageInput
                      type="file"
                      accept="image/*"
                      required
                      onChange={onChange}
                    />
                  )}
                </InputContainer>
              )}
            </ProfileImageContainer>

            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
              marginBottom="l"
            >
              <Text.H2
                marginTop="4xs"
                marginBottom="xl"
                style={{
                  width: '100%',
                  display: 'block',
                  wordBreak: 'break-all',
                }}
              >
                {displayUser(userData as any) || 'Name undefined'}
              </Text.H2>

              {userData.description && (
                <Text.Body color="text/75%" marginBottom="xl">
                  {userData.description ||
                    'This user did not provide any information about himself here.'}
                </Text.Body>
              )}

              <Box
                noPadding
                display="flex"
                flexDirection="row"
                alignItems="center"
                style={{ display: 'none' }}
              >
                <MapPinLineIcon
                  style={{ marginRight: '8px', width: '16px', height: '16px' }}
                />
                <Text.TextSmall1
                  color="text/75%"
                  marginTop="4xs"
                  marginBottom="4xs"
                >
                  {capitalizeString(userData.role || 'No Role Defined')}
                </Text.TextSmall1>
              </Box>

              <Box
                noPadding
                display="flex"
                flexDirection="row"
                alignItems="center"
                marginTop="xs"
              >
                <BriefCaseIcon
                  style={{ marginRight: '8px', width: '16px', height: '16px' }}
                />
                <Text.TextSmall1
                  color="text/75%"
                  marginTop="4xs"
                  marginBottom="4xs"
                >
                  {capitalizeString(userData.role || 'No Role Defined')}
                </Text.TextSmall1>
              </Box>

              <Box
                noPadding
                display="flex"
                flexDirection="row"
                alignItems="center"
                marginTop="xs"
              >
                <EnvelopeIcon
                  style={{ marginRight: '8px', width: '16px', height: '16px' }}
                  fill="#8C83C3"
                />
                <Text.TextSmall1
                  color="text/75%"
                  marginTop="4xs"
                  marginBottom="4xs"
                >
                  {userData.businessEmail}
                </Text.TextSmall1>
              </Box>

              <div
                className={css`
                  width: 100%;
                  height: 1px;
                  border-bottom: 1px solid #453e69;
                  margin-top: 24px;
                  margin-bottom: 24px;
                `}
              />

              <Text.TextSmall1 marginTop="4xs" marginBottom="4xs">
                External links
              </Text.TextSmall1>

              <Box
                flexDirection="row"
                marginBottom="3xs"
                marginTop="m"
                noPadding
                width="100%"
                alignItems="center"
                justifyContent="flex-start"
              >
                <Button
                  iconPosition="icon-only"
                  iconColor="blue/soft"
                  Icon={LinkedinLogo}
                  style={{ marginRight: '8px' }}
                  onClick={() => {
                    if (userData.linkedinProfile) {
                      let a = document.createElement('a');
                      a.target = '_blank';
                      a.href = userData.linkedinProfile;
                      a.click();
                    }
                  }}
                />
                <Button
                  iconPosition="icon-only"
                  iconColor="blue/soft"
                  Icon={SlackLogoSimple}
                  style={{ marginRight: '8px' }}
                  onClick={() => {
                    if (userData.linkedinProfile) {
                      let a = document.createElement('a');
                      a.target = '_blank';
                      a.href = slackData?.slackProfileUrl || '#';
                      a.click();
                    }
                  }}
                />
                <Button
                  iconPosition="icon-only"
                  iconColor="blue/soft"
                  Icon={GithubLogo}
                  onClick={() => {
                    if (userData.githubProfile) {
                      let a = document.createElement('a');
                      a.target = '_blank';
                      a.href = userData.githubProfile;
                      a.click();
                    }
                  }}
                />
              </Box>
            </Box>

            <div
              className={css`
                position: absolute;
                bottom: 0;
                right: 0;
                width: 60px;
                height: 60px;
                border-radius: 16px 0px 0px 0px;
                background-color: ${theme.colors.primary['primary/01']};
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
              `}
            >
              <div
                className={css`
                  width: 48px;
                  height: 48px;
                  border-radius: 24px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: ${theme.colors.primary['primary/02']};
                  cursor: pointer;
                `}
                onClick={goToStat}
              >
                <ChartBarIcon />
              </div>
            </div>
          </Box>

          <Box
            width="100%"
            // height="100%"
            backgroundColor="primary/02"
            borderRadius="l"
            padding="2xl"
            position="relative"
            marginTop="l"
          >
            <div
              className={css`
                height: 20px;
                border-radius: 40px;
                padding-inline: 8px;
                border: 0.769px solid #8c83c3;
                display: inline-flex;
                align-items: center;
                color: #8c83c3;
                font-size: 11px;
                margin-bottom: 16px;
              `}
            >
              <div
                className={css`
                  width: 4px;
                  height: 4px;
                  border-radius: 2px;
                  background-color: #8c83c3;
                  margin-right: 8px;
                `}
              />
              Connections
            </div>

            {!Boolean(
              collabData?.collaborators?.filter((c) => c._id !== userId)?.length
            ) && (
              <Text.TextSmall1 style={{ width: '60%' }}>
                Peope you interact with the most will be shown here.{' '}
              </Text.TextSmall1>
            )}

            {collabData?.collaborators
              ?.filter((c) => c._id !== userId)
              ?.slice(0, 3)
              ?.map((user, idx, all) => (
                <React.Fragment key={user._id}>
                  <div
                    className={css`
                      display: grid;
                      grid-template-columns: 40px auto;
                      gap: 5px;
                      margin-top: 8px;
                      overflow: hidden;
                      width: 100%;
                    `}
                  >
                    <ProfileIcon
                      userId={user._id}
                      userName={displayUser(user as any) || ''}
                      profilePicture={user.profileUrl}
                    />
                    <Box noPadding marginTop="xs">
                      <Text.TextSmall1 marginTop="4xs" marginBottom="4xs">
                        {displayUser(user as any)}
                      </Text.TextSmall1>
                      <Text.TextSmall2
                        marginTop="4xs"
                        marginBottom="4xs"
                        style={{ fontSize: '8px', color: '#838094' }}
                      >
                        {displayUser(user as any)}
                      </Text.TextSmall2>
                    </Box>
                  </div>

                  {idx !== all.length - 1 && (
                    <div
                      className={css`
                        width: 100%;
                        height: 1px;
                        border-bottom: 1px solid #272539;
                        margin-top: 8px;
                      `}
                    />
                  )}
                </React.Fragment>
              ))}
          </Box>
        </Box>
        <Box width="100%" padding="xs">
          <ItemTitle Icon={SwordIcon} title="Working on" />

          <Box noPadding marginTop="l" marginBottom="2xl" width="100%">
            {!topics?.length && (
              <div
                className={css`
                  width: 100%;
                  height: 148px;
                  display: inline-flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  border-radius: 16px;
                  border: 1px dashed #453e69;
                `}
              >
                <Text.TextSmall1 marginBottom="3xs">
                  Links to your most active topics will appear here.
                </Text.TextSmall1>
                <Text.TextSmall1 marginBottom="4xs" marginTop="4xs">
                  You can also{' '}
                  <Text.A href={ERouteName.Workspace} size="text-small-1">
                    create your first topic
                  </Text.A>
                </Text.TextSmall1>
              </div>
            )}
            <div
              className={css`
                width: 100%;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 8px;
              `}
            >
              {topics?.map((topic) => {
                return (
                  <Link
                    key={topic._id}
                    to={ERouteName.Topic + topic._id}
                    style={{ width: '100%', textDecoration: 'none' }}
                  >
                    <Box
                      backgroundColor="primary/06"
                      width="100%"
                      height="148px"
                      borderRadius="l"
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-evenly"
                    >
                      <div
                        className={css`
                          color: #8c83c3;
                          font-size: 11px;
                          border: 0.769px solid #8c83c3;
                          border-radius: 40px;
                          height: 20px;
                          /* width: 65; */
                          display: flex;
                          flex-direction: row;
                          align-items: center;
                          padding-inline: 8px;
                          /* margin-top: -10px; */
                        `}
                      >
                        <div
                          className={css`
                            height: 4px;
                            width: 4px;
                            border-radius: 2px;
                            background-color: #8c83c3;
                            margin-right: 4px;
                          `}
                        />
                        {topic.closed
                          ? 'Closed'
                          : topic.momentum?.label === EMomentumType.DORMANT
                          ? 'Dormant'
                          : 'Active'}
                      </div>

                      <Box
                        noPadding
                        display="block"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="flex-end"
                        width="100%"
                      >
                        <Text.Body
                          marginBottom="4xs"
                          marginTop="4xs"
                          color="text/100%"
                          style={{ display: 'inline-block', width: '92%' }}
                        >
                          {topic.name}
                        </Text.Body>
                        <ArrowRight
                          style={{
                            width: '20px',
                            height: '20px',
                            display: 'inline-block',
                          }}
                          fill="#8C83C3"
                        />
                      </Box>
                    </Box>
                  </Link>
                );
              })}
            </div>
          </Box>

          <ItemTitle Icon={SpinnerGapIcon} title="Most edited files" />

          <Box
            noPadding
            marginTop="l"
            marginBottom="2xl"
            width="100%"
            display="grid"
            style={{
              gridTemplateColumns: 'repeat(3, 1fr)',
              gap: '8px',
            }}
          >
            {Boolean(topKLoading || !topData?.topKDocuments?.length)
              ? [1, 2, 3].map((key) => (
                  <div
                    key={key}
                    className={css`
                      height: 100px;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: flex-start;
                      border-radius: 16px;
                      border: 1px dashed #453e69;
                      padding: 16px;
                    `}
                  >
                    <div
                      className={css`
                        height: 10px;
                        width: 17px;
                        border-radius: 10px;
                        background-color: #453e69;
                        margin-bottom: 16px;
                      `}
                    />
                    <div
                      className={css`
                        height: 10px;
                        width: 70%;
                        border-radius: 10px;
                        background-color: #453e69;
                        margin-bottom: 4px;
                      `}
                    />
                    <div
                      className={css`
                        height: 10px;
                        width: 40%;
                        border-radius: 10px;
                        background-color: #453e69;
                      `}
                    />
                  </div>
                ))
              : topData?.topKDocuments.map((doc) => (
                  <a
                    href={doc.url}
                    target="_blank"
                    rel="noreferrer"
                    className={css`
                      text-decoration: none;
                      display: inline-block;
                      width: 100%;
                      height: 100px;
                      margin-right: 8px;
                      &:last-child {
                        margin-right: 0px;
                      }
                    `}
                  >
                    <div
                      className={css`
                        width: 100%;
                        height: 100%;
                        border-radius: 16px;
                        background: #272539;
                        display: flex;
                        flex-direction: column;
                        padding: 16px;
                      `}
                    >
                      <FileTextIcon style={{ marginBottom: '18px' }} />
                      <Text.TextSmall1 marginBottom="4xs" marginTop="4xs">
                        {doc.title}
                      </Text.TextSmall1>
                      <Text.TextSmall2
                        color="text/40%"
                        marginBottom="4xs"
                        marginTop="4xs"
                      >
                        {format(
                          new Date(doc.updated),
                          'MMMM d, yyyy • h:mm aa'
                        )}
                      </Text.TextSmall2>
                    </div>
                  </a>
                ))}
          </Box>

          <ItemTitle Icon={WaveSineIcon} title="Activity" />

          <div
            className={css`
              width: 100%;
              display: grid;
              grid-template-columns: 1fr 130px;
              gap: 8px;
              margin-top: 16px;
              margin-bottom: 24px;
            `}
          >
            {Boolean(
              dataRepartitionLoading ||
                !dataRepartition?.userActivityRepartition?.length
            ) ? (
              <div className="grid-container">
                <div className="grid-item item1">
                  <div className="loading-item" />
                </div>
                <div className="grid-item item2">
                  <div className="loading-item" />
                </div>
                <div className="grid-item item3">
                  <div className="loading-item" />
                </div>
                <div className="grid-item item4">
                  <div className="loading-item" />
                </div>
                <div className="grid-item item5">
                  <div className="loading-item" />
                </div>
                <div className="grid-item item6">
                  <div className="loading-item" />
                </div>
                <div className="grid-item item7">
                  <div className="loading-item" />
                </div>
                <div className="grid-item item8">
                  <div className="loading-item" />
                </div>
                <div className="grid-item item9">
                  <div className="loading-item" />
                </div>
                <div className="grid-item item10">
                  <div className="loading-item" />
                </div>
              </div>
            ) : (
              <Box
                width="100%"
                borderRadius="l"
                backgroundColor="primary/02"
                // marginTop="l"
                padding="2xl"
                // marginBottom="2xl"
              >
                {RenderTree}
              </Box>
            )}
            <DropdownI.Root
              triggerComponent={
                <div
                  className={css`
                    display: inline-flex;
                    padding: 12px 16px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 8px;
                    background-color: ${theme.colors.primary['primary/02']};
                    font-size: 14px;
                  `}
                >
                  This {range}
                  <CaretDownIcon style={{ marginLeft: '8px' }} />
                </div>
              }
            >
              <DropdownI.Item>
                <div
                  className={css`
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 6px;
                    cursor: pointer;
                  `}
                  onClick={() => setRange('month')}
                >
                  <div
                    className={css`
                      width: 6px;
                      height: 6px;
                      border-radius: 50%;
                      background-color: ${theme.colors.accent['purple/main']};
                      margin-right: 4px;
                      margin-top: 1px;
                      visibility: ${range === 'month' ? 'visible' : 'hidden'};
                    `}
                  />
                  <Text.TextSmall2 marginBottom="4xs" marginTop="4xs">
                    30 days
                  </Text.TextSmall2>
                </div>
              </DropdownI.Item>

              <DropdownI.Item>
                <div
                  className={css`
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 6px;
                    cursor: pointer;
                  `}
                  onClick={() => setRange('week')}
                >
                  <div
                    className={css`
                      width: 6px;
                      height: 6px;
                      border-radius: 50%;
                      background-color: ${theme.colors.accent['purple/main']};
                      margin-right: 4px;
                      margin-top: 1px;
                      visibility: ${range === 'week' ? 'visible' : 'hidden'};
                    `}
                  />
                  <Text.TextSmall2 marginBottom="4xs" marginTop="4xs">
                    7 days
                  </Text.TextSmall2>
                </div>
              </DropdownI.Item>

              <DropdownI.Item>
                <div
                  className={css`
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 6px;
                    cursor: pointer;
                  `}
                  onClick={() => setRange('day')}
                >
                  <div
                    className={css`
                      width: 6px;
                      height: 6px;
                      border-radius: 50%;
                      background-color: ${theme.colors.accent['purple/main']};
                      margin-right: 4px;
                      margin-top: 1px;
                      visibility: ${range === 'day' ? 'visible' : 'hidden'};
                    `}
                  />
                  <Text.TextSmall2 marginBottom="4xs" marginTop="4xs">
                    1 day
                  </Text.TextSmall2>
                </div>
              </DropdownI.Item>
            </DropdownI.Root>
          </div>

          <ItemTitle Icon={HandWavingIcon} title="Feed" />
          <br />
          {Boolean(activityList?.length) && (
            <FeedHolder.Root>
              <div
                className={css`
                  width: 100%;
                  /* height: 280px; */
                  overflow-y: auto;

                  /* background-color: ${theme.colors.primary['primary/02']}; */
                  &::-webkit-scrollbar {
                    display: block;
                    width: 8px;
                    background-color: #2d2a41;
                  }

                  &::-webkit-scrollbar-thumb {
                    background: #8c83c3;
                    border-radius: 8px;
                  }
                `}
              >
                {newUpdates > 0 && (
                  <FeedSeparator
                    helperText={`${newUpdates}`}
                    text={previousDate(new Date().getTime())}
                    newUpdate
                  />
                )}
                {newUpdates === 0 && activityList[0]?.created && (
                  <FeedSeparator text={previousDate(activityList[0].created)} />
                )}
                {activityList?.map((item, idx) => (
                  <React.Fragment key={item._id}>
                    <Link
                      to={
                        item.topicId
                          ? `${ERouteName.Topic}${item.topicId}#${
                              item._id || ''
                            }`
                          : '#'
                      }
                      className={css`
                        /* text-decoration: none; */
                        all: unset;
                        cursor: pointer;
                      `}
                    >
                      {/* TODO -- the comment field should not contain topic name. Get the topic name from db instead */}
                      <FeedHolder.Item
                        Icon={renderIcon(item.data) || <div />}
                        title={
                          item.topicId
                            ? item.comment
                            : 'This is a private topic'
                        }
                        subTitle={new Date(item.created).toLocaleString()}
                        noFormat
                      />
                    </Link>

                    {new Date(item.created).getDate() !==
                      new Date(
                        activityList[
                          idx < activityList.length - 1 ? idx + 1 : idx
                        ]?.created
                      ).getDate() && (
                      <FeedSeparator
                        text={previousDate(
                          activityList[
                            idx < activityList.length - 1 ? idx + 1 : idx
                          ].created
                        )}
                      />
                    )}
                  </React.Fragment>
                ))}
                {activityList &&
                  activityData &&
                  activityList.length < activityData.totalActivity && (
                    <ActivityHolder.Item
                      Icon={
                        <ChevronDown
                          fill={theme.colors.primary['primary/05']}
                          style={{
                            width: '16px',
                            height: '16px',
                          }}
                        />
                      }
                      title="See more"
                      type={EActivityType.UNKNOWN}
                      last={true}
                      onClick={moreLoading ? undefined : loadMore}
                    />
                  )}
              </div>
            </FeedHolder.Root>
          )}
          {!activityList?.length && (
            <div
              className={css`
                width: 100%;
                height: 148px;
                display: inline-flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 16px;
                border: 1px dashed #453e69;
                margin-bottom: 16px;
              `}
            >
              <Text.TextSmall1 marginBottom="3xs">
                You daily activity from Multiplayer and other integrated apps
                will appear here.
              </Text.TextSmall1>
              <Text.TextSmall1 marginBottom="4xs" marginTop="4xs">
                This feed is populated automatically.
              </Text.TextSmall1>
            </div>
          )}
        </Box>
      </div>
    </Page>
  );
};
