/* eslint-disable no-useless-escape */
import { GoogleDocType, IUser } from 'model';
import Cookies from 'js-cookie';
import isNil from 'lodash/isNil';

export * from './request';
/**
 * Color from Id
 */
export function strToNum(str: string) {
  let num = 0;
  for (let i = 0; i < str?.length || 0; i++) {
    num = str.charCodeAt(i) + ((num << 5) - num);
  }
  return num;
}

export function color(str: string) {
  const num = strToNum(str);
  return `hsl(${~~(num % 360)},70%,70%)`;
}

/**
 * Local storage
 */
type Key =
  | 'refreshToken'
  | 'accessToken'
  | 'workspaceId'
  | 'defaultTheme'
  | 'currentTopic'
  | 'djibril'
  | 'firstTime';

export const getFromLocalStorage = (key: Key) => {
  return Cookies.get(key);
};

export const setToLocalStorage = (key: Key, data: string): void => {
  Cookies.set(key, data);
};

export const removeLocalStorage = (key: Key): void => {
  Cookies.remove(key);
};

/**
 * Short id
 */
export function shortId(topic: string): string {
  return `#${topic?.slice(topic.length - 4).toUpperCase()}`;
}

/**
 * the text entered will be capitalized
 */
export const capitalizeString = (text: string, onlyFirst = false) => {
  return text;
  // return onlyFirst
  //   ? text[0].toUpperCase() + text.substring(1)
  //   : text
  //       .split(' ')
  //       .map((element) => {
  //         return element.charAt(0).toUpperCase() + element.substring(1);
  //       })
  //       .join(' ');
};

type Params = Record<string, string>;

export function extractFromQuery<P extends object = Params>(query: string) {
  const result = {} as P;
  const str = query.startsWith('?') ? query.slice(1) : query;
  const parts = str.split('&');
  const pairs = parts.map((part) => part.split('='));

  for (const [key, value] of pairs) {
    const k = decodeURIComponent(key) as keyof P;
    const v = decodeURIComponent(value) as unknown as P[keyof P];
    result[k] = v;
  }

  return result;
}

export function displayUser(user: IUser) {
  return user?.firstName
    ? `${user.firstName} ${user?.lastName || ''}`
    : user && user?.businessEmail
    ? user!.businessEmail
    : null;
}

export const isProduction =
  process.env.NODE_ENV && process.env.NODE_ENV === 'production';

export const formatComment = (comment: string) => {
  const temp = JSON.parse(comment).map((item: any) => {
    if (item.type === 'paragraph') {
      const index = (item.children as any[]).findIndex(
        (el) => el.type === 'link' && el.children[0].text === ''
      );
      if (index >= 0) {
        return {
          type: 'paragraph',
          children: [{ text: '' }],
        };
      }
    }
    return item;
  });

  let canAdd = false;
  if (!temp) return [false, null];

  temp.forEach((p: any) => {
    canAdd =
      (JSON.stringify(p).split('"text":""').length < 2 &&
        JSON.stringify(p).split('"text":" "').length < 2) ||
      canAdd ||
      JSON.stringify(p).split('"type":"link"').length >= 2 ||
      JSON.stringify(p).split('"type":"topic-mention"').length >= 2 ||
      JSON.stringify(p).split('"type":"image"').length >= 2;
  });

  return [canAdd, temp];
};

/**
 * Remove duplicates from an array
 * @param items the array to remove duplicates from
 * @param fn  a function that returns true if the two items are duplicates
 * @returns   the array with duplicates removed
 */
export const unique = (items: any[], fn: Function) => {
  if (items.length === 0 || items.length === 1) {
    return items;
  }
  if (!fn) {
    return items;
  }

  for (let i = 0; i < items.length; i++) {
    for (let j = i + 1; j < items.length; j++) {
      if (fn(items[i], items[j])) {
        j = i;
        items.splice(i, 1);
      }
    }
  }
  return items;
};

export const formatURL = (url: string | null) => {
  const isCorrect =
    url?.toLowerCase().startsWith('https://') ||
    url?.toLowerCase().startsWith('http://');
  return isCorrect ? url : url ? 'https://' + url : '';
};

/**
 * Determines if a url points to a GoogleDoc
 */
export const isGoogleDoc = (url: string) => {
  return url.includes('docs.google.com');
};

export function isNotionUrl(url: string) {
  return url.includes('notion.so/');
}
export function isLinearLink(url: string) {
  return url.includes('https://linear.app/') && url.includes('/issue/');
}

export const isLikelyUrl = (url: string) => {
  const split = url.split('.');
  if (split.length <= 1 || !split[0]) return false;
  const ext = split[split.length - 1];
  const topLevelDomains = [
    'com',
    'org',
    'net',
    'int',
    'edu',
    'gov',
    'mil',
    'xyz',
    'fr',
    'ne',
    'be',
    'app',
    'top',
    'it',
    'uk',
    'de',
    'asia',
    'ru',
    'arpa',
    'academy',
    'aws',
    'accountant',
    'active',
    'adult',
    'aero',
    'africa',
    'ads',
    'amazon',
    'analytics',
    'apple',
    'art',
    'audible',
    'audio',
    'author',
    'auto',
    'bio',
    'biz',
    'blog',
    'book',
    'bot',
    'boutique',
    'buy',
    'buzz',
    'business',
    'business',
    'cab',
    'cam',
    'call',
    'car',
    'cars',
    'care',
    'career',
    'careers',
    'cash',
    'center',
    'ceo',
    'cern',
    'cfd',
    'channel',
    'chat',
    'charity',
    'city',
    'company',
    'community',
    'consulting',
    'contact',
    'dev',
    'docs',
    'drive',
    'eco',
    'engineer',
    'expert',
    'express',
    'family',
    'fly',
    'info',
    'jobs',
    'mov',
    'new',
    'news',
    'ong',
    'online',
    'page',
    'pay',
    'pro',
    'pub',
    'quebec',
    'sale',
    'scholarships',
    'shopping',
    'site',
    'storage',
    'tax',
    'tech',
    'technology',
    'travel',
    'website',
    'wiki',
    'works',
    'world',
    'wtf',
    'youtube',
    'you',
    'zero',
    'work',
  ];
  return topLevelDomains.includes(ext.toLowerCase());
};

export function extractUrlFromMarkdown(markdown: string) {
  const regex = /\[([^\]]+)\]\(([^\)]+)\)/g;

  // Extract link name and URL from the Markdown string
  const links: {
    name: string;
    url: string;
  }[] = [];
  let match = regex.exec(markdown);
  while (match !== null) {
    links.push({
      name: match[1],
      url: match[2],
    });
    match = regex.exec(markdown);
  }
  return links;
}

export function extractIdFromString(str: string) {
  const match = str.match(/[a-fA-F0-9]{24}/g);
  return match ? match[0] : null;
}

/**
 * Determine what type of GoogleDoc (Document, Spreadsheet, Presentation) a
 * url represents. return null if nothing matches.
 */
export function extractGoogleDocType(url: string) {
  if (url.includes('docs.google.com/presentation')) {
    return GoogleDocType.Presentation;
  }

  if (url.includes('docs.google.com/spreadsheets')) {
    return GoogleDocType.Sheets;
  }

  if (url.includes('docs.google.com/document')) {
    return GoogleDocType.Document;
  }

  if (url.includes('https://colab.research.google.com/')) {
    return GoogleDocType.Colab;
  }

  if (url.includes('https://drive.google.com/drive/folders')) {
    return GoogleDocType.Folder;
  }

  return null;
}

export const getDocIdFromUrl = (url: string) => {
  const splitted = url.split('/edit')[0].split('/');
  const output = splitted[splitted.length - 1].split('#')[0];
  return output;
};

export function valueFromString(str: string, max = 6) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    let char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to a 32-bit integer
  }

  const value = Math.abs(hash) % max;

  return value;
}

export const displayNumber = (number: number, max = 1000) => {
  // add + if number more than max
  if (number >= max) {
    return `${Math.floor(number / 1000)}k+`;
  }
  return number.toString();
};

export function secondsToMinutes(sec: number) {
  // Calculate minutes and remaining seconds
  let minutes = Math.floor(sec / 60);
  let seconds = (sec % 60).toFixed(0);

  // Convert minutes and seconds to strings and add leading zeros if needed
  let minutesStr = String(minutes).padStart(2, '0');
  let secondsStr = String(seconds).padStart(2, '0');

  // Return the time in mm:ss format
  return `${minutesStr}:${secondsStr}`;
}

// function findAndHighlightMatch(mainText, subText) {
//   const regex = new RegExp(subText.replaceAll(/\s+/g, '\\s+').replaceAll(/\W/g, '.'), "gi");
//   const matches = mainText.match(regex);

//   console.log(subText.replace(/\s+/g, '\\s+').replace(/\W/g, '.'))
//   if (matches && matches.length > 0) {
//     const highlightedText = mainText.replace(regex, `**$&**`);
//     return highlightedText;
//   } else {
//     return mainText;
//   }
// }

// // Example usage
// const mainText = "The quick brown fox jumps over the lazy dog.";
// const subText = "foxes are jumping";
// const highlightedText = findAndHighlightMatch(mainText, subText);
// console.log(highlightedText); // The quick brown <mark>fox jumps</mark> over the lazy dog.

interface QueryParams {
  [param: string]: string | undefined | null;
}

export function buildQuery(params: QueryParams) {
  const parts = Object.entries(params)
    .map(([k, v]) => {
      if (!isNil(v)) {
        return `${k}=${encodeURIComponent(v)}`;
      }
      return null;
    })
    .filter(Boolean);

  return '?' + parts.join('&');
}

export function googleAuthUrl(redirect: string) {
  const BASE_URL = 'https://accounts.google.com/o/oauth2/v2/auth';
  const SCOPES = [
    'https://www.googleapis.com/auth/drive.readonly',
    // 'https://www.googleapis.com/auth/drive.metadata.readonly',
    'https://www.googleapis.com/auth/gmail.readonly',
    'https://www.googleapis.com/auth/userinfo.email',
    // 'https://www.googleapis.com/auth/calendar.readonly',
    'https://www.googleapis.com/auth/calendar.events.readonly',
  ];
  const query = buildQuery({
    access_type: 'offline',
    prompt: 'consent',
    scope: SCOPES.join(' '),
    state: JSON.stringify({ redirect }),
    response_type: 'code',
    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    redirect_uri: `${process.env.REACT_APP_API_BASE_URI}/wh/google-callback`,
  });
  return BASE_URL + query;
}
