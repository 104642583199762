import { colors } from './colors';
import { textVariants } from './font';
import { spacing, borderWidth, borderRadius } from './sizes';

export const theme = {
  colors,
  textVariants,
  spacing,
  borderWidth,
  borderRadius,
};

export type Theme = typeof theme;
