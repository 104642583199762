import { css } from '@emotion/css';
import { Dialog, Text } from 'components';
import { GameOverIllustration1, GameOverIllustration2 } from 'components/Icons';
import { ERouteName } from 'pages';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { theme } from 'theme';
import { removeLocalStorage } from 'utils';

interface Props {
  open?: boolean;
  onOpenChange?(open: boolean): void;
}
export function LogoutDialog(props: Props) {
  const randomValue = Math.floor(Math.random() * 2);
  const navigate = useNavigate();
  const handleLogout = () => {
    removeLocalStorage('accessToken');
    removeLocalStorage('refreshToken');
    navigate(ERouteName.Login);
  };
  return (
    <Dialog
      noClose
      additionalContent={
        <div
          className={css`
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        >
          {randomValue < 1 ? (
            <GameOverIllustration1 />
          ) : (
            <GameOverIllustration2 style={{ marginBottom: '16px' }} />
          )}
          <div
            className={css`
              width: 60%;
              margin-bottom: 24px;
            `}
          >
            <Text.Body
              style={{
                color: theme.colors.primary['primary/05'],
                textAlign: 'center',
              }}
            >
              You are about to log out of Multiplayer. Are you sure?
            </Text.Body>
          </div>
          <button
            className={css`
              /* all: unset; */
              width: 360px;
              height: 46px;
              border-radius: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid ${theme.colors.accent['purple/main']};
              filter: drop-shadow(0px 4px 32px #cfa8ff);
              color: ${theme.colors.accent['purple/main']};
            `}
            onClick={handleLogout}
          >
            Quit
          </button>
          <Text.Body
            style={{
              color: theme.colors.primary['primary/05'],
              textAlign: 'center',
              opacity: 0.5,
              cursor: 'pointer',
            }}
            onClick={() => props.onOpenChange?.(false)}
          >
            Play Again
          </Text.Body>
        </div>
      }
      open={props.open}
      onOpenChange={props.onOpenChange}
    />
  );
}
