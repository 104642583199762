import styled from '@emotion/styled';
import { theme } from 'theme';

const ICON_SIZE = 42;
export const HEIGHT = 160;

export const ProfileInfoContainerBody = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ProfileImageContainer = styled.div`
  height: ${HEIGHT}px;
  width: ${HEIGHT}px;
  border-radius: ${HEIGHT}px;
  background-color: ${theme.colors.primary['primary/01']};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  margin-left: calc(50% - ${HEIGHT / 2}px);
  margin-top: 27px;
`;

export const ProfileImage = styled.img`
  height: 100%;
  width: 100%;
  background-color: ${theme.colors.primary['primary/03']};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InputContainer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease-out;
  &:hover {
    opacity: 1;
  }
`;

export const ImageInput = styled.input`
  width: 100%;
  height: 100%;
  &::-webkit-file-upload-button {
    visibility: hidden;
  }
  &::before {
    content: 'Click to upload';
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* border-radius: 50%; */
    outline: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    /* color: rgba(255, 255, 255, 0); */
    color: #fff;
    //   border: 1px solid black;
    background-color: #1c294896;
  }
`;

export const ProfileInfoItem = styled.div`
  margin-right: 10px;
  height: fit-content;
`;

export const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #fff;
  margin-right: 15px;
`;

export const Stats = styled.div`
  height: 53px;
  display: flex;
  flex-direction: row;
`;

export const Divider = styled.div`
  height: 95%;
  width: 4px;
  border-radius: 2px;
  background-color: #1c2948;
  margin-right: 15px;
`;

export const Body = styled.div`
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: 7%;
  margin-top: ${theme.spacing['3xl']}px;
  width: 93%;
  /* padding-inline: 30px; */
`;

export const Left = styled.div`
  width: 25%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* padding: 24px; */
  gap: 32px;
`;

export const Right = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 10%;
`;

export const TeamContainer = styled.div`
  width: 90%;
  height: 230px;
  margin: auto;
  background-color: #172342;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 20px;
`;

export const TeammateViewContainer = styled.div`
  width: 100%;
  height: 48px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
`;

export const Separator = styled.div`
  width: 2px;
  height: 100%;
  background-color: #172342;
  /* margin-left: ${ICON_SIZE / 2}px; */
  position: absolute;
  left: ${-(ICON_SIZE / 2 + 9)}px;
  top: 0px;
  z-index: -1;
`;

export const Inline = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

export const TopicSeparator = styled.div`
  width: 100%;
  /* height: 2px; */
  border-bottom: 2px solid ${theme.colors.accent['purple/light']};
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const IconSection = styled.div`
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
  background-color: ${(props) => props.color};
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const IconCardItemBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 95%;
  position: relative;
`;
export const IconCardText1 = styled.h3`
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 00px;
  margin-top: 0px;
  margin-right: 3px;
`;
export const IconCardText2 = styled.h4`
  font-size: 12px;
  color: #ffffff93;
  font-weight: 400;
  margin-top: 6px;
  margin-bottom: 0px;
`;
export const IconCardLink = styled.a`
  font-size: 16px;
  color: #3284fd;
  font-weight: 400;
  &:hover {
    text-decoration: underline;
  }
`;
