import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { PuzzlePieceIcon } from 'components/Icons';
import { Button, Text } from 'components';
import { css } from '@emotion/css';
import { theme } from 'theme';

/**
 * TODO - make this a custom component
 */

type Props = {
  onClick?: () => void;
  open?: boolean;
  onOpenChange?(newState: boolean): void;
};
export const Popup: React.FC<Props> = ({ onClick, onOpenChange, open }) => (
  <Dialog.Root open={open} onOpenChange={onOpenChange}>
    {/* <Dialog.Trigger asChild>
      <button className="Button violet">Edit profile</button>
    </Dialog.Trigger> */}
    <Dialog.Portal>
      <Dialog.Overlay
        className={css`
          @keyframes fadeIn {
            from {
              backdrop-filter: blur(0px);
              opacity: 0;
            }
            to {
              backdrop-filter: blur(2px);
              opacity: 1;
            }
          }
          animation: fadeIn 0.15s;
          backdrop-filter: blur(2px);
          background-color: rgba(0, 0, 0, 0.3);
          inset: 0;
          position: fixed;
          z-index: 999;
        `}
      />
      <Dialog.Content
        // className="DialogContent"
        className={css`
          @keyframes expand {
            0% {
              opacity: 0;
              transform: translateX(-50%) translateY(-50%) scale(0.9);
            }
            100% {
              opacity: 1;
              transform: translateX(-50%) translateY(-50%) scale(1);
            }
          }
          animation: expand 0.15s;
          background-color: #222032;
          border-radius: 16px;
          /* box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.8); */
          left: 50%;
          /* padding: 40px; */
          position: fixed;
          top: 50%;
          width: 264px;
          transform: translateX(-50%) translateY(-50%);
          z-index: 1000;
        `}
      >
        <div
          className={css`
            border-bottom: 1px solid ${theme.colors.accent['purple/light']};
            padding: 8px 16px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
          `}
        >
          <PuzzlePieceIcon
            fill={theme.colors.primary['primary/05']}
            style={{ width: '24px', height: '24px', marginRight: '8px' }}
          />
          <Dialog.Title
            className={css`
              color: ${theme.colors.text['text/100%']};
              font-size: 12px;
            `}
          >
            <Text.TextSmall2 marginBottom="4xs" marginTop="4xs">
              You’re missing a puzzle piece!
            </Text.TextSmall2>
          </Dialog.Title>
        </div>
        <Dialog.Description
          className={css`
            padding: 8px 16px;
            width: 100%;
          `}
        >
          <Text.TextSmall2 marginBottom="4xs" marginTop="4xs" color="text/75%">
            Integrate your apps to be able to track your file changes.
          </Text.TextSmall2>
        </Dialog.Description>
        {/* <fieldset className="Fieldset">
          <label className="Label" htmlFor="name">
            Name
          </label>
          <input className="Input" id="name" defaultValue="Pedro Duarte" />
        </fieldset>
        <fieldset className="Fieldset">
          <label className="Label" htmlFor="username">
            Username
          </label>
          <input className="Input" id="username" defaultValue="@peduarte" />
        </fieldset> */}
        <div
          className={css`
            padding: 8px 16px;
            margin-top: 24px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
          `}
        >
          <Dialog.Close asChild>
            <Button
              text="Connect"
              variant="contained"
              style={{ backgroundColor: '#1a1825', borderWidth: 0 }}
              onClick={onClick}
            />
          </Dialog.Close>
        </div>
        {/* <Dialog.Close asChild>
          <button className="IconButton" aria-label="Close">
            <Close />
          </button>
        </Dialog.Close> */}
      </Dialog.Content>
    </Dialog.Portal>
  </Dialog.Root>
);
