import { IUser } from 'model';
import { TTopicBasics } from 'pages/Topic/types';

export enum EFeedType {
  TOPIC_UPDATE = 'topicUpdate',
  TOPIC_IMPORTANT = 'topicImportant',
  TOPIC_CREATED = 'topicCreated',
  TOPIC_CLOSED = 'topicClosed',
  WORKSPACE_NEW_USER = 'workspaceNewUser',
  TOPIC_NEW_USER = 'topicNewUser',
  USER_LOGIN = 'userLogin',
  USER_LOGOUT = 'userLogout',
}

export type TFeed = {
  _id: string;
  topic?: TTopicBasics;
  workspace: {
    _id: string;
    name: string;
  };
  user?: IUser;
  type: EFeedType;
  activity?: string;
  closed?: boolean;
  count?: number;
  created: number;
};
