import { css } from '@emotion/css';
import { Text } from 'components/Text';
import React from 'react';
import { theme } from 'theme';

export type LabelProps = {
  text: string;
  color?: string;
  className?: string;
  darkBorder?: boolean;
};
export const LabelBox: React.FC<LabelProps> = ({
  text,
  color,
  className,
  darkBorder,
}) => {
  return (
    <div className={className}>
      <div
        className={css`
          border: 2px solid
            ${darkBorder
              ? theme.colors.primary['primary/01']
              : theme.colors.primary['primary/02']};
          border-radius: ${theme.borderRadius.l}px;
          width: fit-content;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 6px 10px;
        `}
      >
        <div
          className={css`
            background-color: ${color};
            width: 6px;
            height: 6px;
            border-radius: 3px;
            margin-right: 8px;
          `}
        />
        <Text.TextSmall2 marginBottom="4xs" marginTop="4xs">
          {text}
        </Text.TextSmall2>
      </div>
    </div>
  );
};
