type IntegrationData = {
  accessToken?: string;
  refreshToken?: string;
};

export enum ERoleScope {
  Workspace = 'workspace',
  Topic = 'topic',
}

export enum EUserRole {
  Owner = 'Owner',
  Viewer = 'Viewer',
}

export type TUserRole = {
  scope: ERoleScope;
  role: EUserRole;
  refId: string;
};

export interface IUser {
  _id: string;
  firstName?: string;
  lastName?: string;
  description?: string;
  role?: string;
  profileUrl?: string;
  organization?: string;
  workspace?: string;
  integrations?: {
    google?: IntegrationData;
    slack?: IntegrationData;
    notion?: IntegrationData;
  };
  privateData?: any;
  linkedinProfile?: string;
  githubProfile?: string;
  slackProfile?: string;
  email?: string;
  businessEmail: string;
  userRoles?: TUserRole[];
  created?: Date;
  updated?: Date;
  deleted?: Date;
  isAdmin?: boolean;
  isDisabled?: boolean;
  userHasGoogleAuth?: boolean;
  lastPodcast?: string;
}

export enum EUserAction {
  SET_USER,
  SET_CURRENT_EMAIL,
  CLEAR,
}
