import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Input,
  Switch,
  Text,
  // TextEditor,
  TextEditor,
} from 'components';
import styled from '@emotion/styled';
import { theme } from 'theme';
import { shortId } from 'utils';
import { Close, TrashIcon } from 'components/Icons';
import { useAppSelector } from 'hooks';
import { RootState } from 'reducers';

// const formatComment = (comment: string) => {
//   if (!comment) {
//     return { canAdd: true, temp: '' };
//   }
//   const temp = JSON.parse(comment).map((item: any) => {
//     if (item.type === 'paragraph') {
//       const index = (item.children as any[]).findIndex(
//         (el) => el.type === 'link' && el.children[0].text === ''
//       );
//       if (index >= 0) {
//         return {
//           type: 'paragraph',
//           children: [{ text: '' }],
//         };
//       }
//     }
//     return item;
//   });

//   let canAdd = false;
//   if (!temp) return { canAdd, temp };

//   // temp.forEach((p: any) => {
//   //   canAdd =
//   //     (JSON.stringify(p).split('"text":""').length < 2 &&
//   //       JSON.stringify(p).split('"text":" "').length < 2) ||
//   //     JSON.stringify(p).split('"type":"link"').length >= 2 ||
//   //     JSON.stringify(p).split('"type":"topic-mention"').length >= 2;
//   // });

//   canAdd = true;

//   return { canAdd, temp: JSON.stringify(temp) };
// };

const Section = styled.div`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-bottom: ${theme.spacing.s}px; */
`;

type Props = {
  // description?: string;
  // topicId: string;
  // topicName: string;
  workspaceName: string;
  loading?: boolean;
  // topicList?: { label: string; value: string }[];
  // closed: boolean;
  // isPublic: boolean;
  onSubmit: (name: string, description: string) => void;
  onCancel: VoidFunction;
  onDelete: VoidFunction;
  onClose: VoidFunction;
  handlePublicPrivate(newState: boolean): void;
};
export const TopicInfoEditFormxxxxx: React.FC<Props> = ({
  // description,
  workspaceName,
  // topicId,
  // topicName,
  loading,
  // closed,
  // isPublic,
  onCancel,
  onSubmit,
  onDelete,
  onClose,
  handlePublicPrivate,
}) => {
  const topicBasics = useAppSelector((state: RootState) => state.topic);

  const [newDescription, setNewDescription] = useState(
    topicBasics?.description || ''
  );
  const [name, setName] = useState(topicBasics?.name);

  const onNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  const onTopicSubmit = useCallback(() => {
    onCancel();
    onSubmit(name, newDescription);
  }, [name, newDescription, onCancel, onSubmit]);

  // const renderDescription = useMemo(
  //   () =>
  //     description ? (
  //       <TextEditor
  //         value={description}
  //         onValueChange={setNewDescription}
  //         placeholder={
  //           closed
  //             ? 'No description provided to this topic'
  //             : 'Add a description to the topic'
  //         }
  //         submitText="Update"
  //         mentions={topicList}
  //         // onSubmit={onTopicSubmit}
  //         loading={loading}
  //         readonly={closed}
  //         noBackground
  //       />

  //     ) : (
  //       <TextEditor
  //         value={newDescription}
  //         onValueChange={setNewDescription}
  //         placeholder={
  //           closed
  //             ? 'No description provided to this topic'
  //             : 'Add a description to the topic'
  //         }
  //         submitText="Update"
  //         mentions={topicList}
  //         // onSubmit={onTopicSubmit}
  //         loading={loading}
  //         readonly={closed}
  //         noBackground
  //       />
  //     ),
  //   [closed, description, loading, newDescription, topicList]
  // );

  return (
    <Section>
      <Header>
        <Text.A size="body-sm" color="text/light">
          {workspaceName}
        </Text.A>
        <Text.BodySM
          color="text/light"
          style={{ marginLeft: theme.spacing.xs }}
        >
          /
        </Text.BodySM>
        <Text.A
          size="body-sm"
          color="text/light"
          style={{ marginLeft: theme.spacing.xs }}
        >
          {topicBasics?.name}
        </Text.A>
      </Header>
      <Header>
        <Box
          noPadding
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box
            noPadding
            display="flex"
            flexDirection="row"
            alignItems="center"
            width="100%"
          >
            {topicBasics?.closed ? (
              <Text.H3>{name}</Text.H3>
            ) : (
              <Input
                value={name}
                placeholder="Enter topic name"
                onChange={onNameChange}
                background={theme.colors.primary['primary/02']}
                style={{
                  width: name.length * (name.length < 10 ? 15 : 12),
                  minWidth: '100px',
                  maxWidth: '600px',
                }}
              />
            )}
            <Text.H3
              color="text/blue/soft"
              // marginTop="4xs"
              style={{ marginLeft: theme.spacing.l }}
            >
              {shortId(topicBasics?._id)}
            </Text.H3>
          </Box>
          {topicBasics?.closed ? (
            <Text.Body>{topicBasics?.public ? 'Public' : 'Private'}</Text.Body>
          ) : (
            <div>
              <Switch
                label={topicBasics?.public ? 'Public' : 'Private'}
                checked={topicBasics?.public}
                onCheckedChange={handlePublicPrivate}
              />
            </div>
          )}
        </Box>
      </Header>
      <Box
        width="100%"
        // borderWidth={
        //   topicBasics?.closed || !topicBasics?.scope?.isAdmin ? '3xs' : 's'
        // }
        borderColor="primary/inactive"
        // borderStyle="solid"
        // borderRadius="m"
        noPadding
        style={{ overflow: 'hidden' }}
        marginTop="m"
      >
        {/* <Text.Body>{description}</Text.Body> */}
        <TextEditor
          placeholder="Enter some text..."
          value={newDescription}
          handleChange={setNewDescription}
          noBackground
          readonly={topicBasics?.closed}
        />
      </Box>
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
      >
        <Button
          text={topicBasics?.closed ? 'Reopen' : 'Mark as closed'}
          variant="contained"
          color="secondary"
          shape="round"
          onClick={onClose}
          loading={loading}
          Icon={Close}
          iconColor="white"
        />
        <Button
          text="Delete"
          variant="contained"
          color="secondary"
          shape="round"
          onClick={onDelete}
          loading={loading}
          style={{ marginLeft: theme.spacing.m }}
          Icon={TrashIcon}
          iconColor="white"
        />
        <Button
          text={topicBasics?.closed ? 'Cancel' : 'Discard'}
          variant="contained"
          color="tertiary"
          shape="round"
          onClick={onCancel}
          style={{ marginLeft: theme.spacing.m }}
          loading={loading}
        />
        {!topicBasics?.closed && (
          <Button
            text="Save changes"
            variant="contained"
            shape="round"
            style={{ marginLeft: theme.spacing.m }}
            onClick={onTopicSubmit}
            disabled={loading}
          />
        )}
      </Box>
    </Section>
  );
};
