import { ITopic } from 'features/topic';
import { IUser } from 'model';

export enum ENotificationType {
  MULTIPLAYER_USER_MENTION = 'multiplayerUserMention',
  SLACK_USER_MENTION = 'slackUserMention',
  SAVED_SLACK_MESSAGE = 'savedSlackMessage',
  GOOGLE_MENTION = 'googleMention',
  RESOLVED = 'resolved',
  ADDED_TO_TOPIC = 'addedToTopic',
}

export enum ENotificationCategory {
  INBOX = 'inbox',
  TODO = 'todo',
  DONE = 'done',
  ARCHIVED = 'archived',
}

export interface INotification {
  _id: string;
  type: ENotificationType;
  category: ENotificationCategory;
  created: Date;
  sender?: IUser;
  receiver: IUser;
  topic?: ITopic;
  activity?: any;
  archived?: Date;
  resolved?: Date;
  isRead?: boolean;
  link?: string;
  message?: string;
  google?: {
    docId: string;
    sender: string;
    message: string;
    link: string;
    title: string;
  };
}
