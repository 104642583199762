import React from 'react';
import * as CheckboxComp from '@radix-ui/react-checkbox';
import { Checkmark } from 'components/Icons';
import { Box, Text } from 'components';
import { css } from '@emotion/css';
import { theme } from 'theme';

type Props = {
  checked?: boolean;
  small?: boolean;
  label?: string;
  id?: string;
  onCheckChange?: (value: CheckboxComp.CheckedState) => void;
};
export const Checkbox: React.FC<Props> = ({
  checked,
  small,
  label,
  id,
  onCheckChange,
}) => {
  const uniqueId = id || Math.random().toString();
  return (
    <Box
      flexDirection="row"
      alignItems="center"
      noPadding
      // marginBottom="m"
      // marginRight="m"
      // backgroundColor="primary/inactive"
    >
      <CheckboxComp.Root
        id={uniqueId}
        checked={checked}
        onCheckedChange={onCheckChange}
        className={css`
          all: 'unset';
          background-color: ${checked
            ? theme.colors.accent['purple/main']
            : 'transparent'};
          width: ${small ? 12 : 16}px;
          height: ${small ? 12 : 16}px;
          border-radius: 4px;
          display: 'flex';
          align-items: 'center';
          justify-content: 'center';
          /* box-shadow: 0 2px 10px black; */
          border: 1.4px solid #8c83c3;
          border-radius: 4px;
          margin-right: 10px;
          position: relative;
          &:hover {
            /* background-color: ${theme.colors.accent.white}; */
          }
          &:focus {
            /* box-shadow: 0 0 0 2px ${theme.colors.accent.grey}; */
            /* background-color: ${theme.colors.accent.white}; */
          }
        `}
      >
        <CheckboxComp.Indicator>
          <Checkmark
            style={{
              width: small ? 10 : 18,
              height: small ? 10 : 18,
              position: 'absolute',
              top: 1,
              left: 0.6,
              // marginLeft: small ? -1 : 3,
              // marginTop: small ? -5 : 3,
            }}
          />
        </CheckboxComp.Indicator>
      </CheckboxComp.Root>
      <Text.Label
        color="text/light"
        marginBottom="4xs"
        marginTop="4xs"
        htmlFor={uniqueId}
        size="body-sm"
      >
        {label || ''}
      </Text.Label>
    </Box>
  );
};
