import { gql, useQuery } from '@apollo/client';
import { css } from '@emotion/css';
import { Box, Text } from 'components';
import { ArrowDownIcon, GoogleLogo } from 'components/Icons';
import React from 'react';
import { useParams } from 'react-router-dom';
import { displayNumber, displayUser } from 'utils';
import { MeetingRepartition } from './components/MeetingRepartition';
import { EmailStat } from './components/Email';
import { MeetingStats } from './components/Meeting';
import { MeetingHourRepartition } from './components/MeetingHourRepartition';
import { EmailStatsPerDay } from './components/EmailStatsPerDay';
import { WorkLifeBalance } from './components/WorkLifeBalance';
import { StatsItem } from './components/StatsItem';

const GET_STATS = gql`
  query CalendarStats($userId: ID!) {
    calendarStats(userId: $userId) {
      adHocMeetings
      averageMeetingsPerWeek
      externalMeeting
      internalMeeting
      meanFreeTime
      medianFreeTime
      meetingCount
      modeFreeTime
      oneOnOnes
      recurringMeetings
      teamMeetings
      totalDuration
    }
  }
`;

const GET_DRIVE_STATS = gql`
  query GDriveStats($userId: ID!) {
    gDriveStats(userId: $userId) {
      totalDriveFiles
      numberOfDocuments
      numberOfSheets
      numberOfSlides
      numberGoogleColabFiles
    }
  }
`;

// const GET_TOP_DOC = gql`
//   query TopKDocuments($userId: ID!) {
//     topKDocuments(userId: $userId) {
//       docId
//       title
//       type
//       url
//       revisions
//     }
//   }
// `;

const GET_TEMP_STAT = gql`
  query UserStats($userId: ID!) {
    userStats(userId: $userId) {
      lastName
      firstName
      businessEmail
      gmailStatsPerDay
      meetingDurationPerHour
      gmailStats
      meetingDurationPerDay
      calendarStats
      gDriveStats
    }
  }
`;

// type TTopKDocument = {
//   topKDocuments: {
//     docId: string;
//     title: string;
//     type: GoogleDocType;
//     url: string;
//     revisions: number;
//   }[];
// };

type DriveStats = {
  gDriveStats: {
    totalDriveFiles: number;
    numberOfDocuments: number;
    numberOfSheets: number;
    numberOfSlides: number;
    numberGoogleColabFiles: number;
  };
};

type CalendarStats = {
  calendarStats: {
    adHocMeetings?: number;
    averageMeetingsPerWeek?: number;
    externalMeeting?: number;
    internalMeeting?: number;
    meanFreeTime?: number;
    medianFreeTime?: number;
    meetingCount?: number;
    modeFreeTime?: number;
    oneOnOnes?: number;
    recurringMeetings?: number;
    teamMeetings?: number;
    totalDuration?: number;
  };
};

type Params = {
  userId: string;
};

export const Stats = () => {
  const { userId } = useParams<Params>();
  const [expand, setExpand] = React.useState(false);

  const { data: tempData } = useQuery(GET_TEMP_STAT, {
    variables: { userId },
  });

  const { data, loading } = useQuery<CalendarStats>(GET_STATS, {
    variables: { userId },
  });

  const { data: driveData, loading: driveLoading } = useQuery<DriveStats>(
    GET_DRIVE_STATS,
    {
      variables: { userId },
    }
  );

  // const { data: topData } = useQuery<TTopKDocument>(GET_TOP_DOC, {
  //   variables: { userId },
  // });

  const calendarStats =
    loading && !data?.calendarStats
      ? tempData?.userStats?.calendarStats
        ? JSON.parse(tempData.userStats?.calendarStats)
        : {}
      : data?.calendarStats;

  const gDriveStats =
    driveLoading && !driveData?.gDriveStats
      ? tempData?.userStats?.gDriveStats
        ? JSON.parse(tempData?.userStats?.gDriveStats)
        : {}
      : driveData?.gDriveStats;

  // if (loading) return <PageLoading />;
  return (
    <div
      className={css`
        width: fit-content;
        /* margin: auto; */
        /* text-align: center; */
        /* padding: 30px; TODO */
        position: relative;
      `}
    >
      <div
        className={css`
          position: absolute;
          top: -40px;
          left: -40px;
          width: 110%;
          height: 120%;
          background-color: #171425d7;
          z-index: 100;
          display: flex;
          justify-content: center;
          align-items: center;
          display: none;
        `}
      >
        <Text.H2>This page is coming soon 🚧</Text.H2>
      </div>
      <Text.H1 color="text/main">Hi 👋🏾</Text.H1>

      <Text.H1 color="text/main" marginBottom="l">
        Track stats for {displayUser(tempData?.userStats)}
      </Text.H1>

      <div
        className={css`
          display: flex;
          flex-direction: row;
          margin-bottom: 15px;
        `}
      >
        {userId && <WorkLifeBalance userId={userId} />}
        {userId && (
          <MeetingRepartition
            userId={userId}
            placeholder={tempData?.userStats?.meetingDurationPerDay}
          />
        )}
      </div>

      <div
        className={css`
          display: flex;
          flex-direction: row;
          margin-bottom: 15px;
        `}
      >
        {userId && (
          <EmailStatsPerDay
            userId={userId}
            placeholder={tempData?.userStats?.gmailStatsPerDay}
          />
        )}
        {userId && (
          <MeetingHourRepartition
            userId={userId}
            placeholder={tempData?.userStats?.meetingDurationPerHour}
          />
        )}
      </div>

      <Box
        display="flex"
        width="100%"
        justifyContent="center"
        alignItems="center"
        marginTop="2xl"
        marginBottom="2xl"
      >
        <span
          className={css`
            height: 32px;
            border-radius: 53px;
            border: 1px solid #dfe0f1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding-inline: 16px;
            cursor: pointer;
          `}
          onClick={() => setExpand(!expand)}
        >
          {expand ? 'Hide' : 'See'} details
          {expand ? (
            <ArrowDownIcon style={{ marginLeft: '8px' }} />
          ) : (
            <ArrowDownIcon style={{ marginLeft: '8px' }} />
          )}
        </span>
      </Box>

      {expand && (
        <div
          className={css`
            display: flex;
            flex-direction: row;
          `}
        >
          {userId && (
            <EmailStat
              userId={userId}
              placeholder={tempData?.userStats?.gmailStats}
            />
          )}
          <MeetingStats
            values={[
              {
                label: 'Scheduled',
                value: calendarStats?.meetingCount || 0,
              },
              {
                label: 'Recurring',
                value: calendarStats?.recurringMeetings || 0,
              },
              {
                label: 'Total hours',
                value: (calendarStats?.totalDuration || 0) + 'h',
              },
              {
                label: 'Team meetings',
                value: calendarStats?.teamMeetings || 0,
              },
              {
                label: '1:1 meetings',
                value: calendarStats?.oneOnOnes || 0,
              },
              {
                label: 'AVG per week',
                value: calendarStats?.averageMeetingsPerWeek || 0,
              },
              {
                label: 'Internal',
                value: calendarStats?.internalMeeting || 0,
              },
              {
                label: 'External',
                value: calendarStats?.externalMeeting || 0,
              },
              {
                label: 'AdHoc meetings',
                value: calendarStats?.adHocMeetings || 0,
              },
            ]}
          />

          <div>
            <StatsItem
              text="Drive files"
              background="primary/11"
              Icon={GoogleLogo}
              values={[
                {
                  label: 'Documents',
                  value: gDriveStats?.numberOfDocuments || 0,
                },
                {
                  label: 'Sheets',
                  value: gDriveStats?.numberOfSheets || 0,
                },
                {
                  label: 'Presentations',
                  value: gDriveStats?.numberOfSlides || 0,
                },
                {
                  label: 'Google Colab',
                  value: gDriveStats?.numberGoogleColabFiles || 0,
                },
                {
                  label: 'All files',
                  value: displayNumber(gDriveStats?.totalDriveFiles || 0),
                },
              ]}
            />
          </div>
        </div>
      )}
    </div>
  );
};
