import React, { useState } from 'react';
import * as RadixAccordion from '@radix-ui/react-accordion';
import { css } from '@emotion/css';
import { theme, Theme } from 'theme';
import { ChevronDown, ChevronUpIcon } from 'components/Icons';
import { Text } from 'components';

type Props = {
  backgroundColor?: keyof Theme['colors']['primary'];
  width?: string;
  height?: string;
  margin?: keyof Theme['spacing'];
  marginLeft?: keyof Theme['spacing'];
  marginRight?: keyof Theme['spacing'];
  marginTop?: keyof Theme['spacing'];
  marginBottom?: keyof Theme['spacing'];
  items: {
    value: string;
    title: string;
    length: number;
    body: JSX.Element;
  }[];
  defaultValue?: string;
};
export const Accordion: React.FC<Props> = ({
  width,
  height,
  backgroundColor,
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  defaultValue,
  items,
}) => {
  const [value, setValue] = useState(defaultValue);
  return (
    <RadixAccordion.Root
      type="single"
      collapsible
      value={value}
      onValueChange={setValue}
      className={css`
        background-color: ${backgroundColor
          ? theme.colors.primary[backgroundColor]
          : 'transparent'};
        border-radius: ${theme.borderRadius.l}px;
        padding: ${theme.spacing['2xs']}px ${theme.spacing.m}px;
        color: ${theme.colors.text['text/100%']};
        height: ${height || 'fit-content'};
        width: ${width || 'fit-content'};
        margin: ${theme.spacing[margin || '4xs']}px;
        margin-top: ${theme.spacing[marginTop || '4xs']}px;
        margin-bottom: ${theme.spacing[marginBottom || '4xs']}px;
        margin-left: ${theme.spacing[marginLeft || '4xs']}px;
        margin-right: ${theme.spacing[marginRight || '4xs']}px;
      `}
    >
      {items.map((item) => (
        <RadixAccordion.Item key={item.value} value={item.value}>
          <RadixAccordion.Header
            className={css`
              margin-bottom: 15px;
            `}
          >
            <RadixAccordion.Trigger
              className={css`
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
              `}
            >
              {value === item.value ? (
                <ChevronDown
                  fill={theme.colors.text['text/100%']}
                  style={{ marginRight: '8px', width: '12px' }}
                />
              ) : (
                <ChevronUpIcon
                  fill={theme.colors.text['text/100%']}
                  style={{ marginRight: '8px', width: '12px' }}
                />
              )}

              <Text.TextSmall2 marginBottom="4xs" marginTop="4xs">
                {item.title}
              </Text.TextSmall2>
              <Text.TextSmall2
                marginBottom="4xs"
                marginTop="4xs"
                color="text/menu"
                style={{ marginLeft: '8px' }}
              >
                {item.length}
              </Text.TextSmall2>
            </RadixAccordion.Trigger>
          </RadixAccordion.Header>
          <RadixAccordion.Content>{item.body}</RadixAccordion.Content>
        </RadixAccordion.Item>
      ))}
    </RadixAccordion.Root>
  );
};
