import { Popup } from 'components';
import { ERouteName } from 'pages';
import { useNavigate } from 'react-router-dom';

interface Props {
  open?: boolean;
  onOpenChange?(open: boolean): void;
}

export function IntegrationDialog(props: Props) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(ERouteName.IntegrationSetting);
  };
  return (
    <Popup
      onClick={handleClick}
      // additionalContent={
      //   <Box noPadding width="100%" backgroundColor="primary/05">
      //     <Text.TextSmall1 color="text/75%" marginTop="2xs" marginBottom="4xs">
      //       Tips: Integrate Google and Notion to be able to track your file
      //       changes
      //     </Text.TextSmall1>
      //     <Text.A
      //       href={ERouteName.IntegrationSetting}
      //       size="text-small-1"
      //       color="text/link"
      //     >
      //       Add your integrations from here
      //     </Text.A>
      //   </Box>
      // }
      open={props.open}
      onOpenChange={props.onOpenChange}
    />
  );
}
