import React, { useMemo } from 'react';
import styled from '@emotion/styled';

import { Theme, theme } from 'theme';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
`;

type RowProps = {
  justifyContent?: 'space-around' | 'flex-start' | 'flex-end' | 'space-between';
};
const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  align-items: center;
`;
const Col = styled.div`
  padding: ${theme.spacing.m}px;
`;

type BoxProps = React.HTMLAttributes<HTMLDivElement> & {
  backgroundColor?: keyof Theme['colors']['primary'];
  borderWidth?: keyof Theme['borderWidth'];
  borderColor?: keyof Theme['colors']['primary'];
  borderStyle?: 'dashed' | 'dotted' | 'double' | 'solid' | 'none';
  borderRadius?: keyof Theme['borderRadius'];
  padding?: keyof Theme['spacing'];
  margin?: keyof Theme['spacing'];
  marginLeft?: keyof Theme['spacing'];
  marginRight?: keyof Theme['spacing'];
  marginTop?: keyof Theme['spacing'];
  marginBottom?: keyof Theme['spacing'];
  display?: 'flex' | 'block' | 'grid' | 'inline-block';
  flexDirection?: 'row' | 'column' | 'column-reverse' | 'row-reverse';
  width?: string;
  height?: string;
  justifyContent?:
    | 'baseline'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  alignItems?: 'baseline' | 'center' | 'flex-start' | 'flex-end';
  noPadding?: boolean;
  position?: 'relative' | 'absolute' | 'fixed';
};
export const Box: React.FC<BoxProps> = ({
  children,
  alignItems,
  backgroundColor,
  borderColor,
  borderRadius,
  borderStyle,
  borderWidth,
  display,
  flexDirection,
  justifyContent,
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  width,
  height,
  noPadding,
  padding,
  position,
  ...props
}) => {
  const BoxComponent = useMemo(
    () => styled.div`
      padding: ${noPadding ? 0 : theme.spacing[padding || 's']}px
        ${noPadding ? 0 : theme.spacing[padding || '2xl']}px
        ${noPadding ? 0 : theme.spacing[padding || 'l']}px
        ${noPadding ? 0 : theme.spacing[padding || '2xl']}px;
      position: ${position ? position : ''};
      color: ${theme.colors.text['text/main']};
      height: ${height || 'fit-content'};
      width: ${width || 'fit-content'};
      background-color: ${backgroundColor
        ? theme.colors.primary[backgroundColor]
        : 'transparent'};
      border-color: ${theme.colors.primary[borderColor || 'primary/inactive']};
      border-radius: ${theme.borderRadius[borderRadius || 'xs']}px;
      border-style: ${borderStyle || 'none'};
      border-width: ${theme.borderWidth[borderWidth || '3xs']}px;
      display: ${display || 'flex'};
      flex-direction: ${flexDirection || 'column'};
      justify-content: ${justifyContent || 'flex-start'};
      align-items: ${alignItems || 'flex-start'};
      margin: ${theme.spacing[margin || '4xs']}px;
      margin-top: ${theme.spacing[marginTop || '4xs']}px;
      margin-bottom: ${theme.spacing[marginBottom || '4xs']}px;
      margin-left: ${theme.spacing[marginLeft || '4xs']}px;
      margin-right: ${theme.spacing[marginRight || '4xs']}px;
    `,
    [
      alignItems,
      backgroundColor,
      borderColor,
      borderRadius,
      borderStyle,
      borderWidth,
      display,
      flexDirection,
      height,
      justifyContent,
      margin,
      marginBottom,
      marginLeft,
      marginRight,
      marginTop,
      noPadding,
      padding,
      position,
      width,
    ]
  );
  return <BoxComponent {...props}>{children}</BoxComponent>;
};

export const Layout = {
  Grid,
  Row,
  Col,
};
