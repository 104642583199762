import { gql } from '@apollo/client';

export const GET_NOTIFICATIONS = gql`
  query Notifications($category: String!, $page: Int) {
    notifications(category: $category, page: $page) {
      _id
      activity {
        _id
      }
      topic {
        _id
        name
      }
      created
      category
      isRead
      sender {
        _id
        businessEmail
        firstName
        lastName
        role
      }
      type
      link
      google {
        sender
        title
        link
      }
    }
  }
`;

export const UPDATE_NOTIFICATIONS = gql`
  mutation Mutation($id: ID!, $newCategory: String!) {
    changeNotificationCategory(id: $id, newCategory: $newCategory)
  }
`;

export const MARK_AS_READ = gql`
  mutation Mutation($id: ID!) {
    markNotificationAsRead(id: $id)
  }
`;

export const GET_COMMENT = gql`
  query CommentWithReplies($commentId: ID!) {
    commentWithReplies(commentId: $commentId) {
      _id
      comment
      created
      data
      deleted
      deletedBy {
        _id
        email
        businessEmail
        firstName
        lastName
      }
      topicId
      updated
      updatedBy {
        _id
        businessEmail
        firstName
        lastName
      }
      workspaceId
      userId {
        _id
        businessEmail
        lastName
        firstName
      }
    }
  }
`;

export const UNREAD_NOTIFICATIONS = gql`
  query unreadNotificationCount {
    unreadNotificationCount
  }
`;

export const REPLY_COMMENTS = gql`
  mutation replyComment($commentId: ID!, $comment: String!) {
    replyToComment(commentId: $commentId, comment: $comment) {
      comment
      data
      _id
      created
      deleted
      updated
      workspaceId
      mentionWithNoAccess
      userId {
        _id
        firstName
        lastName
        businessEmail
      }
    }
  }
`;
