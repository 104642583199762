import { css } from '@emotion/css';
import { Button, Input, Text } from 'components';
import {
  CalendarBlankIcon,
  SpinnerGapIcon,
  UserIcon,
  WaveSineIcon,
  XCircleIcon,
} from 'components/Icons';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { theme } from 'theme';
import { ESort, ETopicFilter, FilterStatusType } from './types';
import { extractFromQuery } from 'utils';
import { EMomentumType } from 'model';
import { FilterDropdown } from './FilterDropdown';
import { SortByDropdown } from './SortByDropdown';

type Params = {
  users: string;
};

type FilterProps = {
  filterSelected: ETopicFilter | undefined;
  setFilterSelected: React.Dispatch<
    React.SetStateAction<ETopicFilter | undefined>
  >;
  setStatusFilter: React.Dispatch<React.SetStateAction<FilterStatusType>>;
  statusFilter: FilterStatusType;
  setUserFilter: React.Dispatch<React.SetStateAction<string[]>>;
  userFilter: string[];
  setMomentumFilter: React.Dispatch<
    React.SetStateAction<EMomentumType | undefined>
  >;
  momentumFilter: EMomentumType | undefined;
  members: {
    userId: string;
    profilePicture: string | undefined;
    email: string;
    role: string | undefined;
    name: string;
  }[];
  startDate?: Date;
  endDate?: Date;
  sortBy: ESort;
  topicLoading: boolean;
  onStartDateChange(date: Date): void;
  onEndDateChange(date: Date): void;
  onReset(type?: ETopicFilter): void;
  handleSortByChange: (newState: ESort) => void;
  onSearQueySubmit: (query: string) => void;
};
export const FilterSection: React.FC<FilterProps> = ({
  filterSelected,
  statusFilter,
  userFilter,
  momentumFilter,
  members,
  startDate,
  endDate,
  sortBy,
  topicLoading,
  setUserFilter,
  setMomentumFilter,
  setFilterSelected,
  setStatusFilter,
  onEndDateChange,
  onStartDateChange,
  handleSortByChange,
  onReset,
  onSearQueySubmit,
}) => {
  const onSelect = (value?: ETopicFilter) => setFilterSelected(value);

  const filterDetails = (type: ETopicFilter) => {
    switch (type) {
      case ETopicFilter.DATE:
        return {
          label:
            (startDate ? format(startDate, 'MMMM d, yyyy') : '') +
            ' - ' +
            (endDate ? format(endDate, 'MMMM d, yyyy') : ''),
          Icon: CalendarBlankIcon,
        };

      case ETopicFilter.MOMENTUM:
        return {
          label: momentumFilter,
          Icon: WaveSineIcon,
        };

      case ETopicFilter.PEOPLE:
        return {
          label: userFilter
            .map(
              (curr) => members.find((user) => user.userId === curr)?.name || ''
            )
            .join(', '),
          Icon: UserIcon,
        };

      case ETopicFilter.STATUS:
        return {
          label:
            statusFilter === 'opened'
              ? 'Active'
              : statusFilter === 'closed'
              ? 'Closed'
              : 'All',
          Icon: SpinnerGapIcon,
        };

      default:
        return {
          label: '',
          Icon: CalendarBlankIcon,
        };
    }
  };

  // const { state: userState } = useContext(UserContext);

  const [filters, setFilters] = useState<ETopicFilter[]>([]);
  // const [filterName, setFilterName] = useState('');
  // const [savedFilters, setSavedFilters] = useState<TTopicFilter[]>([]);
  // const [updatedFilterName, setUpdatedFilterName] = useState('');
  // const [filterEditMode, setFilterEditMode] = useState('');
  // const [filterHover, setFilterHover] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const handleFilterClick = (type: ETopicFilter) => {
    setFilters((prev) => {
      const index = prev.findIndex((e) => e === type);
      if (index < 0) {
        return [...prev, type];
      } else {
        return prev;
      }
    });
  };

  const handleFilterRemove = (type: ETopicFilter) => {
    setFilters((prev) => prev.filter((el) => el !== type));
  };

  useEffect(() => {
    const { users } = extractFromQuery<Params>(window.location.search);
    if (users && users.split(',').length > 0) {
      onReset();
      setUserFilter(users.split(','));
      handleFilterClick(ETopicFilter.PEOPLE);
    }
  }, [onReset, setUserFilter]);

  // const loadFilter = useCallback(
  //   (id: string) => () => {
  //     const filter = savedFilters.find((f) => f._id === id);
  //     if (filter) {
  //       onReset();
  //       if (filter.participants && filter.participants.length > 0) {
  //         setUserFilter(filter.participants);
  //         handleFilterClick(ETopicFilter.PEOPLE);
  //       }
  //       if (filter.momentum) {
  //         setMomentumFilter(filter.momentum);
  //         handleFilterClick(ETopicFilter.MOMENTUM);
  //       }
  //       if (filter.status) {
  //         setStatusFilter(filter.status);
  //         handleFilterClick(ETopicFilter.STATUS);
  //       }
  //       if (filter.startDate) {
  //         onEndDateChange(filter.startDate);
  //         handleFilterClick(ETopicFilter.DATE);
  //       }
  //       if (filter.endDate) {
  //         onStartDateChange(filter.endDate);
  //         handleFilterClick(ETopicFilter.DATE);
  //       }
  //     }
  //   },
  //   [
  //     onEndDateChange,
  //     onReset,
  //     onStartDateChange,
  //     savedFilters,
  //     setMomentumFilter,
  //     setStatusFilter,
  //     setUserFilter,
  //   ]
  // );

  // const [saveFilter] = useMutation<{
  //   saveFilter: TTopicFilter;
  // }>(SAVE_FILTER);

  // const [updateFilter] = useMutation(UPDATE_SAVED_FILTER);

  // const [deleteFilter] = useMutation(DELETE_SAVED_FILTER);

  // const [fetchSavedFilter] = useLazyQuery<{ topicFilters: TTopicFilter[] }>(
  //   GET_SAVED_FILTER
  // );

  // useEffect(() => {
  //   if (userState?._id) {
  //     fetchSavedFilter().then((res) => {
  //       if (res.data?.topicFilters && res.data?.topicFilters.length > 0) {
  //         setSavedFilters(res.data.topicFilters);
  //       }
  //     });
  //   }
  // }, [fetchSavedFilter, userState?._id]);

  // const handleFilterUpdate = useCallback(
  //   (id: string) => () => {
  //     updateFilter({
  //       variables: {
  //         id,
  //         name: updatedFilterName,
  //       },
  //     }).then(() => {
  //       setSavedFilters((prev) => {
  //         const index = prev.findIndex((e) => e._id === id);
  //         const res = [...prev];
  //         res.splice(index, 1, {
  //           ...prev[index],
  //           name: updatedFilterName,
  //         });
  //         setUpdatedFilterName('');
  //         return res;
  //       });
  //       setFilterEditMode('');
  //     });
  //   },
  //   [updateFilter, updatedFilterName]
  // );

  // const handleFilterEditMode = useCallback(
  //   (id: string) => () => {
  //     setFilterEditMode(id);
  //     setUpdatedFilterName(savedFilters.find((f) => f._id === id)?.name || '');
  //   },
  //   [savedFilters]
  // );

  // const handleFilterDelete = useCallback(
  //   (id: string) => () => {
  //     deleteFilter({
  //       variables: {
  //         id,
  //       },
  //     }).then(() => {
  //       setSavedFilters((prev) => prev.filter((e) => e._id !== id));
  //     });
  //   },
  //   [deleteFilter]
  // );

  // const handleFilterSave = useCallback(() => {
  //   if (filterName && userState?._id && userState?.workspace) {
  //     saveFilter({
  //       variables: {
  //         name: filterName,
  //         user: userState?._id,
  //         endDate: endDate,
  //         momentum: momentumFilter,
  //         participants: userFilter,
  //         startDate: startDate,
  //         status: statusFilter,
  //       },
  //     }).then((res) => {
  //       if (res?.data?.saveFilter?._id) {
  //         setSavedFilters((prev) => [res?.data?.saveFilter!, ...prev]);
  //         setFilterName('');
  //       }
  //     });
  //   }
  // }, [
  //   endDate,
  //   filterName,
  //   momentumFilter,
  //   saveFilter,
  //   startDate,
  //   statusFilter,
  //   userFilter,
  //   userState?._id,
  //   userState?.workspace,
  // ]);

  const filterProps = {
    filterSelected,
    onSelect,
    startDate,
    onStartDateChange,
    handleFilterClick,
    endDate,
    onEndDateChange,
    members,
    setUserFilter,
    userFilter,
    setStatusFilter,
    statusFilter,
    setMomentumFilter,
    momentumFilter,
  };

  // const saveFilterProps = {
  //   savedFilters,
  //   filterEditMode,
  //   updatedFilterName,
  //   setUpdatedFilterName,
  //   handleFilterUpdate,
  //   setFilterHover,
  //   loadFilter,
  //   filterHover,
  //   handleFilterEditMode,
  //   handleFilterDelete,
  //   filterName,
  //   setFilterName,
  //   handleFilterSave,
  // };

  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-top: 2px;
      `}
    >
      <div
        className={css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}
      >
        {/* <SearchDropdown /> */}

        <FilterDropdown {...filterProps} />

        {/* <SaveFilterDropdown {...saveFilterProps} /> */}

        {filters.map((item) => {
          const { Icon, label } = filterDetails(item);
          return label ? (
            <div
              key={item}
              className={css`
                height: 32px;
                border-radius: 16px;
                background-color: ${theme.colors.primary['primary/02']};
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-bottom: 8px;
                padding-inline: 16px;
                margin-left: 8px;
              `}
            >
              <Icon
                fill={theme.colors.text['text/blue/soft']}
                style={{ width: '16px', marginRight: '10px' }}
              />
              <Text.TextSmall1 marginBottom="4xs" marginTop="4xs">
                {label}
              </Text.TextSmall1>
              <XCircleIcon
                fill={theme.colors.text['text/blue/soft']}
                style={{
                  width: '16px',
                  marginLeft: '10px',
                  opacity: 0.5,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  onSelect(undefined);
                  // setFilterSelected(ETopicFilter.DATE);
                  setStatusFilter('opened');
                  onReset(item);
                  handleFilterRemove(item);
                }}
              />
            </div>
          ) : (
            <div />
          );
        })}
      </div>

      <form
        className={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          display: none;
        `}
        onSubmit={(e) => {
          e.preventDefault();
          onSearQueySubmit?.(searchQuery);
        }}
      >
        <Input
          placeholder="Describe topics that may interest you here"
          width="350px"
          name="searchQuery"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
        />

        <Button
          text="Submit"
          type="submit"
          style={{ marginLeft: '8px' }}
          loading={topicLoading}
        />
      </form>

      <div style={{ display: 'none' }}>
        <SortByDropdown
          sortBy={sortBy}
          handleSortByChange={handleSortByChange}
        />
      </div>
    </div>
  );
};
