import React from 'react';
import { Box, Page, PageLoading, Text } from 'components';
import { css } from '@emotion/css';
import { useQuery } from '@apollo/client';
import { displayUser } from 'utils';
import { Collaborator } from './types';
import { GET_COLLABORATORS } from './gql';
import { Search } from './components/Search';
import { Card } from './components/Card';

export const People = () => {
  // const navigate = useNavigate();

  // const { state: userState } = useContext(UserContext);

  const { data, loading } = useQuery<Collaborator>(GET_COLLABORATORS, {
    fetchPolicy: 'cache-first',
  });

  // const [blockUser, { loading: blockUserLoading }] = useMutation(DISABLE_USER);

  // const goToProfile = (userId: string) => () => {
  //   navigate(ERouteName.Profile + userId);
  // };

  // const handleBlockUser =
  //   (userId: string) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  //     e.stopPropagation();
  //     e.preventDefault();
  //     if (blockUserLoading) return;
  //     blockUser({
  //       variables: {
  //         userId,
  //       },
  //     }).then((re) => {
  //       if (re.data?.disableUser) {
  //         refetch();
  //       }
  //     });
  //   };

  return (
    <Page title="People | Multiplayer">
      <Box
        noPadding
        width="100%"
        // height="80vh"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
      >
        <br />
        <Box marginTop="2xl" noPadding>
          <Text.H1>Find all your teammates here</Text.H1>
        </Box>

        <Box
          noPadding
          marginTop="2xl"
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <Search />
        </Box>

        {loading ? (
          <PageLoading />
        ) : (
          <div
            className={css`
              width: 100%;
              display: block;
            `}
          >
            {data?.collaborators
              ?.filter((user) => !user.isDisabled)
              ?.map((user) => (
                <Card
                  key={user._id}
                  id={user._id}
                  name={displayUser(user as any)!}
                  role={user.role || 'No role defined'}
                  profilePicture={user.profileUrl}
                />
              ))}
            {/* <Table>
              <THead>
                <Header>
                  <Th>
                    <Text.TextSmall1 color="text/50%">User</Text.TextSmall1>
                  </Th>
                  <Th></Th>
                  <Th>
                    <Text.TextSmall1 color="text/50%">Role</Text.TextSmall1>
                  </Th>
                  <Th>
                    <Text.TextSmall1 color="text/50%">Activity</Text.TextSmall1>
                  </Th>
                  {userState?.isAdmin && <Th></Th>}
                </Header>
              </THead>
              <TBody>
                {data?.collaborators?.map((user) =>
                  user.momentumForUser ? (
                    <Tr
                      key={user._id}
                      onClick={goToProfile(user._id)}
                      style={{ opacity: user.isDisabled ? 0.5 : 1 }}
                    >
                      <Td
                        className={css`
                          border-radius: 24px 0px 0px 24px;
                        `}
                      >
                        <ProfileIcon
                          userId={user._id}
                          userName={displayUser(user as any) || ''}
                          profilePicture={user.profileUrl}
                        />
                      </Td>
                      <Td>
                        <Text.Body>{displayUser(user as any)}</Text.Body>
                      </Td>
                      <Td>
                        <Text.Body>{user.role || 'No role'}</Text.Body>
                      </Td>
                      <Td
                        className={
                          userState?.isAdmin
                            ? ''
                            : css`
                                border-radius: 0px 24px 24px 0px;
                              `
                        }
                      >
                        <div
                          className={css`
                            width: 155px;
                            height: 12px;
                            border-radius: 6px;
                            background-color: #2d2a41;
                            overflow: hidden;
                          `}
                        >
                          <div
                            className={css`
                              width: ${(user.momentumForUser.count * 100) /
                              20}%;
                              height: 100%;
                              border-radius: 6px;
                              background-color: ${theme.colors.accent[
                                'purple/main'
                              ]};
                            `}
                          />
                        </div>
                      </Td>
                      {userState?.isAdmin && (
                        <Td
                          className={css`
                            border-radius: 0px 24px 24px 0px;
                          `}
                        >
                          <div
                            id="block-user"
                            className={css`
                              width: 24px;
                              height: 24px;
                              border-radius: 50%;
                              background-color: ${theme.colors.accent[
                                user.isDisabled ? 'green/main' : 'red/main'
                              ]};
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              cursor: ${blockUserLoading
                                ? 'not-allowed'
                                : 'pointer'};
                            `}
                            onClick={handleBlockUser(user._id)}
                          >
                            {user.isDisabled ? (
                              <CheckIcon
                                fill={theme.colors.text['text/100%']}
                                style={{ width: '12px', height: '12px' }}
                              />
                            ) : (
                              <Close
                                fill={theme.colors.text['text/100%']}
                                style={{ width: '12px', height: '12px' }}
                              />
                            )}
                          </div>
                        </Td>
                      )}
                    </Tr>
                  ) : null
                )}
              </TBody>
            </Table> */}
          </div>
        )}
      </Box>
    </Page>
  );
};
