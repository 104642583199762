import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  CreateTopic,
  ErrorBoundary,
  Page,
  PageLoading,
  TextEditor,
} from 'components';
import { useParams } from 'react-router-dom';
import { InfoDialog, TopicInfo } from './components';
import { InviteDialog } from './components/InviteCollaborators';
import { Params } from './types';
import { Container, Left } from './components/primary';
import {
  displayUser,
  extractIdFromString,
  removeLocalStorage,
  setToLocalStorage,
} from 'utils';
import { AddLinkDialog } from './components/addLink';
// import { DeleteDialog } from './components/Menu/DeleteDialog';
import { Filter } from './components/Filter';
import { AddToGroup } from './components/Menu/AddToGroup';
import { RightSideBar } from './components/RightSidebar';
import { ActivityListComp } from './components/Activity';
// import { CloseDialog } from './components/Menu/CloseDialog';
import { useAppDispatch, useAppSelector } from 'hooks';
import { RootState } from 'reducers';
import { useTopicActivity, useTopicInfo } from './hooks';
import { EActivityType, IUser } from 'model';
import { TopicCreatedIcon } from 'components/Icons';
import { theme } from 'theme';
import { css } from '@emotion/css';
import { removeAllFilter } from 'features/activityFilter';
import { removeAllTopicInfo } from 'features/topic';
import { removeAllTopicActivity } from 'features/topicActivity';
import { resetTopicLink } from 'features/topicLink';
import pubsub from 'sweet-pubsub';
import { ENotificationKey } from 'shared';
import { IntegrationDialog } from './components/IntegrationDialog';
import { GET_COLLABORATORS, GOOGLE_AUTH } from './gql';
import { useQuery } from '@apollo/client';
import { UserContext } from 'context';
// import { ERouteName } from 'pages';

type GoogleResult = {
  me: {
    _id: string;
    userHasGoogleAuth?: boolean;
    userHasSlackAuth?: boolean;
    userHasZoomAuth?: boolean;
    userHasNotionAuth?: boolean;
    userHasLinearAuth?: boolean;
  };
};

type Collaborator = {
  collaborators: IUser[];
};

export const Topic = () => {
  // const navigate = useNavigate();
  const { topicId: topic } = useParams<Params>();
  const topicId = extractIdFromString(topic || '');

  const [editFormOpen, setEditFormOpen] = useState(false);
  const [currentComment, setCurrentComment] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addLinkDialogOpen, setAddLinkDialogOpen] = useState(false);
  const [keyForUpdate] = useState(Math.random());
  const [stared, setStared] = useState(false);
  const [type, setType] = useState<EActivityType[]>([]);
  const [groups, setGroups] = useState<string[]>([]);
  const [page, setPage] = useState(1);
  // const [deleteOpen, setDeleteOpen] = useState(false);
  // const [closeOpen, setCloseOpen] = useState(false);
  const [addToGroupDialogOpen, setAddToGroupDialogOpen] = useState(false);
  const [commentText, setCommentText] = useState('');
  const [commentImportant, setCommentImportant] = useState(false);
  const [showIntegrationDialog, setShowIntegrationDialog] = useState(false);

  const topicBasics = useAppSelector((state: RootState) => state.topic);
  const topicActivity = useAppSelector(
    (state: RootState) => state.topicActivity
  );

  // TODO -- remove this later and change the links in other pages
  // if (!topic?.split('-')?.[1] && topicBasics.name) {
  //   navigate(
  //     `${ERouteName.Topic}${topicId}-${topicBasics.name
  //       .toLowerCase()
  //       .split(' ')
  //       .join('-')
  //       .split('&')
  //       .join('and')
  //       .split('%')
  //       .join('percent')
  //       .split('?')
  //       .join('')}`,
  //     {
  //       replace: true,
  //     }
  //   );
  // }

  const { data: authData } = useQuery<GoogleResult>(GOOGLE_AUTH, {
    fetchPolicy: 'network-only',
  });

  const { data: collabData } = useQuery<Collaborator>(GET_COLLABORATORS);

  const dispatch = useAppDispatch();

  useEffect(() => {
    // remove topic data from redux
    return () => {
      dispatch(removeAllTopicActivity());
      dispatch(resetTopicLink());
      dispatch(removeAllTopicInfo());
      dispatch(removeAllFilter());
    };
  }, [dispatch]);

  useEffect(() => {
    const googleCallback = () => {
      if (!authData?.me?.userHasGoogleAuth) {
        setShowIntegrationDialog(true);
      }
    };
    const notionCallback = () => {
      if (!authData?.me?.userHasNotionAuth) {
        setShowIntegrationDialog(true);
      }
    };
    const linearCallback = () => {
      if (!authData?.me?.userHasLinearAuth) {
        setShowIntegrationDialog(true);
      }
    };
    pubsub.on(ENotificationKey.GOOGLE_LINK_ADDED, googleCallback);
    pubsub.on(ENotificationKey.NOTION_LINK_ADDED, notionCallback);
    pubsub.on(ENotificationKey.LINEAR_LINK_ADDED, linearCallback);
    return () => {
      pubsub.off(ENotificationKey.GOOGLE_LINK_ADDED, googleCallback);
      pubsub.off(ENotificationKey.NOTION_LINK_ADDED, notionCallback);
      pubsub.off(ENotificationKey.LINEAR_LINK_ADDED, linearCallback);
    };
  }, [
    authData?.me?.userHasGoogleAuth,
    authData?.me?.userHasLinearAuth,
    authData?.me?.userHasNotionAuth,
  ]);

  const { loadingTopicBasics, syncTopicInfo, topicListData, permissions } =
    useTopicInfo({ topicId: topicId! });

  const {
    onCommentAdd,
    onEdit,
    onReplyComment,
    clearFilter,
    handleGroupClick,
    handleStaredClick,
    handleTypeClick,
    loadMore,
    setShowAlert,
    showAlert,
    loadingActivity,
    poolData,
    addCommentLoading,
  } = useTopicActivity({
    topicId: topicId!,
    page,
    stared,
    type,
    groups,
    commentText,
    currentComment,
    commentImportant,
    lastActivity: topicActivity[topicActivity.length - 1],
    syncTopicInfo,
    setCommentImportant,
    setCurrentComment,
    setEditFormOpen,
    setGroups,
    setPage,
    setStared,
    setType,
  });

  const topicList = useMemo(
    () =>
      (topicListData?.topics || []).map((topic) => ({
        label: topic.name,
        value: topic._id,
      })),
    [topicListData?.topics]
  );

  const { state: userState } = useContext(UserContext);

  const members = useMemo(() => {
    const result = collabData?.collaborators
      .map((item) => {
        return {
          userId: item._id,
          profilePicture: item.profileUrl,
          email: item.businessEmail,
          role: item.role,
          name: displayUser(item)!,
        };
      })
      ?.filter((item) => item.userId !== userState?._id);
    return result || [];
  }, [collabData?.collaborators, userState?._id]);

  // const [shouldWait, setShouldWait] = useState(false);

  // const refreshPage = useCallback(() => {
  //   if (!topicBasics?.closed) {
  //     syncTopicInfo();
  //     /**
  //      * This in case push notification from Google doesn't work
  //      */
  //     if (
  //       !stared &&
  //       (type.length === 0 ||
  //         type.includes(EActivityType.GOOGLE) ||
  //         type.includes(EActivityType.GOOGLE_COMMENT))
  //     ) {
  //       syncDoc({
  //         variables: {
  //           topicId,
  //         },
  //         fetchPolicy: 'network-only',
  //       }).then((result) => {
  //         if (
  //           result.data &&
  //           result.data?.syncGoogleDoc &&
  //           result.data?.syncGoogleDoc?.length > 0
  //         ) {
  //           // dispatch(addNewTopicActivity(result.data?.syncGoogleDoc));
  //           // dispatch(removeFakeTopicActivity());
  //           // dispatch(addNewTopicActivity(result.data?.syncGoogleDoc?.result));
  //         }
  //       });
  //     }

  //     if (
  //       !stared &&
  //       (type.length === 0 || type.includes(EActivityType.MICROSOFT))
  //     ) {
  //       syncMicrosoft({
  //         variables: {
  //           topicId,
  //         },
  //         fetchPolicy: 'network-only',
  //       }).then((result) => {
  //         if (
  //           result.data &&
  //           result.data?.syncMicrosoft &&
  //           result.data?.syncMicrosoft?.length > 0
  //         ) {
  //           // dispatch(addNewTopicActivity(result.data?.syncGoogleDoc));
  //           // dispatch(removeFakeTopicActivity());
  //           // dispatch(addNewTopicActivity(result.data?.syncGoogleDoc?.result));
  //         }
  //       });
  //     }

  //     recalculateMomentum({
  //       variables: {
  //         topicId,
  //       },
  //     }).then((result) => {
  //       if (result.data?.calculateMomentum?.label) {
  //         dispatch(
  //           updateTopicReducer({ momentum: result.data.calculateMomentum })
  //         );
  //       }
  //     });
  //   }
  // }, [
  //   topicBasics?.closed,
  //   syncTopicInfo,
  //   syncDoc,
  //   topicId,
  //   syncMicrosoft,
  //   recalculateMomentum,
  //   stared,
  //   type,
  //   dispatch,
  // ]);

  const onCancelEdit = useCallback(() => {
    setEditFormOpen(false);
    // navigate(ERouteName.TopicCreate);
  }, []);

  // const throttleFunction = (cb: Function, delay: number) => {
  //   return (...args: any[]) => {
  //     if (shouldWait) return;

  //     cb(...args);
  //     setShouldWait(true);
  //     setTimeout(() => {
  //       setShouldWait(false);
  //     }, delay);
  //   };
  // };

  // const refreshPageThrottle = throttleFunction(refreshPage, 3000);

  // const handleVisibilityChange = useCallback(() => {
  //   if (document.hidden) {
  //   } else {
  //     refreshPageThrottle();
  //   }
  // }, [refreshPageThrottle]);

  // const handleFocus = useCallback(() => {
  //   refreshPageThrottle();
  // }, [refreshPageThrottle]);

  /**
   * Detect user interaction with the UI and then fetch new updates
   * TODO ---  remove this when websocket available
   */
  // useEffect(() => {
  //   window.addEventListener('scroll', refreshPageThrottle);
  //   window.addEventListener('focus', refreshPageThrottle);
  //   document.addEventListener('visibilitychange', handleVisibilityChange);
  //   return () => {
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //     window.removeEventListener('focus', refreshPageThrottle);
  //     window.removeEventListener('scroll', refreshPageThrottle);
  //   };
  // }, [handleFocus, handleVisibilityChange, refreshPageThrottle]);

  useEffect(() => {
    setToLocalStorage('currentTopic', topicId || '');
    return () => {
      removeLocalStorage('currentTopic');
    };
  }, [topicId]);

  useEffect(() => {
    pubsub.emit(ENotificationKey.EDIT_TOPIC_OPEN, { value: !editFormOpen });
  }, [editFormOpen]);

  if (loadingTopicBasics) return <PageLoading />;

  return (
    <Page
      title={`${topicBasics?.name || 'Topic'} | Multiplayer`}
      key={keyForUpdate}
    >
      <Container
      // onMouseMove={refreshPageThrottle}
      // onClick={refreshPageThrottle}
      >
        <AddLinkDialog
          open={addLinkDialogOpen}
          topicId={topicId!}
          onOpenChange={setAddLinkDialogOpen}
          linkAdded={syncTopicInfo}
        />
        <Left>
          <ErrorBoundary componentName="TopicInfoEditForm">
            <TopicInfo
              workspaceName={'Home'}
              onEdit={() => {
                setEditFormOpen(true);
                pubsub.emit(ENotificationKey.EDIT_TOPIC_OPEN, { value: true });
              }}
              onAddLink={() => setAddLinkDialogOpen(true)}
            />
          </ErrorBoundary>
          {!topicBasics?.closed && !editFormOpen && (
            <Box noPadding width="100%">
              <TextEditor
                placeholder="Type your update here..."
                submit="Comment"
                value={currentComment}
                handleSubmit={onCommentAdd}
                handleChange={setCurrentComment}
                handleChangeText={setCommentText}
                loading={addCommentLoading}
                label="Add an update"
                additionalContent={
                  <div
                    className={css`
                      width: 24px;
                      height: 24px;
                      border-radius: 12px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      background-color: ${commentImportant
                        ? theme.colors.primary['primary/04']
                        : theme.colors.primary['primary/03']};
                      cursor: pointer;
                    `}
                    onClick={() => setCommentImportant(!commentImportant)}
                  >
                    <TopicCreatedIcon
                      fill={
                        commentImportant
                          ? theme.colors.text['text/dark']
                          : theme.colors.text['text/blue/soft']
                      }
                      style={{ width: '16px' }}
                    />
                  </div>
                }
              />
            </Box>
          )}
          <Filter
            topicId={topicId!}
            type={type}
            stared={stared}
            selectedGroup={groups}
            clearFilter={clearFilter}
            handleGroupClick={handleGroupClick}
            handleStaredClick={handleStaredClick}
            handleTypeClick={handleTypeClick}
          />
          <ActivityListComp
            groups={groups}
            stared={stared}
            topicId={topicId!}
            loading={loadingActivity}
            type={type}
            topic={{
              created: topicBasics?.created,
              creator: displayUser(topicBasics?.creatorId) || '',
            }}
            topicList={topicList}
            onReplyComment={onReplyComment}
            onEditComment={onEdit}
            loadMore={loadMore}
            hasMore={(poolData?.activityList?.result?.length || 0) > 0}
            topicActivity={topicActivity}
            permissions={permissions}
          />
        </Left>

        <ErrorBoundary componentName="RightSideBar">
          <RightSideBar
            topicId={topicId!}
            permissions={permissions}
            // changeScopeLoading={changeScopeLoading}
            onAddContributors={() => setDialogOpen(true)}
          />
        </ErrorBoundary>

        {/* <DeleteDialog
          open={deleteOpen}
          onOpenChange={setDeleteOpen}
          topicId={topicId!}
          topicName={topicBasics?.name}
        /> */}

        <InfoDialog
          open={showAlert}
          onOpenChange={setShowAlert}
          message={
            "This is a private topic! If you're the topic owner, please add this person as a collaborator if you want them to receive notifications when they're mentioned."
          }
        />

        <IntegrationDialog
          open={showIntegrationDialog}
          onOpenChange={setShowIntegrationDialog}
        />

        {/* <CloseDialog
          open={closeOpen}
          closed={topicBasics?.closed}
          onOpenChange={setCloseOpen}
          topicId={topicId!}
          topicName={topicBasics?.name}
        /> */}

        <AddToGroup
          open={addToGroupDialogOpen}
          topicId={topicId || ''}
          onOpenChange={setAddToGroupDialogOpen}
        />

        <InviteDialog
          open={dialogOpen}
          topicId={topicId!}
          topicName={topicBasics?.name}
          onOpenChange={setDialogOpen}
        />

        <CreateTopic
          isDrawerOpen={editFormOpen}
          members={members}
          onCancel={onCancelEdit}
          setIsDrawerOpen={setEditFormOpen}
          isEdit
          topic={topicBasics}
          onTopicEdited={() => {
            // syncTopicInfo();
            setEditFormOpen(false);
          }}
        />
      </Container>
    </Page>
  );
};
