import React from 'react';
import { format } from 'date-fns';
import { css } from '@emotion/css';
import { ActivityHolder, GoogleCommentBox } from 'components';
import {
  GoogleColabIcon,
  GoogleDocIcon,
  GoogleLogo,
  GooglePresentationIcon,
  GoogleSheetIcon,
} from 'components/Icons';

import { EActivityType, GoogleCommentModel, GoogleDocType } from 'model';
import { extractGoogleDocType } from 'utils';

// export enum GoogleDocType {
//   Document = 'Document',
//   Presentation = 'Presentation',
//   Sheets = 'Sheets',
//   // Colab = 'Colab',
//   // Folder = 'Folder',
// }

const Icon = (url: string) => {
  const type = extractGoogleDocType(url);
  switch (type) {
    case GoogleDocType.Document:
      return <GoogleDocIcon style={{ width: '16px', height: '16px' }} />;
    case GoogleDocType.Presentation:
      return (
        <GooglePresentationIcon style={{ width: '16px', height: '16px' }} />
      );
    case GoogleDocType.Sheets:
      return <GoogleSheetIcon style={{ width: '16px', height: '16px' }} />;
    case GoogleDocType.Colab:
      return <GoogleColabIcon style={{ width: '16px', height: '16px' }} />;
    default:
      return <GoogleLogo style={{ width: '16px', height: '16px' }} />;
  }
};

type DocProps = {
  doc: GoogleCommentModel;
  id: string;
  topicId: string;
  creatorId?: string;
  createdBy: string;
  customRef: React.LegacyRef<HTMLDivElement>;
  focus?: boolean;
  isFirst?: boolean;
  stared: boolean;
  last?: boolean;
  hasAccess: boolean | undefined;
};
export const DocCommentUpdate: React.FC<DocProps> = ({
  doc,
  topicId,
  id,
  createdBy,
  customRef,
  focus,
  creatorId,
  stared,
  last,
  // hasAccess,
}) => {
  const hasAccess = true;
  return hasAccess ? (
    <ActivityHolder.Item
      topicId={topicId}
      type={EActivityType.GOOGLE}
      id={id}
      customRef={customRef}
      owner={creatorId}
      link={`${process.env.REACT_APP_WEB_URL}/topic/${topicId}#${id}`}
      Icon={Icon(doc?.lastVersionUrl)}
      title={`*<a href="${
        creatorId ? '/profile/' + creatorId : '#'
      }">${createdBy}</a>** ${
        doc.resolved ? 'resolved' : 'added'
      } a comment to *<a href="${doc?.lastVersionUrl
        .split('#')
        .join('')}" target="_blank" rel="noreferrer">${
        hasAccess === undefined
          ? 'A Google doc'
          : hasAccess
          ? doc?.title
          : 'a file you don’t have access to'
      }</a>**`}
      subTitle={`${format(+doc?.modifiedDate, 'MMMM d, yyyy • h:mm aa')}`}
      // color={theme.colors.primary['primary/03']}
      stared={stared}
      last={last}
    >
      {hasAccess && (
        <a
          href={`${doc?.lastVersionUrl}`}
          target="_blank"
          rel="noreferrer"
          className={css`
            all: unset;
          `}
        >
          <GoogleCommentBox focus={focus} doc={doc} />
        </a>
      )}
    </ActivityHolder.Item>
  ) : (
    <div />
  );
};
