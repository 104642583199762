import { gql, useMutation } from '@apollo/client';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
// import * as Checkbox from '@radix-ui/react-checkbox';
import {
  Box,
  Button,
  Input,
  Text,
  TextEditor,
  DropdownI,
  TopicLabel,
  TooltipContainer,
  Checkbox,
} from 'components';
import {
  EyeIcon,
  InfoIcon,
  PencilSimpleIcon,
  PlusIcon,
} from 'components/Icons';
import { format } from 'date-fns';
import { updateTopicLink } from 'features/topicLink';
import { useAppDispatch, useAppSelector } from 'hooks';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RootState } from 'reducers';
// import { handleInputChange } from 'react-select/dist/declarations/src/utils';
import { theme } from 'theme';
import {
  formatURL,
  isGoogleDoc,
  isLinearLink,
  isNotionUrl,
  shortId,
} from 'utils';
import pubsub from 'sweet-pubsub';
import { ENotificationKey } from 'shared';
import { CheckedState } from '@radix-ui/react-checkbox';
import {
  SUBSCRIBE_ALL,
  SUBSCRIBE_SUMMARY,
  UNSUBSCRIBE_ALL,
  UNSUBSCRIBE_SUMMARY,
} from '../gql';
import { UserContext } from 'context';
import {
  addNewAllWatcherToTopic,
  addNewSummaryWatcherToTopic,
  removeFromAllTopicWatcherInfo,
  removeFromTopicSummaryWatcherInfo,
} from 'features/topic';
// import { ELinkLabel, LinkedResourceType } from '../types';

const Section = styled.div`
  width: 100%;
  padding-top: 32px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${theme.spacing.l}px;
`;

const SectionBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: ${theme.spacing.s}px;
`;

// type Topic = {
//   topics: {
//     _id: string;
//     name: string;
//   }[];
// };

// eslint-disable-next-line no-unused-vars
// enum LinkType {
//   // eslint-disable-next-line no-unused-vars
//   Multiplayer = 'Multiplayer',
//   // eslint-disable-next-line no-unused-vars
//   Notion = 'Notion',
//   // eslint-disable-next-line no-unused-vars
//   Other = 'Web',
// }

const ADD = gql`
  mutation addLinkToTopic(
    $link: String!
    $topicId: ID!
    $title: String
    $important: Boolean
    $addToFeed: Boolean
    # $startDate: DateTime
    # $endDate: DateTime
    $importAll: Boolean
  ) {
    addLinkToTopic(
      link: $link
      topicId: $topicId
      title: $title
      important: $important
      addToFeed: $addToFeed
      # startDate: $startDate
      # endDate: $endDate
      importAll: $importAll
    ) {
      doc {
        id
        url
        created
        type
        lastUpdated
        lastUpdatedBy
        thumbnail
        title
      }
      type
      url
      important
      groups
    }
  }
`;

export const GET_TOPICS = gql`
  query Topics($order: String, $filter: String) {
    topics(order: $order, filter: $filter) {
      _id
      name
    }
  }
`;

type Props = {
  workspaceName: string;
  onEdit: VoidFunction;
  onAddLink: VoidFunction;
};

export const TopicInfo: React.FC<Props> = ({ onEdit, onAddLink }) => {
  const topicBasics = useAppSelector((state: RootState) => state.topic);
  const { state: userState } = useContext(UserContext);

  const [open, setOpen] = useState(false);

  const renderDescription = topicBasics.description ? (
    <TextEditor noBackground value={topicBasics.description} readonly />
  ) : (
    <div />
  );
  const [importAll, setImportAll] = useState<any>(true);
  const [linkName, setLinkName] = useState('');
  const [linkValue, setLinkValue] = useState('');
  const [isWatchAll, setIsWatchAll] = useState<CheckedState>(false);
  const [isWatchSummary, setIsWatchSummary] = useState<CheckedState>(false);
  const [openWatch, setOpenWatch] = useState(false);

  const [watchAllUpdates] = useMutation(SUBSCRIBE_ALL);
  const [watchSummary] = useMutation(SUBSCRIBE_SUMMARY);
  const [unsubscribeForAll] = useMutation(UNSUBSCRIBE_ALL);
  const [unsubscribeForSummary] = useMutation(UNSUBSCRIBE_SUMMARY);

  const dispatch = useAppDispatch();

  const [addToTopic, { loading }] = useMutation(ADD);

  useEffect(() => {
    setIsWatchAll(
      topicBasics?.allUpdatesWatchers
        ? topicBasics?.allUpdatesWatchers?.findIndex(
            (u) => u._id === userState?._id
          ) > -1
        : false
    );
  }, [topicBasics?.allUpdatesWatchers, userState?._id]);

  useEffect(() => {
    setIsWatchSummary(
      topicBasics?.summaryWatchers
        ? topicBasics?.summaryWatchers?.findIndex(
            (u) => u._id === userState?._id
          ) > -1
        : false
    );
  }, [topicBasics?.summaryWatchers, userState?._id]);

  const handleLinkValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLinkValue(e.target.value);
    },
    []
  );

  const handleTitleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLinkName(e.target.value);
    },
    []
  );

  const handleSubmit = useCallback(() => {
    const variables: any = {
      link: formatURL(linkValue),
      title: linkName,
      topicId: topicBasics._id,
      importAll,
      // important,
      // addToFeed: postInFeed,
    };

    // check if there is a google url
    if (isGoogleDoc(formatURL(linkValue) || '')) {
      pubsub.emit(ENotificationKey.GOOGLE_LINK_ADDED, []);
    }

    if (isNotionUrl(formatURL(linkValue) || '')) {
      pubsub.emit(ENotificationKey.NOTION_LINK_ADDED, []);
    }

    if (isLinearLink(formatURL(linkValue) || '')) {
      pubsub.emit(ENotificationKey.LINEAR_LINK_ADDED, []);
    }

    addToTopic({
      variables,
    }).then((res) => {
      if (res.data.addLinkToTopic) {
        dispatch(
          updateTopicLink({
            id: variables.link,
            link: res.data.addLinkToTopic,
          })
        );
      }
      setLinkName('');
      setLinkValue('');
      setOpen(false);
      pubsub.emit(ENotificationKey.SHOW_DROPDOWN, { value: false });
    });
  }, [addToTopic, dispatch, importAll, linkName, linkValue, topicBasics._id]);

  const handleCancel = useCallback(() => {
    setLinkName('');
    setLinkValue('');
    setOpen(false);
    pubsub.emit(ENotificationKey.SHOW_DROPDOWN, { value: false });
  }, []);

  const onTopicWatch = useCallback(() => {
    if (!isWatchAll) {
      unsubscribeForAll({
        variables: {
          topicId: topicBasics?._id,
        },
      });
      dispatch(removeFromAllTopicWatcherInfo(userState?._id));
    } else {
      watchAllUpdates({
        variables: {
          topicId: topicBasics?._id,
        },
      });
      if (userState?._id) {
        dispatch(addNewAllWatcherToTopic(userState));
      }
    }
    if (!isWatchSummary) {
      unsubscribeForSummary({
        variables: {
          topicId: topicBasics?._id,
        },
      });
      dispatch(removeFromTopicSummaryWatcherInfo(userState?._id));
    } else {
      watchSummary({
        variables: {
          topicId: topicBasics?._id,
        },
      });
      if (userState?._id) {
        dispatch(addNewSummaryWatcherToTopic(userState));
      }
    }
  }, [
    dispatch,
    isWatchAll,
    isWatchSummary,
    topicBasics?._id,
    unsubscribeForAll,
    unsubscribeForSummary,
    userState,
    watchAllUpdates,
    watchSummary,
  ]);

  return (
    <Section>
      <Header>
        <Box noPadding display="flex" flexDirection="row" alignItems="center">
          <Text.TextSmall1
            marginTop="4xs"
            marginBottom="4xs"
            color="text/blue/soft"
          >
            {format(
              +(topicBasics.created || new Date().getTime()),
              'MMMM dd, yyyy'
            )}
          </Text.TextSmall1>

          <Text.TextSmall1
            color="text/blue/soft"
            marginTop="4xs"
            marginBottom="4xs"
            style={{ marginLeft: theme.spacing.m }}
          >
            {shortId(topicBasics._id)}
          </Text.TextSmall1>

          <div style={{ marginLeft: '16px' }}>
            <TopicLabel
              closed={topicBasics.closed}
              momentum={topicBasics.momentum}
              dotOnly
            />
          </div>
        </Box>
        <Box noPadding display="flex" flexDirection="row" alignItems="center">
          {!topicBasics?.closed && (
            <DropdownI.Root
              open={openWatch}
              onOpenChange={setOpenWatch}
              triggerComponent={
                <Button
                  iconPosition="left"
                  Icon={EyeIcon}
                  text="Watch topic"
                  iconColor="blue/soft"
                  style={{ marginRight: theme.spacing.l }}
                />
              }
            >
              <div
                className={css`
                  padding: 16px;
                  display: flex;
                  flex-direction: column;
                `}
              >
                <TooltipContainer
                  text="Get notified about every update in this topic."
                  side="right"
                >
                  <div
                    style={{
                      marginBottom: '8px',
                      width: 'fit-content',
                    }}
                  >
                    <Checkbox
                      small
                      label="All updates"
                      checked={Boolean(isWatchAll)}
                      onCheckChange={setIsWatchAll}
                    />
                  </div>
                </TooltipContainer>

                {/* HARDCODE --- hide for moov for now */}
                {userState?.workspace &&
                  userState?.workspace !== '6376cfa45a41c748c40af519' && (
                    <TooltipContainer
                      text="Get daily summaries about topic updates in your inbox."
                      side="right"
                      additionalContent={
                        <div
                          className={css`
                            border: 1px solid
                              ${theme.colors.accent['purple/main']};
                            border-radius: 16px;
                            padding: 4px 8px;
                            width: fit-content;
                          `}
                        >
                          <Text.TextSmall2
                            color="text/link"
                            marginTop="4xs"
                            marginBottom="4xs"
                            style={{
                              color: theme.colors.accent['purple/main'],
                            }}
                          >
                            Powered by GPT
                          </Text.TextSmall2>
                        </div>
                      }
                    >
                      <div
                        style={{
                          width: 'fit-content',
                        }}
                      >
                        <Checkbox
                          small
                          label="Daily summary"
                          checked={Boolean(isWatchSummary)}
                          onCheckChange={setIsWatchSummary}
                        />
                      </div>
                    </TooltipContainer>
                  )}

                <div
                  className={css`
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    margin-top: 16px;
                  `}
                >
                  <DropdownI.Item>
                    <div style={{ marginRight: '8px' }}>
                      <Button text="Cancel" variant="link" color="secondary" />
                    </div>
                  </DropdownI.Item>
                  <DropdownI.Item>
                    <div>
                      <Button text="Save" onClick={onTopicWatch} />
                    </div>
                  </DropdownI.Item>
                </div>
              </div>
            </DropdownI.Root>
          )}
          <Button
            iconPosition="icon-only"
            Icon={PencilSimpleIcon}
            iconColor="blue/soft"
            onClick={onEdit}
            style={{ marginRight: theme.spacing.l }}
          />

          {!topicBasics?.closed && (
            <DropdownI.Root
              open={open}
              onOpenChange={setOpen}
              triggerComponent={
                <Button
                  iconPosition="icon-only"
                  Icon={PlusIcon}
                  iconColor="blue/soft"
                  onClick={() => setOpen(true)}
                />
              }
            >
              <Box position="relative" width="280px" padding="m">
                <div
                  className={css`
                    width: 100%;
                    height: 40px;
                    position: absolute;
                    left: 0px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 0px 16px;
                    border-bottom: 1px solid
                      ${theme.colors.accent['purple/light']};
                  `}
                >
                  <Text.BodySM marginBottom="4xs" marginTop="4xs">
                    Add URL
                  </Text.BodySM>
                </div>
                {/* <Select
                    background={theme.colors.primary['primary/01']}
                    placeholder="Type of integration"
                    notSearchable
                    notClearable={true}
                    small
                    defaultValue={{
                      label: LinkType.Other,
                      value: LinkType.Other,
                    }}
                    onChange={onOptionChange}
                    data={[
                      {
                        label: LinkType.Multiplayer,
                        value: LinkType.Multiplayer,
                      },
                      {
                        label: LinkType.Notion,
                        value: LinkType.Notion,
                      },
                      {
                        label: 'Web / Other',
                        value: LinkType.Other,
                      },
                    ]}
                  /> */}
                <Input
                  // Icon={Link}
                  // small
                  placeholder="Name to display"
                  background={theme.colors.primary['primary/03']}
                  value={linkName}
                  onChange={handleTitleChange}
                  // onFocus={handleFocusChange}
                  style={{ marginTop: 53 }}
                />

                <Input
                  placeholder="Paste link here"
                  background={theme.colors.primary['primary/03']}
                  value={linkValue}
                  onChange={handleLinkValueChange}
                  style={{ marginTop: 10, marginBottom: 10 }}
                />

                <Box
                  noPadding
                  width="100%"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  onClick={() => setImportAll(!importAll)}
                  style={{ cursor: 'pointer' }}
                >
                  <div
                    className={css`
                      width: 12px;
                      height: 12px;
                      border-radius: 6px;
                      border: 1px solid ${theme.colors.primary['primary/05']};
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      margin-right: 8px;
                    `}
                  >
                    {!importAll && (
                      <div
                        className={css`
                          width: 8px;
                          height: 8px;
                          border-radius: 5px;
                          background-color: ${theme.colors.primary[
                            'primary/05'
                          ]};
                        `}
                      />
                    )}
                  </div>

                  <TooltipContainer
                    side="left"
                    text="Multiplayer will import all editing history of the linked file, unless this is checked."
                  >
                    <div
                      className={css`
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                      `}
                    >
                      <Text.TextSmall2
                        color="text/body"
                        marginBottom="4xs"
                        marginTop="4xs"
                      >
                        Don't include previous edits
                      </Text.TextSmall2>

                      <InfoIcon
                        style={{ marginLeft: '4px', marginTop: '1px' }}
                      />
                    </div>
                  </TooltipContainer>
                </Box>

                <Box
                  noPadding
                  width="100%"
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  marginTop="s"
                >
                  <Button
                    disabled={loading}
                    text="Cancel"
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={handleCancel}
                    style={{ marginRight: '8px' }}
                  />
                  <Button
                    loading={loading}
                    disabled={!linkValue}
                    text="Create"
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleSubmit}
                  />
                </Box>
              </Box>
            </DropdownI.Root>
          )}
        </Box>
      </Header>
      <SectionBody>
        <Text.H1 marginBottom="2xs" marginTop="4xs">
          {topicBasics.name}
        </Text.H1>
      </SectionBody>
      <Box noPadding width="100%" style={{ overflow: 'hidden' }}>
        <div
          className={css`
            margin-left: -13px;
            width: 100%;
          `}
        >
          {renderDescription}
        </div>
      </Box>
    </Section>
  );
};

// type ItemProps = {
//   label: string;
//   id: string;
//   onSelect?: (id: string, label: string) => void;
// };
// const Item: React.FC<ItemProps> = ({ id, label, onSelect }) => {
//   return (
//     <Box
//       noPadding
//       display="flex"
//       flexDirection="row"
//       alignItems="center"
//       style={{ cursor: 'pointer' }}
//       onClick={() => onSelect?.(id, label)}
//     >
//       <Text.BodySM style={{ marginRight: 4 }}>{label}</Text.BodySM>
//       <Text.BodySM color="text/blue/soft">{shortId(id)}</Text.BodySM>
//     </Box>
//   );
// };
