import { TooltipContainer } from 'components';
import { InfoIcon } from 'components/Icons';
import React from 'react';

type Props = {
  text: string;
  size?: {
    width: string;
    height: string;
  };
  color?: string;
};
export const ToolTipHelper: React.FC<Props> = ({ text, size, color }) => (
  <TooltipContainer text={text}>
    <div
      style={{ width: size?.width || '16px', height: size?.height || '16px' }}
    >
      <InfoIcon
        style={{
          width: size?.width || '16px',
          height: size?.height || '16px',
        }}
        fill={color || '#A99DCB'}
      />
    </div>
  </TooltipContainer>
);
