import { gql, useMutation, useQuery } from '@apollo/client';
import { Dialog, Input, Select, Text } from 'components';
import React, { useCallback, useMemo, useState } from 'react';
import { MultiValue, SingleValue } from 'react-select';
import { theme } from 'theme';
import { css } from '@emotion/css';

interface Props {
  open?: boolean;
  topicId: string;
  onOpenChange?(open: boolean): void;
  linkAdded: VoidFunction;
}

export enum LinkType {
  // eslint-disable-next-line no-unused-vars
  Multiplayer = 'Multiplayer',
  // eslint-disable-next-line no-unused-vars
  Zoom = 'Zoom',
  // eslint-disable-next-line no-unused-vars
  Other = 'Web',
}

const ADD = gql`
  mutation addLinkToTopic($link: String!, $topicId: ID!, $title: String) {
    addLinkToTopic(link: $link, topicId: $topicId, title: $title)
  }
`;

// const MEETINGS = gql`
//   query meetings {
//     meetings {
//       id
//       topic
//       join_url
//     }
//   }
// `;

export const GET_TOPICS = gql`
  query Topics($order: String, $filter: String) {
    topics(order: $order, filter: $filter) {
      _id
      name
    }
  }
`;

// type Meeting = {
//   meetings: {
//     id: number;
//     topic: string;
//     join_url: string;
//   }[];
// };

type Topic = {
  topics: {
    _id: string;
    name: string;
  }[];
};

export function AddLinkDialog(props: Props) {
  const [link, setLink] = useState('');
  const [title, setTitle] = useState('');
  const [type, setType] = useState<LinkType>(LinkType.Multiplayer);

  const [addToTopic, { loading }] = useMutation(ADD);

  // const { data: meetings } = useQuery<Meeting>(MEETINGS);
  const { data: topicData } = useQuery<Topic>(GET_TOPICS, {
    variables: {
      order: 'desc',
      filter: 'opened',
    },
  });

  const onLinkValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLink(e.target.value);
    },
    []
  );

  const onTitleValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTitle(e.target.value);
    },
    []
  );

  const topics = useMemo(
    () =>
      topicData?.topics.map((item) => ({
        label: item.name,
        value: item._id,
      })) || [],
    [topicData?.topics]
  );

  const meetingList = useMemo(() => [], []);

  const onSelectChange = useCallback(
    (
      values:
        | SingleValue<{
            value: string;
            label: string;
          }>
        | MultiValue<{
            value: string;
            label: string;
          }>
    ) => {
      const casted = values as { label: string; value: string };
      setLink(
        type === LinkType.Multiplayer
          ? `${process.env.REACT_APP_WEB_URL}/topic/${casted.value}`
          : '#'
      );
    },
    [type]
  );

  const onTypeChange = useCallback(
    (value: LinkType) => () => {
      setLink('');
      setType(value);
    },
    []
  );

  return (
    <Dialog
      additionalContent={
        <div>
          <div
            className={css`
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
            `}
          >
            <div
              className={css`
                display: flex;
                flex-direction: row;
                align-items: center;
              `}
            >
              <input
                type="radio"
                name="link-type"
                id={LinkType.Multiplayer}
                value={LinkType.Multiplayer}
                onChange={onTypeChange(LinkType.Multiplayer)}
              />
              <Text.Label
                htmlFor={LinkType.Multiplayer}
                size="body-sm"
                marginBottom="s"
              >
                {LinkType.Multiplayer}
              </Text.Label>
            </div>

            {/* <div
              className={css`
                display: flex;
                flex-direction: row;
                align-items: center;
              `}
            >
              <input
                type="radio"
                name="link-type"
                id={LinkType.Zoom}
                value={LinkType.Zoom}
                onChange={onTypeChange(LinkType.Zoom)}
              />
              <Text.Label
                htmlFor={LinkType.Zoom}
                size="body-sm"
                marginBottom="s"
              >
                {LinkType.Zoom}
              </Text.Label>
            </div> */}

            <div
              className={css`
                display: flex;
                flex-direction: row;
                align-items: center;
              `}
            >
              <input
                type="radio"
                name="link-type"
                id={LinkType.Other}
                value={LinkType.Other}
                onChange={onTypeChange(LinkType.Other)}
              />
              <Text.Label
                htmlFor={LinkType.Other}
                size="body-sm"
                marginBottom="s"
              >
                Google / Web
              </Text.Label>
            </div>
          </div>
          {type === LinkType.Other ? (
            <>
              <Input
                placeholder="Link URL"
                value={link}
                onChange={onLinkValueChange}
                background={theme.colors.primary['primary/01']}
              />{' '}
              <br />
              <Input
                placeholder="Link title"
                value={title}
                onChange={onTitleValueChange}
                background={theme.colors.primary['primary/01']}
              />
            </>
          ) : (
            <Select
              background={theme.colors.primary['primary/01']}
              // color="text/dark"
              // borderColor="text/light"
              data={type === LinkType.Multiplayer ? topics : meetingList}
              onChange={onSelectChange}
              placeholder={`${
                type === LinkType.Multiplayer ? 'Multiplayer' : 'Zoom'
              } link`}
            />
          )}
        </div>
      }
      description="Track a link in this Topic"
      open={props.open}
      submitting={loading}
      submitDisabled={link.length <= 0}
      title="Add new link"
      onOpenChange={props.onOpenChange}
      onSubmit={async (onClose) => {
        if (link) {
          addToTopic({
            variables: {
              link,
              title,
              topicId: props.topicId,
            },
          }).then(() => {
            props.linkAdded();
            setLink('');
            onClose();
          });
        }
      }}
    />
  );
}
