import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { theme } from 'theme';
import { Text } from 'components';

type SeparatorProps = {
  helperText?: string;
  text: string;
  newUpdate?: boolean;
  big?: boolean;
};
export const ActivitySeparator: React.FC<SeparatorProps> = ({
  text,
  helperText,
  newUpdate,
  big,
}) => {
  const Barre = styled.div`
    background-color: ${newUpdate
      ? theme.colors.text['text/link']
      : theme.colors.accent['purple/light']};
    width: 100%;
    height: 1px;
    margin-top: 17px;
    margin-left: -4px;
    z-index: -1;
  `;
  return (
    <div
      className={css`
        width: 100%;
        display: grid;
        grid-template-columns: auto ${big ? '320px' : '120px'} auto;
        height: 35px;
        gap: 3px;
        margin-top: 20px;
        position: relative;
        margin-bottom: 17px;
        overflow: hidden;
      `}
    >
      {newUpdate && (
        <div
          className={css`
            display: flex;
            justify-content: center;
            flex-direction: row;
            align-items: center;
            height: 100%;
            position: absolute;
            z-index: 1;
            left: 0px;
            background-color: ${theme.colors.primary['primary/01']};
            padding-right: 5px;
          `}
        >
          <Text.TextSmall2 color="text/link" marginBottom="4xs" marginTop="4xs">
            {helperText}
          </Text.TextSmall2>
        </div>
      )}
      <Barre />
      <div
        className={css`
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          height: 32px;
          margin-left: -6px;
          border: 1px solid
            ${newUpdate
              ? theme.colors.text['text/link']
              : theme.colors.primary['primary/02']};
          border-radius: 16px;
        `}
      >
        <Text.TextSmall2
          color={newUpdate ? 'text/link' : 'text/blue/soft'}
          marginBottom="4xs"
          marginTop="4xs"
        >
          {text}
        </Text.TextSmall2>
      </div>
      <Barre />
    </div>
  );
};
