import styled from '@emotion/styled';
import { theme } from 'theme';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  width: 100%;
  overflow: hidden;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-top: ${theme.spacing.l}px;
`;
export const Header = styled.tr``;
export const Tr = styled.tr`
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.primary['primary/03']};
  }
`;
export const Th = styled.th`
  /* border: 1px solid #dddddd; */
  text-align: left;
  padding-left: ${theme.spacing.m}px;
`;
export const Td = styled.td`
  /* padding-left: ${theme.spacing.m}px; */
`;

export const TBody = styled.tbody``;
export const THead = styled.thead``;
