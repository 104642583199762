import { gql, useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/css';
import { Box, Button, Dialog, Select } from 'components';
import { PlusCircle } from 'components/Icons';
import { UserContext } from 'context';
import { addNewCollaboratorToTopic } from 'features/topic';
import { useAppDispatch } from 'hooks';
import { IUser } from 'model';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { MultiValue, SingleValue } from 'react-select';
import { theme } from 'theme';
import { displayUser } from 'utils';

interface Props {
  open?: boolean;
  topicId: string;
  topicName: string;
  onOpenChange?(open: boolean): void;
}

export const GET_COLLABORATORS = gql`
  query Collaborators {
    collaborators {
      _id
      businessEmail
      profileUrl
      firstName
      lastName
    }
  }
`;

const INVITE = gql`
  mutation InviteUserToTopic($topicId: ID!, $userEmails: [String!]!) {
    inviteUserToTopic(topicId: $topicId, userEmails: $userEmails) {
      _id
      profileUrl
      firstName
      lastName
      businessEmail
    }
  }
`;

type Collaborators = {
  collaborators: IUser[];
};

export function InviteDialog(props: Props) {
  const [topicCollaborators, setTopicCollaborators] = useState<string[]>([]);

  const dispatch = useAppDispatch();

  const { state: userState } = useContext(UserContext);

  const { data } = useQuery<Collaborators>(GET_COLLABORATORS);

  const [addToTopic, { loading }] = useMutation(INVITE);

  const members = useMemo(() => {
    const result = data?.collaborators
      .map((item) => {
        return {
          label: JSON.stringify({
            email: item.businessEmail!,
            userName: displayUser(item),
            profilePicture: item.profileUrl,
            userId: item._id,
          }),
          value: item.businessEmail!,
        };
      })
      ?.filter((item) => item.value !== userState._id);
    return result || [];
  }, [data?.collaborators, userState._id]);

  const onCollaboratorChange = useCallback(
    (
      values:
        | SingleValue<{
            value: string;
            label: string;
          }>
        | MultiValue<{
            value: string;
            label: string;
          }>
    ) => {
      const casted = values as { label: string; value: string }[];
      const collaborators = casted.map((item) => item.value);
      setTopicCollaborators(collaborators);
    },
    []
  );

  return (
    <Dialog
      noRadius
      additionalContent={
        <Box noPadding width="100%" display="flex" flexDirection="row">
          <div
            className={css`
              width: 350px;
              margin-top: 28px;
            `}
          >
            <Select
              isMulti
              isUser
              background={theme.colors.primary['primary/02']}
              // color="text/dark"
              // borderColor="text/light"
              data={members}
              onChange={onCollaboratorChange}
              placeholder="Enter email address"
            />
          </div>
          <Button
            text="Add"
            color="primary"
            shape="rectangle"
            textColor="text/100%"
            customBackground={theme.colors.primary['primary/02']}
            customHeight="42px"
            Icon={PlusCircle}
            iconPosition="right"
            disabled={topicCollaborators.length <= 0}
            loading={loading}
            // size="large"
            onClick={() => {
              addToTopic({
                variables: {
                  userEmails: topicCollaborators,
                  topicId: props.topicId,
                },
              }).then((res) => {
                dispatch(addNewCollaboratorToTopic(res.data.inviteUserToTopic));
                // window.location.reload();
                props.onOpenChange?.(false);
              });
            }}
            style={{ marginLeft: '8px', marginTop: '28px' }}
          />
        </Box>
      }
      // description="Invite others to collaborate on this Topic."
      open={props.open}
      submitting={loading}
      submitDisabled={topicCollaborators.length <= 0}
      title="Add new participants"
      onOpenChange={props.onOpenChange}
    />
  );
}
