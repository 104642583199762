import { css } from '@emotion/css';
import React from 'react';
import { Text } from 'components';

type Props = {
  title: string;
  Icon: React.FC<any>;
  selected: boolean;
  buildProgress?: number;
  onSelect(): void;
};
export const TopicTabItem: React.FC<Props> = ({
  Icon,
  title,
  selected,
  buildProgress,
  onSelect,
}) => {
  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        min-width: 105px;
        height: 100%;
        padding-inline: 19px;
        border-bottom: 1px solid #6a53fe;
        border-width: ${selected ? 1 : 0}px;
        cursor: pointer;
        position: relative;
      `}
      onClick={onSelect}
    >
      <Icon
        style={{ width: '16px', height: '16px', marginRight: '8px' }}
        fill={selected ? '#6A53FE' : '#838094'}
      />
      <Text.TextSmall1
        marginBottom="4xs"
        marginTop="4xs"
        style={{ color: selected ? '' : '#838094' }}
      >
        {title}
      </Text.TextSmall1>
      {Boolean(buildProgress && buildProgress > 0) && (
        <Text.TextSmall2
          marginBottom="4xs"
          marginTop="4xs"
          style={{
            color: selected ? '' : '#838094',
            position: 'absolute',
            bottom: '2px',
            marginLeft: '16px',
            fontSize: '10px',
          }}
        >
          Building ({buildProgress}%)
        </Text.TextSmall2>
      )}
    </div>
  );
};
