import { css } from '@emotion/css';
import { Text } from 'components';
import { EnvelopeIcon } from 'components/Icons';
import React from 'react';
import { theme } from 'theme';

export const Support: React.FC = () => {
  return (
    <div
      className={css`
        width: 80%;
        margin: auto;
        /* text-align: center; */
        padding: 30px;
      `}
    >
      <Text.H1 marginBottom="l" style={{ textAlign: 'center' }}>
        Multiplayer support
      </Text.H1>

      <div
        className={css`
          color: ${theme.colors.text['text/100%']};
          font-size: 18px;
          margin: auto;
          width: 100%;
          text-align: center;
          margin-top: 100px;
          a {
            color: ${theme.colors.accent['purple/main']};
          }
        `}
      >
        <EnvelopeIcon
          style={{ width: '62px', height: '62px' }}
          fill={theme.colors.accent['purple/main']}
        />{' '}
        <br />
        Need assistance? Email us at{' '}
        <a href="mailto:hello@multiplayer.work">hello@multiplayer.work</a>{' '}
        <br /> and we will handle your request within 2 business days.
      </div>
    </div>
  );
};
