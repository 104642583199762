import React, { useCallback, useContext, useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Container, Left, Right } from './components';
import { SearchResult } from './type';
import { Page } from 'components';
import { SearchValueContext } from 'context';
import { EActivityType } from 'model';

const SEARCH = gql`
  query Search(
    $query: String!
    $limit: Int
    $activityType: [String!]
    $startDate: DateTime
    $endDate: DateTime
    $creator: [String!]
    $topicOnly: Boolean
    $collaborators: [ID!]
    $operator: String
  ) {
    search(
      query: $query
      limit: $limit
      activityType: $activityType
      endDate: $endDate
      creator: $creator
      startDate: $startDate
      topicOnly: $topicOnly
      collaborators: $collaborators
      operator: $operator
    ) {
      name
      score
      activityType
      activityId
      topicId
      comment
      message
      title
    }
  }
`;

let timeout: NodeJS.Timeout;

export const Search = () => {
  //   const { query } = extractFromQuery<Params>(window.location.search);

  const searchContextValue = useContext(SearchValueContext);

  const [activityType, setActivityType] = useState<EActivityType[]>([]);
  const [topicOnly, setTopicOnly] = useState(false);
  const [creator, setCreator] = useState<string[]>([]);
  const [query, setQuery] = useState(searchContextValue);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [collaborators, setCollaborators] = useState<string[]>([]);
  const [operator, setOperator] = useState<'and' | 'or'>('and');

  const { data, loading } = useQuery<{ search: SearchResult[] }>(SEARCH, {
    variables: {
      query,
      limit: 50,
      activityType,
      topicOnly,
      creator,
      startDate,
      endDate,
      collaborators,
      operator,
    },
  });

  useEffect(() => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setQuery(searchContextValue);
    }, 500);
  }, [searchContextValue]);

  const onActivityTypeChange = useCallback((type: EActivityType) => {
    setActivityType((act) =>
      act.findIndex((el) => el === type) < 0
        ? [...act, type]
        : act.filter((el) => el !== type)
    );
  }, []);

  const onCreatorChange = useCallback((users: string[]) => {
    setCreator(users);
  }, []);

  const onCollaboratorChange = useCallback(
    (user: string, operator: 'and' | 'or') => {
      setCollaborators((act) =>
        act.findIndex((el) => el === user) < 0
          ? [...act, user]
          : act.filter((el) => el !== user)
      );
      setOperator(operator);
    },
    []
  );

  const onStartDateChange = useCallback((date: Date) => {
    setStartDate(date);
  }, []);

  const onEndDateChange = useCallback((date: Date) => {
    setEndDate(date);
  }, []);

  const onTopicOnly = useCallback((newState: boolean) => {
    setTopicOnly(newState);
  }, []);

  // if (loading) return <PageLoading />;

  return (
    <Page title="Search | Multiplayer">
      <Container>
        <Left
          activityType={activityType}
          topicOnly={topicOnly}
          creator={creator}
          startDate={startDate}
          endDate={endDate}
          collaborators={collaborators}
          onStartDateChange={onStartDateChange}
          onEndDateChange={onEndDateChange}
          onActivityTypeChange={onActivityTypeChange}
          onTopicOnly={onTopicOnly}
          onCreatorChange={onCreatorChange}
          onCollaboratorChange={onCollaboratorChange}
        />
        <Right searchResult={data?.search || []} loading={loading} />
      </Container>
    </Page>
  );
};
