import { useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/css';
import { Box, Button, Input, TextArea, Text, Notification } from 'components';
import {
  AlienIcon,
  ConfettiIcon,
  CrownSimpleIcon,
  FloppyDiskIcon,
  FlyingSaucerIcon,
  GameControllerIcon,
  GhostIcon,
  HeartIcon,
  HorseIcon,
  MaskHappyIcon,
  PuzzlePieceIcon,
  SwordIcon,
} from 'components/Icons';
import { UserContext } from 'context';
import { IUser } from 'model';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { theme } from 'theme';
import { formatURL } from 'utils';
import { GET_USER_INFO, UPDATE_PROFILE } from './gql';
import {
  ImageInput,
  InputContainer,
  ProfileImage,
  ProfileImageContainer,
} from './primary';

const ICON_LIST = [
  MaskHappyIcon,
  AlienIcon,
  GameControllerIcon,
  GhostIcon,
  HeartIcon,
  SwordIcon,
  CrownSimpleIcon,
  PuzzlePieceIcon,
  HorseIcon,
  FlyingSaucerIcon,
];

const RandomIcon = ICON_LIST[Math.floor(Math.random() * ICON_LIST.length)];

export const MyDetails: React.FC<any> = () => {
  const { state: userState } = useContext(UserContext);
  const [pictureLoading, setPictureLoading] = useState(false);
  const [userData, setUserData] = useState(
    {} as Omit<IUser, '_id' | 'businessEmail'>
  );
  const [showNotification, setShowNotification] = useState(false);

  const { data } = useQuery<{ me: IUser }>(GET_USER_INFO, {
    fetchPolicy: 'network-only',
  });

  const [updateUser, { loading: accountLoading }] = useMutation<{
    updateUser: Omit<IUser, '_id'>;
  }>(UPDATE_PROFILE);

  useEffect(() => {
    setUserData({
      firstName: data?.me?.firstName,
      lastName: data?.me?.lastName,
      description: data?.me?.description,
      githubProfile: data?.me?.githubProfile,
      linkedinProfile: data?.me?.linkedinProfile,
      slackProfile: '',
      role: data?.me?.role,
      email: data?.me?.email,
    });
  }, [
    data?.me?.description,
    data?.me?.email,
    data?.me?.firstName,
    data?.me?.githubProfile,
    data?.me?.lastName,
    data?.me?.linkedinProfile,
    data?.me?.role,
  ]);

  const handleUserDataChange = useCallback(
    (key: keyof IUser) =>
      (
        e:
          | React.ChangeEvent<HTMLInputElement>
          | React.ChangeEvent<HTMLTextAreaElement>
      ) => {
        setUserData({ ...userData, [key]: e.target.value });
      },
    [userData]
  );

  const onChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      setPictureLoading(true);
      try {
        const bodyContent = new FormData();
        bodyContent.append('image', event.target.files?.[0]!);
        // const res = await publicRequest({
        //   method: 'POST',
        //   url: `${env.backendURL}/api/profile-upload`,
        //   body: bodyContent,
        // });

        const headersList = {
          Accept: '*/*',
          'User-Agent': 'Thunder Client (https://www.thunderclient.com)',
        };

        const res = await fetch(
          `${process.env.REACT_APP_API_URL?.split('graphql').join(
            'api'
          )}/profile-upload`,
          {
            method: 'POST',
            body: bodyContent,
            headers: headersList,
          }
        );

        await res
          .text()
          .then((response) => {
            updateUser({
              variables: {
                profileUrl: response,
              },
            }).then((result) => {
              setUserData({ ...userData, profileUrl: response });
              setPictureLoading(false);
              window?.location?.reload();
            });
          })
          .catch((e) => {
            setPictureLoading(false);
          });
      } catch (error) {
        setPictureLoading(false);
      }
    },
    [updateUser, userData]
  );

  const handleAccountSave = useCallback(() => {
    updateUser({
      variables: {
        ...userData,
        githubProfile: formatURL(userData.githubProfile || ''),
        linkedinProfile: formatURL(userData.linkedinProfile || ''),
      },
    }).then((result) => {
      //   setDescription(result.data?.updateUser.description || '');

      if (result.data?.updateUser) {
        // Toast.notify('Account saved!');
        setShowNotification(true);
      }

      setUserData({
        ...result.data?.updateUser!,
        profileUrl: userState.profileUrl,
      });
      //   window?.location?.reload();
    });
  }, [updateUser, userData, userState.profileUrl]);

  return (
    <div
      className={css`
        width: 58%;
      `}
    >
      <Text.H2>Your profile details</Text.H2>
      {/* <Toast.Component /> */}
      <Notification
        success
        show={showNotification}
        handleShow={setShowNotification}
      />
      <Box
        noPadding
        marginTop="m"
        marginBottom="m"
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <ProfileImageContainer>
          {userState.profileUrl ? (
            <ProfileImage src={userState.profileUrl} alt="User profile image" />
          ) : (
            <RandomIcon
              fill={theme.colors.text['text/link']}
              style={{ width: '32px', height: '32px' }}
            />
          )}

          <InputContainer>
            {pictureLoading ? (
              <Text.TextSmall2>Loading...</Text.TextSmall2>
            ) : (
              <ImageInput
                type="file"
                accept="image/*"
                required
                onChange={onChange}
              />
            )}
          </InputContainer>
        </ProfileImageContainer>
        <div>
          <Text.TextSmall2 color="text/50%" marginBottom="xs">
            Photo
          </Text.TextSmall2>
          <Text.TextSmall1 color="text/50%" marginTop="4xs">
            A photo will help personalize your account
          </Text.TextSmall1>
        </div>
      </Box>

      <div
        className={css`
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 24px;
          /* margin-bottom: 35px; */
        `}
      >
        <div>
          <Text.TextSmall2 color="text/light" marginBottom="2xs">
            Name
          </Text.TextSmall2>
          <Input
            placeholder="Anonymous Ghost "
            value={userData.firstName || ''}
            onChange={handleUserDataChange('firstName')}
          />
        </div>
        <div>
          <Text.TextSmall2 color="text/light" marginBottom="2xs">
            Last name
          </Text.TextSmall2>
          <Input
            value={userData.lastName || ''}
            onChange={handleUserDataChange('lastName')}
          />
        </div>
      </div>
      <div
        className={css`
          width: 100%;
        `}
      >
        <Text.TextSmall2 color="text/light" marginBottom="2xs">
          Role
        </Text.TextSmall2>
        <Input
          value={userData.role || ''}
          onChange={handleUserDataChange('role')}
        />
      </div>

      <div
        className={css`
          width: 100%;
        `}
      >
        <Text.TextSmall2 color="text/light" marginBottom="2xs">
          Description
        </Text.TextSmall2>
        <TextArea
          placeholder="Describe yourself with a few words"
          value={userData.description || ''}
          onChange={handleUserDataChange('description')}
          style={{ width: '100%' }}
        />
      </div>

      <Text.Body marginTop="2xl">Social profiles</Text.Body>

      <div
        className={css`
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 24px;
          margin-bottom: 35px;
        `}
      >
        <div>
          <Text.TextSmall2 color="text/light" marginBottom="2xs">
            Linkedin
          </Text.TextSmall2>
          <Input
            placeholder="Anonymous Ghost "
            value={userData.linkedinProfile || ''}
            onChange={handleUserDataChange('linkedinProfile')}
          />
        </div>

        <div>
          <Text.TextSmall2 color="text/light" marginBottom="2xs">
            Github
          </Text.TextSmall2>
          <Input
            value={userData.githubProfile || ''}
            onChange={handleUserDataChange('githubProfile')}
          />
        </div>
      </div>

      <div
        className={css`
          margin-top: 70px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        `}
      >
        <ConfettiIcon
          fill={theme.colors.text['text/link']}
          style={{ width: '16px', height: '16px', marginRight: '4px' }}
        />
        <Text.TextSmall2
          color="text/link"
          marginBottom="4xs"
          marginTop="4xs"
          style={{ fontFamily: 'Roobert-Bold' }}
        >
          COMING SOON
        </Text.TextSmall2>
      </div>
      <Text.Body color="text/75%" marginTop="4xs">
        You can provide your personal email address to enable additional cool
        features.
      </Text.Body>
      <div
        className={css`
          width: 100%;
          background-color: ${theme.colors.primary['primary/02']};
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 4px 4px 4px 4px;
          border-radius: 8px;
        `}
      >
        <Input
          placeholder="E-mail here..."
          value={userData.email || ''}
          width="94%"
          background={theme.colors.primary['primary/02']}
          // Icon={PaperPlaneTilt}
          // iconPosition="right"
          onChange={handleUserDataChange('email')}
        />
        {/* <Button
          text="Submit"
          onClick={handleAccountSave}
          loading={accountLoading}
        /> */}
      </div>
      <Box
        noPadding
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        marginTop="2xl"
      >
        <Button
          Icon={FloppyDiskIcon}
          iconPosition="left"
          text="Save changes"
          onClick={handleAccountSave}
          loading={accountLoading}
        />
      </Box>
    </div>
  );
};
