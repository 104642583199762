import { Box, Text, Dialog } from 'components';

interface Props {
  open?: boolean;
  onOpenChange?(open: boolean): void;
}

export function DataProtection(props: Props) {
  return (
    <Dialog
      open={props.open}
      additionalContent={
        <Box noPadding width="100%">
          <Text.Body>Customer Data protection at Multiplayer</Text.Body>
          <ul>
            <li>
              <Text.BodySM marginBottom="2xs" marginTop="2xs">
                Multiplayer use a role based access control system to ensure
                that only authorized and authenticated users can access any
                ressource
              </Text.BodySM>
            </li>

            <li>
              <Text.BodySM marginBottom="2xs" marginTop="2xs">
                All communications between customers and our server use HTTPS or
                SSH to encrypt the data in transit.
              </Text.BodySM>
            </li>

            <li>
              <Text.BodySM marginBottom="2xs" marginTop="2xs">
                We use MongoDB Atlas as database infrastructure and only our
                allowed applications are able to query information from our
                databases.
              </Text.BodySM>
            </li>

            <li>
              <Text.BodySM marginBottom="2xs" marginTop="2xs">
                We use Google Cloud for cloud storage, all data will be
                encrypted on the server-side using GCP’s default encryption
                keys. Data is automatically decrypted when read by an authorized
                user using KMS-based protections.
              </Text.BodySM>
            </li>

            <li>
              <Text.BodySM marginBottom="2xs" marginTop="2xs">
                Since our only authentication method is through magic link,
                there is no need to store any password from the end user in our
                database which eliminates the risk of any attacker stealing
                passwords.
              </Text.BodySM>
            </li>

            <li>
              <Text.BodySM marginBottom="2xs" marginTop="2xs">
                Only allowed domains (currently only multiplayer.work) are
                allowed to communicate with our servers.
              </Text.BodySM>
            </li>
          </ul>
        </Box>
      }
      onOpenChange={props.onOpenChange}
    />
  );
}
