import { css } from '@emotion/css';
import { Box, DropdownI, Text } from 'components';
import { ChevronDown } from 'components/Icons';
import React from 'react';
import { theme } from 'theme';
import { ESort } from '../types';

type Props = {
  sortBy: ESort;
  handleSortByChange: (newState: ESort) => void;
};
export const SortByDropdown: React.FC<Props> = (props) => {
  return (
    <DropdownI.Root
      triggerComponent={
        <Box noPadding display="flex" flexDirection="row" alignItems="center">
          <Text.BodySM color="text/40%">Sort by</Text.BodySM>
          <Text.BodySM style={{ marginLeft: '8px' }}>
            {props.sortBy === ESort.UPDATED ? 'Date updated' : 'Date created'}
          </Text.BodySM>
          <ChevronDown
            style={{
              width: '12px',
              fill: theme.colors.text['text/100%'],
              marginLeft: '8px',
            }}
          />
        </Box>
      }
    >
      <div
        className={css`
          padding: 16px;
          cursor: pointer;
        `}
      >
        <DropdownI.Item>
          <Box
            noPadding
            display="flex"
            flexDirection="row"
            alignItems="center"
            onClick={() => props.handleSortByChange(ESort.CREATED)}
          >
            <div
              className={css`
                width: 6px;
                height: 6px;
                border-radius: 3px;
                background-color: ${theme.colors.text['text/blue/soft']};
                margin-right: 4px;
              `}
            />
            <Text.Body marginBottom="4xs" marginTop="4xs">
              Date created
            </Text.Body>
          </Box>
        </DropdownI.Item>

        <DropdownI.Item>
          <Box
            noPadding
            display="flex"
            flexDirection="row"
            alignItems="center"
            marginTop="l"
            onClick={() => props.handleSortByChange(ESort.UPDATED)}
          >
            <div
              className={css`
                width: 6px;
                height: 6px;
                border-radius: 3px;
                background-color: ${theme.colors.text['text/blue/soft']};
                margin-right: 4px;
              `}
            />
            <Text.Body marginBottom="4xs" marginTop="4xs">
              Date updated
            </Text.Body>
          </Box>
        </DropdownI.Item>
      </div>
    </DropdownI.Root>
  );
};
