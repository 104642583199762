import { gql, useMutation } from '@apollo/client';
import { css } from '@emotion/css';
import { Box, CircularProgress, InviteDialog, Text } from 'components';
import { CheckIcon } from 'components/Icons';
import { UserContext } from 'context';
import { TutorialProgress } from 'model';
import { ERouteName } from 'pages';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { theme } from 'theme';
import { TutorialItem, TutorialItemSeparator } from './TutorialItem';

type TutorialType = {
  key: string;
  title: string;
  subtitle: string;
  checked: boolean;
  link: string;
  onClick?: VoidFunction;
};

const COMPLETE = gql`
  mutation CompleteTutorial($userId: ID!) {
    completeTutorial(userId: $userId)
  }
`;
export const VIDEO_WATCHED = gql`
  mutation OnboardingVideoWatched {
    onboardingVideoWatched
  }
`;

type Props = {
  tutorialProgress?: TutorialProgress;
  onTutorialCompleted?: VoidFunction;
};
export const Tutorial: React.FC<Props> = ({
  tutorialProgress,
  onTutorialCompleted,
}) => {
  const { state: userState } = useContext(UserContext);
  const [inviteOpen, setInviteOpen] = useState(false);

  const [tutorialList, setTutorialList] = useState<TutorialType[]>([]);

  const [completeTutorial, { loading }] = useMutation(COMPLETE);
  const [markAsWatched] = useMutation(VIDEO_WATCHED);

  const isTutorialCompleted = Boolean(
    tutorialProgress?.integrations &&
      tutorialProgress?.invitation &&
      tutorialProgress?.profile &&
      tutorialProgress?.tutorial
  );

  useEffect(() => {
    setTutorialList([
      {
        title: 'Setup your profile',
        subtitle: 'Add your name, role, and pic to your profile.',
        checked: Boolean(tutorialProgress?.profile),
        link: ERouteName.MyDetailsSetting,
        key: 'profile',
      },
      {
        title: 'Setup your app integrations',
        subtitle:
          'Multiplayer can track progress in other apps automatically and save messages from Slack.',
        checked: Boolean(tutorialProgress?.integrations),
        link: ERouteName.IntegrationSetting,
        key: 'integrations',
      },
      {
        title: 'Go through our tutorial',
        subtitle: "Here's a quick tutorial on how to use Multiplayer.",
        checked: Boolean(tutorialProgress?.tutorial),
        link: '#',
        key: 'tutorial',
        onClick: () => {
          markAsWatched().then(() => {});
          const a = document.createElement('a');
          a.target = '_blank';
          a.href =
            'https://www.loom.com/share/bd73a11344d04a3288d391a58d286f6b';
          a.click();
        },
      },
      {
        title: 'Invite collaborators',
        subtitle:
          'Invite teammates to Multiplayer for an even better experience',
        checked: Boolean(tutorialProgress?.invitation),
        link: '#',
        key: 'invitation',
        onClick: () => {
          setInviteOpen(true);
        },
      },
    ]);
  }, [
    markAsWatched,
    tutorialProgress?.integrations,
    tutorialProgress?.invitation,
    tutorialProgress?.profile,
    tutorialProgress?.tutorial,
  ]);

  const handleTutorialComplete = useCallback(async () => {
    try {
      await completeTutorial({
        variables: {
          userId: userState?._id,
        },
      });
      onTutorialCompleted?.();
    } catch (error) {}
  }, [completeTutorial, onTutorialCompleted, userState?._id]);

  const progress = tutorialList.reduce((prev, curr) => {
    if (curr.checked) {
      return (
        (((prev * tutorialList.length) / 100 + 1) * 100) / tutorialList.length
      );
    }
    return prev;
  }, 0);

  return (
    <div
      className={css`
        position: relative;
        padding-left: 30px;
        max-width: 360px;
      `}
    >
      <div
        className={css`
          border-left: 1px solid ${theme.colors.accent['purple/light']};
          position: absolute;
          height: calc(100% + 100px);
          width: 1px;
          /* background-color: ${theme.colors.primary['primary/02']}; */
          top: -50px;
          left: 0px;
        `}
      />
      <Box
        noPadding
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        width="100%"
        marginBottom="3xl"
      >
        <Box noPadding marginRight="xl">
          <Text.Body marginBottom="4xs" marginTop="4xs">
            Welcome to Multiplayer
          </Text.Body>
          <Text.BodySM marginBottom="4xs" marginTop="xs" color="text/75%">
            Get familiar with our app
          </Text.BodySM>
        </Box>
        <InviteDialog open={inviteOpen} onOpenChange={setInviteOpen} />
        <CircularProgress
          value={progress}
          color={
            progress < 30
              ? theme.colors.accent['red/main']
              : progress < 60
              ? theme.colors.accent['yellow/main']
              : theme.colors.accent['green/main']
          }
        />
      </Box>

      {tutorialList.map((item, idx) => (
        <React.Fragment key={item.key}>
          <TutorialItem {...item} />
          {tutorialList.length !== idx + 1 && <TutorialItemSeparator />}
        </React.Fragment>
      ))}

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        width="100%"
        backgroundColor="primary/02"
        borderRadius="l"
        padding="l"
        marginTop="2xl"
      >
        <Box noPadding>
          <Text.Body marginBottom="4xs" marginTop="4xs">
            {isTutorialCompleted ? 'You’re all set up!' : 'Skip for now?'}
          </Text.Body>
          <Text.TextSmall1 marginBottom="4xs" marginTop="xs" color="text/75%">
            {isTutorialCompleted
              ? 'You’re ready to start your Multiplayer adventure. Remember, you can always rewatch our tutorial in Settings if you ever feel stuck.'
              : 'You can always restart tutorial from the settings menu.'}
          </Text.TextSmall1>
        </Box>
        <div
          className={css`
            width: 32px;
            height: 32px;
            border-radius: 16px;
            background-color: ${theme.colors.primary['primary/03']};
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: ${loading ? 'not-allowed' : 'pointer'};
            opacity: ${loading ? 0.5 : 1};
          `}
          onClick={handleTutorialComplete}
        >
          <CheckIcon
            fill={theme.colors.text['text/100%']}
            style={{ width: '16px' }}
          />
        </div>
      </Box>
    </div>
  );
};
