import React from 'react';

import { DropdownI, ProfileIcon, Text } from 'components';
import { GearIcon, SignOutIcon, UserIcon } from 'components/Icons';

import './index.css';
import { useNavigate } from 'react-router-dom';
import { ERouteName } from 'pages';
import { css } from '@emotion/css';
import { ENotificationKey } from 'shared';
import pubsub from 'sweet-pubsub';

type NavLinkProps = {
  to: string;
  label: string;
  Icon: React.FC<any>;
};
const NavLink: React.FC<NavLinkProps> = ({ to, Icon, label }) => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate(to);
  };
  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin-bottom: 18px;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0px;
        }
      `}
      onClick={onClick}
    >
      <Icon
        fill="white"
        style={{
          height: '16px',
          width: '16px',
          marginRight: '5px',
        }}
      />
      <Text.TextSmall1 marginBottom="4xs" marginTop="4xs">
        {label}
      </Text.TextSmall1>
    </div>
  );
};

type Props = {
  user: {
    name: string;
    id: string;
    profileUrl?: string;
    role?: string;
  };
  workspace: {
    id: string;
    name: string;
  }[];
};

export const UserDropdown: React.FC<Props> = ({ user, workspace }) => {
  const handleOnLogout = () => {
    pubsub.emit(ENotificationKey.SHOW_LOGOUT, { value: true });
  };
  return (
    <DropdownI.Root
      align="menu"
      noShadow
      triggerComponent={
        <div>
          <ProfileIcon
            userId={user.id}
            userName={user.name}
            profilePicture={user.profileUrl}
            noDisplay
            size="large"
          />
        </div>
      }
    >
      <div
        className={css`
          width: 100%;
          height: 92px;
          border-bottom: 1px solid #302e44;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding-inline: 10px;
        `}
      >
        <ProfileIcon
          userId={user.id}
          userName={user.name}
          profilePicture={user.profileUrl}
          noDisplay
          size="large"
        />
        <div
          className={css`
            width: calc(100% - 58px);
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
          `}
        >
          <Text.TextSmall1
            marginBottom="4xs"
            marginTop="4xs"
            style={{
              marginLeft: '10px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              // width: '100%',
              paddingLeft: '0px',
              textAlign: 'left',
            }}
          >
            {user.name}
          </Text.TextSmall1>
          <Text.TextSmall1
            marginBottom="4xs"
            marginTop="xs"
            style={{
              marginLeft: '10px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              // width: '140px',
              paddingLeft: '0px',
              textAlign: 'left',
              color: '#C5C4C8',
            }}
          >
            {user.role}
          </Text.TextSmall1>
        </div>
      </div>

      <DropdownI.Item>
        <div
          className={css`
            width: 100%;
            height: 95px;

            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 20px;
            color: #fa4d35;
          `}
        >
          <NavLink
            label="Profile"
            Icon={UserIcon}
            to={ERouteName.Profile + user.id}
          />

          <NavLink
            label="Settings"
            Icon={GearIcon}
            to={ERouteName.MyDetailsSetting}
          />
        </div>
      </DropdownI.Item>

      <DropdownI.Item>
        <div
          className={css`
            width: 100%;
            height: calc(100% - 187px);
            /* height: 48px; */
            border-top: 1px solid #302e44;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 20px;
            color: #fa4d35;
            cursor: pointer;
          `}
          onClick={handleOnLogout}
        >
          <SignOutIcon
            fill="#fa4d35"
            style={{
              width: '16px',
              height: '16px',
              marginRight: '8px',
            }}
          />
          Logout
        </div>
      </DropdownI.Item>
    </DropdownI.Root>
  );
};
