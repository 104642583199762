import { useLazyQuery, useMutation } from '@apollo/client';
import { css } from '@emotion/css';
import { Text } from 'components';

import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';

import { GET_NOTIFICATIONS, UPDATE_NOTIFICATIONS } from './gql';
import { NotificationEmptyState } from './NotificationEmptyState';
import { NotificationItem } from './NotificationItem';
import { ENotificationCategory, INotification } from './type';

export const Todo: React.FC<any> = () => {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [fetchNotification, { loading }] = useLazyQuery<{
    notifications: INotification[];
  }>(GET_NOTIFICATIONS);

  useEffect(() => {
    fetchNotification({
      variables: {
        category: ENotificationCategory.TODO,
        page: 0,
      },
      fetchPolicy: 'network-only',
    }).then((res) => {
      if (res.data?.notifications && res.data?.notifications.length > 0) {
        setNotifications(res.data?.notifications);
      }
    });
  }, [fetchNotification]);

  const [changeStatus] = useMutation(UPDATE_NOTIFICATIONS);

  const onStatusChange = useCallback(
    (id: string, newCategory: ENotificationCategory) => {
      changeStatus({
        variables: {
          id,
          newCategory,
        },
      }).then(() => {
        setNotifications((prev) => prev.filter((e) => e._id !== id));
      });
    },
    [changeStatus]
  );

  return (
    <div
      className={css`
        width: 100%;
      `}
    >
      {loading && <Text.Body>Loading...</Text.Body>}
      {notifications.map((item) => (
        <NotificationItem
          key={item._id}
          id={item._id}
          created={format(new Date(item.created), 'MMMM d, yyyy • h:mm aa')}
          type={item.type}
          status={item.category}
          sender={item.sender}
          isRead={item.isRead}
          topicBasics={{
            id: item.topic?._id,
            name: item.topic?.name,
            isAdmin: true,
            isWrite: true,
            closed: item.topic?.closed,
          }}
          link={item.link}
          activityId={item.activity?._id}
          google={item.google}
          onStatusChange={onStatusChange}
        />
      ))}

      {!loading && notifications.length === 0 && (
        <NotificationEmptyState text="Save your notifications as to-dos and they’ll show up here." />
      )}
    </div>
  );
};
