import { gql, useMutation } from '@apollo/client';
import { Dialog, Input, Select } from 'components';
import { addFilter } from 'features/activityFilter';
import { useAppDispatch, useAppSelector } from 'hooks';
import React, { useCallback, useMemo, useState } from 'react';
import { MultiValue, SingleValue } from 'react-select';
import { RootState } from 'reducers';
import { theme } from 'theme';
import { capitalizeString } from 'utils';

interface Props {
  open?: boolean;
  topicId: string;
  itemId?: string;
  onOpenChange?(open: boolean): void;
}

const ADD_TO_GROUP = gql`
  mutation addToGroup($topicId: ID!, $itemId: ID!, $group: String!) {
    addToGroup(topicId: $topicId, itemId: $itemId, group: $group) {
      _id
      name
    }
  }
`;

const CREATE_GROUP = gql`
  mutation createGroup($topicId: ID!, $group: String!) {
    createGroup(topicId: $topicId, group: $group) {
      _id
      name
    }
  }
`;

// const GROUP = gql`
//   query groups($topicId: ID!) {
//     groups(topicId: $topicId) {
//       name
//       _id
//     }
//   }
// `;

export function AddToGroup(props: Props) {
  const [newGroup, setNewGroup] = useState('');
  const [category, setCategory] = useState('');

  const dispatch = useAppDispatch();
  const group = useAppSelector((state: RootState) => state.activityFilter);

  const [addToGroup, { loading }] = useMutation(ADD_TO_GROUP);
  const [createGroup] = useMutation(CREATE_GROUP);
  // const { data: groupResult } = useQuery<any>(GROUP, {
  //   variables: {
  //     topicId: props.topicId,
  //   },
  // });

  const categories = useMemo(
    () =>
      group?.map((item: any) => ({
        label: capitalizeString(item.name, true),
        value: item.name,
      })) || [],
    [group]
  );

  const onSelectChange = useCallback(
    (
      values:
        | SingleValue<{
            value: string;
            label: string;
          }>
        | MultiValue<{
            value: string;
            label: string;
          }>
    ) => {
      const casted = values as { label: string; value: string };
      setCategory(casted.value);
      setNewGroup('');
    },
    []
  );

  return (
    <Dialog
      additionalContent={
        <div>
          {props.itemId && (
            <>
              <Select
                background={theme.colors.primary['primary/01']}
                // color="text/dark"
                // borderColor="text/light"
                data={categories}
                onChange={onSelectChange}
                placeholder="Select the group"
              />
              <br />
            </>
          )}
          <Input
            placeholder="Create a new group"
            value={newGroup}
            onChange={(e) => {
              setNewGroup(e.target.value);
              setCategory('');
            }}
          />
        </div>
      }
      description={''}
      open={props.open}
      submitting={loading}
      submitDisabled={Boolean(!category && !newGroup)}
      title="Add to a group"
      onOpenChange={props.onOpenChange}
      onSubmit={async (onClose) => {
        if (!props.itemId) {
          createGroup({
            variables: {
              topicId: props.topicId,
              group: newGroup,
            },
          })
            .then((re) => {
              // window.location.reload();
              dispatch(addFilter(re.data.createGroup));
              onClose();
              // navigate(ERouteName.Workspace);
            })
            .catch(() => {
              onClose();
            });
        } else {
          addToGroup({
            variables: {
              topicId: props.topicId,
              itemId: props.itemId,
              group: newGroup || category,
            },
          })
            .then((re) => {
              dispatch(addFilter(re.data.addToGroup));
              onClose();
              // navigate(ERouteName.Workspace);
            })
            .catch(() => {
              onClose();
            });
        }
      }}
    />
  );
}
