import { css } from '@emotion/css';
import React from 'react';
import { theme } from 'theme';
import { Text } from 'components';

type Props = {
  text: string;
  Icon: React.FC<any>;
  selected: boolean;
  onClick?: VoidFunction;
};
export const SearchFilterItem: React.FC<Props> = ({
  text,
  Icon,
  selected,
  onClick,
}) => {
  return (
    <div
      className={css`
        height: 28px;
        border-radius: 14px;
        border: 2px solid
          ${selected
            ? theme.colors.accent['purple/main']
            : theme.colors.accent['purple/light']};
        /* background-color: ${selected
          ? theme.colors.primary['primary/04']
          : ''}; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-inline: 16px;
        cursor: pointer;
        margin-right: 8px;
      `}
      onClick={onClick}
    >
      <Icon
        fill={
          selected
            ? theme.colors.accent['purple/main']
            : theme.colors.text['text/blue/soft']
        }
        style={{
          width: '12px',
          height: '12px',
          marginRight: '8px',
        }}
      />
      <Text.TextSmall2 color={selected ? 'purple/main' : 'text/blue/soft'}>
        {text}
      </Text.TextSmall2>
    </div>
  );
};
