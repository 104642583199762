export enum ENotificationKey {
  NOTIFICATION_READ = 'NOTIFICATION_READ',
  GOOGLE_LINK_ADDED = 'GOOGLE_LINK_ADDED',
  NOTION_LINK_ADDED = 'NOTION_LINK_ADDED',
  LINEAR_LINK_ADDED = 'LINEAR_LINK_ADDED',
  SHOW_DROPDOWN = 'SHOW_DROPDOWN',
  CHAT_RESPONSE_END = 'CHAT_RESPONSE_END',
  INTEGRATION_DONE = 'INTEGRATION_DONE',
  EDIT_TOPIC_OPEN = 'EDIT_TOPIC_OPEN',
  SHOW_LOGOUT = 'SHOW_LOGOUT',
}
