import React from 'react';
import { Text } from 'components';
import { css } from '@emotion/css';
import { Close, FileArrowUpIcon, FilePdfIcon } from 'components/Icons';
import { theme } from 'theme';

interface Props {
  className?: string;
  label?: string;
  placeholder?: string;
  files: File[] | null;
  setFiles: React.Dispatch<React.SetStateAction<File[] | null>>;
}

export const FileUpload: React.FC<Props> = ({
  className,
  label,
  placeholder,
  files,
  setFiles,
}) => {
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setFiles(Array.from(event.dataTransfer.files));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFiles(Array.from(event.target.files as FileList));
  };

  return (
    <div
      className={className}
      onDrop={handleDrop}
      onDragOver={(event) => event.preventDefault()}
      //   style={{ height: '100px', border: '1px solid black' }}
    >
      <div
        className={css`
          padding: 0px 16px 16px 16px;
        `}
      >
        <Text.TextSmall2 color="text/light">{label || 'Files'}</Text.TextSmall2>
        <div
          className={css`
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
          `}
        >
          <div
            className={css`
              width: 150px;
              height: 65px;
              position: relative;
            `}
          >
            <FileArrowUpIcon
              fill="#817AEC"
              style={{
                width: '32px',
                height: '32px',
                cursor: 'pointer',
                position: 'absolute',
                top: 0,
                left: 59,
              }}
            />
            <div
              className={css`
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
              `}
            >
              <Text.TextSmall2
                marginBottom="4xs"
                marginTop="4xs"
                style={{
                  color: '#817AEC',
                  textAlign: 'center',
                }}
              >
                Drag and drop to upload or click to select files
              </Text.TextSmall2>
            </div>
            <input
              className={css`
                width: 100%;
                height: 100%;
                position: absolute;
                &::-webkit-file-upload-button {
                  visibility: hidden;
                }
                &::before {
                  content: '';
                  display: flex;
                  align-items: flex-end;
                  justify-content: center;
                  width: 100%;
                  height: 100%;
                  outline: none;
                  -webkit-user-select: none;
                  user-select: none;
                  cursor: pointer;
                  font-weight: 400;
                  font-size: 12px;
                  color: ${theme.colors.accent['purple/main']};
                  background-color: '#ff00000';
                  word-wrap: break-word;
                }
              `}
              type="file"
              onChange={handleInputChange}
            />
          </div>
        </div>
        {files && (
          <ul
            className={css`
              width: 100%;
              padding: 0;
              list-style-type: none;
            `}
          >
            {Array.from(files).map((file, index) => (
              <li
                key={index}
                className={css`
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                `}
              >
                <span
                  className={css`
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                  `}
                >
                  <span
                    className={css`
                      width: 24px;
                      height: 24px;
                      margin-right: 8px;
                      border-radius: 12px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      background-color: ${theme.colors.primary['primary/03']};
                    `}
                  >
                    <FilePdfIcon
                      fill={theme.colors.text['text/blue/soft']}
                      style={{ width: '12px' }}
                    />
                  </span>{' '}
                  {file.name}
                </span>
                <Close
                  fill={theme.colors.text['text/blue/soft']}
                  style={{ width: '12px', cursor: 'pointer' }}
                  onClick={() => {
                    const newFiles = Array.from(files);
                    newFiles.splice(index, 1);
                    setFiles(newFiles);
                  }}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
