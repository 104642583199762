import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { Comment, SlackLogo, GoogleLogo } from 'components/Icons';
import { EActivityType } from 'model';
import { ActivityHolder } from 'components';

type DocProps = {
  userName: string;
  createdBy: string;
  deletedBy?: string;
  createAt: number;
  type: EActivityType;
  last?: boolean;
};

export const DeletedItem: React.FC<DocProps> = ({
  userName,
  deletedBy,
  createAt,
  createdBy,
  type,
  last = false,
}) => {
  const renderIcon = useMemo(() => {
    switch (type) {
      case EActivityType.COMMENT:
        return <Comment style={{ width: '16px' }} />;

      case EActivityType.SLACK:
        return <SlackLogo style={{ width: '16px' }} />;

      case EActivityType.GOOGLE:
        return <GoogleLogo style={{ width: '16px' }} />;

      default:
        return <Comment style={{ width: '16px' }} />;
    }
  }, [type]);

  return (
    <ActivityHolder.Item
      topicId=""
      stared={false}
      type={EActivityType.DELETED}
      Icon={renderIcon}
      title={`*<a href="${
        deletedBy ? '/profile/' + deletedBy : '#'
      }">${userName}</a>** deleted this item`}
      subTitle={`The item was created at ${format(
        +createAt,
        'MMMM d, yyyy • h:mm aa'
      )} by ${createdBy}`}
      last={last}
      // color={theme.colors.primary['primary/03']}
    >
      {/* <Text.Body color="text/light">Deleted this item</Text.Body> */}
    </ActivityHolder.Item>
  );
};
