import styled from '@emotion/styled';
import { ContextMenu } from 'components';
import { IUser } from 'model';
import { theme } from 'theme';

export const Container = styled.div`
  /* display: grid;
  grid-template-columns: 70% 30%; */
  width: 100%;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  /* margin-top: ${theme.spacing.l}px; */
`;
export const Header = styled.tr`
  border: 1px solid #272539;
`;
export const Tr = styled.tr`
  width: 100%;
  /* border-bottom: 1px solid #211f30; */
  border: 1px solid #272539;
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.primary['primary/03']};
    border-width: 0px;
  }
`;
export const Th = styled.th`
  text-align: left;
  /* padding-left: ${theme.spacing.m}px; */
  cursor: pointer;
`;
export const Td = styled.td`
  padding-left: ${theme.spacing.m}px;
  padding: 14px 0px;
  /* max-width: 400px; */
  /* border: 1px solid #272539; */
`;

type Props = {
  children: React.ReactNode;
  topic: {
    _id: string;
    name: string;
    public?: boolean;
    closed?: boolean;
    created: number;
    updated?: number;
    creatorId: IUser;
  };
  className?: string;
  style?: any;
  onOpenChange?(open: boolean): void;
  handleTopicDelete(): void;
  handleTopicClose(): void;
  handleMakePublic(): void;
};
export const TdWithMenu: React.FC<Props> = ({
  children,
  handleMakePublic,
  handleTopicClose,
  handleTopicDelete,
  topic,
  onOpenChange,
  className,
  style,
}) => (
  <Td className={className} style={style}>
    <ContextMenu
      topic={topic}
      onOpenChange={onOpenChange}
      handleMakePublic={handleMakePublic}
      handleTopicClose={handleTopicClose}
      handleTopicDelete={handleTopicDelete}
    >
      {children}
    </ContextMenu>
  </Td>
);
export const TBody = styled.tbody``;
export const THead = styled.thead``;
