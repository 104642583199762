import React from 'react';

export const LogoIcon: React.FC<React.HTMLAttributes<SVGElement>> = (props) => {
  return (
    <svg
      width={37}
      height={30}
      viewBox="0 0 37 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke="#6A53FE"
        strokeWidth={5.283}
        strokeLinecap="round"
        d="M3.353 3.224v23.56"
      />
      <path
        stroke="#8D86F8"
        strokeWidth={5.283}
        strokeLinecap="round"
        d="M13.92 3.224v23.56"
      />
      <path
        stroke="#C7C4F2"
        strokeWidth={5.283}
        strokeLinecap="round"
        d="M24.486 3.224v23.56"
      />
      <path
        d="M31.83 11.516c2.85-.435 5.022-2.7 5.022-5.43 0-2.73-2.172-4.995-5.022-5.429-.545-.083-.996.373-.996.925v9.009c0 .552.45 1.008.996.925Z"
        fill="#6A53FE"
      />
    </svg>
  );
};
