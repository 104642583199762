import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  CreateTopic,
  DialogBlank,
  Page,
  PageLoading,
  Text,
  Tutorial,
} from 'components';
import { Container } from './components/primary';
import { Feeds } from './components/Feeds';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { EFeedType, TFeed } from './type';
import { UserContext } from 'context';
import { FeedDashboard } from './components/FeedDashboard';
import { IUser, TutorialProgress } from 'model';
import { css } from '@emotion/css';
import {
  FunnelIcon,
  MotePencilIcon,
  PausePlainIcon,
  PlayPlainIcon,
} from 'components/Icons';
import PodcastBG from './podcast-bg.svg';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { displayUser, secondsToMinutes } from 'utils';

export const GET_COLLABORATORS = gql`
  query Collaborators {
    collaborators {
      _id
      businessEmail
      role
      profileUrl
      firstName
      lastName
    }
  }
`;

type Collaborator = {
  collaborators: IUser[];
};

const GET_FEED = gql`
  query feeds($workspaceId: ID!, $type: String) {
    feeds(workspaceId: $workspaceId, type: $type) {
      _id
      workspace {
        _id
        name
      }
      topic {
        _id
        name
      }
      closed
      count
      created
      user {
        _id
        businessEmail
        firstName
        lastName
      }
      type
      activity
    }
  }
`;

const GET_PROGRESS = gql`
  query TutorialProgress($userId: ID!) {
    tutorialProgress(userId: $userId) {
      integrations
      invitation
      profile
      tutorial
      userOnboarded
    }
  }
`;

const PODCAST = gql`
  query TodayPodcast {
    todayPodcast
  }
`;

// const GET_SUMMARY = gql`
//   query Query($workspaceId: ID!) {
//     feedReport(workspaceId: $workspaceId)
//   }
// `;

export const Feed = () => {
  const { state: userState } = useContext(UserContext);
  const [isTutorialCompleted, setIsTutorialCompleted] = useState(false);
  const [currentTime, setCurrentTime] = useState('');
  const [leftTime, setLeftTime] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [getFeed, { loading, data }] = useLazyQuery<{ feeds: TFeed[] }>(
    GET_FEED,
    {
      fetchPolicy: 'network-only',
    }
  );

  const { data: collabData } = useQuery<Collaborator>(GET_COLLABORATORS);

  const { data: podcastResult, loading: podcastLoading } = useQuery<{
    todayPodcast?: string;
  }>(PODCAST);

  useEffect(() => {
    if (userState?.workspace) {
      getFeed({
        variables: {
          workspaceId: userState?.workspace,
        },
        fetchPolicy: 'network-only',
      });
    }
  }, [getFeed, userState?.workspace]);

  // const { loading: summaryLoading, data: summaryData } = useQuery<{
  //   feedReport: string;
  // }>(GET_SUMMARY, {
  //   variables: {
  //     workspaceId: getFromLocalStorage('workspaceId'),
  //   },
  // });

  const [
    getProgress,
    { data: tutorialResult, loading: progressLoading, error },
  ] = useLazyQuery<{ tutorialProgress: TutorialProgress }>(GET_PROGRESS);

  useEffect(() => {
    if (userState?._id) {
      getProgress({
        variables: {
          userId: userState?._id,
        },
      });
    }
  }, [getProgress, userState?._id]);

  const isTutorialDone = Boolean(
    tutorialResult?.tutorialProgress?.userOnboarded || isTutorialCompleted
  );

  // collapse same feed by increasing count
  const feeds: TFeed[] = [];
  data?.feeds.forEach((_feed) => {
    const feed = { ..._feed };
    if (feed.type === EFeedType.TOPIC_UPDATE) {
      const index = feeds.findIndex(
        (f) =>
          f.topic?._id === feed.topic?._id &&
          f.type === feed.type &&
          new Date(feed.created).getDate() === new Date(f.created).getDate()
      );
      if (index > -1) {
        feeds[index].count = (feeds[index].count || 0) + (feed.count || 0);
      } else {
        feeds.push(feed);
      }
    } else {
      feeds.push(feed);
    }
  });

  const members = collabData?.collaborators
    .map((item) => {
      return {
        userId: item._id,
        profilePicture: item.profileUrl,
        email: item.businessEmail!,
        role: item.role,
        name: displayUser(item)!,
      };
    })
    ?.filter((item) => item.userId !== userState._id);

  const handleCreateTopic = () => {
    setIsDrawerOpen(true);
    // navigate(ERouteName.TopicCreate);
  };

  const handleCancelCreation = () => {
    setIsDrawerOpen(false);
    // navigate(ERouteName.TopicCreate);
  };

  return (
    <Page title="Feed | Multiplayer">
      <Container
        completed={Boolean(isTutorialDone || progressLoading || error?.message)}
      >
        <Box height="100%" width="100%" padding="s">
          <Box
            noPadding
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Text.H1 color="text/100%" marginTop="4xs" marginBottom="4xs">
              Welcome to your Feed ☀️
            </Text.H1>

            <Box
              noPadding
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <DialogBlank
                content={
                  <div
                    className={css`
                      width: 800px;
                      height: 600px;
                      background: url(${PodcastBG});
                      background-size: cover;
                      padding: 100px;
                    `}
                  >
                    <FunnelIcon
                      style={{ width: '64px', height: '64px' }}
                      fill="#8D86F8"
                    />
                    <Text.H1 marginTop="2xl" marginBottom="4xs">
                      Your Multiplayer podcast
                    </Text.H1>
                    {!userState?.lastPodcast && (
                      <Text.Body>
                        No updates for you today! Check back tomorrow, new
                        podcasts drop every morning.
                      </Text.Body>
                    )}
                    <div
                      className={css`
                        border: 1px solid #ffffff;
                        font-size: 8px;
                        color: #ffffff;
                        border-radius: 10px;
                        padding: 4px 8px;
                        width: fit-content;
                      `}
                    >
                      Powered using AI
                    </div>

                    <div
                      className={css`
                        margin-top: 130px;
                        width: 100%;
                      `}
                    >
                      <AudioPlayer
                        autoPlay
                        src={
                          podcastResult?.todayPodcast ||
                          'https://storage.googleapis.com/multiplayer-images-public/podcast-multiplayer1695708471307.mp3'
                        }
                        // onPlay={(e) => console.log('onPlay')}
                        showJumpControls={false}
                        defaultCurrentTime="0:00"
                        defaultDuration="0:00"
                        customIcons={{
                          play: (
                            <PlayPlainIcon
                              fill="#8C83C3"
                              style={{ width: '18px', height: '18px' }}
                            />
                          ),
                          pause: (
                            <PausePlainIcon
                              fill="#8C83C3"
                              style={{ width: '18px', height: '18px' }}
                            />
                          ),
                          rewind: <div>3</div>,
                          forward: <div>4</div>,
                          previous: <div>5</div>,
                          next: <div>6</div>,
                          loop: <div>7</div>,
                          loopOff: <div>8</div>,
                          volume: <div>9</div>,
                          volumeMute: <div>10</div>,
                        }}
                        onListen={(e: any) => {
                          setCurrentTime(
                            secondsToMinutes(e.srcElement.currentTime)
                          );
                          setLeftTime(
                            secondsToMinutes(
                              e.srcElement.duration - e.srcElement.currentTime
                            )
                          );
                        }}
                        customProgressBarSection={[
                          <Text.TextSmall1
                            marginBottom="4xs"
                            marginTop="4xs"
                            style={{ color: '#8d86f8' }}
                          >
                            {currentTime}
                          </Text.TextSmall1>,
                          RHAP_UI.PROGRESS_BAR,
                          <Text.TextSmall1
                            marginBottom="4xs"
                            marginTop="4xs"
                            style={{ color: '#8d86f8' }}
                          >
                            {leftTime}
                          </Text.TextSmall1>,
                        ]}
                        customControlsSection={[
                          <div />,
                          // RHAP_UI.ADDITIONAL_CONTROLS,
                          RHAP_UI.MAIN_CONTROLS,
                          <div />,
                          // RHAP_UI.VOLUME_CONTROLS,
                        ]}
                        className={css`
                          border: 1px solid transparent;
                          box-shadow: none;
                          background-color: transparent;
                          .rhap_main-controls {
                            width: 64px;
                            height: 64px;
                            border-radius: 32px;
                            background-color: #fff;
                            .rhap_main-controls-button {
                              width: 100%;
                              height: 100%;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                            }
                          }
                          .rhap_progress-filled {
                            background-color: #fff;
                          }
                          .rhap_download-progress {
                            background-color: #8d86f8;
                          }
                          .rhap_progress-indicator {
                            width: 15px;
                            height: 15px;
                            background-color: #fff;
                            margin-top: 2.7px;
                          }
                          .rhap_time {
                            color: #8d86f8;
                            font-family: Fira Sans;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                          }
                        `}
                      />
                    </div>
                  </div>
                }
                // open={openPodcast}
                // onOpenChange={setOpenPodcast}
              >
                <button
                  className={css`
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    background-color: #211f30;
                    border-radius: 16px;
                    height: 80px;
                    padding: 1px 24px;
                    cursor: ${podcastLoading ? 'progress' : 'pointer'};
                    margin-right: 16px;
                  `}
                  disabled={podcastLoading}
                >
                  <FunnelIcon
                    style={{
                      width: '32px',
                      height: '32px',
                      marginRight: '8px',
                    }}
                    fill="#fff"
                  />
                  <Text.TextSmall1 marginBottom="4xs" marginTop="4xs">
                    Listen to your daily podcast
                  </Text.TextSmall1>
                </button>
              </DialogBlank>

              <div
                className={css`
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: flex-start;
                  background-color: #6a53fe;
                  border-radius: 16px;
                  height: 80px;
                  padding: 16px 24px;
                  cursor: pointer;
                `}
                onClick={handleCreateTopic}
              >
                <MotePencilIcon
                  style={{
                    width: '32px',
                    height: '32px',
                  }}
                  fill="#fff"
                />
                <Text.TextSmall1
                  marginBottom="4xs"
                  marginTop="4xs"
                  style={{ marginLeft: '8px' }}
                >
                  Create new topic
                </Text.TextSmall1>
              </div>
            </Box>
          </Box>

          <FeedDashboard
            onBoardingCompleted={Boolean(
              isTutorialDone || progressLoading || error?.message
            )}
          />

          {loading && <PageLoading />}

          {feeds && feeds?.length > 0 ? (
            <Feeds feeds={feeds} />
          ) : (
            !loading && (
              <Box width="100%" justifyContent="center" alignItems="center">
                <Text.H4 color="text/light">
                  Catch up with the latest updates and topic activities inside
                  your organization here
                </Text.H4>
              </Box>
            )
          )}
        </Box>

        {!isTutorialDone && !progressLoading && !error?.message && (
          <Tutorial
            tutorialProgress={tutorialResult?.tutorialProgress}
            onTutorialCompleted={() => {
              setIsTutorialCompleted(true);
            }}
          />
        )}
      </Container>
      <CreateTopic
        isDrawerOpen={isDrawerOpen}
        members={members || []}
        onCancel={handleCancelCreation}
        setIsDrawerOpen={setIsDrawerOpen}
        isEdit={false}
      />
    </Page>
  );
};
