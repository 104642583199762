import React, { Ref, PropsWithChildren, useState } from 'react';
import ReactDOM from 'react-dom';
import { cx, css } from '@emotion/css';
import { theme } from 'theme';
import { Button as CustomButton, Dialog, Text } from 'components';
import styled from '@emotion/styled';

interface BaseProps {
  className: string;
  [key: string]: unknown;
}
type OrNull<T> = T | null;

export const Button = React.forwardRef(
  (
    {
      className,
      active,
      reversed,
      ...props
    }: PropsWithChildren<
      {
        active: boolean;
        reversed: boolean;
      } & BaseProps
    >,
    ref: Ref<OrNull<HTMLSpanElement>>
  ) => (
    <span
      {...props}
      ref={ref as any}
      className={cx(
        className,
        css`
          cursor: pointer;
          color: ${reversed
            ? active
              ? 'white'
              : '#aaa'
            : active
            ? 'black'
            : '#ccc'};
        `
      )}
    />
  )
);

type SubmitProps = {
  text?: string;
  loading?: boolean;
  onSubmit?: VoidFunction;
};
export const SubmitButton: React.FC<SubmitProps> = ({
  text = 'Submit',
  loading,
  onSubmit,
}) => {
  return (
    <div>
      <CustomButton
        text={text}
        shape="rectangle"
        color="secondary"
        onClick={onSubmit}
        loading={loading}
        className={css`
          margin-left: 16px !important;
          background-color: ${theme.colors.primary['primary/04']} !important;
          border-color: ${theme.colors.primary['primary/04']} !important;
        `}
      />
    </div>
  );
};

// export const SubmitButton = React.forwardRef(
//   (
//     { text = 'Submit', onSubmit, ...props }: PropsWithChildren<SubmitProps>,
//     ref: Ref<OrNull<HTMLSpanElement>>
//   ) => (
//     <Button
//       text={text}
//       shape="rectangle"
//       color="secondary"
//       onClick={onSubmit}
//     />
//   )
// );

export const Icon = React.forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: Ref<OrNull<HTMLSpanElement>>
  ) => (
    <span
      {...props}
      ref={ref as any}
      className={cx(
        'material-icons',
        className,
        css`
          font-size: 18px;
          vertical-align: text-bottom;
        `
      )}
    />
  )
);

export const Menu = React.forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: Ref<OrNull<HTMLDivElement>>
  ) => (
    <div
      {...props}
      ref={ref as any}
      className={cx(
        className,
        css`
          & > * {
            display: inline-block;
          }
          & > * + * {
            margin-left: 15px;
          }
        `
      )}
    />
  )
);

export const Portal = ({ children }: any) => {
  return typeof document === 'object'
    ? ReactDOM.createPortal(children, document.body)
    : null;
};

export const Toolbar = React.forwardRef(
  (
    { className, dark, ...props }: PropsWithChildren<BaseProps>,
    ref: Ref<OrNull<HTMLDivElement>>
  ) => (
    <Menu
      {...props}
      ref={ref as any}
      className={cx(
        className,
        css`
          width: 100%;
          box-sizing: border-box;
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
          /* border-bottom: 1px solid ${theme.colors.primary[
            'primary/inactive'
          ]}; */
          background-color: ${dark
            ? theme.colors.primary['primary/04']
            : theme.colors.primary['primary/03']};
          border-radius: 0px 0px ${theme.borderRadius.m}px
            ${theme.borderRadius.m}px;
          padding: ${theme.spacing.m}px ${theme.spacing.m}px
            ${theme.spacing.m}px ${theme.spacing.m}px;
        `
      )}
    />
  )
);

// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
const InlineChromiumBugfix = () => (
  <span
    contentEditable={false}
    className={css`
      font-size: 0;
    `}
  >
    ${String.fromCodePoint(160) /* Non-breaking space */}
  </span>
);

export const LinkComponent = ({ attributes, children, element }: any) => {
  // const selected = useSelected();
  return (
    <a
      {...attributes}
      href={element.url}
      target="_blank"
      rel="noreferrer"
      className={css`
        color: ${theme.colors.text['text/pink']};
        font-size: ${theme.textVariants.body.fontSize};
        font-weight: ${theme.textVariants.body.fontWeight};
        font-family: ${theme.textVariants.body.fontFamily};
        line-height: ${theme.textVariants.body.lineHeight};
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      `}
    >
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </a>
  );
};

export const ImageInput = styled.input`
  width: 100%;
  height: 40px;
  &::-webkit-file-upload-button {
    visibility: hidden;
  }
  &::before {
    content: 'Click to upload';
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* border-radius: 50%; */
    outline: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    /* color: rgba(255, 255, 255, 0); */
    color: #fff;
    //   border: 1px solid black;
    background-color: #1c294896;
  }
`;

type AddImageProps = {
  open?: boolean;
  onOpenChange?(open: boolean): void;
  onSubmit: (imageUrl: string) => void;
};
export function AddImageDialog(props: AddImageProps) {
  // const [topicCollaborators, setTopicCollaborators] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  // const [imageUrl, setImageUrl] = useState('');

  // const { state: userState } = useContext(UserContext);

  // const { data } = useQuery<WorkspaceInfo>(GET_MEMBERS, {
  //   variables: {
  //     loggedEmail: getFromLocalStorage('loggedEmail'),
  //   },
  // });

  // const [addToTopic, { loading }] = useMutation(INVITE);

  const onImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    // setPictureLoading(true);
    setLoading(true);
    try {
      const bodyContent = new FormData();
      bodyContent.append('image', event.target.files?.[0]!);
      // const res = await publicRequest({
      //   method: 'POST',
      //   url: `${env.backendURL}/api/profile-upload`,
      //   body: bodyContent,
      // });

      const headersList = {
        Accept: '*/*',
        'User-Agent': 'Thunder Client (https://www.thunderclient.com)',
      };

      const res = await fetch(
        `${process.env.REACT_APP_API_URL?.split('graphql').join(
          'api'
        )}/profile-upload`,
        {
          method: 'POST',
          body: bodyContent,
          headers: headersList,
        }
      );

      await res
        .text()
        .then((response) => {
          setLoading(false);
          // setImageUrl(response);
          props.onSubmit(response);
        })
        .catch((e) => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Dialog
      additionalContent={
        <div>
          {loading ? (
            <Text.H2>Loading...</Text.H2>
          ) : (
            <ImageInput
              type="file"
              accept="image/*"
              required
              onChange={onImageChange}
            />
          )}
        </div>
      }
      // description="Invite others to collaborate on this Topic"
      open={props.open}
      submitting={loading}
      submitDisabled={false}
      title="Upload image"
      onOpenChange={props.onOpenChange}
      // onSubmit={async (onClose) => {
      //   onClose();
      //   props.onSubmit(imageUrl);
      // }}
    />
  );
}

export function AddFileDialog(props: AddImageProps) {
  const [loading, setLoading] = useState(false);

  const onImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    try {
      const bodyContent = new FormData();
      bodyContent.append('image', event.target.files?.[0]!);

      const headersList = {
        Accept: '*/*',
        'User-Agent': 'Thunder Client (https://www.thunderclient.com)',
      };

      const res = await fetch(
        `${process.env.REACT_APP_API_URL?.split('graphql').join(
          'api'
        )}/profile-upload`,
        {
          method: 'POST',
          body: bodyContent,
          headers: headersList,
        }
      );

      await res
        .text()
        .then((response) => {
          setLoading(false);
          props.onSubmit(response);
        })
        .catch((e) => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Dialog
      additionalContent={
        <div>
          {loading ? (
            <Text.H2>Loading...</Text.H2>
          ) : (
            <ImageInput
              type="file"
              // accept="*/*"
              required
              onChange={onImageChange}
            />
          )}
        </div>
      }
      // description="Invite others to collaborate on this Topic"
      open={props.open}
      submitting={loading}
      submitDisabled={false}
      title="Upload file"
      onOpenChange={props.onOpenChange}
      // onSubmit={async (onClose) => {
      //   onClose();
      //   props.onSubmit(imageUrl);
      // }}
    />
  );
}

export * from './LinkDialog';
