import { Box, Checkbox, DropdownI, FilterDropdownItem, Text } from 'components';
import {
  CalendarBlankIcon,
  FunnelSimpleIcon,
  SpinnerGapIcon,
  UserIcon,
  WaveSineIcon,
} from 'components/Icons';
import { EMomentumType } from 'model';
import DatePicker from 'react-datepicker';
import React from 'react';
import { ETopicFilter, FilterStatusType } from '../types';
import { css } from '@emotion/css';
import { theme } from 'theme';

type Props = {
  filterSelected: ETopicFilter | undefined;
  onSelect: (value?: ETopicFilter) => void;
  startDate: Date | undefined;
  onStartDateChange: (date: Date) => void;
  handleFilterClick: (type: ETopicFilter) => void;
  endDate: Date | undefined;
  onEndDateChange: (date: Date) => void;
  members: {
    userId: string;
    profilePicture: string | undefined;
    email: string;
    role: string | undefined;
    name: string;
  }[];
  setUserFilter: React.Dispatch<React.SetStateAction<string[]>>;
  userFilter: string[];
  setStatusFilter: React.Dispatch<React.SetStateAction<FilterStatusType>>;
  statusFilter: string;
  setMomentumFilter: React.Dispatch<
    React.SetStateAction<EMomentumType | undefined>
  >;
  momentumFilter: EMomentumType | undefined;
};
export const FilterDropdown: React.FC<Props> = (props) => {
  return (
    <DropdownI.Root
      triggerComponent={
        <div>
          <FunnelSimpleIcon
            style={{ width: '24px', height: '24px' }}
            fill="#8C83C3"
          />
        </div>
      }
    >
      <Box position="relative" noPadding>
        <Box noPadding>
          <DropdownI.Sub
            triggerComponent={
              <FilterDropdownItem
                text="Date"
                Icon={CalendarBlankIcon}
                selected={props.filterSelected === ETopicFilter.DATE}
                onClick={() => props.onSelect(ETopicFilter.DATE)}
              />
            }
          >
            <Text.TextSmall2 color="text/light">Select range</Text.TextSmall2>
            <DatePicker
              closeOnScroll={true}
              selected={props.startDate}
              onChange={(date: Date) => {
                props.onStartDateChange(date);
                props.handleFilterClick(ETopicFilter.DATE);
              }}
              placeholderText="Start date"
              className={css`
                border: 0px solid red;
                height: 30px;
                border-radius: 8px;
                margin-top: 8px;
                background-color: ${theme.colors.primary['primary/03']};
                color: ${theme.colors.text['text/100%']};
              `}
            />
            <DatePicker
              closeOnScroll={true}
              selected={props.endDate}
              onChange={(date: Date) => {
                props.onEndDateChange(date);
                props.handleFilterClick(ETopicFilter.DATE);
              }}
              placeholderText="End date"
              className={css`
                border: 0px solid red;
                height: 30px;
                border-radius: 8px;
                margin-top: 8px;
                background-color: ${theme.colors.primary['primary/03']};
                color: ${theme.colors.text['text/100%']};
              `}
            />
          </DropdownI.Sub>
          <DropdownI.Sub
            triggerComponent={
              <FilterDropdownItem
                text="People"
                Icon={UserIcon}
                selected={props.filterSelected === ETopicFilter.PEOPLE}
                onClick={() => props.onSelect(ETopicFilter.PEOPLE)}
              />
            }
          >
            <div
              className={css`
                max-height: 150px;
                overflow: auto;
              `}
            >
              {props.members.map((user, idx) => (
                <div
                  key={idx}
                  className={css`
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    cursor: pointer;
                  `}
                  onClick={() => {
                    props.onSelect(ETopicFilter.PEOPLE);
                    props.handleFilterClick(ETopicFilter.PEOPLE);
                    props.setUserFilter((prev) => {
                      if (prev.includes(user.userId)) {
                        return prev.filter((u) => u !== user.userId);
                      } else {
                        return [...prev, user.userId];
                      }
                    });
                  }}
                >
                  <Checkbox
                    small
                    checked={props.userFilter.includes(user.userId)}
                  />
                  {user.profilePicture ? (
                    <img
                      src={user.profilePicture}
                      alt="profile"
                      className={css`
                        width: 12px;
                        height: 12px;
                        border-radius: 6px;
                        background-color: ${theme.colors.primary['primary/01']};
                        margin-right: 8px;
                      `}
                    />
                  ) : (
                    <div
                      className={css`
                        width: 12px;
                        height: 12px;
                        border-radius: 6px;
                        background-color: ${theme.colors.primary['primary/01']};
                        margin-right: 8px;
                      `}
                    />
                  )}
                  <Text.TextSmall1 color="text/blue/soft">
                    {user.name}
                  </Text.TextSmall1>
                </div>
              ))}
            </div>
          </DropdownI.Sub>
          <DropdownI.Sub
            triggerComponent={
              <FilterDropdownItem
                text="Status"
                Icon={SpinnerGapIcon}
                selected={props.filterSelected === ETopicFilter.STATUS}
                onClick={() => props.onSelect(ETopicFilter.STATUS)}
              />
            }
          >
            <DropdownI.Item>
              <div
                className={css`
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  align-items: center;
                  margin-bottom: 10px;
                  cursor: pointer;
                `}
                onClick={() => {
                  props.onSelect(ETopicFilter.STATUS);
                  props.setStatusFilter('opened');
                  props.handleFilterClick(ETopicFilter.STATUS);
                }}
              >
                <Checkbox small checked={props.statusFilter === 'opened'} />

                <div
                  className={css`
                    width: 6px;
                    height: 6px;
                    border-radius: 3px;
                    background-color: ${theme.colors.accent['green/main']};
                    margin-right: 5px;
                  `}
                />
                <Text.TextSmall1 marginBottom="4xs" marginTop="4xs">
                  Active
                </Text.TextSmall1>
              </div>
            </DropdownI.Item>
            <DropdownI.Item>
              <div
                className={css`
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  align-items: center;
                  margin-bottom: 10px;
                  cursor: pointer;
                `}
                onClick={() => {
                  props.onSelect(ETopicFilter.STATUS);
                  props.setStatusFilter('closed');
                  props.handleFilterClick(ETopicFilter.STATUS);
                }}
              >
                <Checkbox small checked={props.statusFilter === 'closed'} />

                <div
                  className={css`
                    width: 6px;
                    height: 6px;
                    border-radius: 3px;
                    background-color: ${theme.colors.primary['primary/03']};
                    margin-right: 5px;
                  `}
                />
                <Text.TextSmall1 marginBottom="4xs" marginTop="4xs">
                  Closed
                </Text.TextSmall1>
              </div>
            </DropdownI.Item>
            <DropdownI.Item>
              <div
                className={css`
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  align-items: center;
                `}
                onClick={() => {
                  props.onSelect(ETopicFilter.STATUS);
                  props.setStatusFilter('all');
                  props.handleFilterClick(ETopicFilter.STATUS);
                }}
              >
                <Checkbox small checked={props.statusFilter === 'all'} />

                <div
                  className={css`
                    width: 6px;
                    height: 6px;
                    border-radius: 3px;
                    background-color: ${theme.colors.accent['yellow/main']};
                    margin-right: 5px;
                  `}
                />
                <Text.TextSmall1 marginBottom="4xs" marginTop="4xs">
                  All
                </Text.TextSmall1>
              </div>
            </DropdownI.Item>
          </DropdownI.Sub>
          <DropdownI.Sub
            triggerComponent={
              <FilterDropdownItem
                text="Momentum"
                Icon={WaveSineIcon}
                selected={props.filterSelected === ETopicFilter.MOMENTUM}
                onClick={() => props.onSelect(ETopicFilter.MOMENTUM)}
              />
            }
          >
            {/* <DropdownI.Item>
          <div
            className={css`
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              margin-bottom: 10px;
              cursor: pointer;
            `}
            onClick={() => {
              onSelect(ETopicFilter.MOMENTUM);
              setMomentumFilter(EMomentumType.HIGHER);
              handleFilterClick(ETopicFilter.MOMENTUM);
            }}
          >
            <Checkbox
              small
              checked={momentumFilter === EMomentumType.HIGHER}
            />

            <div
              className={css`
                width: 6px;
                height: 6px;
                border-radius: 3px;
                background-color: ${theme.colors.accent[
                  'green/main'
                ]};
                margin-right: 5px;
              `}
            />
            <Text.TextSmall1 marginBottom="4xs" marginTop="4xs">
              Higher
            </Text.TextSmall1>
          </div>
        </DropdownI.Item> */}

            <DropdownI.Item>
              <div
                className={css`
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  align-items: center;
                  margin-bottom: 10px;
                  cursor: pointer;
                `}
                onClick={() => {
                  props.onSelect(ETopicFilter.MOMENTUM);
                  props.setMomentumFilter(EMomentumType.HIGH);
                  props.handleFilterClick(ETopicFilter.MOMENTUM);
                }}
              >
                <Checkbox
                  small
                  checked={props.momentumFilter === EMomentumType.HIGH}
                />

                <div
                  className={css`
                    width: 6px;
                    height: 6px;
                    border-radius: 3px;
                    background-color: ${theme.colors.accent['green/main']};
                    margin-right: 5px;
                  `}
                />
                <Text.TextSmall1 marginBottom="4xs" marginTop="4xs">
                  High
                </Text.TextSmall1>
              </div>
            </DropdownI.Item>

            <DropdownI.Item>
              <div
                className={css`
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  align-items: center;
                  margin-bottom: 10px;
                  cursor: pointer;
                `}
                onClick={() => {
                  props.onSelect(ETopicFilter.MOMENTUM);
                  props.setMomentumFilter(EMomentumType.MEDIUM);
                  props.handleFilterClick(ETopicFilter.MOMENTUM);
                }}
              >
                <Checkbox
                  small
                  checked={props.momentumFilter === EMomentumType.MEDIUM}
                />

                <div
                  className={css`
                    width: 6px;
                    height: 6px;
                    border-radius: 3px;
                    background-color: ${theme.colors.accent['yellow/main']};
                    margin-right: 5px;
                  `}
                />
                <Text.TextSmall1 marginBottom="4xs" marginTop="4xs">
                  Medium
                </Text.TextSmall1>
              </div>
            </DropdownI.Item>

            <DropdownI.Item>
              <div
                className={css`
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  align-items: center;
                  margin-bottom: 10px;
                  cursor: pointer;
                `}
                onClick={() => {
                  props.onSelect(ETopicFilter.MOMENTUM);
                  props.setMomentumFilter(EMomentumType.LOW);
                  props.handleFilterClick(ETopicFilter.MOMENTUM);
                }}
              >
                <Checkbox
                  small
                  checked={props.momentumFilter === EMomentumType.LOW}
                />

                <div
                  className={css`
                    width: 6px;
                    height: 6px;
                    border-radius: 3px;
                    background-color: ${theme.colors.accent['red/main']};
                    margin-right: 5px;
                  `}
                />
                <Text.TextSmall1 marginBottom="4xs" marginTop="4xs">
                  Low
                </Text.TextSmall1>
              </div>
            </DropdownI.Item>

            <DropdownI.Item>
              <div
                className={css`
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  align-items: center;
                  margin-bottom: 10px;
                  cursor: pointer;
                `}
                onClick={() => {
                  props.onSelect(ETopicFilter.MOMENTUM);
                  props.setMomentumFilter(EMomentumType.DORMANT);
                  props.handleFilterClick(ETopicFilter.MOMENTUM);
                }}
              >
                <Checkbox
                  small
                  checked={props.momentumFilter === EMomentumType.DORMANT}
                />

                <div
                  className={css`
                    width: 6px;
                    height: 6px;
                    border-radius: 3px;
                    background-color: ${theme.colors.accent['red/main']};
                    margin-right: 5px;
                  `}
                />
                <Text.TextSmall1 marginBottom="4xs" marginTop="4xs">
                  Dormant
                </Text.TextSmall1>
              </div>
            </DropdownI.Item>
          </DropdownI.Sub>
        </Box>
      </Box>
    </DropdownI.Root>
  );
};
