import { gql, useQuery } from '@apollo/client';
import { Box, Text, VerticalBarChart } from 'components';
import { EnvelopeIcon } from 'components/Icons';
import React from 'react';
import { CardHeader } from './CardHeader';
import { css } from '@emotion/css';
import { ToolTipHelper } from './ToolTipHelper';

const QUERY = gql`
  query GmailStatsPerDay($userId: ID!) {
    gmailStatsPerDay(userId: $userId) {
      fri {
        numberOfInbox
        numberOfSent
      }
      mon {
        numberOfSent
        numberOfInbox
      }
      sat {
        numberOfSent
        numberOfInbox
      }
      sun {
        numberOfSent
        numberOfInbox
      }
      thu {
        numberOfSent
        numberOfInbox
      }
      tue {
        numberOfSent
        numberOfInbox
      }
      wed {
        numberOfSent
        numberOfInbox
      }
    }
  }
`;

type GmailStats = {
  gmailStatsPerDay: {
    sun: { numberOfInbox: number; numberOfSent: number };
    mon: { numberOfInbox: number; numberOfSent: number };
    tue: { numberOfInbox: number; numberOfSent: number };
    wed: { numberOfInbox: number; numberOfSent: number };
    thu: { numberOfInbox: number; numberOfSent: number };
    fri: { numberOfInbox: number; numberOfSent: number };
    sat: { numberOfInbox: number; numberOfSent: number };
  };
};

const labels = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

type Props = {
  userId: string;
  placeholder?: string;
};
export const EmailStatsPerDay: React.FC<Props> = ({ userId, placeholder }) => {
  const { data, loading } = useQuery<GmailStats>(QUERY, {
    variables: { userId },
  });

  const stats =
    loading || !data?.gmailStatsPerDay
      ? placeholder
        ? JSON.parse(placeholder)
        : {}
      : data?.gmailStatsPerDay;
  return (
    <Box
      backgroundColor="primary/05"
      position="relative"
      width="386px"
      height="380px"
      borderRadius="l"
      noPadding
    >
      <div
        className={css`
          width: 100%;
          position: relative;
          padding: 36px;
        `}
      >
        <div
          className={css`
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
          `}
        >
          <div
            className={css`
              display: flex;
              flex-direction: row;
              align-items: center;
            `}
          >
            <CardHeader text="Drowning" color="#000" Icon={EnvelopeIcon} />
            <div
              className={css`
                margin-left: -8px;
              `}
            >
              <ToolTipHelper
                text="Are you drowning in email? See your ratio of received to email to sent."
                size={{ width: '26px', height: '26px' }}
                color="#000"
              />
            </div>
          </div>
          <div>
            <div
              className={css`
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 4px;
              `}
            >
              <div
                style={{
                  width: '12px',
                  height: '12px',
                  borderRadius: '6px',
                  backgroundColor: '#766DAA',
                  marginRight: '4px',
                }}
              />
              <Text.TextSmall2
                color="text/dark"
                marginBottom="4xs"
                marginTop="4xs"
              >
                Emails sent
              </Text.TextSmall2>
            </div>
            <div
              className={css`
                display: flex;
                flex-direction: row;
                align-items: center;
              `}
            >
              <div
                style={{
                  width: '12px',
                  height: '12px',
                  borderRadius: '6px',
                  backgroundColor: '#453E69',
                  marginRight: '4px',
                }}
              />
              <Text.TextSmall2
                color="text/dark"
                marginBottom="4xs"
                marginTop="4xs"
              >
                Emails received
              </Text.TextSmall2>
            </div>
          </div>
        </div>
        <Box noPadding width="100%">
          <div
            className={css`
              margin-top: 10px;
            `}
          />
          <VerticalBarChart
            labels={labels}
            values1={[
              stats?.sun?.numberOfInbox || 0,
              stats?.mon?.numberOfInbox || 0,
              stats?.tue?.numberOfInbox || 0,
              stats?.wed?.numberOfInbox || 0,
              stats?.thu?.numberOfInbox || 0,
              stats?.fri?.numberOfInbox || 0,
              stats?.sat?.numberOfInbox || 0,
            ]}
            values2={[
              stats?.sun?.numberOfSent || 0,
              stats?.mon?.numberOfSent || 0,
              stats?.tue?.numberOfSent || 0,
              stats?.wed?.numberOfSent || 0,
              stats?.thu?.numberOfSent || 0,
              stats?.fri?.numberOfSent || 0,
              stats?.sat?.numberOfSent || 0,
            ]}
          />
        </Box>
      </div>
    </Box>
  );
};
