import { useLazyQuery } from '@apollo/client';
import { css } from '@emotion/css';
import { Dialog, PageLoading } from 'components';
import { EActivityType } from 'model';
import { GET_DOC_DELTA, GET_MICROSOFT_DELTA } from 'pages/Topic/gql';
import React, { useEffect } from 'react';
import { theme } from 'theme';

// const GET_DOC_DELTA = gql`
//   query googleDocDelta($firstId: ID, $lastId: ID!) {
//     googleDocDelta(firstId: $firstId, lastId: $lastId)
//   }
// `;

interface Props {
  open?: boolean;
  itemId: string;
  type: EActivityType;
  onOpenChange?(open: boolean): void;
}
export function DeltaDialog(props: Props) {
  const [getDocDelta, { data: docDelta, loading }] = useLazyQuery<{
    googleDocDelta: string;
  }>(GET_DOC_DELTA);

  const [
    getMicrosoftDelta,
    { data: microsoftDelta, loading: microsoftLoading },
  ] = useLazyQuery<{
    microsoftDelta: string;
  }>(GET_MICROSOFT_DELTA);

  useEffect(() => {
    if (props.open && props.itemId) {
      switch (props.type) {
        case EActivityType.GOOGLE:
          getDocDelta({
            variables: {
              // firstId: props.previousItem,
              lastId: props.itemId,
            },
          });
          break;

        case EActivityType.MICROSOFT:
          getMicrosoftDelta({
            variables: {
              // firstId: props.previousItem,
              id: props.itemId,
            },
          });
          break;

        default:
          break;
      }
    }
  }, [getDocDelta, getMicrosoftDelta, props.itemId, props.open, props.type]);

  return (
    <Dialog
      additionalContent={
        loading || microsoftLoading ? (
          <div
            className={css`
              min-width: 400px;
              height: 80vh;
              background-color: ${theme.colors.primary['primary/02']};
              overflow: auto;
              padding: 10px;
              border-radius: 8px;
            `}
          >
            <PageLoading />
          </div>
        ) : (
          <div
            className={css`
              min-width: 400px;
              height: 80vh;
              background-color: ${theme.colors.primary['primary/02']};
              overflow: auto;
              padding: 10px;
              border-radius: 8px;
              color: ${theme.colors.text['text/100%']};
            `}
          >
            <div
              dangerouslySetInnerHTML={{
                __html:
                  props.type === EActivityType.MICROSOFT
                    ? microsoftDelta?.microsoftDelta || ''
                    : docDelta?.googleDocDelta || '',
              }}
            />
          </div>
        )
      }
      open={props.open}
      // title="Google doc changes"
      onOpenChange={props.onOpenChange}
    />
  );
}
