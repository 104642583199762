import React from 'react';
import _ from 'lodash';
import { css } from '@emotion/css';
import { theme } from 'theme';
import { Text } from 'components';
import { EMomentumType } from 'model';

const height = 10;
const max = 20;

type Props = {
  value: number;
  labelLeft?: string;
  labelRight?: string;
  color?: string;
  small?: boolean;
};
export const DottedProgressBar: React.FC<Props> = ({
  value,
  labelLeft,
  labelRight,
  color,
  small,
}) => {
  const correctValue = value < 0 ? 0 : value > max ? max : value;

  const getColor = () => {
    switch (labelLeft) {
      case EMomentumType.HIGHER:
        return theme.colors.accent['green/main'];

      case EMomentumType.HIGH:
        return theme.colors.accent['green/main'];

      case EMomentumType.MEDIUM:
        return theme.colors.accent['yellow/main'];

      case EMomentumType.LOW:
        return theme.colors.accent['red/main'];

      case EMomentumType.DORMANT:
        return theme.colors.accent['red/main'];

      default:
        return theme.colors.primary['primary/03'];
    }
  };
  return small ? (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        align-items: center;
      `}
    >
      <div
        style={{
          height: '12px',
          width: '6px',
          borderRadius: '3px',
          backgroundColor: getColor(),
          marginRight: '8px',
        }}
      />
      <Text.TextSmall2
        color="text/50%"
        marginBottom="4xs"
        marginTop="4xs"
        style={{ color: getColor() }}
      >
        {labelLeft === EMomentumType.HIGHER ? EMomentumType.HIGH : labelLeft}
      </Text.TextSmall2>
    </div>
  ) : (
    <div
      className={css`
        width: fit-content;
      `}
    >
      {Boolean(labelLeft || labelRight) && (
        <div
          className={css`
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 3px;
          `}
        >
          <Text.TextSmall2 color="text/50%" marginBottom="4xs" marginTop="4xs">
            {labelLeft === EMomentumType.HIGHER
              ? EMomentumType.HIGH
              : labelLeft}
          </Text.TextSmall2>
          <Text.TextSmall2
            color="text/blue/soft"
            style={{ opacity: 0.5, fontSize: '10px' }}
            marginBottom="4xs"
            marginTop="4xs"
          >
            {labelRight}
          </Text.TextSmall2>
        </div>
      )}

      <div
        className={css`
          width: 100%;
          position: relative;
          display: grid;
          grid-template-columns: repeat(${max}, 7px);
          gap: 6px;
        `}
      >
        {_.range(max).map((item) => (
          <div
            key={item}
            className={css`
              width: 100%;
              height: ${height}px;
              background-color: ${item <= correctValue && item <= max - 5
                ? getColor()
                : labelLeft === EMomentumType.HIGHER
                ? theme.colors.accent['purple/main']
                : '#2D2A41'};
              border-radius: 4px;
            `}
          />
        ))}
      </div>
    </div>
  );
};
