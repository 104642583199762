import { Box } from 'components';
import React from 'react';
import { TrendingTopics } from './TrendingTopics';

// const LAST_TOPICS = gql`
//   query LastSeenTopics {
//     lastSeenTopics {
//       name
//       _id
//       closed
//       momentum {
//         count
//         label
//         lastActivity
//       }
//     }
//   }
// `;

type Props = {
  onBoardingCompleted: boolean;
};
export const FeedDashboard: React.FC<Props> = ({ onBoardingCompleted }) => {
  // const { data, loading } = useQuery<{ lastSeenTopics: ITopic[] }>(
  //   LAST_TOPICS,
  //   { fetchPolicy: 'network-only' }
  // );

  return (
    <Box noPadding width="100%" marginTop="2xl" marginBottom="2xl">
      <TrendingTopics />
      {/* <Box
        width="100%"
        backgroundColor="primary/02"
        borderRadius="l"
        padding="xl"
      >
        <Text.TextSmall2 color="text/light" marginTop="4xs" marginBottom="s">
          Recently viewed topics
        </Text.TextSmall2>
        {data?.lastSeenTopics && data?.lastSeenTopics?.length > 0 ? (
          <div
            className={css`
              width: 100%;
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              gap: 15px;
            `}
          >
            {data?.lastSeenTopics.map((topic) => (
              <TopicCard
                key={topic._id}
                topicId={topic._id}
                topicName={topic.name}
                closed={topic.closed}
                momentum={topic.momentum}
              />
            ))}
          </div>
        ) : (
          <div
            className={css`
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100px;
            `}
          >
            {loading ? (
              <Text.H2 color="text/light">Loading...</Text.H2>
            ) : (
              <div
                className={css`
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  width: 80%;
                `}
              >
                <Text.H2 color="text/light">
                  Your recently interacted with topics will appear here. <br />{' '}
                  Why don’t you go ahead and{' '}
                  <Text.A
                    color="text/link"
                    size="h2"
                    href={ERouteName.Workspace}
                  >
                    create your first topic?
                  </Text.A>
                </Text.H2>
              </div>
            )}
          </div>
        )}
      </Box>

      <div
        className={css`
          width: 100%;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 32px;
          margin-top: 24px;
        `}
      >
        <TrendingTopics smallMomentum={!onBoardingCompleted} />

        <DormantTopics />
      </div> */}
    </Box>
  );
};
