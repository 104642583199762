import { css } from '@emotion/css';
import { Box, Page, Text } from 'components';
import { EGraphQlErrorCode } from 'model';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import illustrationUrl from './illustration.png';

export default function Error() {
  const { code } = useParams();

  const errorMessage = useMemo(() => {
    switch (code) {
      case 'DOES_NOT_EXIST':
        return 'Sorry, resource not found';

      case 'UNAUTHORIZED':
        return 'You do not have access to this resource';

      case EGraphQlErrorCode.INTERNAL_SERVER_ERROR:
        return 'An error has occurred on the server. Please try again or contact us.';

      default:
        return 'Page not found';
    }
  }, [code]);

  return (
    <Page title="Error page | Multiplayer">
      <Box
        width="100%"
        justifyContent="center"
        alignItems="center"
        height="90vh"
        borderRadius="l"
        marginTop="l"
        className={css`
          /* background-image: url(${illustrationUrl}); */
          /* background-repeat: no-repeat;
          background-position: -100px; */
        `}
      >
        <Text.H1>{errorMessage}</Text.H1>
      </Box>
    </Page>
  );
}
