import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { css } from '@emotion/css';
import { ActivityHolder, Box, Text } from 'components';
import { PaperClipIcon, SlackLogo } from 'components/Icons';
import { theme } from 'theme';
import { EActivityType } from 'model';

type DocProps = {
  comment?: string;
  message: string;
  channel: string;
  permaLink: string;
  createdBy: string;
  reportedBy: string;
  reporterId?: string;
  createAt: number;
  topicId: string;
  id: string;
  customRef: React.LegacyRef<HTMLDivElement>;
  focus?: boolean;
  stared: boolean;
  files?: string[];
  last?: boolean;
};

export const SlackItem: React.FC<DocProps> = ({
  comment,
  message,
  permaLink,
  channel,
  createAt,
  reporterId,
  createdBy,
  reportedBy,
  topicId,
  id,
  customRef,
  stared,
  focus,
  files,
  last,
}) => {
  // if (!comment) return null;
  const renderMessage = useMemo(
    () => (
      <div
        dangerouslySetInnerHTML={{
          __html: message
            // .split('<')
            // .join('**')
            // .split('>')
            // .join('##')
            // .split('**@')
            // .join('<span className="mention">@')
            // .split('**')
            // .join('<span>')
            // .split('##')
            // .join('</span>')
            .split('\n')
            .join('<br />')
            .split('**See more**')
            .join(
              `<span style="color: ${theme.colors.primary['primary/05']};">See more <span />`
            ),
          // .split('|')
          // .join(' '),
        }}
      />
    ),
    [message]
  );

  return (
    <ActivityHolder.Item
      topicId={topicId}
      type={EActivityType.SLACK}
      customRef={customRef}
      owner={reporterId}
      id={id}
      link={`${process.env.REACT_APP_WEB_URL}/topic/${topicId}#${id}`}
      Icon={<SlackLogo style={{ width: '16px' }} />}
      // title={`Slack message from *#${channel}* channel`}
      title={`*<a href="${
        reporterId ? '/profile/' + reporterId : '#'
      }">${reportedBy}</a>** reported this Slack message from channel *#${channel}*`}
      subTitle={`${format(+createAt, 'MMMM d, yyyy • h:mm aa')}`}
      // color={theme.colors.primary['primary/03']}
      stared={stared}
      last={last}
    >
      <a
        href={permaLink}
        target="_blank"
        rel="noreferrer"
        className={css`
          all: unset;
        `}
      >
        <Box
          // noPadding
          padding="m"
          borderRadius="m"
          width="100%"
          backgroundColor="primary/02"
          className={css`
            cursor: pointer;
            border: 1px solid
              ${focus ? theme.colors.accent['blue/main'] : 'transparent'} !important;
            /* overflow: hidden; */
            transition: border-color 0.15s;
            &:hover {
              border-color: ${theme.colors.accent['blue/main']} !important;
            }
          `}
        >
          {comment && (
            <Box flexDirection="row" noPadding>
              {/* <Text.BodySM color="text/link">Comment: </Text.BodySM> */}
              <Text.BodySM color="text/50%" marginTop="4xs">
                {comment}
              </Text.BodySM>
            </Box>
          )}
          <div
            className={css`
              width: 100%;
              word-wrap: break-word;
              font-size: ${theme.textVariants['body-sm']}px;
              span.mention {
                color: ${theme.colors.text['text/yellow']};
                /* background-color: ${theme.colors.accent['blue/light']}; */
              }
              a {
                color: ${theme.colors.text['text/link']};
                text-decoration: none;
                &:hover {
                  text-decoration: underline;
                }
              }
              code {
                color: #dea003;
                font-size: 90%;
                border: 1px solid #b4b3b3b5;
                background-color: #2828284f;
                border-radius: 3px;
                padding-inline: 3px;
              }
            `}
          >
            <div>{renderMessage}</div>
          </div>
          {Boolean(files && files.length > 0 && files[0]) && (
            <Box display="block" flexDirection="row" noPadding marginTop="m">
              {files?.map((file) => (
                <a
                  href={file}
                  key={file}
                  className={css`
                    color: ${theme.colors.text['text/main']};
                    font-size: ${theme.textVariants['body-sm'].fontSize}px;
                    /* margin-left: ${theme.spacing.m}px; */
                    display: inline-block;
                    padding: 8px 16px;
                    border-radius: 16px;
                    background-color: ${theme.colors.primary['primary/03']};
                    text-decoration: none;
                    height: 32px;
                    margin-right: 8px;
                  `}
                >
                  <Box
                    noPadding
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <PaperClipIcon
                      fill={theme.colors.text['text/green']}
                      style={{ marginRight: 5 }}
                    />
                    <Text.TextSmall1
                      color="text/green"
                      marginBottom="4xs"
                      marginTop="4xs"
                    >
                      {file?.split('/')[file?.split('/').length - 1]}
                    </Text.TextSmall1>
                  </Box>
                </a>
              ))}
            </Box>
          )}
        </Box>
      </a>
    </ActivityHolder.Item>
  );
};
