import { css } from '@emotion/css';
import React from 'react';
import { Text } from 'components';
import { StatIcon } from 'components/Icons';
import Image1 from './image1.svg';
import Image2 from './image2.svg';
import Image3 from './image3.svg';
import Image4 from './image4.svg';
import Image5 from './image5.svg';
import Image6 from './image6.svg';
import { useNavigate } from 'react-router-dom';
import { ERouteName } from 'pages';

type Props = {
  id: string;
  name: string;
  role: string;
  profilePicture?: string;
};

function getImage(id: string) {
  const images = [Image1, Image2, Image3, Image4, Image5, Image6];
  let hash = 0;
  for (let i = 0; i < id.length; i++) {
    let char = id.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to a 32-bit integer
  }

  const value = Math.abs(hash) % 6;

  return images[value];
}

export const Card: React.FC<Props> = ({ name, role, id, profilePicture }) => {
  const navigate = useNavigate();
  const goToStats = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    navigate(ERouteName.Stats + id);
    e.stopPropagation();
  };
  const goToProfile = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    navigate(ERouteName.Profile + id);
  };
  return (
    <div
      className={css`
        display: inline-block;
        margin-right: 17px;
        margin-top: 17px;
      `}
    >
      <div
        className={css`
          width: fit-content;
          height: 367px;
          flex-shrink: 0;
          border-radius: 16px;
          padding: 24px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          background-image: url(${profilePicture || getImage(id)});
          background-size: cover;
          background-color: #272539;
          cursor: pointer;
        `}
        onClick={goToProfile}
      >
        <div
          className={css`
            position: relative;
            width: fit-content;
            height: fit-content;
          `}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="324"
            height="94"
            viewBox="0 0 324 94"
            fill="white"
          >
            <path
              d="M0 12C0 5.37258 5.37258 0 12 0H154H270.5C276.023 0 280.5 4.47715 280.5 10V27.5C280.5 36.3366 287.663 43.5 296.5 43.5H314C319.523 43.5 324 47.9772 324 53.5V82C324 88.6274 318.627 94 312 94H12C5.37258 94 0 88.6274 0 82V12Z"
              fill="#DFE0F1"
            />
          </svg>

          <div
            className={css`
              position: absolute;
              top: 0px;
              right: 0px;
              background-color: #dfe0f1;
              width: 32px;
              height: 32px;
              border-radius: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            `}
            onClick={goToStats}
          >
            <StatIcon
              fill="#6A53FE"
              style={{ width: '16px', height: '16px' }}
            />
          </div>

          <div
            className={css`
              position: absolute;
              top: 0px;
              left: 0px;
              width: 85%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              padding: 24px;
              overflow: hidden;
            `}
          >
            <Text.H4 style={{ color: '#171425' }}>{name}</Text.H4>
            <Text.TextSmall1
              marginBottom="4xs"
              marginTop="4xs"
              style={{ color: '#171425' }}
            >
              {role}
            </Text.TextSmall1>
          </div>
        </div>
      </div>
    </div>
  );
};
