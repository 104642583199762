import React from 'react';
import './style.css';

type Props = {
  style?: React.CSSProperties;
  type: 'rectangle' | 'circle';
};
export const SkeletonLoading: React.FC<Props> = ({ type, style }) => {
  return <div className={`skeleton-loading ${type}`} style={style} />;
};
