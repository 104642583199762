import { css } from '@emotion/css';
import { Text } from 'components';

type SeparatorProps = {
  helperText?: string;
  text: string;
  newUpdate?: boolean;
};
export const FeedSeparator: React.FC<SeparatorProps> = ({
  text,
  helperText,
  newUpdate,
}) => {
  return (
    <div
      className={css`
        width: 100%;
        display: flex;
        flex-direction: row;
        height: 23px;
        margin-bottom: 24px;
      `}
    >
      <div
        className={css`
          display: inline-flex;
          padding: 4px 8px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 19px;
          background: #2b2841;
        `}
      >
        <Text.TextSmall2
          style={{ color: '#736DA0' }}
          marginBottom="4xs"
          marginTop="4xs"
        >
          {text}
        </Text.TextSmall2>
      </div>

      {newUpdate && (
        <div
          className={css`
            display: flex;
            justify-content: center;
            flex-direction: row;
            align-items: center;
            width: 23px;
            height: 23px;
            border-radius: 100%;
            background-color: #8d86f8;
            margin-left: 3px;
          `}
        >
          <Text.TextSmall2
            color="text/100%"
            marginBottom="4xs"
            marginTop="4xs"
            style={{ fontSize: '9px' }}
          >
            {helperText}
          </Text.TextSmall2>
        </div>
      )}
    </div>
  );
};
