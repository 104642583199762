import React from 'react';
import { styled } from '@stitches/react';
// import { violet, mauve, blackA } from '@radix-ui/colors';
// import {
//   CheckIcon,
//   ChevronDownIcon,
//   ChevronUpIcon,
// } from '@radix-ui/react-icons';
import * as SelectPrimitive from '@radix-ui/react-select';
import { theme } from 'theme';
import { Checkmark, ChevronDown, ChevronDownIcon } from 'components/Icons';

const StyledTrigger = styled(SelectPrimitive.SelectTrigger, {
  all: 'unset',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: 4,
  padding: '0 15px',
  fontSize: 13,
  lineHeight: 1,
  height: 35,
  width: '100%',
  gap: 5,
  backgroundColor: theme.colors.primary['primary/04'],
  color: theme.colors.text['text/main'],
  //   boxShadow: `0 2px 10px ${theme.colors.accent.black}`,
  '&:hover': { backgroundColor: theme.colors.primary['primary/03'] },
  // '&:focus': { boxShadow: `0 0 0 2px black` },
  '&[data-placeholder]': { color: theme.colors.text['text/light'] },
});

const StyledIcon = styled(SelectPrimitive.SelectIcon, {
  color: theme.colors.text['text/main'],
});

const StyledContent = styled(SelectPrimitive.Content, {
  overflow: 'hidden',
  backgroundColor: theme.colors.primary['primary/04'],
  borderRadius: 6,
  position: 'absolute',

  zIndex: 5000,
  boxShadow:
    '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
});

const StyledViewport = styled(SelectPrimitive.Viewport, {
  padding: 5,
});

function Content({ children, ...props }: any) {
  return (
    <SelectPrimitive.Portal style={{ zIndex: 30000, position: 'absolute' }}>
      <StyledContent {...props}>{children}</StyledContent>
    </SelectPrimitive.Portal>
  );
}

const StyledItem = styled(SelectPrimitive.Item, {
  all: 'unset',
  fontSize: 13,
  lineHeight: 1,
  color: theme.colors.text['text/main'],
  borderRadius: 3,
  display: 'flex',
  alignItems: 'center',
  height: 25,
  padding: '0 35px 0 25px',
  position: 'relative',
  userSelect: 'none',

  '&[data-disabled]': {
    color: theme.colors.text['text/light'],
    pointerEvents: 'none',
  },

  '&[data-highlighted]': {
    backgroundColor: theme.colors.accent['purple/main'],
    color: theme.colors.text['text/main'],
  },
});

const StyledLabel = styled(SelectPrimitive.Label, {
  padding: '0 25px',
  fontSize: 12,
  lineHeight: '25px',
  color: theme.colors.text['text/main'],
});

const StyledSeparator = styled(SelectPrimitive.Separator, {
  height: 1,
  backgroundColor: theme.colors.primary['primary/01'],
  margin: 5,
});

const StyledItemIndicator = styled(SelectPrimitive.ItemIndicator, {
  position: 'absolute',
  left: 0,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const scrollButtonStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 25,
  backgroundColor: theme.colors.primary['primary/01'],
  color: 'red',
  cursor: 'default',
};

const StyledScrollUpButton = styled(
  SelectPrimitive.ScrollUpButton,
  scrollButtonStyles
);

const StyledScrollDownButton = styled(
  SelectPrimitive.ScrollDownButton,
  scrollButtonStyles
);

// Exports
export const Select = SelectPrimitive.Root;
export const SelectTrigger = StyledTrigger;
export const SelectValue = SelectPrimitive.Value;
export const SelectIcon = StyledIcon;
export const SelectContent = Content;
export const SelectViewport = StyledViewport;
export const SelectGroup = SelectPrimitive.Group;
export const SelectItem = StyledItem;
export const SelectItemText = SelectPrimitive.ItemText;
export const SelectItemIndicator = StyledItemIndicator;
export const SelectLabel = StyledLabel;
export const SelectSeparator = StyledSeparator;
export const SelectScrollUpButton = StyledScrollUpButton;
export const SelectScrollDownButton = StyledScrollDownButton;

// Your app...
const Box = styled('div', {
  width: '100%',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  marginBottom: '8px',
  // border: '1px solid red',
});

type Props = {
  options: {
    label: string;
    value: string;
  }[];
  ariaLabel?: string;
  placeholder?: string;
  defaultValue?: string;
  onValueChange?: (value: string) => void;
};
export const BasicSelect: React.FC<Props> = ({
  options,
  ariaLabel,
  placeholder,
  defaultValue,
  onValueChange,
}) => (
  <Box>
    <Select onValueChange={onValueChange} defaultValue={defaultValue}>
      <SelectTrigger aria-label={ariaLabel}>
        <SelectValue placeholder={placeholder || 'Select an option'} />
        <SelectIcon>
          <ChevronDownIcon
            fill={theme.colors.text['text/blue/soft']}
            style={{ width: 14 }}
          />
        </SelectIcon>
      </SelectTrigger>
      <SelectContent>
        <SelectScrollUpButton>
          <ChevronDown />
        </SelectScrollUpButton>
        <SelectViewport>
          {options.map((item) => (
            <SelectItem key={item.value + item.label} value={item.value}>
              <SelectItemText>{item.label}</SelectItemText>
              <SelectItemIndicator>
                <Checkmark
                  fill={theme.colors.text['text/main']}
                  style={{ width: '14px' }}
                />
              </SelectItemIndicator>
            </SelectItem>
          ))}

          {/* <SelectSeparator /> */}

          {/* <SelectGroup>
            <SelectLabel>Vegetables</SelectLabel>
            <SelectItem value="aubergine">
              <SelectItemText>Aubergine</SelectItemText>
              <SelectItemIndicator>
                <Checkmark />
              </SelectItemIndicator>
            </SelectItem>
            <SelectItem value="broccoli">
              <SelectItemText>Broccoli</SelectItemText>
              <SelectItemIndicator>
                <Checkmark />
              </SelectItemIndicator>
            </SelectItem>
            <SelectItem value="carrot" disabled>
              <SelectItemText>Carrot</SelectItemText>
              <SelectItemIndicator>
                <Checkmark />
              </SelectItemIndicator>
            </SelectItem>
            <SelectItem value="courgette">
              <SelectItemText>Courgette</SelectItemText>
              <SelectItemIndicator>
                <Checkmark />
              </SelectItemIndicator>
            </SelectItem>
            <SelectItem value="leek">
              <SelectItemText>leek</SelectItemText>
              <SelectItemIndicator>
                <Checkmark />
              </SelectItemIndicator>
            </SelectItem>
          </SelectGroup> */}
        </SelectViewport>
        <SelectScrollDownButton>
          <ChevronDownIcon />
        </SelectScrollDownButton>
      </SelectContent>
    </Select>
  </Box>
);
