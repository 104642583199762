import { css } from '@emotion/css';
import { Box, Text } from 'components';
import { PaperClipIcon } from 'components/Icons';
import React from 'react';
import { theme } from 'theme';
import { extractUrlFromMarkdown } from 'utils';

type Props = {
  comment: string;
  focus?: boolean;
};
export const LinearCommentBox: React.FC<Props> = ({ comment, focus }) => {
  const files = extractUrlFromMarkdown(comment);
  let commentWithoutFiles = comment;
  if (files.length > 0) {
    files.forEach((file) => {
      commentWithoutFiles = commentWithoutFiles.replace(
        `![${file.name}](${file.url})`,
        ''
      );
      commentWithoutFiles = commentWithoutFiles.replace(
        `[${file.name}](${file.url})`,
        ''
      );
    });
  }
  return (
    <Box
      // noPadding
      padding="m"
      borderRadius="m"
      width="100%"
      backgroundColor="primary/02"
      className={css`
        cursor: pointer;
        border: 1px solid
          ${focus ? theme.colors.accent['blue/main'] : 'transparent'} !important;
        /* overflow: hidden; */
        transition: border-color 0.15s;
        &:hover {
          border-color: ${theme.colors.accent['blue/main']} !important;
        }
      `}
    >
      {commentWithoutFiles && (
        <Box flexDirection="row" noPadding>
          {/* <Text.BodySM color="text/link">Comment: </Text.BodySM> */}
          <Text.BodySM color="text/100%" marginTop="4xs" marginBottom="4xs">
            {commentWithoutFiles}
          </Text.BodySM>
        </Box>
      )}
      {Boolean(files && files.length > 0) && (
        <Box
          display="block"
          flexDirection="row"
          noPadding
          marginTop={commentWithoutFiles ? 'm' : '4xs'}
        >
          {files?.map((file) => (
            <a
              href={file.url}
              key={file.url}
              className={css`
                color: ${theme.colors.text['text/main']};
                font-size: ${theme.textVariants['body-sm'].fontSize}px;
                display: inline-block;
                padding: 8px 16px;
                border-radius: 16px;
                background-color: ${theme.colors.primary['primary/03']};
                text-decoration: none;
                height: 32px;
                margin-right: 8px;
              `}
            >
              <Box
                noPadding
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <PaperClipIcon
                  fill={theme.colors.text['text/green']}
                  style={{ marginRight: 5 }}
                />
                <Text.TextSmall1
                  color="text/green"
                  marginBottom="4xs"
                  marginTop="4xs"
                >
                  {file.name}
                </Text.TextSmall1>
              </Box>
            </a>
          ))}
        </Box>
      )}
    </Box>
  );
};
