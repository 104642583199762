import React from 'react';
import { format } from 'date-fns';
import { css } from '@emotion/css';
import { ActivityHolder, LinearCommentBox } from 'components';
// import { theme } from 'theme';
import { EActivityType, EIssueActivityType, GithubIssueActivity } from 'model';
import { GithubLogoIcon } from 'components/Icons';

type Props = {
  id: string;
  topicId: string;
  customRef: React.LegacyRef<HTMLDivElement>;
  focus?: boolean;
  stared: boolean;
  last?: boolean;
  createdBy?: string;
  creatorId?: string;
  data: GithubIssueActivity;
};
export const GithubUpdate: React.FC<Props> = ({
  topicId,
  id,
  customRef,
  stared,
  last,
  focus,
  data,
  createdBy,
  creatorId,
}) => {
  // const files = extractUrlFromMarkdown(comment);
  // let commentWithoutFiles = comment;
  // if (files.length > 0) {
  //   files.forEach((file) => {
  //     commentWithoutFiles = commentWithoutFiles.replace(
  //       `![${file.name}](${file.url})`,
  //       ''
  //     );
  //     commentWithoutFiles = commentWithoutFiles.replace(
  //       `[${file.name}](${file.url})`,
  //       ''
  //     );
  //   });
  // }
  const comment = (data.data as any)?.body;
  const dateCreated = new Date(data.created);
  const getTitle = () => {
    switch (data.data?.event) {
      case EIssueActivityType.Assigned:
        return `*<a href="${
          data.data.assignee.multiplayerId
            ? '/profile/' + data.data.assignee.multiplayerId
            : data.data.assignee.url
        }" target="_blank" rel="noreferrer">${
          data.data.assignee.multiplayerName
            ? data.data.assignee.multiplayerName
            : data.data.assignee.login
        }</a>** assigned to issue *<a href="${
          data.issueUrl
        }" target="_blank" rel="noreferrer">${data.title}</a>**`;
      case EIssueActivityType.Labeled:
        return `Label *${data.data.label}** added to issue *<a href="${data.issueUrl}" target="_blank" rel="noreferrer">${data.title}</a>**`;
      case EIssueActivityType.Unlabeled:
        return `*${data.data.label}** removed from issue *<a href="${data.issueUrl}" target="_blank" rel="noreferrer">${data.title}</a>**`;
      case EIssueActivityType.Milestoned:
        return `Issue *<a href="${data.issueUrl}" target="_blank" rel="noreferrer">${data.title}</a>** added to *${data.data.milestone}** milestone `;
      case EIssueActivityType.Demilestoned:
        return `*${data.data.milestone} removed from issue *<a href="${data.issueUrl}" target="_blank" rel="noreferrer">${data.title}</a>**`;
      case EIssueActivityType.AddedToProject:
        return `*<a href="${
          creatorId ? '/profile/' + creatorId : data.creator.url
        }" target="_blank" rel="noreferrer">${
          createdBy || data.creator.login
        }</a>** added issue *<a href="${
          data.issueUrl
        }" target="_blank" rel="noreferrer">${data.title}</a>** to *${
          data.data.columnName
        }**`;
      case EIssueActivityType.MovedColumnsInProject:
        return `*<a href="${
          creatorId ? '/profile/' + creatorId : data.creator.url
        }" target="_blank" rel="noreferrer">${
          createdBy || data.creator.login
        }</a>** removed issue *<a href="${
          data.issueUrl
        }" target="_blank" rel="noreferrer">${
          data.title
        }</a>** from the project dashboard`;
      case EIssueActivityType.Commented:
        return `*<a href="${
          creatorId ? '/profile/' + creatorId : data.creator.url
        }" target="_blank" rel="noreferrer">${
          createdBy || data.creator.login
        }</a>** commented on issue *<a href="${
          data.issueUrl
        }" target="_blank" rel="noreferrer">${data.title}</a>**`;
      case EIssueActivityType.Reopened:
        return `*<a href="${
          creatorId ? '/profile/' + creatorId : data.creator.url
        }" target="_blank" rel="noreferrer">${
          createdBy || data.creator.login
        }</a>** reopened issue *<a href="${
          data.issueUrl
        }" target="_blank" rel="noreferrer">${data.title}</a>**`;
      case EIssueActivityType.Closed:
        return `*<a href="${
          creatorId ? '/profile/' + creatorId : data.creator.url
        }" target="_blank" rel="noreferrer">${
          createdBy || data.creator.login
        }</a>** closed issue *<a href="${
          data.issueUrl
        }" target="_blank" rel="noreferrer">${data.title}</a>**`;
      case EIssueActivityType.Deleted:
        return `*<a href="${
          creatorId ? '/profile/' + creatorId : data.creator.url
        }" target="_blank" rel="noreferrer">${
          createdBy || data.creator.login
        }</a>** Deleted issue *<a href="${
          data.issueUrl
        }" target="_blank" rel="noreferrer">${data.title}</a>**`;
      case EIssueActivityType.IssueCreated:
        return `*<a href="${
          creatorId ? '/profile/' + creatorId : data.creator.url
        }" target="_blank" rel="noreferrer">${
          createdBy || data.creator.login
        }</a>** created issue *<a href="${
          data.issueUrl
        }" target="_blank" rel="noreferrer">${data.title}</a>**`;
      default:
        return `*<a href="${
          creatorId ? '/profile/' + creatorId : data.creator.url
        }" target="_blank" rel="noreferrer">${
          createdBy || data.creator.login
        }</a>** added change to issue *<a href="${
          data.issueUrl
        }" target="_blank" rel="noreferrer">${data.title}</a>**`;
    }
  };
  return (
    <ActivityHolder.Item
      topicId={topicId}
      type={EActivityType.GITHUB}
      id={id}
      customRef={customRef}
      link={`${process.env.REACT_APP_WEB_URL}/topic/${topicId}#${id}`}
      Icon={<GithubLogoIcon style={{ width: '16px', height: '16px' }} />}
      title={getTitle()}
      subTitle={`${format(dateCreated, 'MMMM d, yyyy • h:mm aa')}`}
      // color={theme.colors.primary['primary/03']}
      stared={stared}
      last={last}
    >
      {comment && (
        <a
          href={data.issueUrl}
          target="_blank"
          rel="noreferrer"
          className={css`
            all: unset;
          `}
        >
          <LinearCommentBox comment={comment} focus={focus} />
        </a>
      )}
    </ActivityHolder.Item>
  );
};
