import { TopicList } from 'features/topics';
import { EMomentumType } from 'model';

export type WorkspaceResult = {
  topics: TopicList;
};

export enum ETopicFilter {
  DATE,
  PEOPLE,
  STATUS,
  MOMENTUM,
}

export enum ESort {
  CREATED = 'created',
  UPDATED = 'updated',
}

export type FilterStatusType = 'opened' | 'closed' | 'all';

export type TTopicFilter = {
  _id: string;
  user: string;
  workspace: string;
  name: string;
  status?: FilterStatusType;
  startDate?: Date;
  endDate?: Date;
  participants?: string[];
  momentum?: EMomentumType;
  created: Date;
};
