import React, { useCallback } from 'react';
import styled from '@emotion/styled';
// import * as HoverCard from '@radix-ui/react-hover-card';
import { theme } from 'theme';
import { Text, ProfileIcon } from 'components';
import { useNavigate } from 'react-router-dom';
import { ERouteName } from 'pages';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
`;

const Last = styled.div`
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.primary['primary/dark']};
  cursor: pointer;
  z-index: 0;
  transition: z-index 0.1s ease-out;
  &:hover {
    box-shadow: rgba(3, 3, 4, 0.629) 0px 8px 24px;
    position: relative;
    z-index: 100;
  }
`;

const marginLeft = '-12px';

type Props = {
  users: {
    userId: string;
    userName: string;
    userRole?: string;
    profilePicture?: string;
  }[];
  containerBackground: string;
  noInfo?: boolean;
  size?: 'small' | 'normal' | 'large';
  maxUser?: number;
};

const MAX_USERS = 4;

export const AvatarList: React.FC<Props> = ({
  users,
  containerBackground,
  noInfo,
  size = 'normal',
  maxUser,
}) => {
  const navigate = useNavigate();
  const style = {
    borderWidth: theme.borderWidth.m,
    borderStyle: 'solid',
    borderColor: containerBackground,
  };

  const handleLastClick = useCallback(() => {
    navigate(ERouteName.People);
  }, [navigate]);
  return (
    <Container>
      {users.slice(0, maxUser || MAX_USERS).map((user, index) => (
        <ProfileIcon
          noDisplay={noInfo}
          key={user.userId}
          {...user}
          style={{
            marginLeft: index === 0 ? '0px' : marginLeft,
            ...style,
          }}
          size={size}
        />
      ))}
      {users.length > (maxUser || MAX_USERS) && (
        <Last
          style={{
            marginLeft,
            width: size === 'small' || size === 'normal' ? '40px' : '48px',
            height: size === 'small' || size === 'normal' ? '40px' : '48px',
            ...style,
          }}
          onClick={handleLastClick}
        >
          <Text.Body>+{users.length - (maxUser || MAX_USERS)}</Text.Body>
        </Last>
      )}
    </Container>
  );
};
