import React, { useCallback, useContext, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { css } from '@emotion/css';
import {
  ActivityHolder,
  Box,
  Button,
  ReplyDrawer,
  TextEditor,
} from 'components';
import { ChatTeardropText, Comment } from 'components/Icons';
import { theme } from 'theme';
import { Activity, IReplyModel } from '../types';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_REPLIES, REPLY_COMMENTS } from '../gql';
import { UserContext } from 'context';
import { useAppSelector } from 'hooks';
import { RootState } from 'reducers';
import { EActivityType } from 'model';
import { ENotificationKey } from 'shared';
import pubsub from 'sweet-pubsub';

type DocProps = {
  comment: string;
  creatorId?: string;
  responses: {
    _id: string;
    comment: string;
    created: number;
    author: {
      email: string;
      displayText: string;
    };
  }[];
  createdBy: string;
  createAt: number;
  topicId: string;
  updated?: number;
  id: string;
  customRef: React.LegacyRef<HTMLDivElement>;
  focus?: boolean;
  topicClosed: boolean;
  isFake?: boolean;
  numberOfReply: number;
  stared: boolean;
  last?: boolean;
  loading?: boolean;
  topicList?: { label: string; value: string }[];
  onReply?: (value: string) => void;
  onEdit?: (value: string, text: string) => void;
};
export const CommentItem: React.FC<DocProps> = ({
  comment: theComment,
  responses,
  updated,
  createAt,
  createdBy,
  topicId,
  id,
  customRef,
  focus,
  topicClosed,
  topicList = [],
  isFake,
  numberOfReply,
  creatorId,
  stared,
  last,
  loading,
  onReply,
  onEdit,
}) => {
  const [reply, setReply] = useState('');
  const [replyText, setReplyText] = useState('');
  const [comment, setComment] = useState(theComment);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [replies, setReplies] = useState<Activity[]>([]);

  const { state: userState } = useContext(UserContext);

  const topicBasics = useAppSelector((state: RootState) => state.topic);

  useEffect(() => {
    pubsub.emit(ENotificationKey.EDIT_TOPIC_OPEN, { value: !isDrawerOpen });
  }, [isDrawerOpen]);

  useEffect(() => {
    setComment(theComment);
  }, [theComment]);

  const [getReplies, { loading: getRepliesLoading }] = useLazyQuery<{
    replies: { result: Activity[]; size: number };
  }>(GET_REPLIES);

  const [replyComment, { loading: repliesLoading }] = useMutation<{
    replyToComment: Activity;
  }>(REPLY_COMMENTS);

  useEffect(() => {
    getReplies({
      variables: {
        commentId: id,
        page: 0,
      },
    }).then((res) => {
      if (res.data?.replies.result) {
        setReplies(res.data?.replies.result);
      }
    });
  }, [getReplies, id]);

  const handleReply = useCallback(() => {
    if (
      (!replyText.trim() || replyText.trim() === '') &&
      !(
        reply.includes('<img') ||
        reply.includes('<iframe') ||
        reply.includes('class="mention"')
      )
    )
      return;

    const theComment: IReplyModel = {
      comment: reply,
      parentId: id,
    };

    const data = {
      type: EActivityType.COMMENT,
      payload: theComment,
    };

    const toAdd: Activity = {
      _id: new Date().getTime().toString(),
      comment: 'fake',
      created: new Date().getTime(),
      data: JSON.stringify(data),
      topicId: topicId!,
      workspaceId: '',
      stared: false,
      group: [],
      userId: {
        _id: userState._id,
        firstName: userState.firstName!,
        lastName: userState.lastName!,
        businessEmail: userState.businessEmail,
      },
    };
    setReplies((to) => [...to, toAdd]);
    replyComment({
      variables: {
        commentId: id,
        comment: reply,
      },
    }).then((res) => {
      if (res.data?.replyToComment._id) {
        setReply('');
        setReplyText('');
        setReplies((to) => to.filter((el) => el.comment !== 'fake'));
        setReplies((el) => [...el, res.data?.replyToComment!]);
      }
    });
  }, [
    id,
    reply,
    replyComment,
    replyText,
    topicId,
    userState._id,
    userState.businessEmail,
    userState.firstName,
    userState.lastName,
  ]);

  const [editMode, setEditMode] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const onEditStart = useCallback(() => {
    setEditMode(!editMode);
  }, [editMode]);

  const [commentText, setCommentText] = useState(comment);

  const handleEdit = useCallback(() => {
    onEdit?.(comment, commentText);
    setEditMode(false);
  }, [onEdit, comment, commentText]);

  const handleShowReplies = useCallback(() => {
    setIsDrawerOpen(true);
    setReadOnly(false);
    getReplies({
      variables: {
        commentId: id,
        page: 0,
      },
      nextFetchPolicy: 'network-only',
    }).then((res) => {
      if (res.data?.replies.result) {
        setReplies((old) => {
          const result = old;
          res.data!.replies?.result?.forEach((item) => {
            if (!result.find((r) => r._id === item._id)) {
              result.push(item);
            }
          });
          return result;
        });
      }
    });
  }, [getReplies, id]);

  const [render, setRender] = useState(false);

  useEffect(() => {
    /**
     * Weird but Quill text Editor need to be unmounted before rendering
     * So we did this:
     */
    setTimeout(() => {
      setRender(true);
    }, 0);
  }, []);

  return (
    <>
      <ActivityHolder.Item
        topicId={topicId}
        type={EActivityType.COMMENT}
        customRef={customRef}
        id={id}
        owner={creatorId}
        link={`${process.env.REACT_APP_WEB_URL}/topic/${topicId}#${id}`}
        Icon={<Comment style={{ width: '16px' }} />}
        title={`*<a href="${
          creatorId ? '/profile/' + creatorId : '#'
        }">${createdBy}</a>** commented`}
        subTitle={`${format(+createAt, 'MMMM d, yyyy • h:mm aa')} ${
          updated && updated > 0
            ? ' | updated at ' + format(+updated, 'MMMM d, yyyy • h:mm aa')
            : ''
        }`}
        onEdit={onEditStart}
        stared={stared}
        last={last}
      >
        <Box
          noPadding
          width="100%"
          backgroundColor="primary/02"
          className={css`
            position: relative;
            opacity: ${isFake ? 0.5 : 1};
            /* cursor: pointer; */
            border: 1px solid
              ${focus ? theme.colors.accent['blue/main'] : 'transparent'} !important;
            transition: border-color 0.15s;
          `}
        >
          {render && (
            <div
              className={css`
                width: 100%;
                border: ${editMode ? 1 : 0}px solid
                  ${theme.colors.primary['primary/05']};
                border-radius: 4px;
              `}
            >
              <TextEditor
                toolbarId={'a' + id} // id property can't start with number
                placeholder="Enter some text..."
                submit="Done"
                value={comment}
                handleSubmit={handleEdit}
                handleChange={setComment}
                handleChangeText={setCommentText}
                readonly={!editMode}
                noBackground
              />
            </div>
          )}

          {!editMode && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              padding="m"
            >
              <Button
                Icon={ChatTeardropText}
                text={`${replies.length || 0} Comment${
                  replies.length > 1 ? 's' : ''
                }`}
                iconPosition="left"
                iconColor="purple/main"
                onClick={handleShowReplies}
              />
            </Box>
          )}
        </Box>
      </ActivityHolder.Item>
      {!editMode && render && (
        <ReplyDrawer
          isDrawerOpen={isDrawerOpen}
          toggleDrawer={() => setIsDrawerOpen(!isDrawerOpen)}
          replies={replies}
          readOnly={readOnly}
          repliesLoading={repliesLoading}
          handleReply={handleReply}
          setReply={setReply}
          setReplyText={setReplyText}
          reply={reply}
          topicBasics={{
            closed: topicBasics?.closed,
            isAdmin: true,
            isWrite: true,
          }}
          getRepliesLoading={getRepliesLoading}
          topComment={{
            id,
            comment,
            createAt,
            createdBy,
            render,
          }}
        />
      )}
    </>
  );
};
