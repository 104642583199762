import { css } from '@emotion/css';
import { Box, Text } from 'components';
import { SwordIcon } from 'components/Icons';
import React, { useState } from 'react';
import { theme } from 'theme';
import { DataProtection } from './DataProtection';

export const PrivacySettings: React.FC = () => {
  const [dataProtectionOpen, setDataProtectionOpen] = useState(false);
  return (
    <div
      className={css`
        width: 100%;
      `}
    >
      <Text.H2>Privacy details</Text.H2>
      <Box
        width="80%"
        height="400px"
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        marginTop="xl"
        noPadding
      >
        <SwordIcon
          fill={theme.colors.accent['purple/main']}
          style={{ width: '64px', height: '64px', marginRight: '23px' }}
        />
        <Box noPadding>
          <Text.Body color="text/100%" marginBottom="4xs" marginTop="4xs">
            We take privacy very seriously.
          </Text.Body>
          <Text.BodySM color="text/75%" marginTop="2xs" marginBottom="4xs">
            Please feel free to read more in detail via the following links:
          </Text.BodySM>
          <ul
            className={css`
              color: ${theme.colors.text['text/link']};
            `}
          >
            <li>
              <Text.A
                color="text/link"
                size="body-sm"
                marginBottom="4xs"
                marginTop="4xs"
                href="https://www.multiplayer.work/company/terms-of-service"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Service
              </Text.A>
            </li>
            <li>
              <Text.A
                color="text/link"
                size="body-sm"
                marginBottom="4xs"
                marginTop="4xs"
                href="https://www.multiplayer.work/company/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </Text.A>
            </li>
            <li
              className={css`
                cursor: pointer;
              `}
              onClick={() => {
                setDataProtectionOpen(true);
              }}
            >
              <Text.BodySM color="text/link" marginBottom="4xs" marginTop="4xs">
                Customer Data Protection
              </Text.BodySM>
            </li>
          </ul>

          <DataProtection
            open={dataProtectionOpen}
            onOpenChange={setDataProtectionOpen}
          />
        </Box>
      </Box>
    </div>
  );
};
