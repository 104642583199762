export enum EIssueActivityType {
  Assigned = 'assigned',
  Deleted = 'deleted',
  Milestoned = 'milestoned',
  ReviewRequested = 'review_requested',
  Reviewed = 'reviewed',
  Unassigned = 'unassigned',
  Unlabeled = 'unlabeled',
  Unlocked = 'unlocked',
  Closed = 'closed',
  Commented = 'commented',
  Committed = 'committed',
  ConvertedToDiscussion = 'converted_to_discussion',
  ConvertedNoteToIssue = 'converted_note_to_issue',
  Demilestoned = 'demilestoned',
  Deployed = 'deployed',
  Labeled = 'labeled',
  Locked = 'locked',
  Merged = 'merged',
  MovedColumnsInProject = 'moved_columns_in_project',
  ReadyForReview = 'ready_for_review',
  Referenced = 'referenced',
  RemovedFromProject = 'removed_from_project',
  AddedToProject = 'added_to_project',
  Renamed = 'renamed',
  ReviewDismissed = 'review_dismissed',
  Reopened = 'reopened',
  ReviewRequestRemoved = 'review_request_removed',
  IssueCreated = 'issueCreated',
}

export type GithubDataType =
  | {
      event: EIssueActivityType.Assigned;
      assignee: {
        login: string;
        id: number;
        url: string;
        multiplayerId?: string;
        multiplayerName?: string;
      };
    }
  | {
      event: EIssueActivityType.Labeled;
      label: string;
    }
  | {
      event: EIssueActivityType.Unlabeled;
      label: string;
    }
  | {
      event: EIssueActivityType.Milestoned;
      milestone: string;
    }
  | {
      event: EIssueActivityType.Demilestoned;
      milestone: string;
    }
  | {
      event: EIssueActivityType.AddedToProject;
      columnName: string;
    }
  | {
      event: EIssueActivityType.MovedColumnsInProject;
      columnName: string;
    }
  | {
      event: EIssueActivityType.Commented;
      body: string;
    }
  | {
      event: EIssueActivityType.IssueCreated;
      body: string;
    }
  | {
      event: EIssueActivityType.Reopened;
    }
  | {
      event: EIssueActivityType.Closed;
    }
  | {
      event: EIssueActivityType.Deleted;
    };

export type GithubIssueActivity = {
  id: number;
  issueNumber: number;
  title: string;
  creator: {
    login: string;
    id: number;
    url: string;
  };
  created: string;
  data: GithubDataType;
  issueUrl: string;
};

export type GithubIssueInfo = {
  title: string;
  id: number;
  body: string;
  author: {
    login: string;
    id: number;
    url: string;
  };
  created: string;
};
