import { css } from '@emotion/css';
import { Box, Text } from 'components';
import { PuzzlePieceIcon } from 'components/Icons';
import { ERouteName } from 'pages';
import React from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'theme';

export const Landing: React.FC = () => {
  return (
    <div
      className={css`
        width: 80%;
        margin: auto;
        /* text-align: center; */
        padding: 30px;
      `}
    >
      <Text.H1 marginBottom="l">Multiplayer App for Slack</Text.H1>

      <Text.Body>
        Multiplayer helps companies integrate, organize, and track work and
        communication spread across apps.
      </Text.Body>

      <Text.Body>Features:</Text.Body>

      <ul
        className={css`
          /* list-style-type: none; */
          /* padding-inline-start: 0px; */
          color: ${theme.colors.text['text/100%']};
        `}
      >
        <li>
          <Text.BodySM>
            Save any message in Slack to Multiplayer by selecting the "more
            actions" menu (when hovering on a Slack message, a three vertical
            dots button appears in the upper right of the message) and finding
            Multiplayer in the list of message shortcuts at the bottom of the
            menu (you may have to click on "more message shortcuts..." if you do
            not see Multiplayer listed).
          </Text.BodySM>
        </li>
        <li>
          <Text.BodySM>
            Receive daily summaries on topics you care about with Multiplayer.
            When in the Multiplayer app, each topic has a “Watch topic” button.
            Click to subscribe; updates appear in your Multiplayer App for Slack
            (if you have Slack notifications turned on). <br /> <br />
            Please note that features powered by Large Language Models (LLMs),
            such as GPT-generated daily topic summaries, may generate inaccurate
            responses. We note in the app features that are powered by GPT with
            a visual identifier.
          </Text.BodySM>
        </li>
        <li>
          <Text.BodySM>
            Save any message in Slack as a to-do item in Multiplayer from the
            “more actions” menu (available when hovering over a Slack message).
            These saved to-do items live in your Multiplayer notification
            workflow.
          </Text.BodySM>
        </li>
      </ul>

      <Box
        width="100%"
        height="110px"
        backgroundColor="primary/02"
        borderRadius="l"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        marginTop="3xl"
        marginBottom="2xl"
      >
        <Box
          noPadding
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          width="70%"
        >
          <PuzzlePieceIcon
            fill={theme.colors.text['text/link']}
            style={{ width: '48px', height: '48px', marginRight: '32px' }}
          />
          <Box noPadding>
            <Text.Body marginBottom="4xs">Bot for Slack installation</Text.Body>
            <Text.TextSmall1 color="text/75%" marginTop="4xs">
              To install and start using our bot for Slack, you need to login
              and start the onboarding flow on Multiplayer. Did you miss the
              onboarding? You can also find the integration flow on our Setting
              page at Multiplayer. First connect your Multiplayer account with
              your Slack account. Then click on the button at the bottom of the
              page to install the bot for Slack (if not already installed in
              your workspace).
            </Text.TextSmall1>
          </Box>
        </Box>

        <Link
          to={ERouteName.Login}
          className={css`
            text-decoration: none;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 44px;
            border-radius: 8px;
            background-color: ${theme.colors.primary['primary/03']};
            padding-inline: 16px;
          `}
        >
          <Text.Body marginBottom="4xs" marginTop="4xs">
            Login to Multiplayer
          </Text.Body>
        </Link>
      </Box>

      <a
        className={css`
          color: ${theme.colors.accent['purple/main']};
          margin-top: 40px;
        `}
        href="https://www.multiplayer.work/privacy-policy"
      >
        Check our privacy policy
      </a>
    </div>
  );
};
