import { useMutation } from '@apollo/client';
import { css } from '@emotion/css';
import { Box, CircularProgress, InviteDialog, Text } from 'components';
import { CheckIcon } from 'components/Icons';
import { UserContext } from 'context';
import { TutorialProgress } from 'model';
import { ERouteName } from 'pages';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'theme';
import { VIDEO_WATCHED } from './gql';

type TutorialType = {
  key: string;
  title: string;
  subtitle: string;
  checked: boolean;
  link: string;
  onClick?: VoidFunction;
};

type Props = {
  tutorialProgress?: TutorialProgress;
  onTutorialCompleted?: VoidFunction;
};
export const TutorialSetting: React.FC<Props> = ({
  tutorialProgress,
  onTutorialCompleted,
}) => {
  const { state: userState } = useContext(UserContext);
  const [inviteOpen, setInviteOpen] = useState(false);

  const [tutorialList, setTutorialList] = useState<TutorialType[]>([]);

  const [markAsWatched] = useMutation(VIDEO_WATCHED);

  const isTutorialCompleted = Boolean(
    tutorialProgress?.integrations &&
      tutorialProgress?.invitation &&
      tutorialProgress?.profile &&
      tutorialProgress?.tutorial
  );

  useEffect(() => {
    setTutorialList([
      {
        title: 'Setup your profile',
        subtitle: 'Add your name, role, and pic to your profile.',
        checked: Boolean(tutorialProgress?.profile),
        link: ERouteName.MyDetailsSetting,
        key: 'profile',
      },
      {
        title: 'Setup your app integrations',
        subtitle:
          'Multiplayer can track progress in other apps automatically and save messages from Slack.',
        checked: Boolean(tutorialProgress?.integrations),
        link: ERouteName.IntegrationSetting,
        key: 'integrations',
      },
      {
        title: 'Go through our tutorial',
        subtitle: "Here's a quick tutorial on how to use Multiplayer.",
        checked: Boolean(tutorialProgress?.tutorial),
        link: '#',
        key: 'tutorial',
        onClick: () => {
          markAsWatched().then(() => {});
          const a = document.createElement('a');
          a.target = '_blank';
          a.href =
            'https://www.loom.com/share/bd73a11344d04a3288d391a58d286f6b';
          a.click();
        },
      },
      {
        title: 'Invite collaborators',
        subtitle:
          'Invite teammates to Multiplayer for an even better experience',
        checked: Boolean(tutorialProgress?.invitation),
        link: '#',
        key: 'invitation',
        onClick: () => {
          setInviteOpen(true);
        },
      },
    ]);
  }, [
    markAsWatched,
    tutorialProgress?.integrations,
    tutorialProgress?.invitation,
    tutorialProgress?.profile,
    tutorialProgress?.tutorial,
    userState._id,
  ]);

  const progress = tutorialList.reduce((prev, curr) => {
    if (curr.checked) {
      return (
        (((prev * tutorialList.length) / 100 + 1) * 100) / tutorialList.length
      );
    }
    return prev;
  }, 0);

  return (
    <div
      className={css`
        position: relative;
        width: 100%;
        margin-top: 16px;
      `}
    >
      <Box
        noPadding
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        width="100%"
        marginBottom="xl"
      >
        <InviteDialog open={inviteOpen} onOpenChange={setInviteOpen} />
        <CircularProgress
          value={progress}
          color={
            progress < 100
              ? theme.colors.accent['yellow/main']
              : theme.colors.accent['green/main']
          }
        />
        <Box noPadding marginLeft="xl">
          <Text.Body marginBottom="4xs" marginTop="4xs">
            {isTutorialCompleted
              ? 'You’ve completed onboarding!'
              : 'Get familiar with our app'}
          </Text.Body>
          <Text.BodySM marginBottom="4xs" marginTop="xs" color="text/75%">
            {isTutorialCompleted
              ? 'Great job! Feel free to refresh your memory by redoing the steps below or re-watching the tutorial.'
              : 'Get familiar with our app by following the steps below.'}
          </Text.BodySM>
        </Box>
      </Box>

      {tutorialList.map((item, idx) => (
        <React.Fragment key={item.key}>
          <TutorialItem {...item} />
          {tutorialList.length !== idx + 1 && <TutorialItemSeparator />}
        </React.Fragment>
      ))}
    </div>
  );
};

type TutorialItemProps = {
  checked?: boolean;
  title: string;
  subtitle: string;
  link?: string;
  onClick?: VoidFunction;
};
const TutorialItem: React.FC<TutorialItemProps> = ({
  checked,
  title,
  subtitle,
  link = '#',
  onClick,
}) => {
  return (
    <Link to={link} style={{ textDecoration: 'none' }} onClick={onClick}>
      <div
        className={css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}
      >
        <div
          className={css`
            width: 19px;
            height: 19px;
            border-radius: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-right: 16px;
            background-color: ${checked
              ? theme.colors.accent['green/main']
              : ''};
            border: ${checked
              ? ''
              : `2px solid ${theme.colors.primary['primary/02']}`};
          `}
        >
          {checked && (
            <CheckIcon
              fill={theme.colors.text['text/100%']}
              style={{ width: '12px' }}
            />
          )}
        </div>
        <div
          className={css`
            max-width: 80%;
          `}
        >
          <Text.Body>{title}</Text.Body>
          <Text.TextSmall1 color="text/75%">{subtitle}</Text.TextSmall1>
        </div>
      </div>
    </Link>
  );
};

const TutorialItemSeparator: React.FC = () => (
  <div
    className={css`
      border: 1px solid ${theme.colors.accent['purple/light']};
      width: 100%;
      height: 1px;
      margin-top: 16px;
      margin-bottom: 16px;
    `}
  />
);
