import React, { useMemo } from 'react';
import SelectComponent, {
  Colors,
  components,
  ContainerProps,
  GroupBase,
  MultiValue,
  MultiValueGenericProps,
  OptionsOrGroups,
  SingleValue,
  ThemeSpacing,
} from 'react-select';
import styled from '@emotion/styled';

import { Theme, theme } from 'theme';
import { Box, ProfileIcon, Text } from 'components';
import { css } from '@emotion/css';

type SelectProps = {
  isMulti?: boolean;
  placeholder?: string;
  background?: string;
  color?: keyof Theme['colors']['text'];
  notSearchable?: boolean;
  small?: boolean;
  defaultValue?: any;
  isUser?: boolean;
  notClearable?: boolean;
  data: OptionsOrGroups<
    {
      value: string;
      label: string;
    },
    GroupBase<{
      value: string;
      label: string;
    }>
  >;
  onChange?: (
    update:
      | SingleValue<{
          value: string;
          label: string;
        }>
      | MultiValue<{
          value: string;
          label: string;
        }>
  ) => void;
};

const spacing: ThemeSpacing = {
  baseUnit: 5,
  controlHeight: 40,
  menuGutter: 2,
};

const OptionContainer = styled.div`
  padding: ${theme.spacing['2xs']}px ${theme.spacing.m}px;

  &:hover {
    background-color: ${theme.colors.primary['primary/03']};
  }
`;

const OptionUserContainer = styled.div`
  padding: ${theme.spacing['2xs']}px ${theme.spacing.m}px;
  display: flex;
  flex-direction: row;
  /* width: 150% !important; */

  &:hover {
    background-color: ${theme.colors.primary['primary/03']};
  }
`;

const CustomOption: React.FC<any> = ({ innerProps, data, isDisabled }) =>
  !isDisabled ? (
    <OptionContainer {...innerProps} style={{ paddingInline: theme.spacing.m }}>
      <Text.BodySM>{data.label}</Text.BodySM>
    </OptionContainer>
  ) : null;

const CustomUserOption: React.FC<any> = ({ innerProps, data, isDisabled }) => {
  const info = JSON.parse(data.label);
  return !isDisabled ? (
    <OptionUserContainer
      {...innerProps}
      style={{
        paddingInline: theme.spacing.m,
        cursor: 'pointer',
        marginBottom: theme.spacing.s,
        // border: '1px solid red',
      }}
    >
      <div>
        <ProfileIcon
          userId={info.userId}
          userName={info.userName}
          profilePicture={info.profilePicture}
          noDisplay
        />
      </div>
      <Box noPadding marginLeft="s" display="block" style={{ maxWidth: '80%' }}>
        <Text.Body marginBottom="4xs" marginTop="4xs">
          {info.userName}
        </Text.Body>
        <Text.BodySM color="text/light" marginBottom="4xs" marginTop="4xs">
          {info.email}
        </Text.BodySM>
      </Box>
    </OptionUserContainer>
  ) : null;
};

const MultiValueUserLabel = ({
  children,
  data,
  ...props
}: MultiValueGenericProps<any>) => {
  return (
    <components.MultiValueLabel data {...props}>
      {JSON.parse(data.label).email}
    </components.MultiValueLabel>
  );
};

const MultiValueLabel = ({
  children,
  data,
  ...props
}: MultiValueGenericProps<any>) => {
  return (
    <components.MultiValueLabel data {...props}>
      {data.label}
    </components.MultiValueLabel>
  );
};

const SelectContainer = ({ children, ...props }: ContainerProps<any>) => {
  return (
    <div
      className={css`
        width: 100%;
      `}
    >
      <components.SelectContainer {...props}>
        {children}
      </components.SelectContainer>
    </div>
  );
};

export const Select: React.FC<SelectProps> = ({
  isMulti,
  placeholder,
  data,
  background,
  small,
  color = 'text/main',
  notSearchable,
  defaultValue,
  isUser,
  notClearable,
  onChange,
}) => {
  const colors: Colors = useMemo(
    () => ({
      primary: background || theme.colors.accent['blue/main'],
      danger: theme.colors.accent['red/dark'],
      dangerLight: theme.colors.accent['red/main'],
      neutral0: background || theme.colors.primary['primary/02'],
      neutral10: theme.colors.primary['primary/01'],
      neutral20: background || theme.colors.primary['primary/02'],
      neutral30: background || theme.colors.accent['blue/main'],
      neutral40: theme.colors.text['text/light'],
      neutral5: 'red',
      neutral50: theme.colors.text['text/light'],
      neutral60: background ? background : theme.colors.accent.white,
      neutral70: 'red',
      neutral80: theme.colors.text['text/main'],
      neutral90: 'red',
      primary25: theme.colors.primary['primary/03'],
      primary50: 'red',
      primary75: 'red',
    }),
    [background]
  );
  return (
    <SelectComponent
      closeMenuOnScroll
      closeMenuOnSelect
      hideSelectedOptions
      isClearable={!notClearable}
      defaultValue={defaultValue}
      isSearchable={!notSearchable}
      noOptionsMessage={(ob) => 'Nothing matches your filter'}
      isMulti={isMulti}
      placeholder={placeholder}
      theme={{
        borderRadius: 4,
        colors,
        spacing,
      }}
      options={data}
      delimiter=":"
      onChange={onChange}
      components={{
        Option: isUser ? CustomUserOption : CustomOption,
        SelectContainer,
        MultiValueLabel: isUser ? MultiValueUserLabel : MultiValueLabel,
      }}
    />
  );
};
