import React from 'react';
import { styled } from '@stitches/react';
// import { violet, mauve, blackA, whiteA } from '@radix-ui/colors';
import * as SwitchPrimitive from '@radix-ui/react-switch';
import { theme } from 'theme';

const StyledSwitch = styled(SwitchPrimitive.Root, {
  all: 'unset',
  width: 54,
  height: 24,
  backgroundColor: '#2D2A41',
  borderRadius: '9999px',
  position: 'relative',
  // boxShadow: `0 2px 10px black`,
  WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
  // borderWidth: 1,
  border: '1px solid transparent',
  '&:focus': {
    /*boxShadow: `0 0 0 2px black`*/
  },
  '&[data-state="checked"]': {
    backgroundColor: '#453E69',
  },
});

const StyledThumb = styled(SwitchPrimitive.Thumb, {
  display: 'block',
  width: 20,
  height: 20,
  backgroundColor: theme.colors.primary['primary/02'],
  borderRadius: '9999px',
  // boxShadow: `0 2px 2px black`,
  transition: 'transform 100ms',
  transform: 'translateX(2px)',
  willChange: 'transform',
  '&[data-state="checked"]': { transform: 'translateX(32px)' },
});

// Exports
export const SwitchComponent = StyledSwitch;
export const SwitchThumb = StyledThumb;

// Your app...
const Flex = styled('div', { display: 'flex' });
const Label = styled('label', {
  color: theme.colors.text['text/light'],
  fontSize: '12px',
  lineHeight: 1,
  userSelect: 'none',
});

type Props = {
  label?: string;
  checked?: boolean;
  onCheckedChange?: (value: boolean) => void;
};
export const Switch: React.FC<Props> = ({
  label,
  checked,
  onCheckedChange,
}) => (
  <form>
    <Flex css={{ alignItems: 'center' }}>
      {label && (
        <Label htmlFor="s1" css={{ paddingRight: 4 }}>
          {label}
        </Label>
      )}
      <SwitchComponent
        checked={checked}
        onCheckedChange={onCheckedChange}
        id="s1"
      >
        <SwitchThumb />
      </SwitchComponent>
    </Flex>
  </form>
);
