import { css } from '@emotion/css';
import { Button, DropdownButton, Text } from 'components';
import { FunnelSimpleIcon } from 'components/Icons';
import React, { useCallback, useState } from 'react';
import { theme } from 'theme';

type Props = {
  body: JSX.Element;
};
export const FilterDropdown: React.FC<Props> = ({ body }) => {
  const [open, setOpen] = useState(false);
  const onOpenChange = useCallback((state: boolean) => {
    setOpen(state);
  }, []);
  return (
    <DropdownButton
      open={open}
      onOpenChange={onOpenChange}
      noMargin
      body={body}
    >
      <Button
        iconPosition="icon-only"
        Icon={FunnelSimpleIcon}
        style={{ marginBottom: '8px' }}
      />
    </DropdownButton>
  );
};

type ItemProps = {
  text: string;
  selected: boolean;
  Icon?: React.FC<any>;
  onClick: VoidFunction;
};
export const FilterDropdownItem: React.FC<ItemProps> = ({
  text,
  selected,
  Icon,
  onClick,
}) => (
  <td
    className={css`
      padding: 0px;
      padding-right: 20px;
      width: 100%;
    `}
  >
    <div
      onClick={onClick}
      className={css`
        width: fit-content;
        cursor: pointer;
        background-color: ${selected
          ? theme.colors.primary['primary/01']
          : 'transparent'};
        padding: 5px 10px;
        border-radius: 4px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      `}
    >
      {Icon && (
        <Icon
          fill={
            selected
              ? theme.colors.text['text/100%']
              : theme.colors.text['text/blue/soft']
          }
          style={{ width: '16px', marginRight: '10px' }}
        />
      )}
      <Text.BodySM
        marginBottom="4xs"
        marginTop="4xs"
        color={selected ? 'text/100%' : 'text/blue/soft'}
      >
        {text}
      </Text.BodySM>
    </div>
  </td>
);
