// import { gql, useMutation } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { Badge, Box, Text, TooltipContainer } from 'components';
import {
  BookmarkIcon,
  CopySimpleIcon,
  LightningSlashIcon,
  PencilSimpleIcon,
  TopicCreatedIcon,
  TrashIcon,
} from 'components/Icons';
import { UserContext } from 'context';
import { useAppSelector } from 'hooks';
import { EActivityType } from 'model';
import { DeleteActivityDialog } from 'pages/Topic/components/ItemMenu/DeleteActivityDialog';
import { AddToGroup } from 'pages/Topic/components/Menu/AddToGroup';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { RootState } from 'reducers';
import { theme } from 'theme';
// import { Menu } from './ItemMenu/Menu';

const ICON_CONTAINER_SIZE = 40;

const RootContainer = styled.div`
  width: 100%;
  position: relative;
  /* margin-top: ${theme.spacing['2xl']}px; */
  /* border: 1px solid red; */
`;

type LineProps = {
  small: boolean;
};

const Line = styled.div<LineProps>`
  height: calc(100% - ${(props) => (props.small ? '73px' : '24px')});
  width: 1px;
  background-color: ${theme.colors.accent['purple/light']};
  position: absolute;
  top: 40px;
  left: ${ICON_CONTAINER_SIZE / 2}px;
  z-index: 1;
`;

type RootProps = {
  // createdAt?: number;
  // createdBy?: string;
  children: React.ReactNode;
  customRef?: React.LegacyRef<HTMLDivElement>;
};
const Root: React.FC<RootProps> = ({
  children,
  // createdAt,
  // createdBy,
  customRef,
}) => {
  return (
    <RootContainer ref={customRef}>
      {/* <Line /> */}

      {children}
      {/* {createdBy && (
        <Item
          type={EActivityType.UNKNOWN}
          topicId=""
          last
          stared={false}
          Icon={<TopicCreatedIcon />}
          children=""
          subTitle={
            createdAt ? format(+createdAt, 'MMMM d, yyyy • h:mm aa') : ''
          }
          title={`*${createdBy}** created this topic`}
        />
      )} */}
    </RootContainer>
  );
};

const ItemHeader = styled.div`
  height: ${ICON_CONTAINER_SIZE}px;
  width: 100%;
  display: flex;
  flex-direction: row;
  z-index: 1;
  position: relative;
`;

const ItemBody = styled.div`
  width: 100%;
  margin-top: 16px;
  padding: ${theme.spacing['4xs']}px ${theme.spacing.s}px ${theme.spacing.m}px
    ${ICON_CONTAINER_SIZE + theme.spacing.m}px;
`;

const ItemTitleContainer = styled.div`
  height: 100%;
  width: 95%;
  padding-inline: ${theme.spacing.l}px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const STARED = gql`
  mutation starActivity($id: ID!, $stared: Boolean!) {
    starActivity(id: $id, stared: $stared)
  }
`;

type ItemProps = {
  children?: React.ReactNode;
  title: string;
  link?: string;
  subTitle?: string;
  Icon: JSX.Element;
  type: EActivityType;
  id?: string;
  last?: boolean;
  customRef?: React.LegacyRef<HTMLDivElement>;
  stared?: boolean;
  topicId?: string;
  owner?: string;
  hasCollapse?: boolean;
  noFormat?: boolean;
  onEdit?: VoidFunction;
  onClick?: VoidFunction;
};
const Item: React.FC<ItemProps> = ({
  children,
  title,
  subTitle,
  Icon,
  id,
  link,
  customRef,
  hasCollapse,
  last,
  type,
  owner,
  stared,
  topicId,
  noFormat,
  onEdit,
  onClick,
}) => {
  const { state: userState } = useContext(UserContext);
  // const [copied, setCopied] = useState(false);
  const [important, markAsImportant] = useState(stared);
  // const [menuShow, setMenuShow] = useState(false);

  const topic = useAppSelector((state: RootState) => state.topic);

  const [starActivity] = useMutation(STARED);
  const Container = useMemo(
    () => styled.div`
      margin-bottom: ${theme.spacing.m}px;
      position: relative;
    `,
    []
  );

  const copyUrl = useCallback(() => {
    navigator.clipboard.writeText(link || '').then(() => {
      // setCopied(true);
      // setTimeout(() => {
      //   setCopied(false);
      // }, 2000);
    });
  }, [link]);

  const handleStared = useCallback(() => {
    starActivity({
      variables: {
        id,
        stared: !important,
      },
    }).then((res) => {
      markAsImportant(!important);
    });
  }, [id, starActivity, important]);

  const renderTitle = useMemo(() => {
    return (
      <div
        className={css`
          color: ${theme.colors.text['text/light']} !important;
          font-size: ${theme.textVariants['body-sm']};
          span {
            color: ${theme.colors.text['text/100%']};
          }
          a {
            all: unset;
            cursor: pointer;
          }
        `}
        dangerouslySetInnerHTML={{
          __html: noFormat
            ? title
            : `${title.split('**').join('</span>').split('*').join('<span>')}`,
        }}
      />
    );
  }, [noFormat, title]);

  const [addToGroupDialogOpen, setAddToGroupDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  // const [summaryDialogOpen, setSummaryDialogOpen] = useState(false);
  const [hover, setHover] = useState(false);

  const hasEdit = !topic?.closed;

  const isOwner = userState?._id && owner === userState?._id;
  const MENU = [
    {
      id: '1',
      text: `Mark as ${important ? 'non' : ''} important`,
      Icon: important ? LightningSlashIcon : TopicCreatedIcon,
      onclick: handleStared,
      canRender:
        [
          EActivityType.COMMENT,
          EActivityType.REPLY,
          EActivityType.SLACK,
          EActivityType.GOOGLE_COMMENT,
          EActivityType.GOOGLE,
          EActivityType.NOTION,
          EActivityType.LINEAR_ISSUE,
          EActivityType.LINEAR_ISSUE_COMMENT,
          EActivityType.MICROSOFT,
        ].includes(type) && hasEdit,
    },
    {
      id: '2',
      text: 'Add to group',
      Icon: BookmarkIcon,
      onclick: () => {
        setAddToGroupDialogOpen(true);
      },
      canRender:
        [
          EActivityType.COMMENT,
          EActivityType.REPLY,
          EActivityType.SLACK,
          EActivityType.GOOGLE_COMMENT,
          EActivityType.GOOGLE,
          EActivityType.NOTION,
          EActivityType.LINEAR_ISSUE,
          EActivityType.LINEAR_ISSUE_COMMENT,
          EActivityType.MICROSOFT,
        ].includes(type) && hasEdit,
    },
    {
      id: '3',
      text: 'Edit content',
      Icon: PencilSimpleIcon,
      onclick: onEdit,
      canRender:
        [EActivityType.COMMENT, EActivityType.REPLY].includes(type) &&
        isOwner &&
        hasEdit,
    },
    {
      id: '4',
      text: 'Delete item',
      Icon: TrashIcon,
      onclick: () => {
        setDeleteDialogOpen(true);
      },
      canRender:
        [
          EActivityType.COMMENT,
          EActivityType.REPLY,
          EActivityType.SLACK,
        ].includes(type) &&
        isOwner &&
        hasEdit,
    },
    {
      id: '5',
      text: 'Copy link',
      Icon: CopySimpleIcon,
      onclick: copyUrl,
      canRender: [
        EActivityType.COMMENT,
        EActivityType.REPLY,
        EActivityType.SLACK,
        EActivityType.GOOGLE,
        EActivityType.GOOGLE_COMMENT,
        EActivityType.NOTION,
        EActivityType.LINEAR_ISSUE,
        EActivityType.LINEAR_ISSUE_COMMENT,
        EActivityType.MICROSOFT,
      ].includes(type),
    },
  ];

  return (
    <Container
      id={id}
      ref={customRef}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
      style={{ cursor: onClick ? 'pointer' : '' }}
    >
      {!last && <Line small={hasCollapse!} />}
      <ItemHeader>
        <Badge Icon={Icon} className={css``} />
        <ItemTitleContainer>
          <Box noPadding display="flex" flexDirection="column" width="100%">
            <div style={{ marginTop: title.length > 100 ? '12px' : '' }}>
              {renderTitle}
            </div>
            {subTitle && (
              <Text.BodyXS color="text/50%" marginBottom="4xs" marginTop="4xs">
                {subTitle}
              </Text.BodyXS>
            )}
          </Box>
          <div
            className={css`
              height: 40px;
              border-radius: 8px;
              background-color: ${theme.colors.primary['primary/02']};
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              overflow: hidden;
              position: absolute;
              z-index: 0;
              right: 0px;
            `}
          >
            {type !== EActivityType.UNKNOWN &&
              topicId &&
              hover &&
              MENU.map((menu) => (
                <TooltipContainer key={menu.id} text={menu.text}>
                  <div
                    className={css`
                      width: 42px;
                      height: 100%;
                      display: ${menu.canRender ? 'flex' : 'none'};
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                      position: relative;
                      cursor: pointer;
                      &:hover {
                        background-color: ${theme.colors.primary['primary/03']};
                      }
                    `}
                    onClick={menu.onclick}
                  >
                    <menu.Icon
                      fill={theme.colors.text['text/blue/soft']}
                      style={{ width: '16px', height: '16px' }}
                    />
                  </div>
                </TooltipContainer>
              ))}
          </div>
        </ItemTitleContainer>
      </ItemHeader>
      {children && <ItemBody>{children}</ItemBody>}
      <AddToGroup
        open={addToGroupDialogOpen}
        topicId={topicId || ''}
        itemId={id!}
        onOpenChange={setAddToGroupDialogOpen}
      />
      <DeleteActivityDialog
        open={deleteDialogOpen}
        itemId={id!}
        onOpenChange={setDeleteDialogOpen}
      />
    </Container>
  );
};

export const ActivityHolder = {
  Root,
  Item,
};
