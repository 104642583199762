import { gql, useQuery } from '@apollo/client';
import { css } from '@emotion/css';
import { AvatarList, Box, ItemTitle, PageLoading, Text } from 'components';
import { ActivityIcon, ArrowRight } from 'components/Icons';
import { format } from 'date-fns';
import { TopicList } from 'features/topics';
import { ERouteName } from 'pages';
import React from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'theme';
import { capitalizeString, displayUser } from 'utils';

const TRENDING = gql`
  query TrendingTopics {
    trendingTopics {
      _id
      name
      closed
      updated
      creatorId {
        _id
        firstName
        lastName
        businessEmail
        profileUrl
      }
      collaborators {
        _id
        firstName
        lastName
        businessEmail
        profileUrl
      }
      momentum {
        count
        label
        lastActivity
      }
    }
  }
`;

type TrendingResult = {
  trendingTopics: {
    _id: string;
    name: string;
    closed: boolean;
    updated: number;
    creatorId: {
      _id: string;
      firstName?: string;
      lastName?: string;
      businessEmail: string;
      profileUrl?: string;
    };
    collaborators: {
      _id: string;
      firstName?: string;
      lastName?: string;
      businessEmail: string;
      profileUrl?: string;
    }[];
    momentum: TopicList[0]['momentum'];
  }[];
};

type Props = {
  smallMomentum?: boolean;
};
export const TrendingTopics: React.FC<Props> = ({ smallMomentum }) => {
  const { data, loading } = useQuery<TrendingResult>(TRENDING);
  return (
    <Box width="100%" height="100%" borderRadius="l" noPadding>
      <ItemTitle Icon={ActivityIcon} title="Trending" />
      {/* <Box
        noPadding
        display="flex"
        flexDirection="row"
        alignItems="center"
        marginBottom="4xs"
      >
        <ActivityIcon
          style={{ width: '16px', height: '16px', marginRight: '8px' }}
        />
        <Text.TextSmall1 color="text/100%" marginTop="4xs" marginBottom="4xs">
          Trending
        </Text.TextSmall1>
      </Box> */}
      {Boolean(
        !loading && data?.trendingTopics && data?.trendingTopics.length > 0
      ) ? (
        <div
          className={css`
            width: 100%;
            display: block;
            margin-top: 24px;
            position: relative;
          `}
        >
          {data?.trendingTopics.map((topic, idx) => (
            <Link
              key={topic._id}
              to={ERouteName.Topic + topic._id}
              style={{
                textDecoration: 'none',
                width: '32%',
                display: 'inline-block',
                verticalAlign: 'top',
                marginRight: '1.33%',
              }}
            >
              <div
                className={css`
                  width: 100%;
                  height: 180px;
                  padding: 32px;
                  border-radius: 16px;
                  background-color: ${theme.colors.primary['primary/02']};
                  display: flex;
                  flex-direction: column;
                  justify-content: space-evenly;
                  /* margin-right: 16px; */
                `}
              >
                <div
                  className={css`
                    width: 100%;
                    overflow: hidden;
                  `}
                >
                  <Text.Body
                    marginBottom="4xs"
                    marginTop="4xs"
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {topic.name}
                  </Text.Body>
                  <Text.TextSmall2 color="text/50%" marginTop="2xs">
                    {topic.updated
                      ? format(+topic.updated, 'MMMM d, yyyy')
                      : ''}
                  </Text.TextSmall2>
                </div>

                <Box
                  noPadding
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <AvatarList
                    containerBackground={theme.colors.primary['primary/02']}
                    noInfo
                    maxUser={3}
                    size="small"
                    users={
                      topic.collaborators.length > 0
                        ? [...topic.collaborators!].map((user) => ({
                            userId: user._id,
                            userName: displayUser(user) || '',
                            userRole: capitalizeString(''),
                            profilePicture: user.profileUrl,
                          }))
                        : [
                            {
                              userId: topic.creatorId._id,
                              userName: displayUser(topic.creatorId) || '',
                              userRole: '',
                              profilePicture: topic.creatorId.profileUrl,
                            },
                          ]
                    }
                  />

                  <ArrowRight
                    style={{
                      width: '30px',
                      height: '30px',
                    }}
                    fill="#6A53FE"
                  />
                </Box>
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <div
          className={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 180px;
            border-radius: 24px;
            border: 2px solid #272539;
            margin-top: 8px;
          `}
        >
          <PageLoading noCover />
          <Text.H4 color="text/light" marginTop="xs">
            You will see trending{' '}
            <Text.A color="text/link" size="h4" href={ERouteName.Workspace}>
              topics
            </Text.A>{' '}
            in your workspace here.
          </Text.H4>
        </div>
      )}
    </Box>
  );
};
