import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { css } from '@emotion/css';
import { ActivityHolder, Box, Drawer, Text } from 'components';
import { ChevronRightIcon, NotionLogo } from 'components/Icons';
import { theme } from 'theme';
import { NotionModel } from '../types';
import { EActivityType } from 'model';
import { ENotificationKey } from 'shared';
import pubsub from 'sweet-pubsub';

type Props = {
  doc: NotionModel;
  id: string;
  topicId: string;
  createdBy: string;
  creatorId?: string;
  customRef: React.LegacyRef<HTMLDivElement>;
  focus?: boolean;
  stared: boolean;
  last?: boolean;
  subComponents?: JSX.Element[];
  // isDrawerOpen?: boolean;
  // toggleDrawer?: VoidFunction;
};
export const NotionUpdate: React.FC<Props> = ({
  doc,
  topicId,
  id,
  createdBy,
  customRef,
  creatorId,
  focus,
  stared,
  last,
  subComponents,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    pubsub.emit(ENotificationKey.EDIT_TOPIC_OPEN, { value: !isDrawerOpen });
  }, [isDrawerOpen]);

  return (
    <>
      <ActivityHolder.Item
        topicId={topicId}
        type={EActivityType.GOOGLE}
        id={id}
        customRef={customRef}
        link={`${process.env.REACT_APP_WEB_URL}/topic/${topicId}#${id}`}
        Icon={<NotionLogo style={{ width: '16px', height: '16px' }} />}
        title={`*<a href="${
          creatorId ? '/profile/' + creatorId : '#'
        }">${createdBy}</a>** added modification to *<a href="${
          doc?.url
        }" target="_blank" rel="noreferrer">${doc?.title}</a>**`}
        subTitle={`${format(+doc?.modifiedDate, 'MMMM d, yyyy • h:mm aa')}`}
        // color={theme.colors.primary['primary/03']}
        stared={stared}
        last={last}
      >
        {/* <Box
            padding="m"
            borderRadius="m"
            width="100%"
            backgroundColor="primary/03"
            display="flex"
            flexDirection="row"
            onClick={() => setOpenDelta(true)}
            className={css`
              cursor: pointer;
              border: 1px solid
                ${focus ? theme.colors.accent['blue/main'] : 'transparent'} !important;
              transition: border-color 0.15s;
              &:hover {
                border-color: ${theme.colors.accent['blue/main']} !important;
              }
            `}
          ></Box> */}
        {subComponents && subComponents.length > 0 && (
          <Box
            noPadding
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            marginTop="s"
            position="relative"
            style={{
              cursor: 'pointer',
            }}
            onClick={() => setIsDrawerOpen(!isDrawerOpen)}
          >
            <div
              className={css`
                position: absolute;
                bottom: 9.5px;
                left: -34px;
              `}
            >
              <div
                className={css`
                  height: 40px;
                  width: 33px;
                  /* background-color: red; */
                  border-bottom-left-radius: 15px;
                  border-bottom: 1px solid
                    ${theme.colors.accent['purple/light']};
                  /* border-left: 1px solid ${theme.colors.accent[
                    'purple/light'
                  ]}; */
                `}
              />
            </div>
            <div
              className={css`
                width: 20px;
                height: 1px;
                margin-left: -20px;
                background-color: ${theme.colors.accent['purple/light']};
              `}
            />
            <div
              className={css`
                width: 20px;
                height: 20px;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                /* background-color: ${theme.colors.primary['primary/04']}; */
                margin-right: 7px;
                border: 1px solid ${theme.colors.accent['purple/light']};
                position: relative;
              `}
            >
              <div
                className={css`
                  height: 1px;
                  width: 11px;
                  background-color: ${theme.colors.accent['purple/light']};
                  position: absolute;
                  top: 8.5px;
                  left: -1px;
                `}
              />
              <ChevronRightIcon fill={theme.colors.accent['purple/main']} />
            </div>
            <Text.BodyXS color="text/link" marginBottom="4xs" marginTop="4xs">
              View {subComponents.length} more updates for {doc?.title}
            </Text.BodyXS>
          </Box>
        )}
      </ActivityHolder.Item>

      <Drawer
        isOpen={isDrawerOpen}
        toggleDrawer={() => setIsDrawerOpen(!isDrawerOpen)}
      >
        <Box noPadding width="100%">
          <ActivityHolder.Root>{subComponents}</ActivityHolder.Root>
        </Box>
      </Drawer>
    </>
  );
};

// type DocCollapsed = {
//   subComponents: JSX.Element[];
//   // onExpand: (components: JSX.Element[]) => void;
// };
// export const NotionUpdateCollapsed: React.FC<Props & DocCollapsed> = ({
//   doc,
//   topicId,
//   id,
//   createdBy,
//   customRef,
//   focus,
//   stared,
//   subComponents,
//   last,
//   creatorId,
//   // isDrawerOpen = false,
//   // toggleDrawer,
//   // onExpand,
// }) => {
//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);

//   return (
//     <>
//       <TopicItem.Item
//         topicId={topicId}
//         type={EActivityType.GOOGLE}
//         id={id}
//         customRef={customRef}
//         link={`${process.env.REACT_APP_WEB_URL}/topic/${topicId}#${id}`}
//         Icon={NotionLogo}
//         title={`*<a href="${
//           creatorId ? '/profile/' + creatorId : '#'
//         }">${createdBy}</a>** added modification to *<a href="${
//           doc?.url
//         }" target="_blank" rel="noreferrer">${doc?.title}</a>**`}
//         subTitle={`${format(+doc?.modifiedDate, 'MMMM d, yyyy • h:mm aa')}`}
//         color={theme.colors.primary['primary/03']}
//         stared={stared}
//         last={last}
//       >
//         {subComponents.length > 0 && (
//           <Box
//             noPadding
//             display="flex"
//             flexDirection="row"
//             justifyContent="center"
//             alignItems="center"
//             marginTop="s"
//             position="relative"
//             style={{
//               cursor: 'pointer',
//             }}
//             onClick={() => setIsDrawerOpen(!isDrawerOpen)}
//           >
//             <div
//               className={css`
//                 position: absolute;
//                 bottom: 1.5px;
//                 left: -44px;
//               `}
//             >
//               <CurveIcon />
//             </div>
//             <div
//               className={css`
//                 width: 20px;
//                 height: 2px;
//                 margin-left: -20px;
//                 background-color: ${theme.colors.primary['primary/04']};
//               `}
//             />
//             <div
//               className={css`
//                 width: 16px;
//                 height: 16px;
//                 display: flex;
//                 justify-content: center;
//                 align-items: center;
//                 background-color: ${theme.colors.primary['primary/04']};
//                 margin-right: 7px;
//               `}
//             >
//               <CornerLeftUpIcon fill={theme.colors.text['text/link']} />
//             </div>
//             <Text.BodyXS color="text/link" marginBottom="4xs" marginTop="4xs">
//               View {subComponents.length} more updates for {doc?.title}
//             </Text.BodyXS>
//           </Box>
//         )}
//       </TopicItem.Item>

//       <Drawer
//         isOpen={isDrawerOpen}
//         toggleDrawer={() => setIsDrawerOpen(!isDrawerOpen)}
//       >
//         <Box noPadding width="100%">
//           <TopicItem.Root>{subComponents}</TopicItem.Root>
//         </Box>
//       </Drawer>
//     </>
//   );
// };
