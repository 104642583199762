import styled from '@emotion/styled';
import { theme } from 'theme';

export const HEIGHT = 64;
export const ProfileImageContainer = styled.div`
  height: ${HEIGHT}px;
  width: ${HEIGHT}px;
  border-radius: ${HEIGHT}px;
  border: 2px solid ${theme.colors.accent['purple/light']};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  margin-right: 8px;
`;

export const ProfileImage = styled.img`
  height: 100%;
  width: 100%;
  background-color: ${theme.colors.primary['primary/03']};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InputContainer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease-out;
  &:hover {
    opacity: 1;
  }
`;

export const ImageInput = styled.input`
  width: 100%;
  height: 100%;
  &::-webkit-file-upload-button {
    visibility: hidden;
  }
  &::before {
    content: 'Upload';
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* border-radius: 50%; */
    outline: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 12px;
    /* color: rgba(255, 255, 255, 0); */
    color: #fff;
    //   border: 1px solid black;
    background-color: #1c294896;
  }
`;
