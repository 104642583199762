import React from 'react';
import { Box, Page, Text } from 'components';
import { css } from '@emotion/css';
import { theme } from 'theme';
import { ERouteName } from 'pages';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { GhostIcon } from 'components/Icons';
import pubsub from 'sweet-pubsub';
import { ENotificationKey } from 'shared';

const menu = [
  {
    label: 'My profile',
    id: '1',
    link: ERouteName.MyDetailsSetting,
  },
  {
    label: 'Workspace',
    id: '6',
    link: ERouteName.WorkspaceSetting,
  },
  {
    label: 'Notifications',
    id: '2',
    link: ERouteName.NotificationsSetting,
  },
  {
    label: 'Integrations',
    id: '3',
    link: ERouteName.IntegrationSetting,
  },
  {
    label: 'Tutorial',
    id: '4',
    link: ERouteName.TutorialSetting,
  },
  {
    label: 'Privacy',
    id: '5',
    link: ERouteName.PrivacySetting,
  },
];

export const Setting = () => {
  // const [logoutOpen, setLogoutOpen] = useState(false);

  const currentPage = useLocation();

  const handleOnLogout = () => {
    pubsub.emit(ENotificationKey.SHOW_LOGOUT, { value: true });
  };

  return (
    <Page title="Settings | Multiplayer">
      <div
        className={css`
          width: 100%;
          padding: 20px 0px;
        `}
      >
        <Text.H1>Settings</Text.H1>
        <Text.Body color="text/75%">
          Manage your account settings and preferences.
        </Text.Body>
        <div
          className={css`
            width: 100%;
            display: grid;
            grid-template-columns: 200px auto;
            margin-top: 50px;
          `}
        >
          <div
            className={css`
              width: 100%;
            `}
          >
            {menu.map((item) => (
              <Link
                key={item.id}
                to={item.link}
                style={{ textDecoration: 'none' }}
              >
                <div
                  className={css`
                    border-left: 1px solid
                      ${currentPage.pathname === item.link
                        ? theme.colors.accent['purple/main']
                        : 'transparent'};
                    padding-inline: 8px;
                    height: 32px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    cursor: pointer;
                    margin-bottom: 16px;
                  `}
                >
                  <Text.Body
                    color={
                      currentPage.pathname === item.link
                        ? 'text/100%'
                        : 'text/50%'
                    }
                  >
                    {item.label}
                  </Text.Body>
                </div>
              </Link>
            ))}
            <Box
              noPadding
              display="flex"
              flexDirection="row"
              alignItems="center"
              width="fit-content"
              marginTop="2xl"
              onClick={handleOnLogout}
              style={{ cursor: 'pointer' }}
            >
              <GhostIcon
                fill={theme.colors.text['text/50%']}
                style={{
                  width: '16px',
                  height: '16px',
                  marginRight: '8px',
                }}
              />
              <Text.Body color="text/50%">Log Out</Text.Body>
            </Box>
          </div>
          <div
            className={css`
              width: 100%;
            `}
          >
            <Outlet />
            {/* <LogoutDialog open={logoutOpen} onOpenChange={setLogoutOpen} /> */}
          </div>
        </div>
      </div>
    </Page>
  );
};
