import React, { useEffect } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import './styles.css';
import { ENotificationKey } from 'shared';
import pubsub from 'sweet-pubsub';

type ItemProps = {
  children: React.ReactNode;
};
const Item: React.FC<ItemProps> = ({ children }) => {
  return (
    <DropdownMenu.Item className="DropdownMenuItem" asChild>
      {children}
    </DropdownMenu.Item>
  );
};

type SubProps = {
  triggerComponent: JSX.Element;
  children: React.ReactNode;
};
const Sub: React.FC<SubProps> = ({ triggerComponent, children }) => {
  return (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger className="DropdownMenuSubTrigger">
        <span style={{ display: 'none' }}>ğ</span>
        {triggerComponent}
      </DropdownMenu.SubTrigger>
      <DropdownMenu.Portal>
        <DropdownMenu.SubContent
          className="DropdownMenuSubContent"
          sideOffset={2}
          alignOffset={-5}
        >
          {children}
        </DropdownMenu.SubContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Sub>
  );
};

type Props = {
  triggerComponent: JSX.Element;
  children: React.ReactNode;
  open?: boolean;
  width?: string;
  noShadow?: boolean;
  align?: 'menu' | 'people' | 'default';
  onOpenChange?: (open: boolean) => void;
};
const Root: React.FC<Props> = ({
  triggerComponent,
  children,
  open,
  width,
  align = 'default',
  noShadow,
  onOpenChange,
}) => {
  const onOpenChangeHandler = (value: boolean) => {
    if (onOpenChange) {
      onOpenChange(value);
    }
    if (!noShadow) {
      pubsub.emit(ENotificationKey.SHOW_DROPDOWN, { value });
    }
  };

  useEffect(() => {
    return () => {
      if (onOpenChange) {
        onOpenChange(false);
      }
      pubsub.emit(ENotificationKey.SHOW_DROPDOWN, { value: false });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DropdownMenu.Root open={open} onOpenChange={onOpenChangeHandler} modal>
      <DropdownMenu.Trigger asChild>
        <div style={{ width: width || '' }} aria-label="Customize options">
          {triggerComponent}
        </div>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={`DropdownMenuContent DropdownMenuContent-${align}`}
          sideOffset={5}
        >
          {children}
          {/* <DropdownMenu.Arrow className="DropdownMenuArrow" /> */}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export const DropdownI = {
  Root,
  Item,
  Sub,
};
