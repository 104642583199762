import { gql, useQuery } from '@apollo/client';
import { css } from '@emotion/css';
import { Box, Text } from 'components';
import { EnvelopeIcon } from 'components/Icons';
import React from 'react';
import { CardHeader } from './CardHeader';
import { theme } from 'theme';
import { ItemSeparator } from './Separator';
import { displayNumber } from 'utils';

const QUERY = gql`
  query GmailStats($userId: ID!) {
    gmailStats(userId: $userId) {
      numberOfInbox
      numberOfSent
      internalInboxEmails
      internalSentEmails
      externalInboxEmails
      externalSentEmails
      averageInboxEmailsPerDay
      averageSentEmailsPerDay
      unreadEmails
    }
  }
`;

type GmailStats = {
  gmailStats: {
    numberOfInbox: number;
    numberOfSent: number;
    internalInboxEmails: number;
    internalSentEmails: number;
    externalInboxEmails: number;
    externalSentEmails: number;
    averageInboxEmailsPerDay: number;
    averageSentEmailsPerDay: number;
    unreadEmails: number;
  };
};

type Props = {
  userId: string;
  placeholder?: string;
};
export const EmailStat: React.FC<Props> = ({ userId, placeholder }) => {
  const { data, loading } = useQuery<GmailStats>(QUERY, {
    variables: { userId },
  });
  const stats =
    loading || !data?.gmailStats
      ? placeholder
        ? JSON.parse(placeholder)
        : {}
      : data?.gmailStats;
  return (
    <Box
      backgroundColor="primary/03"
      position="relative"
      width="386px"
      borderRadius="l"
      noPadding
    >
      <div
        className={css`
          width: 100%;
          position: relative;
          padding: 24px;
        `}
      >
        <CardHeader
          text="Emails"
          color={theme.colors.primary['primary/05']}
          Icon={EnvelopeIcon}
        />
        <Box noPadding width="80%" marginTop="2xl">
          <Item value={stats?.numberOfInbox || 0} label="received" />
          <ItemSeparator color={theme.colors.primary['primary/05']} />
          <Item value={stats?.numberOfSent || 0} label="sent" />

          <ItemSeparator color={theme.colors.primary['primary/05']} />
          <Item value={stats?.internalInboxEmails || 0} label="Int received" />

          <ItemSeparator color={theme.colors.primary['primary/05']} />
          <Item value={stats?.internalSentEmails || 0} label="Int sent" />

          <ItemSeparator color={theme.colors.primary['primary/05']} />
          <Item value={stats?.externalInboxEmails || 0} label="Ext received" />

          <ItemSeparator color={theme.colors.primary['primary/05']} />
          <Item value={stats?.externalSentEmails || 0} label="Ext sent" />

          <ItemSeparator color={theme.colors.primary['primary/05']} />
          <Item
            value={stats?.averageInboxEmailsPerDay || 0}
            label="AVG received"
          />

          <ItemSeparator color={theme.colors.primary['primary/05']} />
          <Item value={stats?.averageSentEmailsPerDay || 0} label="AVG sent" />

          <ItemSeparator color={theme.colors.primary['primary/05']} />
          <Item value={stats?.unreadEmails || 0} label="Unread" />
        </Box>
      </div>
    </Box>
  );
};

type ItemProps = {
  label: string;
  value: number;
};
const Item: React.FC<ItemProps> = ({ label, value }) => {
  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      `}
    >
      <Text.H1 color="text/blue/soft" marginBottom="4xs" marginTop="4xs">
        {displayNumber(value)}
      </Text.H1>

      <Text.H4 color="text/blue/soft" marginBottom="4xs" marginTop="4xs">
        {label}
      </Text.H4>
    </div>
  );
};
