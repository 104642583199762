import { css } from '@emotion/css';
import {
  ActivitySeparator,
  Box,
  Drawer,
  PageLoading,
  Text,
  TextEditor,
} from 'components';
import { ArrowRight } from 'components/Icons';
import { format } from 'date-fns';
import { Activity } from 'pages/Topic/types';
import React from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'theme';
import { displayUser } from 'utils';

type ReplyItemProps = {
  isFake: boolean;
  id: string;
  comment: string;
  user: string;
  dateComment: string;
};
const ReplyItem: React.FC<ReplyItemProps> = ({
  isFake,
  id,
  comment,
  user,
  dateComment,
}) => {
  return (
    <Box noPadding width="100%" marginBottom="m">
      <div>
        <Text.Body marginBottom="4xs" marginTop="4xs">
          {user}
        </Text.Body>
        <Text.BodyXS marginBottom="4xs" marginTop="4xs" color="text/light">
          {dateComment}
        </Text.BodyXS>
      </div>
      <Box
        noPadding
        width="100%"
        backgroundColor="primary/03"
        marginTop="m"
        className={css`
          position: relative;
          opacity: ${isFake ? 0.5 : 1};
        `}
      >
        <TextEditor
          toolbarId={'a' + id} // id property can't start with number
          placeholder="Enter some text..."
          value={comment}
          readonly={true}
          noBackground
        />
      </Box>
    </Box>
  );
};

type Props = {
  isDrawerOpen: boolean;
  toggleDrawer: VoidFunction;
  replies: Activity[];
  redirectTo?: string;
  topComment: {
    id: string;
    comment: string;
    createAt: number;
    createdBy: string;
    render: boolean;
  };
  topicBasics: {
    closed: boolean;
    isAdmin: boolean;
    isWrite: boolean;
  };
  reply: string;
  handleReply: () => void;
  setReply: React.Dispatch<React.SetStateAction<string>>;
  setReplyText: React.Dispatch<React.SetStateAction<string>>;
  readOnly?: boolean;
  repliesLoading: boolean;
  getRepliesLoading: boolean;
};
export const ReplyDrawer: React.FC<Props> = ({
  isDrawerOpen,
  getRepliesLoading,
  redirectTo,
  handleReply,
  replies,
  repliesLoading,
  reply,
  setReply,
  setReplyText,
  topComment,
  topicBasics,
  readOnly,
  toggleDrawer,
}) => {
  return (
    <Drawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} noOverflow>
      {redirectTo && (
        <Link to={redirectTo} style={{ textDecoration: 'none' }}>
          <div
            className={css`
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-end;
              position: absolute;
              top: -28px;
              right: 0px;
              text-decoration: none;
            `}
          >
            <Text.TextSmall2
              marginBottom="4xs"
              marginTop="4xs"
              color="text/50%"
            >
              Go to topic
            </Text.TextSmall2>
            <ArrowRight
              fill={theme.colors.text['text/50%']}
              style={{ marginLeft: '7px' }}
            />
          </div>
        </Link>
      )}
      <Box noPadding width="100%" height="100%" position="relative">
        {getRepliesLoading ? (
          <PageLoading />
        ) : (
          <div
            className={css`
              height: 100%;
              overflow-y: hidden;
              width: 100%;
              height: 100%;
            `}
          >
            <div
              className={css`
                width: 100%;
                height: calc(100% - 16px);
                overflow-y: auto;
                &::-webkit-scrollbar {
                  display: block;
                  width: 8px;
                  background-color: #2d2a41;
                }

                &::-webkit-scrollbar-thumb {
                  background: #8c83c3;
                  border-radius: 8px;
                }
              `}
            >
              {topComment.render && (
                <ReplyItem
                  key={topComment.id}
                  id={topComment.id}
                  comment={topComment.comment}
                  isFake={false}
                  dateComment={format(
                    +topComment.createAt,
                    'MMMM d, yyyy • h:mm aa'
                  )}
                  user={topComment.createdBy}
                />
              )}

              {replies.length > 0 && (
                <ActivitySeparator
                  text={`${replies.length} repl${
                    replies.length > 1 ? 'ies' : 'y'
                  }`}
                />
              )}
              {replies?.map((reply) => (
                <ReplyItem
                  id={reply._id}
                  comment={JSON.parse(reply.data).payload.comment}
                  isFake={reply.comment === 'fake'}
                  dateComment={format(+reply.created, 'MMMM d, yyyy • h:mm aa')}
                  user={displayUser(reply!.userId!) || ''}
                />
              ))}
            </div>
          </div>
        )}

        {!topicBasics?.closed &&
          (topicBasics?.isAdmin || topicBasics?.isWrite) && (
            <div
              className={css`
                width: 100%;
              `}
            >
              {isDrawerOpen && (
                <TextEditor
                  toolbarId={'reply' + topComment.id}
                  placeholder="Enter some text..."
                  submit="Reply"
                  value={reply}
                  handleSubmit={handleReply}
                  handleChange={setReply}
                  handleChangeText={setReplyText}
                  readonly={readOnly}
                  loading={repliesLoading}
                  height={100}
                  dark
                />
              )}
            </div>
          )}
      </Box>
    </Drawer>
  );
};
