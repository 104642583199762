import { gql } from '@apollo/client';

export const GET_TOPICS = gql`
  query Topics(
    $order: String
    $sortField: String
    $momentumOrder: String
    $filter: String
    $limit: Int
    $page: Int
    $startDate: DateTime
    $endDate: DateTime
    $participants: [ID!]
    $momentum: String
    $type: String
    $searchQuery: String
  ) {
    topics(
      order: $order
      sortField: $sortField
      momentumOrder: $momentumOrder
      filter: $filter
      startDate: $startDate
      endDate: $endDate
      participants: $participants
      momentum: $momentum
      limit: $limit
      type: $type
      page: $page
      searchQuery: $searchQuery
    ) {
      _id
      name
      public
      closed
      created
      updated
      isAutoCreated
      creatorId {
        _id
        businessEmail
        firstName
        lastName
        profileUrl
        role
      }
      collaborators {
        _id
        businessEmail
        firstName
        lastName
        profileUrl
        role
      }
      momentum {
        count
        label
        lastActivity
      }
    }
  }
`;

export const SAVE_FILTER = gql`
  mutation SaveFilter(
    $name: String!
    $user: ID!
    $endDate: DateTime
    $momentum: String
    $participants: [ID!]
    $startDate: DateTime
    $status: String
  ) {
    saveFilter(
      name: $name
      user: $user
      endDate: $endDate
      momentum: $momentum
      participants: $participants
      startDate: $startDate
      status: $status
    ) {
      _id
      endDate
      momentum
      name
      participants
      startDate
      status
    }
  }
`;

export const CHANGE_SCOPE = gql`
  mutation changeTopicScope($topicId: ID!, $scope: Boolean!) {
    changeTopicScope(topicId: $topicId, scope: $scope) {
      description
      _id
      name
      public
    }
  }
`;

export const UPDATE_SAVED_FILTER = gql`
  mutation UpdateFilterName($id: ID!, $name: String!) {
    updateFilterName(id: $id, name: $name)
  }
`;

export const DELETE_SAVED_FILTER = gql`
  mutation DeleteFilter($id: ID!) {
    deleteFilter(id: $id)
  }
`;

export const GET_SAVED_FILTER = gql`
  query TopicFilters {
    topicFilters {
      _id
      endDate
      momentum
      name
      participants
      startDate
      status
    }
  }
`;

// export const TOPIC_SEARCH = gql`
//   query TopicFilters($query: String!) {
//     topicSearch(query: $query) {
//       _id
//       name
//       public
//       closed
//       created
//       updated
//       isAutoCreated
//       creatorId {
//         _id
//         businessEmail
//         firstName
//         lastName
//         profileUrl
//         role
//       }
//       collaborators {
//         _id
//         businessEmail
//         firstName
//         lastName
//         profileUrl
//         role
//       }
//       momentum {
//         count
//         label
//         lastActivity
//       }
//     }
//   }
// `;

export const INVITE_USER = gql`
  mutation InviteToMultiplayer($emails: String!, $workspaceId: String!) {
    inviteToMultiplayer(emails: $emails, workspaceId: $workspaceId)
  }
`;

export const CHECK_TOPIC_CREATION_STATUS = gql`
  query TopicCreationStatus {
    topicCreationStatus
  }
`;
