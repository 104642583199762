import React from 'react';
import styled from '@emotion/styled';

import { theme } from 'theme';

type CustomInputProps = {};
type InputProps = React.InputHTMLAttributes<HTMLTextAreaElement> &
  CustomInputProps;

const InputContainer = styled.div<CustomInputProps>`
  position: relative;
`;

const TextAreaComponent = styled.textarea`
  border-radius: 8px;
  border: 1px solid transparent;
  outline: none;
  box-sizing: border-box;
  transition: border-color 0.15s;
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.primary['primary/03']};
  color: ${theme.colors.text['text/main']};
  font-size: ${theme.textVariants['text-small-1'].fontSize};
  font-weight: ${theme.textVariants['text-small-1'].fontWeight};
  font-family: ${theme.textVariants['text-small-1'].fontFamily};
  padding: ${theme.spacing.m}px;
  &::placeholder {
    color: ${theme.colors.text['text/light']};
  }
  &:hover,
  &:focus {
    border-color: ${theme.colors.accent['blue/main']};
  }
`;

export const TextArea: React.FC<InputProps> = ({ ...props }) => {
  return (
    <InputContainer>
      <TextAreaComponent {...props} />
    </InputContainer>
  );
};
