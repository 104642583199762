import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { initializeTopic } from 'features/topic';
import { initializeTopicLink } from 'features/topicLink';
import { TopicList } from 'features/topics';
import { useAppDispatch } from 'hooks';
import { EGraphQlErrorCode } from 'model';
import { ERouteName } from 'pages';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GET_ATTACHMENT, GET_TOPICS, GET_TOPIC_BASICS, MOMENTUM } from '../gql';
import { TTopicBasics } from '../types';

type Props = {
  topicId: string;
};
export function useTopicInfo(props: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const { data: permissionData } = useQuery<{
  //   linkAccessList: ActivityPermission[];
  // }>(PERMISSIONS, {
  //   variables: {
  //     topicId: props.topicId,
  //   },
  // });

  /**
   * Fetch topic detail infos
   */
  const [
    fetchTopicBasics,
    { loading: loadingTopicBasics, data: topicInfoData },
  ] = useLazyQuery<{
    topic: TTopicBasics;
  }>(GET_TOPIC_BASICS, {
    fetchPolicy: 'cache-first',
  });

  const { data: attachmentData, refetch } = useQuery<{
    topicLinks: TTopicBasics['links'];
  }>(GET_ATTACHMENT, {
    variables: { topicId: props.topicId },
    fetchPolicy: 'cache-and-network',
  });

  if (attachmentData?.topicLinks?.length) {
    dispatch(initializeTopicLink(attachmentData?.topicLinks));
  }

  const [refetchTopicInfo] = useLazyQuery<{ topic: TTopicBasics }>(
    GET_TOPIC_BASICS,
    {
      fetchPolicy: 'network-only',
    }
  );

  const [recalculateMomentum] = useMutation<{
    calculateMomentum: TopicList[0]['momentum'];
  }>(MOMENTUM);

  /**
   * Fetch list of topic in the workspace
   */
  const { data: topicListData } = useQuery<{
    topics: { _id: string; name: string }[];
  }>(GET_TOPICS, {
    variables: {
      filter: 'opened',
    },
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    fetchTopicBasics({
      variables: {
        id: props.topicId,
        first: true,
      },
    })
      .then((result) => {
        if (
          result.error?.graphQLErrors?.[0].extensions?.code ===
          EGraphQlErrorCode.PERSISTED_QUERY_NOT_FOUND
        ) {
          navigate(
            ERouteName.Error + EGraphQlErrorCode.PERSISTED_QUERY_NOT_FOUND
          );
        }
        if (result.data?.topic) {
          let description = result.data?.topic.description as string;

          if (description) {
            // Check if the comment has a link to a private file
            result.data?.topic?.fileAccess?.forEach((p) => {
              if (!p.access && description.includes(p.id)) {
                description = description.replace(
                  /<a href="([^"]+)" rel="noopener noreferrer" target="_blank">([^<]+)<\/a>/g,
                  (_, url, text) => {
                    return `<a href="${url}" rel="noopener noreferrer" target="_blank">Private file</a>`;
                  }
                );
              }
            });
          }
          dispatch(
            initializeTopic({
              ...result.data?.topic,
              description,
            })
          );

          if (result.data?.topic?.links) {
            // dispatch(
            //   initializeTopicLink(
            //     result.data?.topic?.links
            //     // unique(
            //     //   result.data?.topic?.links,
            //     //   (a: ITopicLink, b: ITopicLink) => a.url === b.url
            //     // )
            //   )
            // );
          }
        }
      })
      .catch((err) => {});
  }, [dispatch, fetchTopicBasics, navigate, props.topicId, refetch]);

  const syncTopicInfo = useCallback(() => {
    refetchTopicInfo({
      variables: {
        id: props.topicId,
      },
      fetchPolicy: 'network-only',
    })
      .then((result) => {
        refetch();
      })
      .catch((err) => {});
  }, [refetchTopicInfo, props.topicId, refetch]);

  return {
    syncTopicInfo,
    recalculateMomentum,
    loadingTopicBasics,
    topicListData,
    permissions: topicInfoData?.topic?.fileAccess || [],
  };
}
