import styled from '@emotion/styled';
import React from 'react';
import { theme } from 'theme';

const Input = styled.input`
  border-radius: 8px;
  border: 1px solid transparent;
  outline: none;
  box-sizing: border-box;
  transition: border-color 0.15s;
  width: 100%;
  height: fit-content;
  background-color: red;
  color: white;
  font-size: 14px;
  font-weight: ${theme.textVariants.body.fontWeight};
  font-family: ${theme.textVariants.body.fontFamily};
  padding-right: ${theme.spacing.m}px;

  &::placeholder {
    color: ${theme.colors.text['text/menu']};
  }
  &:hover,
  &:focus {
    /* border-color: ${theme.colors.accent['blue/main']}; */
  }
`;

type Props = {
  value: string;
};

export const EditableView: React.FC<Props> = () => {
  return <Input />;
};
