import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { Global } from '@emotion/react';
import { store } from './store';

const render = () => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(
    <React.StrictMode>
      {/* <Global
        styles={{
          '@font-face': {
            fontFamily: 'NunitoSans-SemiBold',
            src: 'url("./assets/fonts/NunitoSans-SemiBold.ttf") format("truetype")',
          },
        }}
      />
      <Global
        styles={{
          '@font-face': {
            fontFamily: 'Nunito-SemiBold',
            src: 'url("./assets/fonts/Nunito-SemiBold.ttf") format("truetype")',
          },
        }}
      />
      <Global
        styles={{
          '@font-face': {
            fontFamily: 'NunitoSans-Regular',
            src: 'url("./assets/fonts/NunitoSans-Regular.ttf") format("truetype")',
          },
        }}
      /> */}
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  );
};

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./App', render);
}

render();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
