/* eslint-disable no-console */
import { ActivityHolder, ActivitySeparator, ErrorBoundary } from 'components';
import { LoadingIcon } from 'components/Icons';
import { format } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import { useRef, useCallback } from 'react';
import { theme } from 'theme';
import { displayUser, getDocIdFromUrl, unique } from 'utils';
import { CommentItem, DocUpdate, NotionUpdate } from '.';
import {
  Activity,
  ActivityData,
  ActivityPermission,
  LinkedResourceType,
} from '../types';
import { DeletedItem } from './DeletedItem';
import { DocCommentUpdate } from './DocCommentUpdate';
import { SlackItem } from './SlackItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import { css } from '@emotion/css';
import { EActivityType } from 'model';
import { LinearIssueUpdate } from './LinearIssueUpdate';
import { LinearIssueComment } from './LinearIssueComment';
import { MicrosoftUpdate } from './MicrosoftUpdate';
import { GithubUpdate } from './GithubUpdate';
// import Post from './Post';

type Props = {
  topicId: string;
  stared: boolean;
  type: EActivityType[];
  groups: string[];
  topic: {
    created: number;
    creator: string;
  };
  topicList: {
    label: string;
    value: string;
  }[];
  topicActivity: Activity[];
  loading?: boolean;
  hasMore: boolean;
  permissions?: ActivityPermission[];
  onReplyComment: (commentId: string) => (reply: string) => void;
  onEditComment: (
    commentId: string
  ) => (reply: string, commentText: string) => void;
  loadMore: VoidFunction;
};
export const ActivityListComp: React.FC<Props> = React.memo(
  ({
    groups,
    stared,
    topicId,
    type,
    topic,
    topicList,
    loading,
    topicActivity,
    permissions,
    onReplyComment,
    onEditComment,
    loadMore,
    hasMore,
  }) => {
    const myRef = useRef<HTMLDivElement>(null);
    const activityRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      setTimeout(() => {
        if (myRef.current) {
          myRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
          // setTimeout(() => {
          //   window.scrollBy(0, -50);
          // }, 500);
        }
      }, 1000);
    }, []);

    const getLabelForActivity = (activityData: any) => {
      if (activityData.payload.extra) {
        const url = activityData.payload.extra.url;
        const title = activityData.payload.extra.title;
        switch (activityData.payload.extra.type) {
          case LinkedResourceType.File:
            return `added *<a href="${url}" target="_blank"> a Attachment</a>** to`;

          case LinkedResourceType.GoogleDoc:
            return `added a Google doc *<a href="${url}" target="_blank"> ${title}</a>** to`;

          case LinkedResourceType.Notion:
            return `added a Notion page *<a href="${url}" target="_blank"> ${title}</a>** to`;

          case LinkedResourceType.Multiplayer:
            return `referenced a topic *<a href="${url}" target="_blank"> ${title}</a>** in`;

          default:
            return `added *<a href="${url}" target="_blank"> a link</a>** to`;
        }
      } else {
        return activityData.payload.type;
      }
    };

    const getActivityComponent = useCallback(
      (
        item: Activity,
        idx: number,
        firstIndex: number[],
        isLast = false,
        inCollapse = false
      ) => {
        const activityData = JSON.parse(item.data) as ActivityData;
        if (item.deleted) {
          return (
            <DeletedItem
              key={item._id}
              type={activityData.type}
              createAt={item.created}
              last={isLast}
              deletedBy={item!.deletedBy?._id}
              createdBy={item.userId ? displayUser(item!.userId!) || '-' : '-'}
              userName={displayUser(item!.deletedBy!) || 'A user'}
            />
          );
        }

        switch (activityData.type) {
          case EActivityType.GOOGLE: {
            return (
              <DocUpdate
                last={isLast}
                customRef={
                  window.location.hash.split('#')[1] === item._id ? myRef : null
                }
                focus={window.location.hash.split('#')[1] === item._id}
                id={item._id}
                key={item._id}
                topicId={topicId!}
                creatorId={item?.userId?._id}
                inCollapse={inCollapse}
                createdBy={
                  item!.userId
                    ? displayUser(item!.userId!) ||
                      activityData.payload.lastModifyingUser.displayName ||
                      activityData.payload.lastModifyingUser.emailAddress
                    : activityData.payload.lastModifyingUser.displayName ||
                      activityData.payload.lastModifyingUser.emailAddress
                }
                isFirst={firstIndex.includes(idx)}
                doc={activityData.payload}
                stared={item.stared}
                hasAccess={
                  permissions
                    ? permissions?.find(
                        (p) =>
                          p.id ===
                          getDocIdFromUrl(activityData.payload.lastVersionUrl)
                      )?.access || false
                    : undefined
                }
              />
            );
          }

          case EActivityType.GOOGLE_COMMENT: {
            return (
              <DocCommentUpdate
                last={isLast}
                customRef={
                  window.location.hash.split('#')[1] === item._id ? myRef : null
                }
                focus={window.location.hash.split('#')[1] === item._id}
                id={item._id}
                key={item._id}
                topicId={topicId!}
                creatorId={item!.userId?._id}
                // creatorId={item?.userId?._id || ''}
                createdBy={
                  displayUser(item!.userId!) ||
                  activityData.payload.lastModifyingUser
                }
                isFirst={firstIndex.includes(idx)}
                doc={activityData.payload}
                stared={item.stared}
                hasAccess={
                  permissions
                    ? permissions?.find(
                        (p) =>
                          p.id ===
                          getDocIdFromUrl(activityData.payload.lastVersionUrl)
                      )?.access || false
                    : undefined
                }
              />
            );
          }

          case EActivityType.COMMENT: {
            let comment = activityData.payload.comment;

            // Check if the comment has a link to a private file
            permissions?.forEach((p) => {
              if (!p.access && comment.includes(p.id)) {
                comment = comment.replace(
                  /<a href="([^"]+)" rel="noopener noreferrer" target="_blank">([^<]+)<\/a>/g,
                  (_, url, text) => {
                    return `<a href="${url}" rel="noopener noreferrer" target="_blank">Private file</a>`;
                  }
                );
              }
            });

            return (
              <CommentItem
                last={isLast}
                customRef={
                  window.location.hash.split('#')[1] === item._id ? myRef : null
                }
                focus={window.location.hash.split('#')[1] === item._id}
                id={item._id}
                topicClosed={false}
                key={item._id}
                topicId={topicId!}
                creatorId={item!.userId?._id}
                comment={comment}
                responses={[]}
                updated={item.updated}
                createAt={item.created}
                topicList={topicList}
                createdBy={displayUser(item!.userId!) || ''}
                onReply={onReplyComment(item._id)}
                onEdit={onEditComment(item._id)}
                numberOfReply={item.replies || 0}
                isFake={item.comment === 'fake'}
                stared={item.stared}
              />
            );
          }

          case EActivityType.SLACK:
            return (
              <SlackItem
                last={isLast}
                customRef={
                  window.location.hash.split('#')[1] === item._id ? myRef : null
                }
                focus={window.location.hash.split('#')[1] === item._id}
                id={item._id}
                key={item._id}
                topicId={topicId!}
                permaLink={activityData.payload.permalink || '#'}
                comment={activityData.payload.comment}
                message={activityData.payload.message}
                channel={activityData.payload.channel}
                files={activityData.payload.files}
                createAt={item.created}
                reporterId={item!.userId?._id}
                reportedBy={displayUser(item!.userId!) || 'Unknown user'}
                createdBy={item.slackMessageAuthor?.displayText!}
                stared={item.stared}
              />
            );

          case EActivityType.TOPIC:
            return (
              <ActivityHolder.Item
                key={item._id}
                topicId=""
                type={EActivityType.UNKNOWN}
                last={isLast}
                stared={false}
                Icon={
                  <div
                    style={{
                      width: '6px',
                      height: '6px',
                      borderRadius: '3px',
                      backgroundColor: theme.colors.text['text/100%'],
                    }}
                  />
                }
                children=""
                // color={theme.colors.primary['primary/03']}
                subTitle={
                  item.created
                    ? format(+item.created, 'MMMM d, yyyy • h:mm aa')
                    : ''
                }
                title={`*<a href="/profile/${item!.userId!._id}">${
                  displayUser(item!.userId!) || ''
                }</a>** ${getLabelForActivity(activityData)} this topic`}
              />
            );

          case EActivityType.NOTION: {
            return (
              <NotionUpdate
                last={isLast}
                customRef={
                  window.location.hash.split('#')[1] === item._id ? myRef : null
                }
                focus={window.location.hash.split('#')[1] === item._id}
                id={item._id}
                key={item._id}
                topicId={topicId!}
                creatorId={item?.userId?._id}
                createdBy={
                  item!.userId
                    ? displayUser(item!.userId!) ||
                      activityData.payload.lastModifyingUser.displayName ||
                      activityData.payload.lastModifyingUser.emailAddress
                    : activityData.payload.lastModifyingUser.displayName ||
                      activityData.payload.lastModifyingUser.emailAddress
                }
                doc={activityData.payload}
                stared={item.stared}
              />
            );
          }

          case EActivityType.LINEAR_ISSUE: {
            return (
              <LinearIssueUpdate
                change={activityData.payload.change}
                modifiedDate={item.created}
                stared={item.stared}
                title={activityData.payload.title}
                topicId={topicId!}
                url={activityData.payload.url}
                customRef={
                  window.location.hash.split('#')[1] === item._id ? myRef : null
                }
                focus={window.location.hash.split('#')[1] === item._id}
                id={item._id}
                key={item._id}
                last={isLast}
              />
            );
          }
          case EActivityType.LINEAR_ISSUE_COMMENT: {
            return (
              <LinearIssueComment
                change={activityData.payload.change}
                modifiedDate={item.created}
                stared={item.stared}
                title={activityData.payload.title}
                comment={activityData.payload.comment}
                author={activityData.payload.commentedBy.name}
                topicId={topicId!}
                url={activityData.payload.url}
                customRef={
                  window.location.hash.split('#')[1] === item._id ? myRef : null
                }
                focus={window.location.hash.split('#')[1] === item._id}
                id={item._id}
                key={item._id}
                last={isLast}
              />
            );
          }

          case EActivityType.MICROSOFT: {
            return (
              <MicrosoftUpdate
                last={isLast}
                customRef={
                  window.location.hash.split('#')[1] === item._id ? myRef : null
                }
                focus={window.location.hash.split('#')[1] === item._id}
                id={item._id}
                key={item._id}
                topicId={topicId!}
                creatorId={item?.userId?._id}
                title={activityData.payload.title}
                delta={activityData.payload.delta}
                modifiedDate={item.created}
                url={activityData.payload.url}
                inCollapse={inCollapse}
                createdBy={
                  item!.userId
                    ? displayUser(item!.userId!) ||
                      activityData.payload.updatedBy.displayName
                    : activityData.payload.updatedBy.displayName
                }
                isFirst={firstIndex.includes(idx)}
                stared={item.stared}
              />
            );
          }

          case EActivityType.GITHUB: {
            return (
              <GithubUpdate
                last={isLast}
                customRef={
                  window.location.hash.split('#')[1] === item._id ? myRef : null
                }
                focus={window.location.hash.split('#')[1] === item._id}
                id={item._id}
                key={item._id}
                topicId={topicId!}
                stared={item.stared}
                creatorId={item?.userId?._id}
                createdBy={
                  displayUser(item!.userId!) ||
                  activityData.payload.creator.login
                }
                data={activityData.payload}
              />
            );
          }

          default:
            return <div key={item._id} />;
        }
      },
      [onEditComment, onReplyComment, permissions, topicId, topicList]
    );

    const list = useMemo(() => {
      const separators: any[] = [];

      /**
       * Somehow google comment activity are being
       * duplicated so we are removing the duplicate
       */
      let uniquesActivity = unique(
        [...topicActivity],
        (a: Activity, b: Activity) =>
          JSON.parse(a.data).type === EActivityType.GOOGLE_COMMENT &&
          JSON.parse(b.data).type === EActivityType.GOOGLE_COMMENT
            ? JSON.parse(a.data).payload.content ===
                JSON.parse(b.data).payload.content &&
              JSON.parse(a.data).payload.quotedFileContent ===
                JSON.parse(b.data).payload.quotedFileContent &&
              JSON.parse(a.data).payload.modifiedDate ===
                JSON.parse(b.data).payload.modifiedDate
            : false
      );

      uniquesActivity = unique(
        [...uniquesActivity],
        (a: Activity, b: Activity) => a._id === b._id
      );

      uniquesActivity = unique(
        [...uniquesActivity],
        (a: Activity, b: Activity) =>
          JSON.parse(a.data).type === EActivityType.GOOGLE &&
          JSON.parse(b.data).type === EActivityType.GOOGLE
            ? a.created === b.created &&
              JSON.parse(a.data).payload.docId ===
                JSON.parse(b.data).payload.docId &&
              JSON.parse(a.data).payload.modifiedDate ===
                JSON.parse(b.data).payload.modifiedDate &&
              JSON.parse(a.data).payload.delta?.value ===
                JSON.parse(b.data).payload.delta?.value
            : false
      );

      uniquesActivity = unique(
        [...uniquesActivity],
        (a: Activity, b: Activity) =>
          JSON.parse(a.data).type === EActivityType.LINEAR_ISSUE_COMMENT &&
          JSON.parse(b.data).type === EActivityType.LINEAR_ISSUE_COMMENT &&
          JSON.parse(b.data).payload.url === JSON.parse(a.data).payload.url
      );

      uniquesActivity = unique(
        [...uniquesActivity],
        (a: Activity, b: Activity) =>
          JSON.parse(a.data).type === EActivityType.LINEAR_ISSUE &&
          JSON.parse(b.data).type === EActivityType.LINEAR_ISSUE &&
          JSON.parse(b.data).payload.change?.to ===
            JSON.parse(a.data).payload.change?.to
      );

      const temp: string[] = [];
      const firstIndex: number[] = [];
      const notionFirstIndex: number[] = [];
      let newActivity = 0;
      const today = new Date();
      uniquesActivity.forEach((item, idx) => {
        try {
          const activityData = JSON.parse(item.data) as ActivityData;
          if (activityData.type === EActivityType.GOOGLE) {
            if (!temp.includes(activityData.payload.title!)) {
              firstIndex.push(idx);
              temp.push(activityData.payload.title!);
            }
          }
          if (activityData.type === EActivityType.NOTION) {
            if (!notionFirstIndex.includes(idx)) {
              notionFirstIndex.push(idx);
              // temp.push(activityData.payload.title!);
            }
          }

          const created = new Date(item.created);
          if (
            created.getFullYear() === today.getFullYear() &&
            created.getMonth() === today.getMonth() &&
            created.getDate() === today.getDate()
          ) {
            newActivity++;
          }
        } catch (error) {
          console.log('log1:', error);
        }
      });

      let components: JSX.Element[] = [];
      let First: Activity | null = null;

      let notionComponents: JSX.Element[] = [];
      let notionFirst: Activity | null = null;

      let result: any = uniquesActivity.map((item, idx) => {
        try {
          let isLast = false;
          const activityData = JSON.parse(item.data) as ActivityData;
          const currentDay = new Date(item.created).getDate();
          if (
            idx < uniquesActivity.length - 1 &&
            new Date(uniquesActivity[idx + 1].created).getDate() !== currentDay
          ) {
            separators.push({
              idx: idx + 1,
              date: format(
                Number(uniquesActivity[idx + 1].created),
                'MMMM d, yyyy'
              ),
            });
            isLast = true;
          }
          if (activityData.type === EActivityType.GOOGLE) {
            if (
              uniquesActivity[idx + 1]?.data &&
              (JSON.parse(uniquesActivity[idx + 1]?.data) as ActivityData)
                .type === EActivityType.GOOGLE &&
              (JSON.parse(uniquesActivity[idx + 1]?.data) as any).payload
                .title === activityData.payload.title &&
              new Date(uniquesActivity[idx + 1]?.created)?.getDate() ===
                currentDay &&
              item?.userId?._id === uniquesActivity[idx + 1]?.userId?._id
            ) {
              if (!First) {
                First = item;
              } else {
                components.push(
                  getActivityComponent(item, idx, firstIndex, false, true)
                );
              }
              return <div key={item._id + idx} />;
            } else if (components.length > 0 || First) {
              components.push(
                getActivityComponent(item, idx, firstIndex, true, true)
              );
              const _activityData = JSON.parse(First!.data) as any;
              const hasAccess = permissions
                ? permissions?.find(
                    (p) =>
                      p.id ===
                      getDocIdFromUrl(activityData.payload.lastVersionUrl)
                  )?.access || false
                : undefined;

              const out = (
                <DocUpdate
                  // key={item._id}
                  stared={First!.stared}
                  last={isLast || idx === uniquesActivity.length - 1}
                  customRef={
                    window.location.hash.split('#')[1] === First!._id
                      ? myRef
                      : null
                  }
                  focus={window.location.hash.split('#')[1] === First!._id}
                  id={First!._id}
                  key={First!._id}
                  topicId={topicId!}
                  subComponents={components}
                  creatorId={First?.userId?._id}
                  createdBy={
                    displayUser(First!.userId!) ||
                    _activityData.payload.lastModifyingUser.displayName ||
                    _activityData.payload.lastModifyingUser.emailAddress
                  }
                  isFirst={true}
                  doc={_activityData.payload}
                  hasAccess={hasAccess}
                />
              );
              // googleCollapsed = [];
              components = [];
              First = null;
              return out;
            }
          } else if (activityData.type === EActivityType.NOTION) {
            if (
              uniquesActivity[idx + 1]?.data &&
              (JSON.parse(uniquesActivity[idx + 1]?.data) as ActivityData)
                .type === EActivityType.NOTION &&
              (JSON.parse(uniquesActivity[idx + 1]?.data) as any).payload
                .title === activityData.payload.title &&
              new Date(uniquesActivity[idx + 1]?.created)?.getDate() ===
                currentDay &&
              item?.userId?._id === uniquesActivity[idx + 1]?.userId?._id
            ) {
              if (!notionFirst) {
                notionFirst = item;
              } else {
                notionComponents.push(
                  getActivityComponent(item, idx, notionFirstIndex, false)
                );
              }
              return <div />;
            } else if (notionComponents.length > 0 || notionFirst) {
              notionComponents.push(
                getActivityComponent(item, idx, notionFirstIndex, true)
              );
              const _activityData = JSON.parse(notionFirst!.data) as any;
              const out = (
                <NotionUpdate
                  // key={item._id}
                  stared={notionFirst!.stared}
                  last={isLast}
                  customRef={
                    window.location.hash.split('#')[1] === notionFirst!._id
                      ? myRef
                      : null
                  }
                  focus={
                    window.location.hash.split('#')[1] === notionFirst!._id
                  }
                  id={notionFirst!._id}
                  key={notionFirst!._id}
                  topicId={topicId!}
                  subComponents={notionComponents.slice(
                    0,
                    notionComponents.length
                  )}
                  creatorId={notionFirst?.userId?._id}
                  createdBy={
                    displayUser(notionFirst!.userId!) ||
                    _activityData.payload.lastModifyingUser.displayName ||
                    _activityData.payload.lastModifyingUser.emailAddress
                  }
                  doc={_activityData.payload}
                />
              );
              notionComponents = [];
              notionFirst = null;
              return out;
            }
          }
          return getActivityComponent(
            item,
            idx,
            firstIndex,
            isLast || uniquesActivity.length === idx + 1
          );
        } catch (error: any) {
          console.warn('log2:', error.message);
          console.log(error);
          return <div key={item?._id + idx} />;
        }
      });

      separators.forEach((el, index) => {
        result.splice(
          el.idx + index,
          0,
          <ActivitySeparator key={Math.random()} text={el.date} />
        );
      });

      if (newActivity > 0) {
        result = [
          <ActivitySeparator
            key={Math.random()}
            helperText={`${newActivity} new updates`}
            text={format(+new Date(), 'MMMM d, yyyy')}
            newUpdate
          />,
          ...result,
        ];
      }

      return result;
    }, [getActivityComponent, permissions, topicActivity, topicId]);

    // const content = useMemo(
    //   () =>
    //     topicActivity.map((post, i) => {
    //       //   console.log('', post._id);
    //       return getActivityComponent(
    //         post,
    //         i,
    //         [],
    //         (results?.activityList?.result?.length || 0) - 1 === i + 1
    //       );
    //     }),
    //   [
    //     getActivityComponent,
    //     results?.activityList?.result?.length,
    //     topicActivity,
    //   ]
    // );

    return (
      <ErrorBoundary componentName="Activity">
        <div id="top" />
        <ActivityHolder.Root customRef={activityRef}>
          <InfiniteScroll
            dataLength={topicActivity.length}
            next={loadMore}
            hasMore={hasMore}
            loader={<div />}
            endMessage={
              !hasMore &&
              !loading &&
              list.length > 1 && (
                <ActivitySeparator
                  text="You've reached the bottom of the well, stop digging! ⛏️"
                  big
                />
              )
            }
            className={css`
              overflow: hidden !important;
              padding-top: 20px;
            `}
          >
            {list}
          </InfiniteScroll>
          {loading && (
            <div>
              <ActivityHolder.Item
                topicId=""
                stared={false}
                type={EActivityType.UNKNOWN}
                Icon={<LoadingIcon style={{ width: '16px' }} />}
                title="Loading..."
                subTitle=""
                last
              />
            </div>
          )}

          {/* {!hasMore && !loading && (
            <ActivitySeparator
              text="You've reached the bottom of the well, stop digging! ⛏️"
              big
            />
          )} */}
        </ActivityHolder.Root>
        {/* {loading && <p className="center">Loading More Posts...</p>} */}
        {/* {list && list?.length > 15 && (
          <p
            className={css`
              text-align: center;
            `}
          >
            <a
              href="#top"
              className={css`
                color: ${theme.colors.text['text/pink']};
              `}
            >
              Back to Top
            </a>
          </p>
        )} */}
      </ErrorBoundary>
    );
  }
);
