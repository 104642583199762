import { Box, Button, DropdownI, Input, ProfileIcon, Text } from 'components';
import { Close, UserIcon } from 'components/Icons';
import { IUser } from 'model';
import React, { useEffect, useState } from 'react';

import { css } from '@emotion/css';
import { theme } from 'theme';
import { Search as SearchIcon } from 'components/Icons';
import { useLazyQuery } from '@apollo/client';
import { SEARCH_PEOPLE } from '../../gql';
import { displayUser } from 'utils';
import { ERouteName } from 'pages';
import { useNavigate } from 'react-router-dom';

let timeout: NodeJS.Timeout;

type Props = {};
export const Search: React.FC<Props> = () => {
  const navigate = useNavigate();

  const [query, setQuery] = useState('');

  const [runQuery, { data, loading }] = useLazyQuery<{
    searchPeople: IUser[];
  }>(SEARCH_PEOPLE);

  useEffect(() => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (query.length > 2) {
        runQuery({
          variables: {
            query,
          },
        })
          .then((res) => {})
          .catch((err) => {});
      }
    }, 500);
  }, [query, runQuery]);

  return (
    <DropdownI.Root
      align="people"
      triggerComponent={
        <Button
          iconPosition="icon-only"
          Icon={SearchIcon}
          style={{ marginBottom: '8px', marginRight: '8px' }}
        />
      }
    >
      <Box position="relative" width="100%" noPadding>
        <Box
          width="100%"
          height="52px"
          padding="m"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Input
            Icon={SearchIcon}
            placeholder="Search by name or role"
            background="transparent"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            autoFocus
          />
          <DropdownI.Item>
            <div
              className={css`
                width: 24px;
                height: 24px;
                border-radius: 12px;
                background-color: ${theme.colors.primary['primary/03']};
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
              `}
            >
              <Close
                fill={theme.colors.text['text/blue/soft']}
                style={{ width: '12px', height: '12px' }}
              />
            </div>
          </DropdownI.Item>
        </Box>
      </Box>

      <Box width="100%" padding="m">
        <Text.TextSmall2 marginTop="4xs">I'm searching for...</Text.TextSmall2>

        <div
          className={css`
            height: 28px;
            width: 88px;
            border-radius: 14px;
            border: 1px solid ${theme.colors.accent['purple/main']};
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
          `}
        >
          <UserIcon
            style={{ marginRight: '6px' }}
            fill={theme.colors.accent['purple/main']}
          />
          <Text.TextSmall2
            marginBottom="4xs"
            marginTop="4xs"
            style={{ color: theme.colors.accent['purple/main'] }}
          >
            People
          </Text.TextSmall2>
        </div>

        {loading ? (
          <div>Loading...</div>
        ) : (
          data?.searchPeople &&
          data?.searchPeople?.length > 0 && (
            <table>
              <body style={{ backgroundColor: 'transparent' }}>
                <Text.TextSmall2>People</Text.TextSmall2>
                {data?.searchPeople.map((user) => (
                  <tr
                    key={user._id}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate(ERouteName.Profile + user._id);
                    }}
                  >
                    <td>
                      <ProfileIcon
                        userId={user._id}
                        userName={displayUser(user as any) || ''}
                        profilePicture={user.profileUrl}
                        size="small"
                      />
                    </td>

                    <td
                      style={{
                        paddingLeft: '8px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Text.Body>{displayUser(user as any)}</Text.Body>

                      <Text.TextSmall2
                        color="text/blue/soft"
                        style={{ opacity: 0.5, marginLeft: '6px' }}
                      >
                        {user.role || 'No role'}
                      </Text.TextSmall2>
                    </td>
                  </tr>
                ))}
              </body>
            </table>
          )
        )}
      </Box>
    </DropdownI.Root>
  );
};
