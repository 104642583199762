import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Dialog } from 'components';
import { initializeTopicLink } from 'features/topicLink';
import { useAppDispatch } from 'hooks';
import { GET_ATTACHMENT } from 'pages/Topic/gql';
import { TTopicBasics } from 'pages/Topic/types';
import React from 'react';

interface Props {
  open?: boolean;
  id: string;
  topicId: string;
  onOpenChange?(open: boolean): void;
}

const DELETE_ITEM = gql`
  mutation deleteLink($id: ID!) {
    deleteLink(id: $id)
  }
`;

export function DeleteLinkDialog(props: Props) {
  const [deleteItem, { loading }] = useMutation(DELETE_ITEM);

  const dispatch = useAppDispatch();

  const [refetch] = useLazyQuery<{
    topicLinks: TTopicBasics['links'];
  }>(GET_ATTACHMENT, {
    variables: { topicId: props.topicId },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Dialog
      // additionalContent={}
      description={`
          Are you sure you want to delete this?
        `}
      open={props.open}
      submitting={loading}
      submitDisabled={false}
      title="Delete Link"
      onOpenChange={props.onOpenChange}
      onSubmit={async (onClose) => {
        deleteItem({
          variables: {
            id: props.id,
          },
        }).then(() => {
          refetch()
            .then((re) => {
              dispatch(initializeTopicLink(re.data?.topicLinks || []));
              onClose();
            })
            .catch(() => {});
        });
        // dispatch(removeTopicLink(props.id));
      }}
    />
  );
}
