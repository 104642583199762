import { ThunkAction } from 'redux-thunk';
// import loggerMiddleware from './middleware/logger';
import {
  Action,
  configureStore,
  ConfigureStoreOptions,
  // getDefaultMiddleware,
} from '@reduxjs/toolkit';
import rootReducer, { RootState } from './reducers';

function configureAppStore(preloadedState?: any) {
  const setup: ConfigureStoreOptions = {
    reducer: rootReducer,
    preloadedState,
    // enhancers: [monitorReducersEnhancer],
  };
  // if (process.env.NODE_ENV !== 'production') {
  //   setup.middleware = [loggerMiddleware, ...getDefaultMiddleware()];
  // }
  const store = configureStore(setup);

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
  }

  return store;
}

export const store = configureAppStore();

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
