import { css } from '@emotion/css';
import { Box, Text } from 'components';
import { ArrowLeft } from 'components/Icons';
import React from 'react';
import DrawerComponent from 'react-modern-drawer';

//import styles 👇
import 'react-modern-drawer/dist/index.css';
import { theme } from 'theme';

type DrawerProps = {
  isOpen: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  noOverflow?: boolean;
  toggleDrawer?: VoidFunction;
};
export const Drawer: React.FC<DrawerProps> = ({
  isOpen,
  children,
  noOverflow,
  toggleDrawer,
}) => {
  return (
    <DrawerComponent
      open={isOpen}
      onClose={toggleDrawer}
      direction="right"
      size={700}
      overlayOpacity={0.5}
      enableOverlay
      zIndex={200}
      style={{
        backgroundColor: theme.colors.primary['primary/02'],
        borderRadius: `32px 0px 0px 32px`,
      }}
    >
      {/* <div
        className={css`
          height: 90px;
          width: 50px;
        `}
      /> */}
      <Box width="100%">
        <Text.BodyXS
          color="text/50%"
          style={{ display: 'flex', cursor: 'pointer' }}
          onClick={toggleDrawer}
        >
          <ArrowLeft
            fill={theme.colors.text['text/50%']}
            style={{ marginRight: '7px' }}
          />
          Close
        </Text.BodyXS>
        <div
          className={css`
            width: 100%;
            height: 93vh;
            overflow: ${noOverflow ? '' : 'auto'};
            position: relative;
            &::-webkit-scrollbar {
              display: block;
              width: 8px;
              background-color: #2d2a41;
            }

            &::-webkit-scrollbar-thumb {
              background: #8c83c3;
              border-radius: 8px;
            }
          `}
        >
          {children}
        </div>
      </Box>
    </DrawerComponent>
  );
};
