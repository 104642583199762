import { css } from '@emotion/css';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { useCallback, useEffect } from 'react';
import { theme } from 'theme';
import { ENotificationKey } from 'shared';
import pubsub from 'sweet-pubsub';

interface Props {
  children?: JSX.Element;
  options: JSX.Element[];
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export function Dropdown(props: Props) {
  const { onOpenChange } = props;

  const onOpenChangeHandler = useCallback(
    (value: boolean) => {
      if (onOpenChange) {
        onOpenChange(value);
      }
      pubsub.emit(ENotificationKey.SHOW_DROPDOWN, { value });
    },
    [onOpenChange]
  );

  useEffect(() => {
    return () => {
      if (onOpenChange) {
        onOpenChange(false);
      }
      pubsub.emit(ENotificationKey.SHOW_DROPDOWN, { value: false });
    };
  }, [onOpenChange]);

  return (
    <DropdownMenu.Root onOpenChange={onOpenChangeHandler} open={props.open}>
      <DropdownMenu.Trigger
        asChild
        className={css`
          z-index: 0;
        `}
      >
        {props.children}
      </DropdownMenu.Trigger>
      {!!props.options.length && (
        <DropdownMenu.Content
          className={css`
            background-color: ${theme.colors.primary['primary/03']};
            border-radius: 8px;
            overflow: hidden;
            padding: 8px;
            z-index: 1000;

            &[data-state='open'] {
              animation: fadeIn 0.15s ease-out;
            }

            &[data-state='closed'] {
              animation: fadeOut 0.15s ease-out;
            }
          `}
          sideOffset={4}
        >
          {props.options.map((option, i) => (
            <DropdownMenu.Item asChild key={i}>
              {React.cloneElement(option, {
                className: css`
                  background-color: transparent;
                  border: none;
                  border-radius: 4px;
                  box-sizing: border-box;
                  color: ${theme.colors.text['text/main']};
                  cursor: pointer;
                  display: block;
                  outline: none;
                  padding: 8px 8px;
                  text-align: left;
                  text-decoration: none;
                  transition: background-color 0.15s;
                  width: 100%;

                  &:focus,
                  &:hover {
                    background-color: ${theme.colors.primary['primary/02']};
                  }
                `,
              })}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      )}
    </DropdownMenu.Root>
  );
}
