import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type IActivityFilter = {
  name: string;
  _id: string;
};

type IUpdate = IActivityFilter;

const initialState = [] as IActivityFilter[];

export const activityFilterSlice = createSlice({
  name: 'topicLink',
  initialState,
  reducers: {
    initializeFilter: (state, action: PayloadAction<IActivityFilter[]>) => {
      return action.payload;
    },
    addFilter: (state, action: PayloadAction<IActivityFilter>) => {
      return [...state, action.payload];
    },
    updateFilter: (state, action: PayloadAction<IUpdate[]>) => {
      return state;
    },
    removeAllFilter: (state) => {
      return initialState;
    },
  },
});

export const { initializeFilter, addFilter, updateFilter, removeAllFilter } =
  activityFilterSlice.actions;

export const activityFilterReducer = activityFilterSlice.reducer;
