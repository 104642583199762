import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { TTopicBasics } from 'pages/Topic/types';

interface ITopic
  extends Omit<TTopicBasics, 'links' | 'scope' | 'shared' | 'description'> {
  updated?: number;
  //   scope: {
  //     isWrite: boolean;
  //     isRead: boolean;
  //     isAdmin: boolean;
  //   };
}

export type TopicList = ITopic[];

type IUpdate = Partial<
  Omit<ITopic, '_id' | 'creatorId' | 'created' | 'updated' | 'collaborators'>
>;

const initialState = [] as TopicList;

export const topicListSlice = createSlice({
  name: 'topicList',
  initialState,
  reducers: {
    initializeTopicList: (state, action: PayloadAction<TopicList>) => {
      return action.payload;
    },
    topicQuickUpdateReducer: (
      state,
      action: PayloadAction<{ id: string; data: IUpdate }>
    ) => {
      const index = state.findIndex((topic) => topic._id === action.payload.id);
      if (index < 0) {
        return state;
      }

      state[index] = {
        ...state[index],
        ...action.payload.data,
      };
      return state;
    },
    addNewTopicsToList: (
      state,
      action: PayloadAction<{ position: 'top' | 'bottom'; topics: TopicList }>
    ) => {
      return action.payload.position === 'top'
        ? [...action.payload.topics, ...state]
        : [...state, ...action.payload.topics];
    },
    removeTopicFromList: (state, action: PayloadAction<{ id: string }>) => {
      return state.filter((topic) => topic._id !== action.payload.id);
    },
  },
});

export const {
  initializeTopicList,
  topicQuickUpdateReducer,
  addNewTopicsToList,
  removeTopicFromList,
} = topicListSlice.actions;

export const topicListReducer = topicListSlice.reducer;
