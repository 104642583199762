import { gql } from '@apollo/client';

export const GET_TOPIC_BASICS = gql`
  query Topic($id: ID!, $first: Boolean) {
    topic(id: $id, first: $first) {
      _id
      name
      description
      public
      closed
      created
      # links {
      #   doc {
      #     id
      #     url
      #     created
      #     type
      #     lastUpdated
      #     lastUpdatedBy
      #     title
      #     googleId
      #   }
      #   type
      #   url
      #   important
      #   groups
      # }
      creatorId {
        profileUrl
        role
        firstName
        lastName
        _id
        businessEmail
      }
      collaborators {
        _id
        profileUrl
        firstName
        lastName
        businessEmail
      }
      allUpdatesWatchers {
        _id
        profileUrl
        firstName
        lastName
        businessEmail
      }
      summaryWatchers {
        _id
        profileUrl
        firstName
        lastName
        businessEmail
      }
      # shared {
      #   type
      #   scope
      #   collaboratorEmails
      # }
      momentum {
        count
        label
        lastActivity
      }
      fileAccess {
        id
        access
      }
    }
  }
`;

export const GET_ATTACHMENT = gql`
  query TopicLinks($topicId: ID!) {
    topicLinks(topicId: $topicId) {
      doc {
        id
        url
        created
        type
        lastUpdated
        lastUpdatedBy
        title
        googleId
      }
      type
      url
      important
      groups
      _id
    }
  }
`;

export const GET_ACTIVITY = gql`
  query ActivityList(
    $topicId: String!
    $stared: Boolean
    $page: Float
    $groups: [String!]
    $type: [String!]
    $noGoogle: Boolean
    $skip: Int
    $limit: Int
    $activityId: ID
  ) {
    activityList(
      topicId: $topicId
      stared: $stared
      page: $page
      groups: $groups
      type: $type
      noGoogle: $noGoogle
      skip: $skip
      limit: $limit
      activityId: $activityId
    ) {
      size
      result {
        comment
        data
        _id
        created
        deleted
        updated
        workspaceId
        replies
        stared
        userId {
          _id
          firstName
          lastName
          businessEmail
        }
        deletedBy {
          _id
          firstName
          lastName
          businessEmail
        }
        # slackMessageAuthor {
        #   displayText
        # }
      }
    }
  }
`;

export const GET_TOPICS = gql`
  query Topics($filter: String) {
    topics(filter: $filter) {
      _id
      name
    }
  }
`;

export const EDIT_TOPIC = gql`
  mutation changeTopicScope(
    $topicId: ID!
    $name: String
    $description: String
  ) {
    updateTopic(topicId: $topicId, name: $name, description: $description) {
      _id
      name
      description
    }
  }
`;

export const CHANGE_SCOPE = gql`
  mutation changeTopicScope($topicId: ID!, $scope: Boolean!) {
    changeTopicScope(topicId: $topicId, scope: $scope) {
      description
      _id
      name
      public
    }
  }
`;

export const ADD_COMMENTS = gql`
  mutation addComment(
    $topicId: ID!
    $comment: String!
    $isImportant: Boolean!
  ) {
    addComment(
      topicId: $topicId
      comment: $comment
      isImportant: $isImportant
    ) {
      comment
      data
      _id
      created
      deleted
      updated
      workspaceId
      mentionWithNoAccess
      userId {
        _id
        firstName
        lastName
        businessEmail
      }
    }
  }
`;

export const REPLY_COMMENTS = gql`
  mutation replyComment($commentId: ID!, $comment: String!) {
    replyToComment(commentId: $commentId, comment: $comment) {
      comment
      data
      _id
      created
      deleted
      updated
      workspaceId
      mentionWithNoAccess
      userId {
        _id
        firstName
        lastName
        businessEmail
      }
    }
  }
`;

export const EDIT_COMMENTS = gql`
  mutation updateComment($commentId: ID!, $comment: String!) {
    updateComment(commentId: $commentId, comment: $comment) {
      comment
      data
      _id
      created
      deleted
      updated
      workspaceId
      mentionWithNoAccess
    }
  }
`;

export const GET_REPLIES = gql`
  query Replies($commentId: String!, $page: Float) {
    replies(commentId: $commentId, page: $page) {
      size
      result {
        comment
        data
        _id
        created
        deleted
        updated
        workspaceId
        userId {
          _id
          firstName
          lastName
          businessEmail
        }
      }
    }
  }
`;

export const GET_WORKSPACE = gql`
  query getWorkspace($loggedEmail: String!) {
    myWorkspace(loggedEmail: $loggedEmail) {
      _id
      name
      topics {
        _id
        name
      }
    }
  }
`;

export const GROUP = gql`
  query groups($topicId: ID!) {
    groups(topicId: $topicId) {
      name
      _id
    }
  }
`;

export const REMOVE_ACTIVITY = gql`
  mutation removeActivity($id: ID!) {
    removeActivity(id: $id)
  }
`;

export const REGISTER_DEVICE = gql`
  mutation AddNewDevice($deviceToken: String!, $topicId: ID!) {
    addNewDevice(deviceToken: $deviceToken, topicId: $topicId)
  }
`;

export const CREATE_GROUP = gql`
  mutation createGroup($topicId: ID!, $group: String!) {
    createGroup(topicId: $topicId, group: $group) {
      name
      _id
    }
  }
`;

export const GET_DOC_DELTA = gql`
  query googleDocDelta($firstId: ID, $lastId: ID!) {
    googleDocDelta(firstId: $firstId, lastId: $lastId)
  }
`;

export const GET_MICROSOFT_DELTA = gql`
  query MicrosoftDelta($id: ID!) {
    microsoftDelta(id: $id)
  }
`;

export const SHARE = gql`
  mutation ShareTopic(
    $id: ID!
    $scope: [String!]!
    $type: String!
    $collaboratorEmails: [String!]
  ) {
    shareTopic(
      id: $id
      scope: $scope
      type: $type
      collaboratorEmails: $collaboratorEmails
    )
  }
`;

export const TOPIC_CHANGED = gql`
  subscription topicUpdated($topicId: ID!) {
    topicUpdated(topicId: $topicId)
  }
`;

export const MOMENTUM = gql`
  mutation CalculateMomentum($topicId: ID!) {
    calculateMomentum(topicId: $topicId) {
      label
      count
      lastActivity
    }
  }
`;

export const GOOGLE_AUTH = gql`
  query Query {
    me {
      _id
      userHasGoogleAuth
      userHasSlackAuth
      userHasNotionAuth
      userHasLinearAuth
    }
  }
`;

export const GET_COLLABORATORS = gql`
  query Collaborators {
    collaborators {
      _id
      businessEmail
      role
      profileUrl
      firstName
      lastName
    }
  }
`;

export const SUBSCRIBE_ALL = gql`
  mutation SubscribeForAllChanges($topicId: ID!) {
    subscribeForAllChanges(topicId: $topicId)
  }
`;

export const UNSUBSCRIBE_ALL = gql`
  mutation UnsubscribeForAllChanges($topicId: ID!) {
    unsubscribeForAllChanges(topicId: $topicId)
  }
`;

export const SUBSCRIBE_SUMMARY = gql`
  mutation SubscribeForSummaryChanges($topicId: ID!) {
    subscribeForSummaryChanges(topicId: $topicId)
  }
`;

export const UNSUBSCRIBE_SUMMARY = gql`
  mutation UnsubscribeForSummaryChanges($topicId: ID!) {
    unsubscribeForSummaryChanges(topicId: $topicId)
  }
`;

// export const PERMISSIONS = gql`
//   query LinkAccessList($topicId: ID!) {
//     linkAccessList(topicId: $topicId) {
//       id
//       access
//     }
//   }
// `;
