import { useMutation } from '@apollo/client';
import { css } from '@emotion/css';
import {
  Box,
  Button,
  Drawer,
  DropdownI,
  Input,
  Switch,
  Text,
  // Toast,
  Notification,
  TooltipContainer,
  TextEditor,
} from 'components';
import { FileUpload } from 'components/FileUpload';
import {
  CheckIcon,
  Close,
  PaperPlaneTilt,
  PlusIcon,
  Search,
  TrashIcon,
} from 'components/Icons';
import { UserContext } from 'context';
import { updateTopicReducer } from 'features/topic';
import { ITopic } from 'features/topicActivity';
import { initializeTopicLink, ITopicLink } from 'features/topicLink';
import {
  addNewTopicsToList,
  removeTopicFromList,
  TopicList,
} from 'features/topics';
import { useAppDispatch, useAppSelector } from 'hooks';
import { IUser } from 'model';
import { ERouteName } from 'pages';
// import { CloseDialog } from 'pages/Topic/components/Menu/CloseDialog';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'reducers';
import { theme } from 'theme';
import { displayUser } from 'utils';
import { INVITE_USER } from '../../pages/Workspace/gql';
import { CLOSE_TOPIC, DELETE_TOPIC, EDIT_TOPIC, TOPIC_CREATE } from './gql';

let timer: NodeJS.Timeout;

export type EditResult = {
  updateTopic: {
    description?: string;
    _id: string;
    name: string;
    public?: boolean;
    collaborators?: IUser[];
    links?: ITopicLink[];
  };
};

type Props = {
  isDrawerOpen: boolean;
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  members: {
    userId: string;
    profilePicture: string | undefined;
    email: string;
    role: string | undefined;
    name: string;
  }[];
  isEdit?: boolean;
  topic?: ITopic;
  onCancel: () => void;
  onTopicEdited?: () => void;
};

export const CreateTopic: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const topicLinks = useAppSelector((state: RootState) => state.topicLink);

  const dispatch = useAppDispatch();
  const { state: userState } = useContext(UserContext);

  const [topicName, setTopicName] = useState('');
  const [topicDescription, setTopicDescription] = useState('');
  const [topicCollaborators, setTopicCollaborators] = useState<
    {
      userId: string;
      profilePicture: string | undefined;
      email: string;
      role: string | undefined;
      name: string;
    }[]
  >([]);
  const [checked, setChecked] = useState<any>(false);
  const [topicValidationError, setTopicValidationError] = useState('');
  const [collaboratorFilter, setCollaboratorFilter] = useState('');
  const [urls, setUrls] = useState<{ title?: string; url: string }[]>([]);
  const [urlTitle, setUrlTitle] = useState('');
  const [url, setUrl] = useState('');
  const [files, setFiles] = useState<File[] | null>(null);
  const [inviteEmail, setInviteEmail] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const [inviteUser, { loading: inviteLoading }] = useMutation(INVITE_USER);

  const [createTopic, { loading }] = useMutation<{
    createTopic: { _id: string; name: string };
  }>(TOPIC_CREATE);

  const [closeTopic, { loading: closeLoading }] = useMutation(CLOSE_TOPIC);
  const [deleteTopic, { loading: deleteLoading }] = useMutation(DELETE_TOPIC);

  const [updateTopic, { loading: updateTopicLoading }] =
    useMutation<EditResult>(EDIT_TOPIC);

  useEffect(() => {
    if (props.isEdit) {
      setTopicName(props.topic?.name || '');
      setTopicDescription(props.topic?.description || '');
      setChecked(!props.topic?.public);
    }
    // setUrls(props.topic.);
  }, [
    props.isEdit,
    props.topic?.description,
    props.topic?.name,
    props.topic?.public,
  ]);

  useEffect(() => {
    /**
     * TODO - change this when we have multiple emails
     */
    setTopicCollaborators(
      props.topic?.collaborators?.map((user) => ({
        email: user.businessEmail,
        name: displayUser(user)!,
        profilePicture: user.profileUrl,
        role: user.role,
        userId: user._id,
      })) || []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.topic?.collaborators?.length]);

  useEffect(() => {
    setUrls(
      topicLinks?.map((link) => ({
        title: link.doc?.title || link.url,
        url: link.url!,
      })) || []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicLinks.length]);

  useEffect(() => {
    if (deleteConfirmation) {
      timer = setTimeout(() => {
        setDeleteConfirmation(false);
      }, 5000);
    } else {
      clearTimeout(timer);
    }
  }, [deleteConfirmation]);

  const onTopicNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTopicName(e.target.value);
  };

  const onDescriptionChange = (value: string) => {
    setTopicDescription(value);
  };

  const handleDelete = useCallback(() => {
    deleteTopic({
      variables: {
        id: props.topic?._id,
      },
    }).then(() => {
      dispatch(removeTopicFromList({ id: props.topic?._id! }));
      navigate(ERouteName.Workspace);
    });
  }, [deleteTopic, dispatch, navigate, props.topic?._id]);

  const uploadLink = useCallback(async () => {
    const uploaded = await Promise.all(
      (files || []).map(async (file) => {
        try {
          const bodyContent = new FormData();
          bodyContent.append('image', file);

          const headersList = {
            Accept: '*/*',
            'User-Agent': 'Thunder Client (https://www.thunderclient.com)',
          };

          const res = await fetch(
            `${process.env.REACT_APP_API_URL?.split('graphql').join(
              'api'
            )}/profile-upload`,
            {
              method: 'POST',
              body: bodyContent,
              headers: headersList,
            }
          );

          const URL = await res.text();
          return { title: file.name, url: URL };
        } catch (error) {
          return '';
        }
      })
    );
    return uploaded;
  }, [files]);

  const onSubmit = useCallback(async () => {
    setTopicValidationError('');
    if (!topicName.trim()) {
      setTopicValidationError('Topic name can not be empty');
      return;
    }
    const variables: any = {
      name: topicName,
      description: topicDescription,
      isPublic: !(checked as boolean),
    };
    if (topicCollaborators.length > 0) {
      variables.collaborators = topicCollaborators.map((el) => el.email);
    }
    // upload files
    let uploaded: any[] = [];
    if ((files || []).length > 0) {
      uploaded = await uploadLink();
    }

    if (urls.length > 0 || uploaded.length > 0) {
      variables.links = [...urls, ...uploaded];
    }

    if (props.isEdit) {
      variables.topicId = props.topic?._id;
      delete variables.workspaceId;
      updateTopic({
        variables,
      })
        .then((res) => {
          dispatch(
            updateTopicReducer({
              name: res.data?.updateTopic?.name /* || topicBasics.name */,
              description:
                res.data?.updateTopic
                  ?.description /*|| topicBasics.description*/,
              public: res.data?.updateTopic?.public,
              collaborators: res.data?.updateTopic?.collaborators || [],
            })
          );

          dispatch(initializeTopicLink(res.data?.updateTopic?.links || []));

          setFiles([]);
          props.onTopicEdited?.();

          /**
           * Fetch topic in case there is a link extracted from the comment
           */
          // setTimeout(() => {
          //   syncTopicInfo();
          // }, 400);
        })
        .catch((err) => {});
    } else {
      createTopic({
        variables,
      }).then((res) => {
        if (res.data?.createTopic?._id) {
          const created: TopicList[0] = {
            _id: res.data.createTopic._id,
            name: topicName,
            public: checked,
            closed: false,
            collaborators: [],
            creatorId: userState,
            created: new Date().getTime(),
          };
          dispatch(addNewTopicsToList({ position: 'top', topics: [created] }));
          navigate(ERouteName.Topic + res.data?.createTopic?._id);
        }
      });
    }
  }, [
    checked,
    createTopic,
    dispatch,
    files,
    navigate,
    props,
    topicCollaborators,
    topicDescription,
    topicName,
    updateTopic,
    uploadLink,
    urls,
    userState,
  ]);

  const handleOnClose = useCallback(() => {
    closeTopic({
      variables: {
        topicId: props.topic?._id,
        closed: !props.topic?.closed,
      },
    }).then((res) => {
      // navigate(ERouteName.Workspace, {
      //   replace: true,
      // });
    });
    dispatch(updateTopicReducer({ closed: !props.topic?.closed }));
  }, [closeTopic, dispatch, props.topic?._id, props.topic?.closed]);

  const handleInvite = useCallback(() => {
    if (!inviteLoading && inviteEmail) {
      inviteUser({
        variables: {
          emails: inviteEmail,
          workspaceId: userState?.workspace,
        },
      }).then((res) => {
        setInviteEmail('');
        setShowNotification(true);
        // Toast.notify('Invitation sent to the user');
      });
    }
  }, [inviteEmail, inviteLoading, inviteUser, userState?.workspace]);

  return (
    <Drawer
      isOpen={props.isDrawerOpen}
      toggleDrawer={() => props.setIsDrawerOpen(!props.isDrawerOpen)}
      noOverflow
    >
      <Notification
        success
        show={showNotification}
        handleShow={setShowNotification}
      />
      {/* <Toast.Component /> */}
      <div
        className={css`
          width: 100%;
          height: 100%;
          position: relative;
        `}
      >
        <div
          className={css`
            position: absolute;
            right: 30px;
            top: -30px;
          `}
        >
          <Switch
            label={checked ? 'Private' : ''}
            checked={checked}
            onCheckedChange={setChecked}
          />
        </div>
        <div
          className={css`
            width: 100%;
            height: calc(100% - 100px);
            position: absolute;
            top: 0px;
            left: 0px;
            overflow: auto;
          `}
        >
          {topicValidationError && <div />}
          <Input
            placeholder="Topic title goes here"
            value={topicName}
            onChange={onTopicNameChange}
            background="transparent"
            big
            height={46}
            style={{ marginBottom: '24px', marginTop: '50px' }}
            readOnly={props.isEdit && props.topic?.closed}
          />
          <div
            className={css`
              width: 100%;
              padding: 0px 10px;
            `}
          >
            {props.isDrawerOpen && (
              <TextEditor
                dark
                value={topicDescription}
                placeholder="Describe the Topic with a few words"
                label="Description"
                height={200}
                handleChange={onDescriptionChange}
              />
            )}

            <div
              className={css`
                display: grid;
                grid-template-columns: 50% 50%;
                gap: 10px;
                margin-top: 24px;
              `}
            >
              <Box
                width="100%"
                height="100%"
                backgroundColor="primary/03"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                // padding="s"
                borderRadius="l"
                style={{ overflow: 'hidden' }}
              >
                <Box
                  noPadding
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Text.TextSmall2
                    color="text/light"
                    marginBottom="4xs"
                    marginTop="s"
                  >
                    Contributors
                  </Text.TextSmall2>
                  {Boolean(!props.isEdit || !props.topic?.closed) && (
                    <DropdownI.Root
                      triggerComponent={
                        <div
                          className={css`
                            width: 24px;
                            height: 24px;
                            border-radius: 12px;
                            background-color: #2d2a41;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-top: ${theme.spacing.s}px;
                            cursor: pointer;
                          `}
                        >
                          <PlusIcon
                            fill={theme.colors.text['text/light']}
                            style={{ width: '12px' }}
                          />
                        </div>
                      }
                    >
                      <div
                        className={css`
                          width: 100%;
                          height: 47px;
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          border-bottom: 1px solid
                            ${theme.colors.accent['purple/light']};
                        `}
                      >
                        <Input
                          placeholder="Search people"
                          Icon={Search}
                          background="transparent"
                          height={24}
                          value={collaboratorFilter}
                          onChange={(e) =>
                            setCollaboratorFilter(e.target.value)
                          }
                          autoFocus
                        >
                          {props.members.filter(
                            (member) =>
                              (member.email
                                .toLocaleLowerCase()
                                .includes(collaboratorFilter) ||
                                member.name
                                  .toLocaleLowerCase()
                                  .includes(collaboratorFilter)) &&
                              topicCollaborators.findIndex(
                                (c) => c.userId === member.userId
                              ) < 0
                          ).length > 0 && (
                            <div
                              className={css`
                                padding: 8px;
                              `}
                            >
                              {props.members
                                .filter(
                                  (member) =>
                                    (member.email
                                      .toLocaleLowerCase()
                                      .includes(collaboratorFilter) ||
                                      member.name
                                        .toLocaleLowerCase()
                                        .includes(collaboratorFilter)) &&
                                    topicCollaborators.findIndex(
                                      (c) => c.userId === member.userId
                                    ) < 0
                                )
                                .map((user) => (
                                  <div
                                    key={user.email}
                                    className={css`
                                      width: 100%;
                                      display: block;
                                      margin-bottom: 24px;
                                      max-height: 200px;
                                      overflow: auto;
                                      cursor: pointer;
                                    `}
                                    onClick={() =>
                                      setTopicCollaborators((prev) => [
                                        ...prev,
                                        user,
                                      ])
                                    }
                                  >
                                    <div
                                      className={css`
                                        display: inline-block;
                                        vertical-align: middle;
                                        margin-right: 5px;
                                        width: 90%;
                                        overflow: hidden;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                      `}
                                    >
                                      {user.profilePicture ? (
                                        <img
                                          src={user.profilePicture}
                                          alt="profile"
                                          className={css`
                                            width: 16px;
                                            height: 16px;
                                            border: 1px solid #171425;
                                            border-radius: 8px;
                                            display: inline;
                                            vertical-align: middle;
                                            margin-right: 5px;
                                          `}
                                        />
                                      ) : (
                                        <div
                                          className={css`
                                            width: 16px;
                                            height: 16px;
                                            border: 1px solid #171425;
                                            background-color: #171425;
                                            border-radius: 8px;
                                            display: inline-block;
                                            vertical-align: middle;
                                            margin-right: 5px;
                                          `}
                                        />
                                      )}
                                      <Text.TextSmall2
                                        marginBottom="4xs"
                                        marginTop="4xs"
                                        style={{
                                          display: 'inline',
                                          verticalAlign: 'middle',
                                          marginRight: '5px',
                                        }}
                                      >
                                        {user.name}
                                      </Text.TextSmall2>
                                      <Text.TextSmall2
                                        color="text/blue/soft"
                                        marginBottom="4xs"
                                        marginTop="4xs"
                                        style={{
                                          fontSize: '9px',
                                          display: 'inline',
                                          verticalAlign: 'middle',
                                        }}
                                      >
                                        {user.role || 'no role defined'}
                                      </Text.TextSmall2>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                        </Input>

                        <DropdownI.Item>
                          <div
                            className={css`
                              width: 24px;
                              height: 24px;
                              border-radius: 12px;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              background-color: ${theme.colors.primary[
                                'primary/03'
                              ]};
                              cursor: pointer;
                            `}
                          >
                            <Close
                              fill={theme.colors.text['text/blue/soft']}
                              style={{ width: '12px' }}
                            />
                          </div>
                        </DropdownI.Item>
                      </div>

                      <div
                        className={css`
                          width: 100%;
                          padding: 16px;
                        `}
                      >
                        <Text.TextSmall2>
                          Not part of Multiplayer? <br /> Invite them via mail.
                        </Text.TextSmall2>
                        <div
                          className={css`
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            background-color: ${theme.colors.primary[
                              'primary/01'
                            ]};
                            padding-right: 8px;
                            border-radius: 5px;
                          `}
                        >
                          <Input
                            placeholder="E-mail here..."
                            // Icon={Search}
                            background={theme.colors.primary['primary/01']}
                            height={40}
                            value={inviteEmail}
                            onChange={(e) => setInviteEmail(e.target.value)}
                          />
                          <PaperPlaneTilt
                            style={{ cursor: 'pointer' }}
                            onClick={handleInvite}
                          />
                        </div>
                      </div>
                    </DropdownI.Root>
                  )}
                </Box>
                {topicCollaborators.map((user, idx) => (
                  <div
                    className={css`
                      width: 100%;
                      display: block;
                      margin-top: ${idx === 0 ? '8px' : ''};
                      margin-bottom: 6px;
                      overflow: auto;
                      max-height: 140px;
                      &::-webkit-scrollbar {
                        display: block;
                        width: 8px;
                        background-color: #2d2a41;
                      }

                      &::-webkit-scrollbar-thumb {
                        background: #8c83c3;
                        border-radius: 8px;
                      }
                    `}
                  >
                    <div
                      className={css`
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 5px;
                        width: 90%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                      `}
                    >
                      {user.profilePicture ? (
                        <img
                          src={user.profilePicture}
                          alt="profile"
                          className={css`
                            width: 16px;
                            height: 16px;
                            border: 1px solid #171425;
                            border-radius: 8px;
                            display: inline;
                            vertical-align: middle;
                            margin-right: 5px;
                          `}
                        />
                      ) : (
                        <div
                          className={css`
                            width: 16px;
                            height: 16px;
                            border: 1px solid #171425;
                            background-color: #171425;
                            border-radius: 8px;
                            display: inline-block;
                            vertical-align: middle;
                            margin-right: 5px;
                          `}
                        />
                      )}
                      <Text.TextSmall2
                        marginBottom="4xs"
                        marginTop="4xs"
                        style={{
                          display: 'inline',
                          verticalAlign: 'middle',
                          marginRight: '5px',
                        }}
                      >
                        {user.name}
                      </Text.TextSmall2>
                      <Text.TextSmall2
                        color="text/blue/soft"
                        marginBottom="4xs"
                        marginTop="4xs"
                        style={{
                          fontSize: '9px',
                          display: 'inline',
                          verticalAlign: 'middle',
                        }}
                      >
                        {user.role || 'no role defined'}
                      </Text.TextSmall2>
                    </div>
                    {Boolean(!props.isEdit || !props.topic?.closed) && (
                      <Close
                        fill={theme.colors.text['text/blue/soft']}
                        style={{
                          width: '12px',
                          display: 'inline',
                          verticalAlign: 'middle',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          setTopicCollaborators((prev) =>
                            prev.filter((el) => el.userId !== user.userId)
                          )
                        }
                      />
                    )}
                  </div>
                ))}
              </Box>
              <Box
                width="100%"
                height="100%"
                backgroundColor="primary/03"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                // padding="s"
                borderRadius="l"
              >
                <Box
                  noPadding
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Text.TextSmall2
                    color="text/light"
                    marginBottom="4xs"
                    marginTop="s"
                  >
                    URLs
                  </Text.TextSmall2>
                  {Boolean(!props.isEdit || !props.topic?.closed) && (
                    <DropdownI.Root
                      triggerComponent={
                        <div
                          className={css`
                            width: 24px;
                            height: 24px;
                            border-radius: 12px;
                            background-color: #2d2a41;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-top: ${theme.spacing.s}px;
                            cursor: pointer;
                          `}
                        >
                          <PlusIcon
                            fill={theme.colors.text['text/light']}
                            style={{ width: '12px' }}
                          />
                        </div>
                      }
                    >
                      <div
                        className={css`
                          width: 100%;
                          height: 47px;
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          border-bottom: 1px solid
                            ${theme.colors.accent['purple/light']};
                          padding-inline: 8px;
                        `}
                      >
                        <Text.TextSmall2 marginBottom="4xs" marginTop="4xs">
                          Add URL
                        </Text.TextSmall2>
                        <DropdownI.Item>
                          <div
                            className={css`
                              width: 24px;
                              height: 24px;
                              border-radius: 12px;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              background-color: ${theme.colors.primary[
                                'primary/03'
                              ]};
                              cursor: pointer;
                            `}
                          >
                            <Close
                              fill={theme.colors.text['text/blue/soft']}
                              style={{ width: '12px' }}
                            />
                          </div>
                        </DropdownI.Item>
                      </div>

                      <div
                        className={css`
                          padding-inline: 8px;
                        `}
                      >
                        <Input
                          placeholder="Name to display"
                          style={{ marginBottom: '8px', marginTop: '8px' }}
                          value={urlTitle}
                          onChange={(e) => setUrlTitle(e.target.value)}
                        />
                        <Input
                          placeholder="Paste link here"
                          value={url}
                          onChange={(e) => setUrl(e.target.value)}
                          autoFocus
                        />

                        <div
                          className={css`
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-end;
                            align-items: center;
                            margin-top: 8px;
                          `}
                        >
                          <DropdownI.Item>
                            <div>
                              <Button
                                text="Cancel"
                                variant="outlined"
                                size="small"
                                onClick={() => {
                                  setUrl('');
                                  setUrlTitle('');
                                }}
                              />
                            </div>
                          </DropdownI.Item>
                          <DropdownI.Item>
                            <div>
                              <Button
                                text="Add"
                                variant="contained"
                                size="small"
                                style={{ marginLeft: '8px' }}
                                disabled={!url}
                                onClick={() => {
                                  if (url) {
                                    setUrls((prev) => [
                                      ...prev,
                                      { title: urlTitle || url, url: url },
                                    ]);
                                    setUrl('');
                                    setUrlTitle('');
                                  }
                                }}
                              />
                            </div>
                          </DropdownI.Item>
                        </div>
                      </div>
                    </DropdownI.Root>
                  )}
                </Box>
                <div
                  className={css`
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    max-height: 140px;
                    overflow: auto;
                    /* overflow-x: hidden; */
                    &::-webkit-scrollbar {
                      display: block;
                      width: 8px;
                      background-color: #2d2a41;
                    }

                    &::-webkit-scrollbar-thumb {
                      background: #8c83c3;
                      border-radius: 8px;
                    }
                  `}
                >
                  {urls.map((item, idx) => (
                    <div
                      key={item.url + idx}
                      className={css`
                        display: flex;
                        flex-direction: row;
                        margin-top: ${idx === 0 ? '8px' : ''};
                        margin-bottom: 6px;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                      `}
                    >
                      <div
                        style={{
                          maxWidth: '80%',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        <Text.TextSmall2
                          marginBottom="4xs"
                          marginTop="4xs"
                          style={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {item.title}
                        </Text.TextSmall2>
                      </div>
                      {Boolean(!props.isEdit || !props.topic?.closed) && (
                        <Close
                          fill={theme.colors.text['text/blue/soft']}
                          style={{ width: '12px', cursor: 'pointer' }}
                          onClick={() =>
                            setUrls((prev) =>
                              prev.filter((el) => el.url !== item.url)
                            )
                          }
                        />
                      )}
                    </div>
                  ))}
                </div>
              </Box>
            </div>

            {Boolean(!props.isEdit || !props.topic?.closed) && (
              <FileUpload
                files={files}
                setFiles={setFiles}
                className={css`
                  width: 100%;
                  /* height: 300px; */
                  border-radius: ${theme.borderRadius.l}px;
                  border: 2px dashed ${theme.colors.text['text/menu']};
                  margin-top: 24px;
                  background-color: ${theme.colors.primary['primary/03']};
                `}
              />
            )}
          </div>
        </div>

        <div
          className={css`
            width: 100%;
            height: 94px;
            position: absolute;
            bottom: 0px;
            left: 0px;
            border-top: 1px solid ${theme.colors.accent['purple/light']};
            display: flex;
            flex-direction: row;
            justify-content: ${props.isEdit ? 'space-between' : 'flex-end'};
            align-items: center;
          `}
        >
          {deleteConfirmation ? (
            <div
              className={css`
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
              `}
            >
              <Text.TextSmall2>Are you sure?</Text.TextSmall2>
              <Button
                iconPosition="icon-only"
                Icon={Close}
                iconColor="red/main"
                size="small"
                onClick={() => setDeleteConfirmation(false)}
                style={{ marginLeft: '8px' }}
                disabled={deleteLoading}
              />
              <Button
                iconPosition="icon-only"
                Icon={CheckIcon}
                iconColor="green/main"
                size="small"
                onClick={handleDelete}
                style={{ marginLeft: '8px' }}
                disabled={deleteLoading}
              />
            </div>
          ) : (
            props.isEdit && (
              <Button
                iconPosition="icon-only"
                Icon={TrashIcon}
                size="small"
                onClick={() => setDeleteConfirmation(true)}
              />
            )
          )}
          <div
            className={css`
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: center;
            `}
          >
            {props.isEdit ? (
              <TooltipContainer
                text={
                  props.topic?.closed
                    ? 'Reopen this topic?'
                    : 'Close this topic?'
                }
                side="left"
              >
                <Button
                  text={props.topic?.closed ? 'Reopen' : 'Mark as closed'}
                  variant="outlined"
                  size="small"
                  Icon={CheckIcon}
                  iconPosition="left"
                  borderColor="black"
                  textColor="text/100%"
                  disabled={closeLoading}
                  onClick={handleOnClose}
                />
              </TooltipContainer>
            ) : (
              <Button
                text="Cancel"
                variant="outlined"
                shape="rectangle"
                onClick={props.onCancel}
                disabled={loading}
                textColor="text/100%"
              />
            )}

            {Boolean(!props.isEdit || !props.topic?.closed) && (
              <Button
                text={props.isEdit ? 'Save changes' : 'Create'}
                shape="rectangle"
                style={{ marginLeft: theme.spacing.m }}
                loading={props.isEdit ? updateTopicLoading : loading}
                onClick={onSubmit}
                // disabled={!topicName.trim()}
                textColor="text/100%"
              />
            )}
          </div>
        </div>
      </div>
    </Drawer>
  );
};
