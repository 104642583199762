import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Text,
  TextEditor,
  GoogleCommentBox,
  LinearCommentBox,
  FeedSeparator,
  FeedHolder,
  ItemTitle,
} from 'components';
import { css } from '@emotion/css';
import { theme } from 'theme';
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  PaperClipIcon,
  PathIcon,
  PlusIcon,
  TopicCreatedIcon,
  UserPlusIcon,
} from 'components/Icons';
import { capitalizeString, displayUser } from 'utils';
import { format } from 'date-fns';
import { EFeedType, TFeed } from '../type';
import { ERouteName } from 'pages';
import { Link, useNavigate } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { EActivityType } from 'model';

type FeedsProps = {
  feeds: TFeed[];
};

export const Feeds: React.FC<FeedsProps> = ({ feeds }) => {
  const [postWithAttachment, setPostWithAttachment] = useState<string[]>([]);
  const [googleCommentDoc, setNewGoogleCommentDoc] = useState<string[]>([]);
  const [linearTicket, setLinearTicket] = useState<string[]>([]);
  const [linearComment, setLinearComment] = useState<string[]>([]);
  const [googleDoc, setNewGoogleDoc] = useState<string[]>([]);
  const [microsoft, setMicrosoft] = useState<string[]>([]);
  const [slack, setNewSlack] = useState<string[]>([]);
  const [notion, setNewNotion] = useState<string[]>([]);
  const [expand, setExpand] = useState('');
  const [toTruncate, setToTruncate] = useState<string[]>([]);

  const navigate = useNavigate();

  const getFeedInfo = useMemo(
    () => (feed: TFeed) => {
      const text = {
        first: '',
        second: '',
        third: '',
      };

      let icon;
      let url = ERouteName.Topic + feed.topic?._id || 'notfound';

      switch (feed.type) {
        case EFeedType.TOPIC_CREATED:
          text.first = capitalizeString(
            feed.topic?.name || 'A new topic',
            true
          );
          text.second = 'was created';
          icon = (
            <PlusIcon
              fill="#736DA0"
              style={{ width: '14px', height: '14px' }}
            />
          );
          break;
        case EFeedType.TOPIC_CLOSED:
          icon = (
            <CheckIcon
              fill="#736DA0"
              style={{ width: '14px', height: '14px' }}
            />
          );
          text.first = capitalizeString(feed.topic?.name || 'a topic', true);
          text.second = `was marked as ${feed.closed ? 'closed' : 'reopened'}`;
          break;
        case EFeedType.TOPIC_IMPORTANT:
          icon = (
            <TopicCreatedIcon
              fill="#736DA0"
              style={{ width: '14px', height: '14px' }}
            />
          );
          if (postWithAttachment.includes(feed.activity || '-')) {
            text.first = 'Slack post with attachment';
          } else if (googleCommentDoc.includes(feed.activity || '-')) {
            text.first = 'Google doc comment';
          } else if (googleDoc.includes(feed.activity || '-')) {
            text.first = 'Google doc update';
          } else if (slack.includes(feed.activity || '-')) {
            text.first = 'Slack post';
          } else if (notion.includes(feed.activity || '-')) {
            text.first = 'Notion page update';
          } else if (linearTicket.includes(feed.activity || '-')) {
            text.first = 'Linear ticket update';
          } else if (linearComment.includes(feed.activity || '-')) {
            text.first = 'Linear comment';
          } else if (microsoft.includes(feed.activity || '-')) {
            text.first = 'Microsoft doc update';
          } else {
            text.first = 'Post';
          }
          text.second = 'was marked important in';
          text.third = capitalizeString(feed.topic?.name || 'a topic', true);
          url = url + `#${feed.activity || ''}`;
          break;
        case EFeedType.TOPIC_NEW_USER:
          icon = (
            <UserPlusIcon
              fill="#736DA0"
              style={{ width: '14px', height: '14px' }}
            />
          );
          text.first = displayUser(feed.user!) || 'A user';
          text.second = 'was added to';
          text.third = capitalizeString(feed.topic?.name || 'a topic', true);
          // url = ERouteName.Profile + feed.user?._id || 'notfound';
          break;
        case EFeedType.TOPIC_UPDATE:
          icon = (
            <div
              className={css`
                width: 5px;
                height: 5px;
                border-radius: 3px;
                background-color: #736da0;
              `}
            />
          );
          text.first = capitalizeString(feed.topic?.name || 'a topic', true);
          text.second = 'was updated';
          text.third = `${feed.count || 1} times`;
          break;

        case EFeedType.WORKSPACE_NEW_USER:
          icon = (
            <UserPlusIcon
              fill="#736DA0"
              style={{ width: '14px', height: '14px' }}
            />
          );
          text.first = displayUser(feed.user!) || 'A new user';
          text.second = 'joined';
          text.third =
            capitalizeString(feed.workspace?.name, true) || 'this workspace';
          url = ERouteName.Profile + feed.user?._id || 'notfound';
          break;

        default:
          break;
      }
      return { text, icon, url };
    },
    [
      googleCommentDoc,
      googleDoc,
      linearComment,
      linearTicket,
      microsoft,
      notion,
      postWithAttachment,
      slack,
    ]
  );

  const addPostWithAttachment = useCallback((id: string) => {
    setPostWithAttachment((el) => [...el, id]);
  }, []);
  const addGoogleCommentDoc = useCallback((id: string) => {
    setNewGoogleCommentDoc((el) => [...el, id]);
  }, []);
  const addGoogleDoc = useCallback((id: string) => {
    setNewGoogleDoc((el) => [...el, id]);
  }, []);
  const addSlack = useCallback((id: string) => {
    setNewSlack((el) => [...el, id]);
  }, []);
  const addNotion = useCallback((id: string) => {
    setNewNotion((el) => [...el, id]);
  }, []);
  const addLinearTicket = useCallback((id: string) => {
    setLinearTicket((el) => [...el, id]);
  }, []);
  const addLinearComment = useCallback((id: string) => {
    setLinearComment((el) => [...el, id]);
  }, []);
  const addMicrosoft = useCallback((id: string) => {
    setMicrosoft((el) => [...el, id]);
  }, []);

  const newUpdates = feeds.filter((fe) => {
    const date = new Date(fe.created);
    const today = new Date();
    return (
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate()
    );
  }).length;

  const previousDate = (date: number) =>
    new Date().getDate() === new Date(date).getDate() + 1
      ? 'Yesterday'
      : format(+date, 'MMMM d, yyyy');

  return (
    <div
      className={css`
        width: 100%;
      `}
    >
      <ItemTitle Icon={PathIcon} title="Catch-up with what’s going on" />

      <br />

      <FeedHolder.Root>
        {newUpdates > 0 && (
          <FeedSeparator
            helperText={`${newUpdates}`}
            text={previousDate(new Date().getTime())}
            newUpdate
          />
        )}
        {newUpdates === 0 && (
          <FeedSeparator text={previousDate(feeds[0].created)} />
        )}
        {feeds.map((feed, idx) => {
          return (
            <React.Fragment key={feed._id}>
              <FeedHolder.Item
                Icon={getFeedInfo(feed).icon || <div />}
                title={`*${getFeedInfo(feed).text.first}** ${
                  getFeedInfo(feed).text.second
                } *${getFeedInfo(feed).text.third}**`}
                subTitle={format(+feed.created, 'h:mm aa')}
                onClick={() => {
                  if (
                    feed.type !== EFeedType.TOPIC_IMPORTANT ||
                    !toTruncate.includes(feed._id)
                  ) {
                    navigate(getFeedInfo(feed).url);
                  }
                }}
              >
                {feed.type === EFeedType.TOPIC_IMPORTANT && (
                  <div
                    className={css`
                      height: ${expand === feed._id ||
                      !toTruncate.includes(feed._id)
                        ? 'fit-content'
                        : '250px'};
                      overflow: hidden;
                      position: relative;
                      border-radius: 0px 0px 10px 10px;
                      transition: 3s height ease;
                    `}
                  >
                    <Link
                      to={getFeedInfo(feed).url}
                      className={css`
                        /* text-decoration: none; */
                        all: unset;
                        cursor: pointer;
                      `}
                    >
                      <ActivityItem
                        feedId={feed._id}
                        activityId={feed.activity || ''}
                        addPostWithAttachment={addPostWithAttachment}
                        addGoogleCommentDoc={addGoogleCommentDoc}
                        addGoogleDoc={addGoogleDoc}
                        addSlack={addSlack}
                        addNotion={addNotion}
                        addLinearTicket={addLinearTicket}
                        addLinearComment={addLinearComment}
                        addMicrosoft={addMicrosoft}
                        truncate={(feedId) => {
                          setToTruncate((prev) => [...prev, feedId]);
                        }}
                      />
                    </Link>

                    {toTruncate.includes(feed._id) && (
                      <div
                        className={css`
                          position: absolute;
                          bottom: 0px;
                          left: 0px;
                          width: 100%;
                          height: 30px;
                          background: linear-gradient(
                            to top,
                            #171425,
                            transparent
                          );
                          display: flex;
                          flex-direction: row;
                          justify-content: center;
                          align-items: flex-end;
                        `}
                        onClick={() => {
                          setExpand((prev) =>
                            prev === feed._id ? '' : feed._id
                          );
                        }}
                      >
                        {expand === feed._id ? (
                          <ChevronUpIcon
                            style={{
                              width: '20px',
                              height: '20px',
                              marginBottom: '-4px',
                            }}
                          />
                        ) : (
                          <ChevronDownIcon
                            style={{
                              width: '20px',
                              height: '20px',
                              marginBottom: '-4px',
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>
                )}
              </FeedHolder.Item>
              {new Date(feed.created).getDate() !==
                new Date(
                  feeds[idx < feeds.length - 1 ? idx + 1 : idx]?.created
                ).getDate() && (
                <FeedSeparator
                  text={previousDate(
                    feeds[idx < feeds.length - 1 ? idx + 1 : idx].created
                  )}
                />
              )}
            </React.Fragment>
          );
        })}

        <FeedSeparator text="That's your last 7 days of feed activity!" />
      </FeedHolder.Root>
    </div>
  );
};

const GET_ACTIVITY = gql`
  query activityData($id: ID!) {
    activityData(id: $id)
  }
`;

type ActivityItemProps = {
  activityId: string;
  feedId: string;
  addPostWithAttachment(id: string): void;
  addGoogleDoc(id: string): void;
  addGoogleCommentDoc(id: string): void;
  addSlack(id: string): void;
  addNotion(id: string): void;
  addLinearTicket(id: string): void;
  addLinearComment(id: string): void;
  addMicrosoft(id: string): void;
  truncate(feedId: string): void;
};
const ActivityItem: React.FC<ActivityItemProps> = ({
  activityId,
  feedId,
  addPostWithAttachment,
  truncate,
  addGoogleCommentDoc,
  addGoogleDoc,
  addSlack,
  addNotion,
  addLinearComment,
  addLinearTicket,
  addMicrosoft,
}) => {
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setTimeout(() => {
      if (elementRef.current) {
        const height = elementRef.current.offsetHeight;
        if (height >= 250) {
          truncate(feedId);
        }
      }
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedId]);

  const { loading, data } = useQuery<{ activityData: string }>(GET_ACTIVITY, {
    variables: {
      id: activityId,
    },
  });
  const activity = data?.activityData ? JSON.parse(data?.activityData) : null;
  const renderComponent = useMemo(() => {
    switch (activity?.type) {
      case EActivityType.COMMENT:
        return <TextEditor value={activity?.payload?.comment} readonly />;

      case EActivityType.GOOGLE_COMMENT: {
        return <GoogleCommentBox doc={activity?.payload} />;
      }

      case EActivityType.REPLY:
        return <TextEditor value={activity?.payload?.comment} readonly />;

      case EActivityType.SLACK:
        return (
          <div
            className={css`
              width: 100%;
              padding: 14px;
              word-wrap: break-word;
              color: ${theme.colors.text['text/100%']};
              font-size: ${theme.textVariants['body-sm']};
              span {
                color: ${theme.colors.text['text/yellow']};
                /* background-color: ${theme.colors.accent['blue/light']}; */
              }
              a {
                color: ${theme.colors.text['text/pink']};
              }
            `}
          >
            <div
              dangerouslySetInnerHTML={{
                __html:
                  activity?.payload?.message || ''.split('\n').join('<br />'),
              }}
            />
          </div>
        );

      case EActivityType.LINEAR_ISSUE_COMMENT: {
        return <LinearCommentBox comment={activity?.payload?.comment} />;
      }

      default:
        return <div />;
    }
  }, [activity?.payload, activity?.type]);

  useEffect(() => {
    if (activity?.payload?.files?.length > 0) {
      addPostWithAttachment(activityId);
    }
    switch (activity?.type) {
      case EActivityType.GOOGLE:
        addGoogleDoc(activityId);
        break;

      case EActivityType.GOOGLE_COMMENT:
        addGoogleCommentDoc(activityId);
        break;

      case EActivityType.SLACK:
        addSlack(activityId);
        break;

      case EActivityType.NOTION:
        addNotion(activityId);
        break;

      case EActivityType.LINEAR_ISSUE_COMMENT:
        addLinearComment(activityId);
        break;

      case EActivityType.LINEAR_ISSUE:
        addLinearTicket(activityId);
        break;

      case EActivityType.MICROSOFT:
        addMicrosoft(activityId);
        break;

      default:
        break;
    }
  }, [
    activity?.payload?.files?.length,
    activity?.payload.message,
    activity?.type,
    activityId,
    addGoogleCommentDoc,
    addGoogleDoc,
    addLinearComment,
    addLinearTicket,
    addNotion,
    addPostWithAttachment,
    addSlack,
    addMicrosoft,
  ]);

  if (loading) return <Text.Body>Loading...</Text.Body>;

  return [
    EActivityType.COMMENT,
    EActivityType.GOOGLE_COMMENT,
    EActivityType.REPLY,
    EActivityType.SLACK,
    EActivityType.LINEAR_ISSUE_COMMENT,
  ].includes(activity?.type) ? (
    <div
      ref={elementRef}
      className={css`
        margin-top: 10px;

        border-radius: 8px;
        width: 100%;
        background-color: ${theme.colors.primary['primary/02']};
        word-wrap: break-word;
      `}
    >
      {renderComponent}
      <div>
        {Boolean(
          activity?.type === EActivityType.SLACK &&
            activity?.payload?.files &&
            activity?.payload?.files?.length > 0 &&
            activity?.payload?.files?.[0]
        ) && (
          <Box display="block" flexDirection="row" marginTop="m" noPadding>
            {activity?.payload?.files?.map((file: string) => (
              <a
                href={file}
                key={file}
                className={css`
                  color: ${theme.colors.text['text/green']} !important;
                  font-size: ${theme.textVariants['body-sm'].fontSize}px;
                  /* margin-left: ${theme.spacing.m}px; */
                  display: inline-block;
                  padding: 8px;
                  border-radius: 16px;
                  background-color: #2d2a41;
                  text-decoration: none;
                `}
              >
                <Box
                  noPadding
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <PaperClipIcon
                    fill={theme.colors.text['text/green']}
                    style={{ marginRight: 5 }}
                  />
                  <Text.TextSmall1
                    color="text/green"
                    marginBottom="4xs"
                    marginTop="4xs"
                  >
                    {file?.split('/')[file?.split('/').length - 1]}
                  </Text.TextSmall1>
                </Box>
              </a>
            ))}
          </Box>
        )}
      </div>
    </div>
  ) : (
    <div />
  );
};
