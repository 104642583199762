import { Continue } from './Auth/Continue';
import Login from './Auth/Login';
import IntegrationDone from './Done';
import Error from './Error';
// import { Home } from './Home';
// import { Home } from './Home';
import { People } from './People';
import { Stats } from './Stats';
import { Profile } from './Profile';
import { Topic } from './Topic';
import { Workspace } from './Workspace';
import { Search } from './Search';
import { Feed } from './Feed';
import { MyDetails } from './Setting/MyDetails';
import { PrivacySettings } from './Setting/PrivacySettings';
import { Tutorial } from './Setting/Tutorial';
import { NotificationSetting } from './Setting/NotificationSetting';
import { WorkspaceSetting } from './Setting/WorkspaceSetting';
import { Integrations } from './Setting/Integrations';
import { Inbox } from './Notification/Inbox';
import { Todo } from './Notification/Todo';
import { Done } from './Notification/Done';
import { Landing } from './Landing';
import { Support } from './Support';
// import Onboarding from './Onboarding';
import { Media } from './Media';

export enum ERouteName {
  Home = '/',
  Feed = '/feed',
  Workspace = '/workspace',
  Topic = '/topic/',
  Profile = '/profile/',
  People = '/people',
  Login = '/auth/login',
  LoginContinue = '/auth/continue',
  Done = '/done',
  Search = '/search',
  Error = '/error/',
  Setting = '/settings',
  MyDetailsSetting = '/settings/profile',
  NotificationsSetting = '/settings/notifications',
  WorkspaceSetting = '/settings/workspace',
  TutorialSetting = '/settings/tutorial',
  PrivacySetting = '/settings/privacy',
  IntegrationSetting = '/settings/integrations',
  Notifications = '/notifications',
  NotificationsInbox = '/notifications/inbox',
  NotificationsTodo = '/notifications/todo',
  NotificationsDone = '/notifications/done',
  LandingPage = '/landing-page-for-slack',
  Support = '/support',
  // Onboarding = '/onboarding',
  GlobalFeed = '/global-feed',
  Media = '/media',
  Stats = '/stats/',
}

export const CommonPages = [
  {
    path: ERouteName.Home,
    element: <Feed />,
  },
  {
    path: ERouteName.Feed,
    element: <Feed />,
  },
  {
    path: ERouteName.Workspace,
    element: <Workspace />,
  },
  {
    path: ERouteName.Topic + ':topicId',
    element: <Topic />,
  },
  {
    path: ERouteName.Profile + ':userId',
    element: <Profile />,
  },
  {
    path: ERouteName.People,
    element: <People />,
  },
  {
    path: ERouteName.Stats + ':userId',
    element: <Stats />,
  },
  {
    path: ERouteName.Search,
    element: <Search />,
  },
  {
    path: ERouteName.Error + ':code',
    element: <Error />,
  },
  {
    path: '*',
    element: <Error />,
  },
];

// TODO -- create a utility function to check this
// if (isMultiplayerUser) {
//   CommonPages.push({
//     path: ERouteName.GlobalFeed,
//     element: <GlobalFeed />,
//   });
// }

export const SettingPages = [
  {
    path: ERouteName.MyDetailsSetting,
    element: <MyDetails />,
    index: true,
  },
  {
    path: ERouteName.PrivacySetting,
    element: <PrivacySettings />,
  },
  {
    path: ERouteName.TutorialSetting,
    element: <Tutorial />,
  },
  {
    path: ERouteName.NotificationsSetting,
    element: <NotificationSetting />,
  },
  {
    path: ERouteName.WorkspaceSetting,
    element: <WorkspaceSetting />,
  },
  {
    path: ERouteName.IntegrationSetting,
    element: <Integrations />,
  },
];

export const NotificationPages = [
  {
    path: ERouteName.NotificationsInbox,
    element: <Inbox />,
    index: true,
  },
  {
    path: ERouteName.NotificationsTodo,
    element: <Todo />,
  },
  {
    path: ERouteName.NotificationsDone,
    element: <Done />,
  },
];

export const AuthPages = [
  {
    path: ERouteName.Login,
    element: <Login />,
  },
  {
    path: ERouteName.LoginContinue,
    element: <Continue />,
  },
  {
    path: ERouteName.Done,
    element: <IntegrationDone />,
  },
  {
    path: ERouteName.LandingPage,
    element: <Landing />,
  },
  {
    path: ERouteName.Support,
    element: <Support />,
  },
  // {
  //   path: ERouteName.Onboarding,
  //   element: <Onboarding />,
  // },
  {
    path: ERouteName.Media,
    element: <Media />,
  },
];

const Pages = CommonPages.concat(AuthPages);

export default Pages;
