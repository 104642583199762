import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { Text } from 'components';
import { color } from 'utils';
import { useNavigate } from 'react-router-dom';
import { ERouteName } from 'pages';

type Props = {
  userId: string;
  userName: string;
  userRole?: string;
  profilePicture?: string;
  style?: any;
  size?: 'small' | 'normal' | 'large';
  noDisplay?: boolean;
  onUserClick?: VoidFunction;
};

const Img = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100px;
  z-index: 0;
  transition: z-index 0.1s ease-out;
  &:hover {
    box-shadow: rgba(3, 3, 4, 0.629) 0px 8px 24px;
    position: relative;
    z-index: 100;
  }
`;

const TextContainer = styled.div`
  position: absolute;
  bottom: -50px;
  left: -130px;
  width: 300px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
`;

// const TextContent = styled.div`
//   background-color: ${theme.colors.primary['primary/dark']};
//   padding: ${theme.spacing['3xs']}px;
// `;

const sizeValue = {
  small: 32,
  normal: 40,
  large: 48,
};

export const ProfileIcon: React.FC<Props> = ({
  userId,
  userName,
  userRole,
  profilePicture,
  style,
  size = 'normal',
  noDisplay,
  onUserClick,
}) => {
  const navigate = useNavigate();
  const initials = userName
    .split(' ')
    .map((name) => name[0]?.toLocaleUpperCase())
    .join('');
  const Container = useMemo(
    () => styled.div`
      width: ${sizeValue[size]}px;
      height: ${sizeValue[size]}px;
      border-radius: ${sizeValue[size] / 2}px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${color(userId)};
      position: relative;
      overflow: hidden;
      cursor: pointer;
      &:hover {
        overflow: visible;
      }
    `,
    [size, userId]
  );
  const onClick = useCallback(() => {
    navigate(ERouteName.Profile + userId);
  }, [navigate, userId]);
  return (
    <Container style={style} onClick={onUserClick || onClick}>
      {!noDisplay && (
        <TextContainer>
          <Text.TextSmall2 marginBottom="4xs" marginTop="4xs">
            {userName}
          </Text.TextSmall2>
          <Text.TextSmall2
            marginBottom="4xs"
            marginTop="4xs"
            color="text/light"
          >
            {userRole || ''}
          </Text.TextSmall2>
        </TextContainer>
      )}
      {profilePicture ? (
        <Img src={profilePicture} alt={userName} />
      ) : size === 'normal' ? (
        <Text.H4 color="text/dark">{initials}</Text.H4>
      ) : size === 'small' ? (
        <Text.TextSmall1 color="text/dark">{initials}</Text.TextSmall1>
      ) : (
        <Text.H2 color="text/dark">{initials}</Text.H2>
      )}
    </Container>
  );
};
