import { gql } from '@apollo/client';

export const GET_COLLABORATORS = gql`
  query Collaborators {
    collaborators {
      role
      firstName
      lastName
      profileUrl
      businessEmail
      isDisabled
      _id
      # momentumForUser(momentum: $momentum) {
      #   count
      #   label
      #   lastActivity
      # }
    }
  }
`;

export const SEARCH_PEOPLE = gql`
  query SearchPeople($query: String!) {
    searchPeople(query: $query) {
      role
      firstName
      lastName
      profileUrl
      businessEmail
      isDisabled
      _id
    }
  }
`;

export const DISABLE_USER = gql`
  mutation DisableUser($userId: ID!) {
    disableUser(userId: $userId)
  }
`;
