import { css } from '@emotion/css';
import { Dropdown } from 'components';
import {
  BookmarkIcon,
  EditIcon2,
  MoreHorizontalIcon,
  TopicCreatedIcon,
  TrashIcon,
} from 'components/Icons';
import React, { useState } from 'react';
import { theme } from 'theme';
import { AddLinkToGroup } from '../Menu/AddLinkToGroup';
import { DeleteLinkDialog } from './DeleteLinkDialog';
import { ENotificationKey } from 'shared';
import pubsub from 'sweet-pubsub';

type Props = {
  linkId: string;
  topicId: string;
  groups: string[];
  stared: boolean;
  noDropdown?: boolean;
  onEdit?: VoidFunction;
  // onCopy: VoidFunction;
  onStared?: VoidFunction;
};

type ItemProps = {
  text: string;
  onClick?: VoidFunction;
  Icon: React.FC<React.HTMLAttributes<SVGElement> & { fill?: string }>;
  className?: string;
};
const Item: React.FC<ItemProps> = ({ text, onClick, Icon, className }) => (
  <button onClick={onClick} className={className}>
    <div
      className={css`
        display: flex;
        flex-direction: row;
        align-items: center;
      `}
    >
      <Icon
        fill={theme.colors.text['text/main']}
        style={{ marginRight: '8px', width: '16px' }}
      />
      {text}
    </div>
  </button>
);

export const LinkMenu: React.FC<Props> = ({
  linkId,
  topicId,
  noDropdown,
  groups,
  onEdit,
  onStared,
  stared,
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [addToGroupDialogOpen, setAddToGroupDialogOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const options = [
    <Item
      text="Rename"
      Icon={EditIcon2}
      onClick={() => {
        onEdit?.();
        setOpen(false);
      }}
    />,
    <Item
      text={stared ? 'Not important' : 'Important'}
      Icon={TopicCreatedIcon}
      onClick={() => {
        pubsub.emit(ENotificationKey.SHOW_DROPDOWN, { value: false });
        onStared?.();
        setOpen(false);
      }}
    />,
    <Item
      text="Save to group"
      Icon={BookmarkIcon}
      onClick={() => {
        setAddToGroupDialogOpen(true);
        setOpen(false);
      }}
    />,
    <Item
      text="Delete"
      Icon={TrashIcon}
      onClick={() => {
        setDeleteDialogOpen(true);
        setOpen(false);
      }}
    />,
  ];

  return (
    <>
      {noDropdown ? (
        <div
          className={css`
            display: flex;
            flex-direction: column;
            background-color: ${theme.colors.primary['primary/03']};
            padding: 8px 16px;
            border-radius: ${theme.borderRadius.l}px;
          `}
        >
          {options.map((option, idx) => (
            <div key={idx} style={{ marginBottom: '8px', cursor: 'pointer' }}>
              {option}
            </div>
          ))}
        </div>
      ) : (
        <Dropdown open={open} onOpenChange={setOpen} options={options}>
          <button
            onClick={() => setOpen(!open)}
            className={css`
              background: transparent;
              border: none;
              display: grid;
              place-items: center;
              cursor: pointer;
              margin: 0;
              padding: 0;

              &:focus,
              &:hover {
                .icon {
                  fill: ${theme.colors.text['text/main']};
                }
              }
            `}
          >
            <MoreHorizontalIcon
              className={css`
                fill: ${theme.colors.text['text/main']};
                height: 16px;
                transition: fill 0.15s;
                width: 24px;
              `}
            />
          </button>
        </Dropdown>
      )}

      {/* Due to issues with nesting a Dialog in a Dropdown, we need to use the
          Dialog as a controlled component */}
      <DeleteLinkDialog
        open={deleteDialogOpen}
        id={linkId}
        topicId={topicId}
        onOpenChange={setDeleteDialogOpen}
      />

      <AddLinkToGroup
        open={addToGroupDialogOpen}
        linkId={linkId}
        groups={groups || []}
        onOpenChange={setAddToGroupDialogOpen}
      />
    </>
  );
};
