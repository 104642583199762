import { gql, useLazyQuery } from '@apollo/client';
import { PageLoading } from 'components';
import { EUserAction, IUser } from 'model';
import { AuthPages, ERouteName } from 'pages';
import React, { createContext, Dispatch, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFromLocalStorage, removeLocalStorage } from 'utils';

type TUserState = IUser & {
  displayText: string;
  displayTextShort: string;
};

type TUserAction =
  | { type: EUserAction.SET_USER; payload: TUserState }
  | { type: EUserAction.CLEAR };

type TContext = {
  state: TUserState;
  dispatch: Dispatch<TUserAction>;
};

const initialState = {} as TUserState;

export const UserContext = createContext({} as TContext);

function userReducer(state: TUserState, action: TUserAction): TUserState {
  switch (action.type) {
    case EUserAction.SET_USER:
      return action.payload;
    case EUserAction.CLEAR:
      return initialState;

    default:
      throw Error('Wrong value');
  }
}

const LOAD_USER = gql`
  query {
    me {
      _id
      firstName
      lastName
      email
      businessEmail
      profileUrl
      workspace
      role
      description
      linkedinProfile
      githubProfile
      slackProfile
      isAdmin
      isDisabled
      userHasGoogleAuth
      lastPodcast
    }
  }
`;

type TUserContextProviderProps = {
  children: React.ReactNode;
};
export const UserContextProvider: React.FC<TUserContextProviderProps> =
  React.memo(({ children }) => {
    const [state, dispatch] = useReducer(userReducer, initialState);
    const [loadUser, { loading }] = useLazyQuery<{ me: TUserState }>(LOAD_USER);
    const navigate = useNavigate();

    useEffect(() => {
      const publicRoutes = AuthPages.map((r) => r.path.toString());
      const current = window.location.pathname;
      if (!publicRoutes.includes(current)) {
        if (getFromLocalStorage('accessToken')) {
          loadUser({ fetchPolicy: 'cache-first' }).then((res) => {
            // if (res.error?.graphQLErrors) {
            //   removeLocalStorage('accessToken');
            //   removeLocalStorage('refreshToken');
            //   removeLocalStorage('loggedEmail');
            //   navigate(ERouteName.Login);
            // }
            if (res.data?.me?.isDisabled) {
              removeLocalStorage('accessToken');
              removeLocalStorage('refreshToken');
              navigate(ERouteName.Login);
            }
            dispatch({
              type: EUserAction.SET_USER,
              payload: res.data?.me as TUserState,
            });
          });
        }
      }
    }, [loadUser, navigate]);

    return (
      <UserContext.Provider value={{ state, dispatch }}>
        {loading ? <PageLoading /> : children}
      </UserContext.Provider>
    );
  });
