import React, { useMemo } from 'react';
import styled from '@emotion/styled';

import { theme } from 'theme';
import { Text } from 'components';
import { StarIcon } from 'components/Icons';

const colors = {
  primary: {
    default: theme.colors.primary['primary/03'],
    hover: theme.colors.primary['primary/03'],
  },
  secondary: {
    default: theme.colors.primary['primary/02'],
    hover: theme.colors.primary['primary/02'],
  },
  tertiary: {
    default: theme.colors.accent['pink/main'],
    hover: theme.colors.accent['pink/dark'],
  },
  quaternary: {
    default: theme.colors.primary['primary/04'],
    hover: theme.colors.primary['primary/04'],
  },
};

export type ButtonProps = {
  variant?: 'contained' | 'outlined' | 'link';
  color?: keyof typeof colors;
  iconColor?: keyof typeof theme.colors.accent;
  iconPosition?: 'text-only' | 'right' | 'left' | 'icon-only';
  shape?: 'round' | 'rectangle';
  Icon?: React.FC<any>;
  text?: string;
  full?: boolean;
  customBackground?: string;
  customHeight?: string;
  size?: 'small' | 'large';
  loading?: boolean;
  small?: boolean;
  showStarIcon?: boolean;
  borderColor?: keyof typeof theme.colors.accent;
  textColor?: keyof typeof theme.colors.text;
};

const iconSize = 16;

export const Button: React.FC<
  ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  text,
  disabled,
  variant = 'contained',
  color = 'primary',
  iconColor,
  full,
  customBackground,
  customHeight,
  shape = 'round',
  size = 'small',
  Icon,
  iconPosition = 'left',
  loading,
  showStarIcon,
  borderColor,
  textColor,
  ...props
}) => {
  const ButtonComponent = useMemo(
    () => styled.button`
      all: unset;
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
      /* height: ${size === 'small' ? 38 : 45}px; */
      width: ${iconPosition === 'icon-only'
        ? '32px'
        : full
        ? '100%'
        : 'fit-content'};
      height: ${customHeight
        ? customHeight
        : iconPosition === 'icon-only'
        ? '32px'
        : size === 'large'
        ? '43px'
        : '32px'};
      padding-inline: ${iconPosition === 'icon-only' ? '0px' : '12px'};
      border-radius: ${iconPosition === 'icon-only'
        ? '16'
        : theme.borderRadius.m}px;
      background-color: ${customBackground
        ? customBackground
        : variant === 'contained'
        ? colors[color].default
        : 'transparent'};
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-width: ${customBackground ? 0 : variant === 'link' ? 0 : 2}px;
      border-color: ${variant === 'outlined'
        ? borderColor
          ? theme.colors.accent[borderColor]
          : theme.colors.accent['purple/light']
        : colors[color].default};
      border-style: solid;
      position: relative;
      &:hover {
        border-color: ${colors[color].hover};
        background-color: ${customBackground
          ? customBackground
          : variant === 'contained'
          ? colors[color].hover
          : ''};
      }
    `,
    [
      borderColor,
      color,
      customBackground,
      customHeight,
      disabled,
      full,
      iconPosition,
      size,
      variant,
    ]
  );
  const ButtonLoading = useMemo(
    () => styled.div`
      cursor: not-allowed;
      width: ${iconPosition === 'icon-only'
        ? '32px'
        : full
        ? '100%'
        : 'fit-content'};
      height: ${iconPosition === 'icon-only'
        ? '32px'
        : size === 'large'
        ? '43px'
        : '32px'};
      padding: 12px 16px;
      border-radius: ${iconPosition === 'icon-only'
        ? '100%'
        : theme.borderRadius.m}px;
      background-color: ${variant === 'contained'
        ? disabled
          ? '#1F2A4A'
          : colors[color].default
        : 'transparent'};
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-width: ${variant === 'link' ? 0 : 2}px;
      border-color: ${disabled
        ? '#515C7A'
        : variant === 'outlined'
        ? theme.colors.primary['primary/03']
        : colors[color].default};
      border-style: solid;
    `,
    [color, disabled, full, iconPosition, size, variant]
  );
  return (
    <>
      {loading ? (
        <ButtonLoading>
          <Text.TextSmall2 marginBottom="4xs" marginTop="4xs" color="text/100%">
            Loading...
          </Text.TextSmall2>
        </ButtonLoading>
      ) : (
        <ButtonComponent disabled={disabled} {...props}>
          {showStarIcon && (
            <StarIcon
              fill={theme.colors.accent['purple/main']}
              style={{
                width: '22px',
                height: '22px',
                position: 'absolute',
                top: '-10px',
                right: '30px',
              }}
            />
          )}
          {Icon && (iconPosition === 'left' || iconPosition === 'icon-only') && (
            <Icon
              fill={
                iconColor
                  ? theme.colors.accent[iconColor]
                  : theme.colors.primary['primary/05']
              }
              style={{
                left: theme.spacing.m,
                width: iconSize,
                marginRight: iconPosition === 'icon-only' ? 0 : theme.spacing.s,
              }}
            />
          )}
          {iconPosition !== 'icon-only' &&
            (size === 'large' ? (
              <Text.Button
                marginBottom="4xs"
                marginTop="4xs"
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
                color={
                  disabled
                    ? 'text/40%'
                    : variant === 'link'
                    ? 'text/link'
                    : textColor || 'text/100%'
                }
              >
                {text}
              </Text.Button>
            ) : (
              <Text.TextSmall2
                marginBottom="4xs"
                marginTop="4xs"
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
                color={
                  disabled
                    ? 'text/light'
                    : variant === 'outlined'
                    ? textColor || 'text/blue/soft'
                    : textColor || 'text/main'
                }
              >
                {text}
              </Text.TextSmall2>
            ))}
          {Icon && iconPosition === 'right' && (
            <Icon
              fill={
                iconColor
                  ? theme.colors.accent[iconColor]
                  : theme.colors.primary['primary/05']
              }
              style={{
                left: theme.spacing.m,
                width: iconSize,
                marginLeft: theme.spacing.s,
              }}
            />
          )}
        </ButtonComponent>
      )}
    </>
  );
};
