import { combineReducers } from '@reduxjs/toolkit';

import { topicReducer } from 'features/topic';
import { topicLinkReducer } from 'features/topicLink';
import { activityFilterReducer } from 'features/activityFilter';
import { topicActivityReducer } from 'features/topicActivity';
import { userReducer } from 'features/user';
import { topicListReducer } from 'features/topics';

const rootReducer = combineReducers({
  topic: topicReducer,
  topicLink: topicLinkReducer,
  activityFilter: activityFilterReducer,
  topicActivity: topicActivityReducer,
  user: userReducer,
  topicList: topicListReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
