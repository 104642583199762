import React from 'react';
import _ from 'lodash';

function Separator(props: any) {
  return (
    <div
      style={{
        position: 'absolute',
        height: '100%',
        transform: `rotate(${props.turns}turn)`,
      }}
    >
      <div style={props.style} />
    </div>
  );
}

type Props = {
  count: number;
  style: any;
};
export const RadialSeparators: React.FC<Props> = (props) => {
  const turns = 1 / props.count;
  return (
    <>
      {_.range(props.count).map((index) => (
        <Separator key={index} turns={index * turns} style={props.style} />
      ))}
    </>
  );
};
