import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { PageContainer } from 'components';
import {
  AuthPages,
  CommonPages,
  ERouteName,
  NotificationPages,
  SettingPages,
} from 'pages';
import {
  ApolloClient,
  HttpLink,
  // split,
  // ApolloLink,
  InMemoryCache,
  // concat,
  from,
  ApolloProvider,
  split,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { getNewToken } from 'utils';
import { UserContextProvider } from 'context';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Setting } from 'pages/Setting';
import { Notification } from 'pages/Notification';
import { css } from '@emotion/css';
import pubsub from 'sweet-pubsub';
import { ENotificationKey } from 'shared';

import { RetryLink } from '@apollo/client/link/retry';
import { EGraphQlErrorCode } from 'model';

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error,
  },
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL!,
});

// const wsLink = new GraphQLWsLink(
//   createClient({
//     url: 'ws://localhost:9000/graphql',
//     // connectionParams: {
//     //   authToken: user.authToken,
//     // },
//   })
// );

const wsLink = new WebSocketLink(
  new SubscriptionClient(process.env.REACT_APP_WS_URL!, {
    // connectionParams: {
    //   authToken: user.authToken,
    // },
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

// let time: NodeJS.Timeout;

// to avoid multiple service calling the renew token the same time
// function renewToken() {
//   return new Promise<string>((resolve, reject) => {
//     if (!time) {
//       time = setTimeout(async () => {
//         try {
//           const token = await getNewToken();
//           clearTimeout(time);
//           resolve(token);
//         } catch (error) {
//           reject(EGraphQlErrorCode.UNAUTHENTICATED);
//         }
//       }, 300);
//     } else {
//       reject();
//     }
//   });
// }

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          case EGraphQlErrorCode.UNAUTHENTICATED: {
            window.location.replace('/auth/login');
            // renewToken()
            //   .then((token) => {
            //     const oldHeaders = operation.getContext().headers;
            //     operation.setContext({
            //       headers: {
            //         ...oldHeaders,
            //         authorization:
            //           'Bearer ' +
            //           'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2MmZlNzdlMmRhM2E4YTRlZjk3ODYxN2MiLCJpYXQiOjE2OTczNjEyNTYsImV4cCI6MTY5OTA4OTI1NiwidHlwZSI6ImFjY2VzcyJ9.4cPnNSG1CcpYXzL5VfPO4RpUSbkKs4PEKPFM4TnvjAI',
            //       },
            //     });
            //     // Retry the request, returning the new observable
            //     return forward(operation);
            //   })
            //   .catch((e) => {
            //     if (e) {
            //       console.log({ you: e });
            //     } else {
            //       console.log({ you: 'Dji' });
            //       return forward(operation);
            //     }
            //   });
            break;
          }
          default: {
            // eslint-disable-next-line no-console
            console.log(
              `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`
            );
            break;
          }
        }
      }
    }
    if (networkError) {
      // eslint-disable-next-line no-console
      console.log(`[Network error]: ${networkError}`);
    }
  }
);

const authLink = setContext(async (_, { headers }) => {
  const token = await getNewToken();
  return {
    headers: {
      ...headers,
      authorization: 'Bearer ' + token,
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, authLink, splitLink, retryLink]),
});

function App() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const showCallback = (data: { value: boolean }) => {
      setShow(data.value);
    };
    pubsub.on(ENotificationKey.SHOW_DROPDOWN, showCallback);
    return () => {
      pubsub.off(ENotificationKey.SHOW_DROPDOWN, showCallback);
    };
  }, []);

  return (
    <ApolloProvider client={client}>
      <HelmetProvider>
        <UserContextProvider>
          <div
            className={css`
              width: 100%;
              height: 100%;
            `}
          >
            {show && (
              <div
                className={css`
                  position: fixed;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  backdrop-filter: blur(2px);
                  background-color: rgba(0, 0, 0, 0.3);
                  inset: 0;
                  z-index: 999;
                `}
              />
            )}
            <Routes>
              <Route path="/" element={<PageContainer />}>
                {CommonPages.map((page, idx) => (
                  <Route key={idx} {...page} />
                ))}
                <Route path={ERouteName.Setting} element={<Setting />}>
                  {SettingPages.map((page, idx) => (
                    <Route key={idx} {...page} />
                  ))}
                </Route>

                <Route
                  path={ERouteName.Notifications}
                  element={<Notification />}
                >
                  {NotificationPages.map((page, idx) => (
                    <Route key={idx} {...page} />
                  ))}
                </Route>
              </Route>
              {AuthPages.map((page, idx) => (
                <Route key={idx} {...page} />
              ))}
            </Routes>
          </div>
        </UserContextProvider>
      </HelmetProvider>
    </ApolloProvider>
  );
}

export default App;
