import React, { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import { theme } from 'theme';

const dotCount = 5;

type Props = {
  className?: string;
  noCover?: boolean;
};
export const PageLoading: React.FC<Props> = ({ className, noCover }) => {
  const [activeDot, setActiveDot] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveDot((prevActiveDot) => (prevActiveDot + 1) % dotCount);
    }, 300); // interval of 500ms for each dot

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div
      className={css`
        width: ${noCover ? '' : '100%'};
        height: ${noCover ? '' : '100%'};
        min-height: ${noCover ? '' : '70vh'};
        display: flex;
        justify-content: center;
        align-items: center;
      `}
    >
      <div style={{ display: 'flex', gap: '10px' }}>
        {Array.from({ length: dotCount }).map((_, idx) => (
          <div
            key={idx}
            style={{
              width: '11.25px',
              height: '18px',
              borderRadius: '50px',
              background: idx === activeDot ? '#6A53FE' : '#453E69',
            }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export const RectangleSkeleton: React.FC = () => (
  <div
    className={css`
      @keyframes loading {
        to {
          background-position: 100% 0, 0 0, 0 190px, 50px 195px;
        }
      }
      width: 100%;
      height: 100px;
      border-radius: ${theme.borderRadius.s}px;
      cursor: progress;
      background: linear-gradient(
          0.25turn,
          transparent,
          ${theme.colors.primary['primary/04']},
          transparent
        ),
        linear-gradient(
          ${theme.colors.primary['primary/03']},
          ${theme.colors.primary['primary/03']}
        );
      /* radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%), */
      /* linear-gradient(
                ${theme.colors.primary['primary/03']},
                ${theme.colors.primary['primary/03']}
              ); */
      background-repeat: no-repeat;
      background-size: 100% 150px, 100% 180px, 100px 100px, 225px 30px;
      background-position: -1015px 0, 0 0, 0px 190px, 50px 195px;
      animation: loading 1.5s infinite;
    `}
  />
);
