import { gql, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Box, Button, ProfileIcon, Select, Switch, Text } from 'components';
import {
  Comment,
  GoogleCommentIcon,
  GoogleLogo,
  NotionLogo,
  SlackLogo,
} from 'components/Icons';
import { Collaborator } from 'pages/People/types';
import { useCallback, useState } from 'react';
import { MultiValue, SingleValue } from 'react-select';
import { displayUser } from 'utils';
import { css } from '@emotion/css';
import { theme } from 'theme';
import { EActivityType } from 'model';

const GET_COLLABORATORS = gql`
  query Collaborators {
    collaborators {
      role
      firstName
      lastName
      profileUrl
      businessEmail
      _id
    }
  }
`;

const InlineBlock = styled.div`
  display: inline-block;
`;

type Props = {
  activityType: EActivityType[];
  topicOnly: boolean;
  creator: string[];
  startDate?: Date;
  endDate?: Date;
  collaborators?: string[];
  onCollaboratorChange(user: string, operator: 'and' | 'or'): void;
  onStartDateChange(date: Date): void;
  onEndDateChange(date: Date): void;
  onActivityTypeChange(type: EActivityType): void;
  onTopicOnly(newState: boolean): void;
  onCreatorChange(user: string[]): void;
};
export const Left: React.FC<Props> = ({
  activityType,
  topicOnly,
  creator,
  startDate,
  collaborators,
  endDate,
  onEndDateChange,
  onStartDateChange,
  onActivityTypeChange,
  onCreatorChange,
  onTopicOnly,
  onCollaboratorChange,
}) => {
  const [isOr, setIsOr] = useState(false);
  const { data } = useQuery<Collaborator>(GET_COLLABORATORS);

  const onCreatorSelect = useCallback(
    (
      values:
        | SingleValue<{
            value: string;
            label: string;
          }>
        | MultiValue<{
            value: string;
            label: string;
          }>
    ) => {
      const casted = values as { label: string; value: string }[];
      const collaborators = casted.map((item) => item.value);
      onCreatorChange(collaborators);
    },
    [onCreatorChange]
  );

  const members = data?.collaborators.map((item) => {
    return {
      label: JSON.stringify({
        email: item.businessEmail,
        userName: displayUser(item),
        profilePicture: item.profileUrl,
        userId: item._id,
      }),
      value: item._id,
    };
  });

  return (
    <Box width="100%">
      <Text.H3 marginBottom="2xl">Filter</Text.H3>
      <Switch
        label="Topic only"
        checked={topicOnly}
        onCheckedChange={onTopicOnly}
      />
      <Text.BodySM marginTop="xl">Activity type</Text.BodySM>
      <Box noPadding display="block" width="100%">
        <InlineBlock>
          <Button
            text="Comment"
            iconPosition="left"
            Icon={Comment}
            iconColor="blue/soft"
            shape="rectangle"
            variant={
              activityType.includes(EActivityType.COMMENT)
                ? 'contained'
                : 'link'
            }
            onClick={() => onActivityTypeChange(EActivityType.COMMENT)}
            style={{ marginRight: '10px' }}
          />
        </InlineBlock>
        <InlineBlock>
          <Button
            text="Google"
            iconPosition="left"
            Icon={GoogleLogo}
            iconColor="blue/soft"
            shape="rectangle"
            variant={
              activityType.includes(EActivityType.GOOGLE) ? 'contained' : 'link'
            }
            onClick={() => onActivityTypeChange(EActivityType.GOOGLE)}
            style={{ marginRight: '10px' }}
          />
        </InlineBlock>
        <InlineBlock>
          <Button
            text="Google comment"
            iconPosition="left"
            Icon={GoogleCommentIcon}
            iconColor="blue/soft"
            shape="rectangle"
            variant={
              activityType.includes(EActivityType.GOOGLE_COMMENT)
                ? 'contained'
                : 'link'
            }
            onClick={() => onActivityTypeChange(EActivityType.GOOGLE_COMMENT)}
            style={{ marginRight: '10px' }}
          />
        </InlineBlock>
        <InlineBlock>
          <Button
            text="Slack"
            iconPosition="left"
            Icon={SlackLogo}
            iconColor="blue/soft"
            shape="rectangle"
            variant={
              activityType.includes(EActivityType.SLACK) ? 'contained' : 'link'
            }
            onClick={() => onActivityTypeChange(EActivityType.SLACK)}
            style={{ marginRight: '10px' }}
          />
        </InlineBlock>
        <InlineBlock>
          <Button
            text="Notion"
            iconPosition="left"
            Icon={NotionLogo}
            iconColor="blue/soft"
            shape="rectangle"
            variant={
              activityType.includes(EActivityType.NOTION) ? 'contained' : 'link'
            }
            onClick={() => onActivityTypeChange(EActivityType.NOTION)}
          />
        </InlineBlock>
      </Box>

      <Text.BodySM marginTop="xl">Created by</Text.BodySM>
      <Select
        isMulti
        isUser
        data={members || []}
        onChange={onCreatorSelect}
        placeholder="Select creators"
      />

      <Text.BodySM marginTop="2xl">Collaborators</Text.BodySM>
      <Switch
        label={isOr ? 'OR operator' : 'AND operator'}
        checked={isOr}
        onCheckedChange={setIsOr}
      />
      <Box noPadding display="block" marginTop="l">
        {data?.collaborators.map((user) => (
          <div
            key={user._id}
            onClick={() => onCollaboratorChange(user._id, isOr ? 'or' : 'and')}
            className={css`
              display: inline-block;
              /* border: 1px solid red; */
              vertical-align: top;
              margin-left: 5px;
              margin-bottom: 5px;
              border-radius: 15px;
              background-color: ${collaborators?.includes(user._id)
                ? theme.colors.accent['blue/dark']
                : ''};
            `}
          >
            <ProfileIcon
              userId={user._id}
              userName={displayUser(user) || ''}
              profilePicture={user.profileUrl}
              size="normal"
              onUserClick={() => {}}
            />
          </div>
        ))}
      </Box>

      <Text.BodySM>Creation date</Text.BodySM>
      <Box noPadding display="flex" flexDirection="row">
        <DatePicker
          closeOnScroll={true}
          selected={startDate}
          onChange={onStartDateChange}
          placeholderText="Start date"
        />
        <DatePicker
          closeOnScroll={true}
          selected={endDate}
          onChange={onEndDateChange}
          placeholderText="End date"
        />
      </Box>
    </Box>
  );
};
