import { css } from '@emotion/css';
import {
  Box,
  Checkbox,
  DialogBlank,
  DropdownI,
  // DropdownI,
  Input,
  PageLoading,
  Text,
  TooltipContainer,
} from 'components';
import {
  GoogleLogo,
  NotionLogo,
  Comment,
  Search,
  SlackLogo,
  TopicCreatedIcon,
  UserIcon,
  LinearIcon,
  MicrosoftLogo,
  ListDashIcon,
  GithubLogoIcon,
} from 'components/Icons';
import { EActivityType, IUser } from 'model';
import DatePicker from 'react-datepicker';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { theme } from 'theme';
import { displayUser, shortId } from 'utils';
import { SearchFilterItem } from './SearchFilterItem';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { ERouteName } from 'pages';
import { useNavigate } from 'react-router-dom';
import { UserContext } from 'context';
import { ENotificationKey } from 'shared';
import pubsub from 'sweet-pubsub';
import { useKeyboardShortcut } from 'hooks';

const SEARCH = gql`
  query Search(
    $query: String!
    $limit: Int
    $activityType: [String!]
    $startDate: DateTime
    $endDate: DateTime
    $creator: [String!]
    $topicOnly: Boolean
    $collaborators: [ID!]
    $operator: String
  ) {
    search(
      query: $query
      limit: $limit
      activityType: $activityType
      endDate: $endDate
      creator: $creator
      startDate: $startDate
      topicOnly: $topicOnly
      collaborators: $collaborators
      operator: $operator
    ) {
      name
      score
      activityType
      activityId
      topicId
      comment
      message
      title
      htmlContent
      content
    }
  }
`;

const GET_COLLABORATORS = gql`
  query Collaborators {
    collaborators {
      _id
      businessEmail
      role
      profileUrl
      firstName
      lastName
    }
  }
`;

const activityFilters = [
  { type: EActivityType.COMMENT, label: 'Comment' },
  { type: EActivityType.GOOGLE, label: 'Google' },
  { type: EActivityType.GOOGLE_COMMENT, label: 'Google comment' },
  { type: EActivityType.SLACK, label: 'Slack' },
  { type: EActivityType.NOTION, label: 'Notion' },
];

let timeout: NodeJS.Timeout;

type Collaborator = {
  collaborators: IUser[];
};

export type SearchResult = {
  topicId: string;
  name: string;
  score: number;
  activityType?: EActivityType;
  activityId?: string;
  title?: string;
  comment?: string;
  message?: string;
  htmlContent?: string;
  content?: string;
};

const getIcon = (activityType?: EActivityType) => {
  switch (activityType) {
    case EActivityType.COMMENT:
      return <Comment style={{ width: '16px', height: '16px' }} />;

    case EActivityType.REPLY:
      return <Comment style={{ width: '16px', height: '16px' }} />;

    case EActivityType.GOOGLE:
      return <GoogleLogo style={{ width: '16px', height: '16px' }} />;

    case EActivityType.GOOGLE_COMMENT:
      return <GoogleLogo style={{ width: '16px', height: '16px' }} />;

    case EActivityType.NOTION:
      return <NotionLogo style={{ width: '16px', height: '16px' }} />;

    case EActivityType.SLACK:
      return <SlackLogo style={{ width: '16px', height: '16px' }} />;

    case EActivityType.LINEAR_ISSUE:
      return <LinearIcon style={{ width: '16px', height: '16px' }} />;

    case EActivityType.LINEAR_ISSUE_COMMENT:
      return <LinearIcon style={{ width: '16px', height: '16px' }} />;

    case EActivityType.MICROSOFT:
      return <MicrosoftLogo style={{ width: '16px', height: '16px' }} />;

    case EActivityType.GITHUB:
      return <GithubLogoIcon style={{ width: '16px', height: '16px' }} />;

    default:
      return <TopicCreatedIcon />;
  }
};

type Props = {};
export const SearchDropdown: React.FC<Props> = (props) => {
  const { state: userState } = useContext(UserContext);

  const [searchQuery, setSearchQuery] = useState('');
  const [topicOnly, setTopicOnly] = useState(false);
  const [activityType, setActivityType] = useState<EActivityType[]>([]);
  const [collaborators, setCollaborators] = useState<string[]>([]);
  const [searchStartDate, setSearchStartDate] = useState<Date | undefined>();
  const [searchEndDate, setSearchEndDate] = useState<Date | undefined>();
  // const [search, setSearch] = useState<SearchResult[] | undefined>();
  const [operator, setOperator] = useState<'and' | 'or'>('and');
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const handleShortcut = (event: KeyboardEvent) => {
    setOpen(true);
  };

  useKeyboardShortcut([{ key: 'k', type: 'metaKey' }], handleShortcut);

  const [runQuery, { data, loading }] = useLazyQuery<{
    search: SearchResult[];
  }>(SEARCH);

  const { data: collabData } = useQuery<Collaborator>(GET_COLLABORATORS);

  useEffect(() => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (searchQuery.length > 2) {
        runQuery({
          variables: {
            query: searchQuery,
            limit: 50,
            activityType,
            topicOnly,
            // creator,
            startDate: searchStartDate,
            endDate: searchEndDate,
            collaborators,
            operator,
          },
        })
          .then((res) => {})
          .catch((err) => {});
      }
    }, 500);
  }, [
    activityType,
    collaborators,
    operator,
    runQuery,
    searchEndDate,
    searchQuery,
    searchStartDate,
    topicOnly,
  ]);

  const members = useMemo(() => {
    const result = collabData?.collaborators
      .map((item) => {
        return {
          userId: item._id,
          profilePicture: item.profileUrl,
          email: item.businessEmail!,
          role: item.role,
          name: displayUser(item)!,
        };
      })
      ?.filter((item) => item.userId !== userState?._id);
    return result || [];
  }, [collabData?.collaborators, userState?._id]);

  const onSearchStartDateChange = useCallback((date: Date) => {
    setSearchStartDate(date);
  }, []);

  const onSearchEndDateChange = useCallback((date: Date) => {
    setSearchEndDate(date);
  }, []);

  const onCollaboratorChange = useCallback(
    (user: string, operator: 'and' | 'or') => {
      setCollaborators((act) =>
        act.findIndex((el) => el === user) < 0
          ? [...act, user]
          : act.filter((el) => el !== user)
      );
      setOperator(operator);
    },
    []
  );

  const onActivityTypeChange = useCallback((type: EActivityType) => {
    setActivityType((act) =>
      act.findIndex((el) => el === type) < 0
        ? [...act, type]
        : act.filter((el) => el !== type)
    );
  }, []);

  const handleClick = useCallback(
    (value: SearchResult) => {
      setOpen(false);
      pubsub.emit(ENotificationKey.SHOW_DROPDOWN, { value: false });
      navigate(`${ERouteName.Topic}${value.topicId}#${value.activityId || ''}`);
    },
    [navigate]
  );

  const topicResult = data?.search?.filter((d) => !d.activityId) || [];
  const activityResult = data?.search?.filter((d) => !!d.activityId) || [];

  const handleQueryChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value),
    []
  );

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      runQuery({
        variables: {
          query: searchQuery,
          limit: 50,
          activityType,
          topicOnly,
          // creator,
          startDate: searchStartDate,
          endDate: searchEndDate,
          collaborators,
          operator,
        },
      })
        .then((res) => {})
        .catch((err) => {});
    },
    [
      activityType,
      collaborators,
      operator,
      runQuery,
      searchEndDate,
      searchQuery,
      searchStartDate,
      topicOnly,
    ]
  );

  return (
    <DialogBlank
      open={open}
      onOpenChange={setOpen}
      content={
        <div
          className={css`
            width: 871px;
            height: 518px !important;
            border: 1px solid #322d4e;
            height: ${Boolean(data?.search?.length) ? '65vh' : 'fit-content'};
            overflow: hidden !important;
            background-color: #211f30;
          `}
        >
          <div
            className={css`
              width: 100%;
              height: 79px;
              border-bottom: 1px solid #453e69;
              display: flex;
              flex-direction: row;
              align-items: center;
            `}
          >
            <form
              onSubmit={onSubmit}
              className={css`
                width: calc(100% - 50px);
                height: 100%;
              `}
            >
              <Input
                Icon={Search}
                iconSize={24}
                placeholder="Type to search"
                background="transparent"
                value={searchQuery}
                onChange={handleQueryChange}
                width="100%"
                autoFocus
                height={79}
              />
            </form>
            <div
              className={css`
                color: #8c83c3;
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 12px;
              `}
            >
              ⌘ K
            </div>
          </div>
          <div
            className={css`
              width: 100%;
              height: calc(100% - 79px);
              padding: 16px;
              position: relative;
            `}
          >
            <Text.TextSmall2 marginTop="4xs">
              I'm searching for...
            </Text.TextSmall2>
            <Box
              noPadding
              width="100%"
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <SearchFilterItem
                Icon={ListDashIcon}
                text="Topic"
                selected={topicOnly}
                onClick={() => setTopicOnly(!topicOnly)}
              />

              <DropdownI.Root
                triggerComponent={
                  <SearchFilterItem
                    Icon={Search}
                    text="Activity"
                    selected={activityType.length > 0}
                  />
                }
              >
                <DropdownI.Item>
                  <div
                    className={css`
                      width: 100%;
                      background-color: ${theme.colors.primary['primary/03']};
                      border-radius: 8px;
                      padding: 8px;
                    `}
                  >
                    {activityFilters.map((act) => (
                      <div
                        key={act.type}
                        className={css`
                          display: flex;
                          flex-direction: row;
                          justify-content: flex-start;
                          align-items: center;
                          margin-bottom: 10px;
                          cursor: pointer;
                        `}
                        onClick={() => {
                          onActivityTypeChange(act.type);
                        }}
                      >
                        <Checkbox
                          small
                          checked={activityType.includes(act.type)}
                        />

                        <Text.TextSmall1 marginBottom="4xs" marginTop="4xs">
                          {act.label}
                        </Text.TextSmall1>
                      </div>
                    ))}
                  </div>
                </DropdownI.Item>
              </DropdownI.Root>

              <DropdownI.Root
                triggerComponent={
                  <SearchFilterItem
                    Icon={UserIcon}
                    text="People"
                    selected={collaborators.length > 0}
                  />
                }
              >
                <DropdownI.Item>
                  <div
                    className={css`
                      width: 100%;
                      background-color: ${theme.colors.primary['primary/03']};
                      border-radius: 8px;
                      padding: 8px;
                    `}
                  >
                    {members.map((member) => (
                      <div
                        key={member.email}
                        className={css`
                          display: flex;
                          flex-direction: row;
                          justify-content: flex-start;
                          align-items: center;
                          margin-bottom: 10px;
                          cursor: pointer;
                        `}
                        onClick={() =>
                          onCollaboratorChange(member.userId, 'and')
                        }
                      >
                        <Checkbox
                          small
                          checked={collaborators.includes(member.userId)}
                        />

                        {member.profilePicture ? (
                          <img
                            src={member.profilePicture}
                            alt="profile"
                            className={css`
                              width: 12px;
                              height: 12px;
                              border-radius: 6px;
                              background-color: ${theme.colors.primary[
                                'primary/01'
                              ]};
                              margin-right: 8px;
                            `}
                          />
                        ) : (
                          <div
                            className={css`
                              width: 12px;
                              height: 12px;
                              border-radius: 6px;
                              background-color: ${theme.colors.primary[
                                'primary/01'
                              ]};
                              margin-right: 8px;
                            `}
                          />
                        )}

                        <Text.TextSmall1 marginBottom="4xs" marginTop="4xs">
                          {member.name}
                        </Text.TextSmall1>
                      </div>
                    ))}
                  </div>
                </DropdownI.Item>
              </DropdownI.Root>

              <DropdownI.Root
                triggerComponent={
                  <SearchFilterItem
                    Icon={Search}
                    text="Created date"
                    selected={Boolean(searchEndDate || searchStartDate)}
                  />
                }
              >
                <DropdownI.Item>
                  <div
                    className={css`
                      width: 100%;
                      background-color: ${theme.colors.primary['primary/03']};
                      border-radius: 8px;
                      padding: 8px;
                    `}
                  >
                    <DatePicker
                      closeOnScroll={true}
                      selected={searchStartDate}
                      onChange={onSearchStartDateChange}
                      placeholderText="Start date"
                      className={css`
                        background-color: ${theme.colors.primary['primary/02']};
                        outline: none;
                        border-radius: 8px;
                        border: 1px solid transparent;
                        height: 27px;
                        padding-inline: 8px;
                        font-family: ${theme.textVariants.body.fontFamily};
                        color: ${theme.colors.text['text/main']};
                      `}
                    />
                    <DatePicker
                      closeOnScroll={true}
                      selected={searchEndDate}
                      onChange={onSearchEndDateChange}
                      placeholderText="End date"
                      className={css`
                        background-color: ${theme.colors.primary['primary/02']};
                        outline: none;
                        border-radius: 8px;
                        border: 1px solid transparent;
                        height: 27px;
                        padding-inline: 8px;
                        font-family: ${theme.textVariants.body.fontFamily};
                        color: ${theme.colors.text['text/main']};
                        margin-top: 8px;
                      `}
                    />
                  </div>
                </DropdownI.Item>
              </DropdownI.Root>
            </Box>

            <div
              className={css`
                width: 100%;
                height: calc(100% - 110px);
              `}
            >
              <Text.TextSmall2 style={{ marginLeft: '5px' }}>
                Search results
              </Text.TextSmall2>
              <div
                className={css`
                  width: 100%;
                  height: calc(100% - 30px);
                  overflow: auto;
                  border-radius: 12px;
                  border: 1px solid #453e69;
                  padding: 16px;
                `}
              >
                {loading ? (
                  <div
                    className={css`
                      width: 100%;
                      height: calc(100% - 0px);
                      padding: 16px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    `}
                  >
                    <PageLoading noCover />
                  </div>
                ) : (
                  data?.search &&
                  data?.search?.length > 0 && (
                    <>
                      {topicResult.map((result) => (
                        <div
                          key={`${result.topicId}-${result.activityId || ''}`}
                          onClick={() => handleClick(result)}
                          className={css`
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;
                            cursor: pointer;
                            width: 100%;
                            padding: 8px 0px;
                            border-radius: 8px;
                            &:hover {
                              background-color: ${theme.colors.primary[
                                'primary/03'
                              ]};
                              /* border-width: 0px; */
                            }
                          `}
                        >
                          <div
                            className={css`
                              color: ${theme.colors.text['text/blue/soft']};
                              margin-right: 24px;
                              width: 65px;
                            `}
                          >
                            {shortId(result.topicId)}
                          </div>

                          <div
                            className={css`
                              color: ${theme.colors.text['text/main']};
                              width: 100%;
                            `}
                            dangerouslySetInnerHTML={{
                              __html: `<div>${result.name}</div>`,
                            }}
                          />
                        </div>
                      ))}

                      {activityResult.map((result) => (
                        <div
                          key={`${result.topicId}-${result.activityId || ''}`}
                          onClick={() => handleClick(result)}
                          className={css`
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;
                            cursor: pointer;
                            width: 100%;
                            padding: 8px 0px;
                            border-radius: 8px;
                            &:hover {
                              background-color: ${theme.colors.primary[
                                'primary/03'
                              ]};
                              /* border-width: 0px; */
                            }
                          `}
                        >
                          <div
                            className={css`
                              margin-right: 24px;
                            `}
                          >
                            {getIcon(result.activityType!)}
                          </div>

                          <div
                            className={css`
                              color: ${theme.colors.text['text/main']};
                              width: 100%;
                            `}
                          >
                            <TooltipContainer
                              side="right"
                              text={result.name.replace(/<[^>]+>/g, '')}
                            >
                              <div
                                className={css`
                                  color: ${theme.colors.text['text/main']};
                                  overflow: hidden;
                                  white-space: nowrap;
                                  text-overflow: ellipsis;
                                  max-width: 400px;
                                `}
                                dangerouslySetInnerHTML={{
                                  __html: `<div style="text-overflow: ellipsis; overflow: hidden;">${
                                    result.content ||
                                    result.htmlContent ||
                                    result.message ||
                                    result.comment ||
                                    result.title
                                  }</div>`,
                                }}
                              />
                            </TooltipContainer>
                          </div>
                        </div>
                      ))}
                    </>
                  )
                )}
              </div>
            </div>

            <div
              className={css`
                width: 100%;
                height: 60px;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                padding: 0px 16px;
                position: absolute;
                bottom: 0;
                left: 0;
              `}
            >
              <div
                className={css`
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                `}
              >
                <div
                  className={css`
                    width: 32px;
                    height: 32px;
                    background-color: #453e69;
                    font-size: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4.571px;
                    margin-right: 7px;
                  `}
                >
                  ESC
                </div>
                <Text.TextSmall1>Close</Text.TextSmall1>
              </div>
            </div>
          </div>
        </div>
      }
    >
      <div
        className={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;
          position: relative;
          width: 64px;
          height: 64px;
        `}
      >
        <Search
          fill="white"
          style={{
            height: '24px',
            width: '24px',
          }}
        />
      </div>
    </DialogBlank>
  );
};
