import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { Box, Text } from 'components';
import { Checkmark, SlackLogo } from 'components/Icons';
import React, { useEffect } from 'react';
import { theme } from 'theme';
import { extractFromQuery } from 'utils';

export const DONE_MESSAGE = 'done';

const IconWrapper = styled.div`
  align-items: center;
  background: linear-gradient(
    60deg,
    ${theme.colors.accent['blue/main']},
    ${theme.colors.accent['purple/light']},
    ${theme.colors.accent['pink/main']}
  );
  border-radius: 150px;
  display: flex;
  flex-direction: column;
  height: 150px;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;
  width: 150px;
`;

type Params = {
  app?: 'slack' | 'github';
  installed?: string;
};
export default function Done() {
  // We may end up on this page after a Google OAuth redirect. In the case
  // where that Google OAuth login took place inside of a popup window, we
  // want to close that window. So, we postMessage to a potential parent
  // window.
  const { app, installed } = extractFromQuery<Params>(window.location.search);

  useEffect(() => {
    if (typeof window !== 'undefined' && (!app || installed === 'true')) {
      window.opener?.postMessage(DONE_MESSAGE);
      window.postMessage(DONE_MESSAGE);
      window.parent?.postMessage(DONE_MESSAGE);
    }
  }, [app, installed]);

  return (
    <Box
      width="100%"
      height="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <IconWrapper>
        <Checkmark
          style={{
            height: '100px',
            fill: 'red',
            stroke: theme.colors.primary['primary/01'],
            strokeWidth: '4px',
          }}
        />
      </IconWrapper>
      <Text.H3>App installed successfully</Text.H3>

      {app === 'github' && (
        <a
          href={`https://github.com/apps/multiplayer-${
            process.env.REACT_APP_ENV === 'prod' ? 'github' : 'dev'
          }`}
          target="_blank"
          rel="noreferrer"
          className={css`
            text-decoration: none;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 44px;
            border-radius: 8px;
            background-color: ${theme.colors.primary['primary/03']};
            padding-inline: 16px;
            margin-top: 16px;
          `}
        >
          <Text.Body marginBottom="4xs" marginTop="4xs">
            Next step is to install the Github App
          </Text.Body>
        </a>
      )}

      {app === 'slack' && installed === 'false' && (
        <>
          <a
            href={process.env.REACT_APP_SLACK_URL}
            target="_blank"
            rel="noreferrer"
            className={css`
              text-decoration: none;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              height: 44px;
              border-radius: 8px;
              background-color: ${theme.colors.primary['primary/03']};
              padding-inline: 16px;
              margin-top: 12px;
            `}
          >
            <SlackLogo
              style={{ width: '16px', height: '16px', marginRight: '8px' }}
            />
            <Text.Body marginBottom="4xs" marginTop="4xs">
              Next step: Add the bot for Slack
            </Text.Body>
          </a>
          <Text.TextSmall1 color="text/75%" marginTop="4xs">
            This second installation step allows you to receive notifications
            from Multiplayer and use message shortcuts.
          </Text.TextSmall1>
        </>
      )}

      {!app && <Text.Body>You can close this window</Text.Body>}
    </Box>
  );
}
