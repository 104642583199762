import { css } from '@emotion/css';
import React, { useEffect, useState } from 'react';
import { theme } from 'theme';

type Props = {
  value: number;
  label?: string;
  style?: React.CSSProperties;
};

export const HorizontalBar: React.FC<Props> = ({ value, label, style }) => {
  const [the, setd] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setd(value);
    }, 1000);
  }, [value]);
  return (
    <div
      style={style}
      className={css`
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      `}
    >
      {label && (
        <span
          className={css`
            color: #211f30;
            font-family: 'Roobert';
            font-size: 9px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
            margin-right: 8px;
            width: 30px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
          `}
        >
          {label}
        </span>
      )}
      <div
        className={css`
          width: 300px;
          height: 24px;
          background-color: ${theme.colors.primary['primary/02']};
          overflow: hidden;
        `}
      >
        <div
          className={css`
            width: ${Math.min(100, Math.max(0, the))}%;
            transition: width 2s ease;
            height: 100%;
            background-color: ${theme.colors.primary['primary/04']};
          `}
        />
      </div>
    </div>
  );
};
