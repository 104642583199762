import { gql, useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/css';
import {
  Accordion,
  AvatarList,
  Box,
  Button,
  DropdownI,
  Input,
  ProfileIcon,
  Switch,
  Text,
  TopicMomentum,
  Notification,
} from 'components';
import {
  CodeIcon,
  FileDocIcon,
  FilePdfIcon,
  FilmStripIcon,
  FolderNotchIcon,
  ImageSquareIcon,
  LinkSimpleIcon,
  MoreHorizontalIcon,
  PaperClipIcon,
  PlusIcon,
  PresentationIcon,
  TableIcon,
  TopicCreatedIcon,
} from 'components/Icons';
import { initializeTopicLink, updateTopicLink } from 'features/topicLink';
import { useAppDispatch, useAppSelector } from 'hooks';
import { GoogleDocType, IUser } from 'model';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RootState } from 'reducers';
import { theme } from 'theme';
import { displayUser, shortId, unique } from 'utils';
import { GET_ATTACHMENT, SHARE } from '../gql';
import {
  ActivityPermission,
  ELinkLabel,
  LinkedResourceType,
  TTopicBasics,
} from '../types';
import { LinkMenu } from './ItemMenu/LinkMenu';
import { Right } from './primary';
import { ENotificationKey } from 'shared';
import pubsub from 'sweet-pubsub';
import { UserContext } from 'context';

const LIMIT = 5;

const getLinkInfo = (link: any) => {
  const split = link.url?.split('/') || [];
  let label = ELinkLabel.LINK;
  let title: string = link.url || '';
  let Icon = LinkSimpleIcon;
  // if (link.important) {
  //   label = ELinkLabel.IMPORTANT;
  // }
  if (link.type === LinkedResourceType.File) {
    const finalName = split[split?.length - 1].split('-');
    title = link.doc.title || finalName[finalName?.length - 1] || '';
    title = title.split('_').join(' ');
    const ext = split[split?.length - 1]?.split('.')[1];
    if (ext === 'pdf') {
      label = ELinkLabel.PDF;
      Icon = FilePdfIcon;
    } else if (['png', 'jpg', 'jpeg', 'gif', 'svg'].includes(ext)) {
      label = ELinkLabel.IMAGE;
      Icon = ImageSquareIcon;
    } else if (['mp4', 'mov', 'avi', 'webm', 'wmv', 'avchd'].includes(ext)) {
      label = ELinkLabel.VIDEO;
      Icon = FilmStripIcon;
    } else {
      label = ELinkLabel.FILE;
      Icon = PaperClipIcon;
    }
  } else if (
    ![
      LinkedResourceType.File,
      LinkedResourceType.Unknown,
      LinkedResourceType.Notion,
      LinkedResourceType.Linear,
      LinkedResourceType.Microsoft,
      LinkedResourceType.Github,
    ].includes(link.type)
  ) {
    switch (link.doc?.type) {
      case GoogleDocType.Document:
        Icon = FileDocIcon;
        label = ELinkLabel.DOC;
        break;

      case GoogleDocType.Presentation:
        Icon = PresentationIcon;
        label = ELinkLabel.SLIDE;
        break;

      case GoogleDocType.Sheets:
        Icon = TableIcon;
        label = ELinkLabel.SHEET;
        break;

      case GoogleDocType.Colab:
        Icon = CodeIcon;
        label = ELinkLabel.COLAB;
        break;

      case GoogleDocType.Folder:
        Icon = FolderNotchIcon;
        label = ELinkLabel.FOLDER;
        break;

      default:
        label = ELinkLabel.FILE;
        Icon = LinkSimpleIcon;
        break;
    }
  } else if (link.type === LinkedResourceType.Notion) {
    label = ELinkLabel.NOTION;
    Icon = LinkSimpleIcon;
  } else if (link.type === LinkedResourceType.Linear) {
    label = ELinkLabel.LINEAR;
    Icon = LinkSimpleIcon;
  } else if (link.type === LinkedResourceType.Microsoft) {
    label = ELinkLabel.MICROSOFT;
    Icon = FileDocIcon;
  } else if (link.type === LinkedResourceType.Github) {
    label = ELinkLabel.GITHUB;
    Icon = FileDocIcon;
  } else {
    if (link.url.includes('youtube.')) {
      label = ELinkLabel.VIDEO;
      Icon = FilmStripIcon;
    } else if (
      link.url.includes('.png') ||
      link.url.includes('.jpeg') ||
      link.url.includes('.jpg') ||
      link.url.includes('.gif') ||
      link.url.includes('.svg')
    ) {
      label = ELinkLabel.IMAGE;
      Icon = ImageSquareIcon;
    } else if (
      link.url.includes('.mp4') ||
      link.url.includes('.mov') ||
      link.url.includes('.avi') ||
      link.url.includes('.webm') ||
      link.url.includes('.wmv') ||
      link.url.includes('.avchd')
    ) {
      label = ELinkLabel.VIDEO;
      Icon = FilmStripIcon;
    } else {
      label = ELinkLabel.LINK;
      Icon = LinkSimpleIcon;
    }
  }

  return {
    label,
    title,
    Icon,
  };
};

const LinkTypes = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Important',
    value: ELinkLabel.IMPORTANT,
  },
  { label: 'Docs', value: ELinkLabel.DOC },
  { label: 'Sheets', value: ELinkLabel.SHEET },
  { label: 'Slides', value: ELinkLabel.SLIDE },
  { label: 'Colabs', value: ELinkLabel.COLAB },
  { label: 'Folders', value: ELinkLabel.FOLDER },
  { label: 'Notion', value: ELinkLabel.NOTION },
  { label: 'Linear', value: ELinkLabel.LINEAR },
  { label: 'Github', value: ELinkLabel.GITHUB },
  { label: 'Links', value: ELinkLabel.LINK },
  { label: 'PDFs', value: ELinkLabel.PDF },
  { label: 'Images', value: ELinkLabel.IMAGE },
  { label: 'Videos', value: ELinkLabel.VIDEO },
  { label: 'Microsoft', value: ELinkLabel.MICROSOFT },
  { label: 'Other files', value: ELinkLabel.FILE },
];

// const labelInfo = {
//   [ELinkLabel.DOC]: '#36DABF80',
//   [ELinkLabel.IMAGE]: '#FFE35180',
//   [ELinkLabel.IMPORTANT]: '#ff519180',
//   [ELinkLabel.LINK]: '#455BA3',
//   [ELinkLabel.PDF]: '#FFE35180',
//   [ELinkLabel.SHEET]: '#36DABF80',
//   [ELinkLabel.SLIDE]: '#36DABF80',
//   [ELinkLabel.VIDEO]: '#FFE35180',
//   [ELinkLabel.FILE]: '#FFE35180',
//   [ELinkLabel.NOTION]: '#ff519180',
// };

type Props = {
  // changeScopeLoading?: boolean;
  topicId: string;
  permissions?: ActivityPermission[];
  onAddContributors: VoidFunction;
};
export const RightSideBar: React.FC<Props> = ({
  // changeScopeLoading,
  topicId,
  permissions,
  onAddContributors,
}) => {
  const [linkLimit] = useState(LIMIT);
  const [filter, setFilter] = useState<string>('all');

  const [shareIsPublic, setShareIsPublic] = useState(false);
  const [shareIsWrite, setShareIsWrite] = useState(false);
  const [shareEmails, setShareEmails] = useState('');
  // const [openImportant, setOpenImportant] = useState(false);
  const [openAttachments, setOpenAttachments] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const { state: userState } = useContext(UserContext);

  const dispatch = useAppDispatch();
  const topicLink = useAppSelector((state: RootState) => state.topicLink);
  // const topicLink = _topicLink?.filter((li) => {
  //   if (
  //     [LinkedResourceType.GoogleDoc, LinkedResourceType.GoogleFolder].includes(
  //       li.type as LinkedResourceType
  //     )
  //   ) {
  //     return permissions?.find((p) => p.id === li.doc?.id)?.access;
  //   } else {
  //     return true;
  //   }
  // });

  // console.log(_topicLink);

  const { data: attachmentData, refetch } = useQuery<{
    topicLinks: TTopicBasics['links'];
  }>(GET_ATTACHMENT, {
    variables: { topicId },
    fetchPolicy: 'cache-first',
  });

  if (attachmentData?.topicLinks?.length) {
    dispatch(initializeTopicLink(attachmentData?.topicLinks));
  }

  useEffect(() => {
    if (permissions?.length) {
      if (!userState?.userHasGoogleAuth) {
        setShowNotification(true);
      }
    }
  }, [permissions?.length, userState?.userHasGoogleAuth]);

  const topic = useAppSelector((state: RootState) => state.topic);

  const [shareTopic, { loading }] = useMutation(SHARE);

  const renderRole = topic?.creatorId?.role
    ?.split(' ')
    .map((element) => {
      return element.charAt(0).toUpperCase() + element.substring(1);
    })
    .join(' ');

  const applyFilter = (link: any) => {
    if (link.type === 'Multiplayer') {
      return false;
    } else {
      if (filter === 'all') {
        return true;
      } else if (filter === ELinkLabel.IMPORTANT) {
        return link.important;
      } else {
        const { label } = getLinkInfo(link);

        return label === filter;
      }
    }
  };

  const applyImportantFilter = (link: any) => {
    if (filter === 'all') {
      return link.important;
    } else {
      const { label } = getLinkInfo(link);

      return label === filter && link.important;
    }
  };

  const onTopicShare = useCallback(() => {
    shareTopic({
      variables: {
        id: topic._id,
        scope: shareIsWrite ? ['topic:read', 'topic:write'] : ['topic:read'],
        type: shareIsPublic ? 'public' : 'restricted',
        collaboratorEmails: shareEmails.split(','),
      },
    }).then((res) => {
      if (res.data.shareTopic) {
        setShareIsPublic(false);
        setShareIsWrite(false);
        setShareEmails('');
      }
    });
  }, [shareEmails, shareIsPublic, shareIsWrite, shareTopic, topic._id]);

  return (
    <Right>
      {openAttachments && <div />}
      <Notification
        text="Please add your integrations from settings page to access all features."
        success
        show={showNotification}
        handleShow={setShowNotification}
      />
      <Box
        backgroundColor="primary/02"
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        borderRadius="l"
        width="100%"
        padding="s"
        marginBottom="xl"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          borderRadius="m"
          width="100%"
        >
          <Text.TextSmall2>Topic details</Text.TextSmall2>
          <Text.TextSmall2 color="text/light">Topic owner</Text.TextSmall2>
          <div
            className={css`
              width: 100%;
            `}
          >
            <ProfileIcon
              userId={topic?.creatorId?._id || ''}
              userName={displayUser(topic?.creatorId) || ''}
              profilePicture={topic?.creatorId?.profileUrl}
              noDisplay
              size="normal"
              style={{
                float: 'left',
                marginRight: theme.spacing.l,
              }}
            />
            <Text.Body marginTop="4xs" marginBottom="4xs">
              {displayUser(topic?.creatorId)}
            </Text.Body>
            <Text.TextSmall2
              color="text/light"
              marginTop="4xs"
              marginBottom="3xs"
            >
              {renderRole}
            </Text.TextSmall2>
          </div>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          borderRadius="m"
          width="100%"
        >
          <Text.TextSmall2 color="text/light">Contributors</Text.TextSmall2>
          <Box
            noPadding
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <AvatarList
              containerBackground={theme.colors.primary['primary/03']}
              size="small"
              users={
                topic?.collaborators?.map((user) => ({
                  userId: user._id || '',
                  userName: displayUser(user) || '',
                  profilePicture: user.profileUrl,
                })) || []
              }
            />

            {!topic.closed && (
              <div
                onClick={onAddContributors}
                className={css`
                  width: 24px;
                  height: 24px;
                  border-radius: 12px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  &:hover {
                    border: 1.5px dashed #b0bbd9;
                  }
                  background-color: ${theme.colors.primary['primary/03']};
                  cursor: pointer;
                `}
              >
                <PlusIcon
                  fill={theme.colors.text['text/blue/soft']}
                  style={{ width: '12px' }}
                />
              </div>
            )}
          </Box>
        </Box>

        {Boolean(
          topic?.allUpdatesWatchers?.length || topic?.summaryWatchers?.length
        ) && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            borderRadius="m"
            width="100%"
          >
            <Text.TextSmall2 color="text/light">Watchers</Text.TextSmall2>
            <Box
              noPadding
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <AvatarList
                containerBackground={theme.colors.primary['primary/03']}
                size="small"
                users={
                  unique(
                    (topic?.summaryWatchers || []).concat(
                      topic?.allUpdatesWatchers || []
                    ),
                    (a: IUser, b: IUser) => a._id === b._id
                  )?.map((user: IUser) => ({
                    userId: user._id || '',
                    userName: displayUser(user) || '',
                    profilePicture: user.profileUrl,
                  })) || []
                }
              />
            </Box>
          </Box>
        )}

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          borderRadius="m"
          width="100%"
        >
          <Text.TextSmall2 color="text/light">Momentum</Text.TextSmall2>
          <TopicMomentum momentum={topic.closed ? undefined : topic.momentum} />
        </Box>

        {!topic.closed && (
          <Box style={{ display: 'none' }}>
            <Text.BodySM>Manage access</Text.BodySM>
            {/* <table>
            {topic.shared?.map((acc, idx) => (
              <tr key={idx}>
                <td>{acc.type}</td>
                <td>{acc.scope.join(', ')}</td>
                <td>{acc.collaboratorEmails?.join(', ')}</td>
              </tr>
            ))}
          </table> */}
            <Switch
              label="Anyone in the internet"
              checked={shareIsPublic}
              onCheckedChange={setShareIsPublic}
            />
            <Switch
              label="Write access"
              checked={shareIsWrite}
              onCheckedChange={setShareIsWrite}
            />
            {!shareIsPublic && (
              <Input
                placeholder="User Emails"
                value={shareEmails}
                onChange={(e) => setShareEmails(e.target.value)}
              />
            )}
            <Button text="Share" onClick={onTopicShare} loading={loading} />
          </Box>
        )}
      </Box>

      <Accordion
        width="100%"
        backgroundColor="primary/02"
        marginBottom="xl"
        defaultValue="important"
        items={[
          {
            title: `Important`,
            value: 'important',
            length: topicLink?.filter((link) => link.important)?.length || 0,
            body: (
              <Box noPadding width="100%">
                {topicLink
                  ?.filter((link) => link.important)
                  ?.slice(0, linkLimit)
                  ?.map((link, idx) => {
                    if (!link.url) return <div />;
                    const { label, title, Icon } = getLinkInfo(link);
                    return (
                      <React.Fragment key={link.url}>
                        <AttachmentItem
                          linkId={link._id}
                          topicId={topic._id}
                          closed={topic.closed}
                          title={
                            link.doc?.title?.split('_').join(' ') ||
                            title ||
                            link.url!
                          }
                          label={label}
                          url={link.url || '#'}
                          groups={link.groups || []}
                          important={link.important}
                          refetch={refetch}
                          Icon={Icon}
                        />
                      </React.Fragment>
                    );
                  })}
                {topicLink?.filter((link) => link.important).length > 0 && (
                  <DropdownI.Root
                    width="100%"
                    triggerComponent={
                      <div
                        className={css`
                          border-top: 1px solid
                            ${theme.colors.accent['purple/light']};
                          width: 100%;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        `}
                      >
                        <Text.TextSmall2
                          color="text/light"
                          style={{ cursor: 'pointer' }}
                          onClick={() => setOpenAttachments(true)}
                        >
                          See all
                        </Text.TextSmall2>
                      </div>
                    }
                  >
                    <div
                      className={css`
                        max-width: 550px;
                        max-height: 700px;
                        overflow: auto;
                      `}
                    >
                      <div
                        className={css`
                          width: 100%;
                          height: 52px;
                          border-bottom: 1px solid
                            ${theme.colors.accent['purple/light']};
                          display: flex;
                          flex-direction: row;
                          justify-content: flex-start;
                          align-items: center;
                          margin-bottom: 20px;
                          padding-inline: 16px;
                        `}
                      >
                        <Text.TextSmall2 marginBottom="4xs" marginTop="4xs">
                          Important
                        </Text.TextSmall2>
                      </div>
                      <div
                        className={css`
                          width: 100%;
                          padding-inline: 16px;
                        `}
                      >
                        <Text.TextSmall2>Filter</Text.TextSmall2>
                        <div
                          className={css`
                            /* display: flex;
                                    flex-direction: row;
                                    justify-content: flex-start;
                                    align-items: center; */
                          `}
                        >
                          {LinkTypes.map((item) => (
                            <div
                              key={item.label}
                              className={css`
                                display: inline-block;
                                vertical-align: top;
                                margin-bottom: 10px;
                                cursor: pointer;
                              `}
                              onClick={() => setFilter(item.value)}
                            >
                              <div
                                className={css`
                                  display: flex;
                                  flex-direction: row;
                                  justify-content: flex-start;
                                  align-items: center;
                                  width: fit-content;
                                  height: 28px;
                                  border-radius: 14px;
                                  border: 1px solid
                                    ${theme.colors.accent['purple/light']};
                                  margin-right: 16px;
                                  padding: 5px 16px;
                                  background-color: ${filter === item.value
                                    ? theme.colors.primary['primary/04']
                                    : ''};
                                `}
                              >
                                <Text.TextSmall2
                                  marginBottom="4xs"
                                  marginTop="4xs"
                                >
                                  {item.label}
                                </Text.TextSmall2>
                              </div>
                            </div>
                          ))}
                        </div>
                        {topicLink
                          ?.filter(applyImportantFilter)
                          ?.map((link) => {
                            if (!link.url) return <div />;
                            const { label, title, Icon } = getLinkInfo(link);
                            return (
                              <React.Fragment key={link.url}>
                                <AttachmentItem
                                  linkId={link._id}
                                  topicId={topic._id}
                                  closed={topic.closed}
                                  title={
                                    link.doc?.title?.split('_').join(' ') ||
                                    title ||
                                    link.url!
                                  }
                                  label={label}
                                  url={link.url || '#'}
                                  groups={link.groups || []}
                                  important={link.important}
                                  refetch={refetch}
                                  Icon={Icon}
                                  hideMenu
                                />
                              </React.Fragment>
                            );
                          })}
                      </div>
                    </div>
                  </DropdownI.Root>
                )}
              </Box>
            ),
          },
        ]}
      />

      <Accordion
        width="100%"
        backgroundColor="primary/02"
        marginBottom="xl"
        defaultValue="attachments"
        items={[
          {
            title: `Attachments`,
            value: 'attachments',
            length:
              topicLink?.filter((link) => link.type !== 'Multiplayer')
                ?.length || 0,
            body: (
              <Box noPadding width="100%">
                {topicLink
                  ?.filter((link) => link.type !== 'Multiplayer')
                  ?.slice(0, linkLimit)
                  ?.map((link, idx) => {
                    if (!link.url) return <div />;
                    const { label, title, Icon } = getLinkInfo(link);
                    return (
                      <React.Fragment key={link.url}>
                        <AttachmentItem
                          linkId={link._id}
                          topicId={topic._id}
                          closed={topic.closed}
                          title={
                            link.doc?.title?.split('_').join(' ') ||
                            title ||
                            link.url!
                          }
                          label={label}
                          url={link.url || '#'}
                          groups={link.groups || []}
                          important={link.important}
                          refetch={refetch}
                          Icon={Icon}
                        />
                      </React.Fragment>
                    );
                  })}

                {topicLink?.filter((link) => link.type !== 'Multiplayer')
                  .length > 0 && (
                  <DropdownI.Root
                    width="100%"
                    triggerComponent={
                      <div
                        className={css`
                          border-top: 1px solid
                            ${theme.colors.accent['purple/light']};
                          width: 100%;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        `}
                      >
                        <Text.TextSmall2
                          color="text/light"
                          style={{ cursor: 'pointer' }}
                          onClick={() => setOpenAttachments(true)}
                        >
                          See all
                        </Text.TextSmall2>
                      </div>
                    }
                  >
                    <div
                      className={css`
                        max-width: 550px;
                        max-height: 700px;
                        overflow: auto;
                      `}
                    >
                      <div
                        className={css`
                          width: 100%;
                          height: 52px;
                          border-bottom: 1px solid
                            ${theme.colors.accent['purple/light']};
                          display: flex;
                          flex-direction: row;
                          justify-content: flex-start;
                          align-items: center;
                          margin-bottom: 20px;
                          padding-inline: 16px;
                        `}
                      >
                        <Text.TextSmall2 marginBottom="4xs" marginTop="4xs">
                          Important
                        </Text.TextSmall2>
                      </div>
                      <div
                        className={css`
                          width: 100%;
                          padding-inline: 16px;
                        `}
                      >
                        <Text.TextSmall2>Filter</Text.TextSmall2>
                        <div
                          className={css`
                            /* display: flex;
                                    flex-direction: row;
                                    justify-content: flex-start;
                                    align-items: center; */
                          `}
                        >
                          {LinkTypes.map((item) => (
                            <div
                              key={item.label}
                              className={css`
                                display: inline-block;
                                vertical-align: top;
                                margin-bottom: 10px;
                                cursor: pointer;
                              `}
                              onClick={() => setFilter(item.value)}
                            >
                              <div
                                className={css`
                                  display: flex;
                                  flex-direction: row;
                                  justify-content: flex-start;
                                  align-items: center;
                                  width: fit-content;
                                  height: 28px;
                                  border-radius: 14px;
                                  border: 1px solid
                                    ${theme.colors.accent['purple/light']};
                                  margin-right: 16px;
                                  padding: 5px 16px;
                                  background-color: ${filter === item.value
                                    ? theme.colors.primary['primary/04']
                                    : ''};
                                `}
                              >
                                <Text.TextSmall2
                                  marginBottom="4xs"
                                  marginTop="4xs"
                                >
                                  {item.label}
                                </Text.TextSmall2>
                              </div>
                            </div>
                          ))}
                        </div>
                        {topicLink?.filter(applyFilter)?.map((link) => {
                          if (!link.url) return <div />;
                          const { label, title, Icon } = getLinkInfo(link);
                          return (
                            <React.Fragment key={link.url}>
                              <AttachmentItem
                                linkId={link._id}
                                topicId={topic._id}
                                closed={topic.closed}
                                title={
                                  link.doc?.title?.split('_').join(' ') ||
                                  title ||
                                  link.url!
                                }
                                label={label}
                                url={link.url || '#'}
                                groups={link.groups || []}
                                important={link.important}
                                refetch={refetch}
                                Icon={Icon}
                                hideMenu
                              />
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </div>
                  </DropdownI.Root>
                )}
              </Box>
            ),
          },
        ]}
      />

      <Accordion
        width="100%"
        backgroundColor="primary/02"
        marginBottom="xl"
        defaultValue="relatedTopics"
        items={[
          {
            title: `Related topics`,
            value: 'relatedTopics',
            length:
              topicLink?.filter((link) => link.type === 'Multiplayer')
                ?.length || 0,
            body: (
              <Box noPadding width="100%">
                {topicLink
                  ?.filter((link) => link.type === 'Multiplayer')
                  ?.slice(0, linkLimit)
                  ?.map((link) => {
                    if (!link.url) return <div />;
                    return (
                      <React.Fragment key={link.url}>
                        <RelatedTopic
                          key={link.doc?.id}
                          topic={link.doc?.title || link.url || 'Topic'}
                          topicId={link.doc?.id!}
                          url={link.url!}
                        />
                      </React.Fragment>
                    );
                  })}
                {topicLink?.filter((link) => link.type === 'Multiplayer')
                  .length > 0 && (
                  <DropdownI.Root
                    width="100%"
                    triggerComponent={
                      <div
                        className={css`
                          border-top: 1px solid
                            ${theme.colors.accent['purple/light']};
                          width: 100%;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        `}
                      >
                        <Text.TextSmall2
                          color="text/light"
                          style={{ cursor: 'pointer' }}
                          onClick={() => setOpenAttachments(true)}
                        >
                          See all
                        </Text.TextSmall2>
                      </div>
                    }
                  >
                    <div
                      className={css`
                        max-width: 550px;
                        max-height: 700px;
                        overflow: auto;
                      `}
                    >
                      <div
                        className={css`
                          width: 100%;
                          height: 52px;
                          border-bottom: 1px solid
                            ${theme.colors.accent['purple/light']};
                          display: flex;
                          flex-direction: row;
                          justify-content: flex-start;
                          align-items: center;
                          margin-bottom: 20px;
                          padding-inline: 16px;
                        `}
                      >
                        <Text.TextSmall2 marginBottom="4xs" marginTop="4xs">
                          Related topics
                        </Text.TextSmall2>
                      </div>
                      <div
                        className={css`
                          width: 100%;
                          padding-inline: 16px;
                        `}
                      >
                        {topicLink
                          ?.filter((link) => link.type === 'Multiplayer')
                          ?.map((link) => {
                            if (!link.url) return <div />;
                            return (
                              <RelatedTopic
                                key={link.doc?.id}
                                topic={link.doc?.title || link.url || 'Topic'}
                                topicId={link.doc?.id!}
                                url={link.url!}
                              />
                            );
                          })}
                      </div>
                    </div>
                  </DropdownI.Root>
                )}
              </Box>
            ),
          },
        ]}
      />
    </Right>
  );
};

type RelatedTopicProps = {
  topic: string;
  topicId: string;
  url: string;
};
const RelatedTopic: React.FC<RelatedTopicProps> = (props) => {
  return (
    <Box
      noPadding
      display="flex"
      flexDirection="row"
      alignItems="center"
      width="100%"
      marginLeft="s"
    >
      <a
        href={props.url}
        target="_blank"
        rel="noreferrer"
        className={css`
          color: ${theme.colors.text['text/main']};
          text-decoration: none;
          margin-right: 10px;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          margin-bottom: 10px;
        `}
      >
        {props.topic}
      </a>
      <a
        href={props.url}
        target="_blank"
        rel="noreferrer"
        className={css`
          color: ${theme.colors.text['text/blue/soft']};
          text-decoration: none;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          margin-bottom: 10px;
        `}
      >
        {shortId(props.topicId)}
      </a>
    </Box>
  );
};

const EDIT_LINK = gql`
  mutation editLink($id: ID!, $title: String, $important: Boolean) {
    editLink(id: $id, title: $title, important: $important)
  }
`;

type AttachmentItemProps = {
  linkId: string;
  title: string;
  label: ELinkLabel;
  topicId: string;
  closed?: boolean;
  url: string;
  groups: string[];
  important?: boolean;
  Icon: React.FC<any>;
  hideMenu?: boolean;
  onMenuOpen?: VoidFunction;
  refetch: VoidFunction;
};
const AttachmentItem: React.FC<AttachmentItemProps> = (props) => {
  const [editMode, setEditMode] = useState(false);
  // const [stared, setStared] = useState(props.important || false);
  const [newTitle, setNewTitle] = useState(props.title);
  const [hover, setHover] = useState(false);

  const dispatch = useAppDispatch();

  const [editLink, { loading }] = useMutation(EDIT_LINK);

  const onEdit = useCallback(() => {
    pubsub.emit(ENotificationKey.SHOW_DROPDOWN, { value: false });
    setEditMode(!editMode);
  }, [editMode]);

  const onStared = useCallback(() => {
    setEditMode(false);
    dispatch(
      updateTopicLink({
        id: props.linkId,
        link: { important: !props.important },
      })
    );
    editLink({
      variables: {
        id: props.linkId,
        important: !props.important,
      },
    }).then((re) => {
      props.refetch();
      // setStared(!stared);
      // window.location.reload();
      // navigate(ERouteName.Workspace);
    });
  }, [dispatch, editLink, props]);

  const handleTitleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewTitle(e.target.value);
    },
    []
  );

  const handleChangeSubmit = useCallback(() => {
    editLink({
      variables: {
        id: props.linkId,
        title: newTitle || props.title,
      },
    }).then((re) => {
      props.refetch();
      // navigate(ERouteName.Workspace);
    });
    dispatch(
      updateTopicLink({
        id: props.linkId,
        link: { doc: { title: newTitle || props.title, url: props.url } },
      })
    );
    setEditMode(false);
  }, [editLink, props, newTitle, dispatch]);

  return (
    <div
      className={css`
        width: 100%;
        display: block;
        /* justify-content: space-between;
        align-items: center; */
        margin-bottom: 10px;
      `}
    >
      {editMode ? (
        <div
          className={css`
            width: 90%;
            display: inline-block;
            margin-bottom: 10px;
          `}
        >
          <div
            className={css`
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Input
              type="text"
              placeholder="Type new title here..."
              value={newTitle}
              onChange={handleTitleChange}
            />

            <Button
              loading={loading}
              text="Done"
              color="secondary"
              shape="rectangle"
              onClick={handleChangeSubmit}
            />
          </div>
        </div>
      ) : (
        <a
          href={props.url}
          target="_blank"
          rel="noreferrer"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          className={css`
            color: ${theme.colors.text['text/main']};
            font-size: ${theme.textVariants.body.fontSize}px;
            font-weight: ${theme.textVariants.body.fontWeight};
            line-height: ${theme.textVariants.body.lineHeight}px;
            text-decoration: none;
            width: 90%;
            display: inline-block;
          `}
        >
          <div
            className={css`
              color: ${theme.colors.text['text/main']};
              font-size: ${theme.textVariants.body.fontSize}px;
              font-weight: ${theme.textVariants.body.fontWeight};
              line-height: ${theme.textVariants.body.lineHeight}px;
              text-decoration: none;
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <div
              className={css`
                width: 100%;
                overflow: hidden;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
              `}
            >
              <span
                className={css`
                  width: 24px;
                  height: 24px;
                  border-radius: 12px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: ${theme.colors.primary['primary/03']};
                  margin-right: 8px;
                  border: ${props.important
                    ? '1px solid ' + theme.colors.accent['purple/main']
                    : 'none'};
                `}
              >
                {hover && props.important ? (
                  <TopicCreatedIcon
                    style={{ width: '12px', height: '12px' }}
                    fill={theme.colors.accent['purple/main']}
                  />
                ) : (
                  <props.Icon
                    style={{ width: '12px', height: '12px' }}
                    fill={theme.colors.text['text/blue/soft']}
                  />
                )}
              </span>
              <span
                className={css`
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  max-width: 70%;
                  overflow: hidden;
                `}
              >
                {newTitle || props.title}
              </span>
              {/* {props.important && (
                <TopicCreatedIcon
                  style={{ marginLeft: '8px' }}
                  fill={theme.colors.text['text/main']}
                />
              )} */}
            </div>

            {/* <span
              className={css`
                background-color: ${labelInfo[props.label]};
                border-radius: 4px;
                padding: 4px 8px 4px 8px;
                font-size: 13px;
              `}
            >
              {props.label.toUpperCase()}
            </span> */}
          </div>
        </a>
      )}
      {!props.closed && (
        <div
          className={css`
            display: inline-block;
            width: 10%;
          `}
        >
          {props.hideMenu ? (
            <DropdownI.Sub
              triggerComponent={
                <MoreHorizontalIcon
                  className={css`
                    fill: ${theme.colors.text['text/main']};
                    height: 16px;
                    transition: fill 0.15s;
                    width: 24px;
                  `}
                />
              }
            >
              <LinkMenu
                stared={props.important || false}
                groups={props.groups}
                linkId={props.linkId}
                topicId={props.topicId}
                onEdit={onEdit}
                onStared={onStared}
                noDropdown
              />
            </DropdownI.Sub>
          ) : (
            <LinkMenu
              stared={props.important || false}
              groups={props.groups}
              linkId={props.linkId}
              topicId={props.topicId}
              onEdit={onEdit}
              onStared={onStared}
            />
          )}
        </div>
      )}
    </div>
  );
};
