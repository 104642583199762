import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IUser } from 'model';
import { Activity } from 'pages/Topic/types';

export interface IDoc {
  id: string;
  url: string;
  created?: string;
  type: string;
  lastUpdated?: string;
  lastUpdatedBy?: string;
  thumbnail?: string;
  title?: string;
}

export type ITopic = {
  collaborators: IUser[];
  created: number;
  creatorId: IUser;
  _id: string;
  name: string;
  description?: string;
  public: boolean;
  closed: boolean;
};

type IUpdate = {
  data: Partial<Omit<Activity, '_id' | 'topicId' | 'created' | 'userId'>>;
  id: string;
};

type DeleteComment = {
  id: string;
  deletedBy: IUser;
};

const initialState = [] as Activity[];

export const topicActivitySlice = createSlice({
  name: 'topicActivityLink',
  initialState,
  reducers: {
    initializeTopicActivity: (state, action: PayloadAction<Activity[]>) => {
      return action.payload;
    },
    updateTopicActivity: (state, action: PayloadAction<IUpdate>) => {
      const index = state.findIndex((act) => act._id === action.payload.id);
      if (index < 0) return state;
      // let value = state;
      // state[index] = { ...state[index], ...action.payload.data };
      // state.splice(index, 1, { ...state[index], ...action.payload.data });

      // if (!action.payload.data.data) return state;
      state[index] = {
        ...state[index],
        ...action.payload.data!,
      };
      // const values = Object(state[index]);
      // state[index].data = action.payload.data.data;
      // delete values.data;
      return state;
    },
    updateOrAddTopicActivity: (state, action: PayloadAction<Activity>) => {
      const index = state.findIndex((act) => act._id === action.payload._id);
      if (index < 0) {
        return [action.payload, ...state].sort((a, b) => b.created - a.created);
      }

      state[index] = {
        ...state[index],
        ...action.payload!,
      };
      return state;
    },
    addNewTopicActivity: (state, action: PayloadAction<Activity[]>) => {
      const newItems = action.payload.filter(
        (item) => state.findIndex((el) => el._id === item._id) < 0
      );
      return [...newItems, ...state].sort((a, b) => b.created - a.created);
    },
    addMoreTopicActivity: (state, action: PayloadAction<Activity[]>) => {
      return [...state, ...action.payload];
    },
    removeTopicActivity: (state, action: PayloadAction<string>) => {
      return state.filter((act) => act._id !== action.payload);
    },
    removeFakeTopicActivity: (state) => {
      return state.filter((act) => act.comment !== 'fake');
    },
    deleteCommentTopicActivity: (
      state,
      action: PayloadAction<DeleteComment>
    ) => {
      const index = state.findIndex((act) => act._id === action.payload.id);
      if (index < 0) return state;
      state[index] = {
        ...state[index],
        deleted: new Date().getTime(),
        deletedBy: action.payload.deletedBy,
      };
      return state;
    },
    removeAllTopicActivity: (state) => {
      return [];
    },
  },
});

export const {
  initializeTopicActivity,
  updateTopicActivity,
  updateOrAddTopicActivity,
  addNewTopicActivity,
  addMoreTopicActivity,
  removeTopicActivity,
  removeFakeTopicActivity,
  deleteCommentTopicActivity,
  removeAllTopicActivity,
} = topicActivitySlice.actions;

export const topicActivityReducer = topicActivitySlice.reducer;
