import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { Text } from 'components';
import React, { useMemo } from 'react';
import { theme } from 'theme';

const RootContainer = styled.div`
  width: 100%;
  border: 1px solid #2b2841;
  border-radius: 16px;
  padding: 32px;
`;
type RootProps = {
  children: React.ReactNode;
};
const Root: React.FC<RootProps> = ({ children }) => {
  return <RootContainer>{children}</RootContainer>;
};

const ItemBody = styled.div`
  width: 100%;
  margin-top: 8px;
  padding-left: 31px;
`;

const Container = styled.div`
  margin-bottom: 20px;
  padding-left: 8px;
`;

type ItemProps = {
  children?: React.ReactNode;
  title: string;
  subTitle?: string;
  Icon: JSX.Element;
  id?: string;
  customRef?: React.LegacyRef<HTMLDivElement>;
  noFormat?: boolean;
  onClick?: VoidFunction;
};
const Item: React.FC<ItemProps> = ({
  children,
  title,
  subTitle,
  Icon,
  id,
  customRef,
  noFormat,
  onClick,
}) => {
  const renderTitle = useMemo(() => {
    return (
      <div
        className={css`
          color: ${theme.colors.text['text/light']} !important;
          font-size: ${theme.textVariants['body-sm']};
          span {
            color: ${theme.colors.text['text/100%']};
          }
          a {
            all: unset;
            cursor: pointer;
          }
        `}
        dangerouslySetInnerHTML={{
          __html: noFormat
            ? title
            : `${title.split('**').join('</span>').split('*').join('<span>')}`,
        }}
      />
    );
  }, [noFormat, title]);

  return (
    <Container
      id={id}
      ref={customRef}
      onClick={onClick}
      style={{ cursor: onClick ? 'pointer' : '' }}
    >
      <div
        className={css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}
      >
        <div
          className={css`
            background-color: #2b2841;
            width: 24px;
            height: 24px;
            border-radius: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 8px;
          `}
        >
          {Icon}
        </div>

        <div>{renderTitle}</div>

        {subTitle && (
          <Text.TextSmall2
            color="text/50%"
            marginBottom="4xs"
            marginTop="3xs"
            style={{ marginLeft: '8px' }}
          >
            {subTitle}
          </Text.TextSmall2>
        )}
      </div>
      {children && <ItemBody>{children}</ItemBody>}
    </Container>
  );
};

export const FeedHolder = {
  Root,
  Item,
};
