import { useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/css';
import { Box, Text } from 'components';
import {
  GithubLogoIcon,
  LinearIcon,
  MicrosoftLogo,
  NotionLogo,
  PuzzlePieceIcon,
  SlackLogo,
} from 'components/Icons';
import { ERouteName } from 'pages';
import { DONE_MESSAGE } from 'pages/Done';
import React, { useCallback, useEffect } from 'react';
import { theme } from 'theme';
import { GOOGLE_AUTH, IS_SLACK_INSTALLED, REMOVE_INTEGRATION } from './gql';
import { IntegrationItem, Separator } from './IntegrationItem';

let timer: NodeJS.Timer;

type GoogleResult = {
  slackOAuthUrl: string;
  zoomOAuthUrl: string;
  notionOAuthUrl: string;
  microsoftOAuthUrl: string;
  linearOAuthUrl: string;
  githubOAuthUrl: string;
  // isSlackBotInstalled?: boolean;
  me: {
    _id: string;
    userHasSlackAuth?: boolean;
    userHasZoomAuth?: boolean;
    userHasNotionAuth?: boolean;
    userHasMicrosoftAuth?: boolean;
    userHasLinearAuth?: boolean;
    userHasGithubAuth?: boolean;
    businessEmail?: string;
  };
};

export const Integrations: React.FC = () => {
  const { data: authData, refetch: authRefetch } = useQuery<GoogleResult>(
    GOOGLE_AUTH,
    {
      variables: {
        redirect: process.env.REACT_APP_WEB_URL + ERouteName.Done,
        // githubRedirect: `${process.env.REACT_APP_WEB_URL}${ERouteName.Done}?github=true`,
      },
      fetchPolicy: 'network-only',
      pollInterval: 1000, // TO Remove later
    }
  );

  const [removeIntegration] = useMutation(REMOVE_INTEGRATION);

  const {
    data: slackInstalldata,
    loading: slackLoading,
    refetch: refetchSlackInstall,
  } = useQuery<{
    isSlackInstalled: boolean;
  }>(IS_SLACK_INSTALLED);

  useEffect(() => {
    // !Temporary sollution
    timer = setInterval(() => {
      refetchSlackInstall().then();
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [refetchSlackInstall]);

  const auth = useCallback(
    (url: string, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      // We want to try to position the popup window in such a way that
      // it appears over the button that opened it.
      const buttonPosition = e.currentTarget.getBoundingClientRect();
      const popupHeight = 690;
      const popupWidth = 420;
      const windowPos = [window.screenX, window.screenY];
      const buttonCenter = [
        buttonPosition.left + buttonPosition.width / 2,
        buttonPosition.top + buttonPosition.height / 2,
      ];
      const popupPos = [
        buttonCenter[0] - popupWidth / 2,
        buttonCenter[1] - popupHeight / 2,
      ];
      const adjustedPopupPos = [
        popupPos[0] + windowPos[0],
        popupPos[1] + windowPos[1],
      ];

      const target = window.open(
        url,
        'googleConnect',
        `height=${popupHeight},width=${popupWidth},left=${adjustedPopupPos[0]},top=${adjustedPopupPos[1]},popup=yes`
      );

      // We may or may not be able to open the oauth flow in a popup
      // window. If we _were_ able to, then wait for a postMessage
      // indicating that oauth is complete, then close the window. In the
      // case where we weren't, just redirect to the oauth url.
      if (target && typeof window !== 'undefined') {
        window.addEventListener('message', async (ev) => {
          if (ev.data === DONE_MESSAGE) {
            await authRefetch();
            await refetchSlackInstall();
            target.close();
          }
        });
        window.addEventListener('load', async (ev) => {
          await authRefetch();
          await refetchSlackInstall();
          target.close();
        });
      } else {
        // eslint-disable-next-line no-restricted-globals
        let a = document.createElement('a');
        a.target = '_blank';
        a.href = url;
        a.click();
      }
    },
    [authRefetch, refetchSlackInstall]
  );

  const onSlackAuth = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      auth(authData?.slackOAuthUrl!, e);
    },
    [auth, authData?.slackOAuthUrl]
  );

  const onNotionAuth = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      auth(authData?.notionOAuthUrl!, e);
    },
    [auth, authData?.notionOAuthUrl]
  );

  const onLinearAuth = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      auth(authData?.linearOAuthUrl!, e);
    },
    [auth, authData?.linearOAuthUrl]
  );

  const onGithubAuth = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      auth(authData?.githubOAuthUrl!, e);
    },
    [auth, authData?.githubOAuthUrl]
  );

  const onMicrosoftAuth = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      auth(authData?.microsoftOAuthUrl!, e);
    },
    [auth, authData?.microsoftOAuthUrl]
  );

  const onRemoveAuth = useCallback(
    (type: 'google' | 'slack' | 'notion' | 'microsoft' | 'linear' | 'github') =>
      () => {
        removeIntegration({ variables: { type } }).then(async (res) => {
          await authRefetch();
        });
      },
    [authRefetch, removeIntegration]
  );

  return (
    <div
      className={css`
        width: 80%;
      `}
    >
      <Text.H2 marginBottom="l">Connected apps</Text.H2>
      <IntegrationItem
        title="Slack"
        description="Connect with Slack. This allows us to match your identity on Slack with your Multiplayer account."
        Icon={SlackLogo}
        completed={!!authData?.me.userHasSlackAuth}
        onClick={onSlackAuth}
        onRemove={onRemoveAuth('slack')}
      />
      <Separator />

      <IntegrationItem
        title="Notion"
        description="Connect with Notion app"
        Icon={NotionLogo}
        completed={!!authData?.me.userHasNotionAuth}
        onClick={onNotionAuth}
        onRemove={onRemoveAuth('notion')}
      />
      <Separator />

      <IntegrationItem
        title="Microsoft"
        description="Connect with your Microsoft services"
        Icon={MicrosoftLogo}
        completed={!!authData?.me.userHasMicrosoftAuth}
        onClick={onMicrosoftAuth}
        onRemove={onRemoveAuth('microsoft')}
        helperText="Note, currently works only with Sharepoint files."
      />

      <Separator />

      <IntegrationItem
        title="Linear"
        description="Connect with Linear app"
        Icon={LinearIcon}
        completed={!!authData?.me.userHasLinearAuth}
        onClick={onLinearAuth}
        onRemove={onRemoveAuth('linear')}
      />

      <Separator />

      <IntegrationItem
        title="Github"
        description="Connect with Github app"
        Icon={GithubLogoIcon}
        completed={!!authData?.me.userHasGithubAuth}
        onClick={onGithubAuth}
        onRemove={onRemoveAuth('github')}
      />

      {Boolean(
        !slackLoading &&
          !slackInstalldata?.isSlackInstalled &&
          authData?.me.userHasSlackAuth
      ) && (
        <Box
          width="100%"
          height="110px"
          backgroundColor="primary/02"
          borderRadius="l"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginTop="3xl"
        >
          <Box
            noPadding
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            width="70%"
          >
            <PuzzlePieceIcon
              fill={theme.colors.text['text/link']}
              style={{ width: '48px', height: '48px', marginRight: '32px' }}
            />
            <Box noPadding>
              <Text.Body marginBottom="4xs">
                Bot for Slack installation
              </Text.Body>
              <Text.TextSmall1 color="text/75%" marginTop="4xs">
                Use this button to install the bot for Multiplayer into your
                workspace. This action needs to be performed only once (usually
                by your admins). This installation allows you to receive
                notifications from Multiplayer and use message shortcuts. You
                can also install this from the Slack App Directory.
              </Text.TextSmall1>
            </Box>
          </Box>

          <a
            href={process.env.REACT_APP_SLACK_URL}
            target="_blank"
            rel="noreferrer"
            className={css`
              text-decoration: none;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              height: 44px;
              border-radius: 8px;
              background-color: ${theme.colors.primary['primary/03']};
              padding-inline: 16px;
            `}
          >
            <SlackLogo
              style={{ width: '16px', height: '16px', marginRight: '8px' }}
            />
            <Text.Body marginBottom="4xs" marginTop="4xs">
              Add to Slack
            </Text.Body>
          </a>
        </Box>
      )}

      {!!authData?.me.userHasGithubAuth && (
        <Box
          width="100%"
          height="110px"
          backgroundColor="primary/02"
          borderRadius="l"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginTop="3xl"
        >
          <Box
            noPadding
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            width="70%"
          >
            <PuzzlePieceIcon
              fill={theme.colors.text['text/link']}
              style={{ width: '48px', height: '48px', marginRight: '32px' }}
            />
            <Box noPadding>
              <Text.Body marginBottom="4xs">Github app installation</Text.Body>
              <Text.TextSmall1 color="text/75%" marginTop="4xs">
                Use this button to install Multiplayer Github app in your
                organization (This is an important step to integrate with
                Github).
              </Text.TextSmall1>
            </Box>
          </Box>

          <a
            href={`https://github.com/apps/multiplayer-${
              process.env.REACT_APP_ENV === 'prod' ? 'github' : 'dev'
            }`}
            target="_blank"
            rel="noreferrer"
            className={css`
              text-decoration: none;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              height: 44px;
              border-radius: 8px;
              background-color: ${theme.colors.primary['primary/03']};
              padding-inline: 16px;
            `}
          >
            <GithubLogoIcon
              style={{ width: '16px', height: '16px', marginRight: '8px' }}
            />
            <Text.Body marginBottom="4xs" marginTop="4xs">
              Install on Github
            </Text.Body>
          </a>
        </Box>
      )}
    </div>
  );
};
