import { Box, Dialog, Text } from 'components';

interface Props {
  open?: boolean;
  message: string;
  onOpenChange?(open: boolean): void;
}

export function InfoDialog(props: Props) {
  return (
    <Dialog
      additionalContent={
        <Box noPadding>
          <Text.TextSmall1 color="text/75%" marginTop="2xs" marginBottom="4xs">
            {props.message}
          </Text.TextSmall1>
        </Box>
      }
      open={props.open}
      onOpenChange={props.onOpenChange}
    />
  );
}
