import { gql, useMutation } from '@apollo/client';
import { Box, Button, Dialog, Input, Notification } from 'components';
import { UserContext } from 'context';
import { useCallback, useContext, useState } from 'react';

const INVITE_USER = gql`
  mutation InviteToMultiplayer($emails: String!, $workspaceId: String!) {
    inviteToMultiplayer(emails: $emails, workspaceId: $workspaceId)
  }
`;

type InviteDialogProps = {
  open?: boolean;
  onOpenChange?(open: boolean): void;
};
export function InviteDialog(props: InviteDialogProps) {
  const [emails, setEmails] = useState('');
  const [showNotification, setShowNotification] = useState(false);

  const { state: userState } = useContext(UserContext);

  const [inviteUser, { loading }] = useMutation(INVITE_USER);

  const handleSubmit = useCallback(() => {
    inviteUser({
      variables: {
        emails,
        workspaceId: userState?.workspace,
      },
    }).then((res) => {
      setShowNotification(true);
      props.onOpenChange?.(false);
    });
  }, [emails, inviteUser, props, userState?.workspace]);
  return (
    <>
      <Notification
        success
        show={showNotification}
        handleShow={setShowNotification}
      />
      <Dialog
        noRadius
        additionalContent={
          <Box width="500px">
            <Input
              placeholder="Enter colleague emails"
              value={emails}
              onChange={(e) => setEmails(e.target.value)}
            />

            <Button
              text="Send invitation"
              style={{ marginTop: '16px' }}
              onClick={handleSubmit}
              loading={loading}
            />
          </Box>
        }
        open={props.open}
        onOpenChange={props.onOpenChange}
      />
    </>
  );
}
