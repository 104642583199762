import { css } from '@emotion/css';
import { Box } from 'components';
import { GoogleCommentModel } from 'model';
import React from 'react';
import { theme } from 'theme';

type Props = {
  focus?: boolean;
  doc: GoogleCommentModel;
};
export const GoogleCommentBox: React.FC<Props> = ({ focus, doc }) => {
  return (
    <Box
      backgroundColor="primary/02"
      padding="s"
      display="flex"
      flexDirection="row"
      borderRadius="m"
      width="100%"
      className={css`
        cursor: pointer;
        border: 1px solid
          ${focus ? theme.colors.accent['blue/main'] : 'transparent'} !important;
        transition: border-color 0.15s;
        &:hover {
          border-color: ${theme.colors.accent['blue/main']} !important;
        }
      `}
    >
      {/* <Box
          backgroundColor="primary/inactive"
          noPadding
          width="70px"
          height="70px"
        >
          <img src={doc?.thumbnail} alt="" width="100%" height="100%" />
        </Box> */}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        // height="70px"
        padding="m"
      >
        {doc.quotedFileContent && (
          <div
            className={css`
              /* background-color: #fff; */
              width: 100%;
              padding: 2px 0px;
              margin-bottom: 5px;
              color: ${theme.colors.text['text/light']};
            `}
          >
            <div
              dangerouslySetInnerHTML={{
                __html:
                  doc.quotedFileContent?.split('\n')?.join('<br />') || '',
              }}
            />
          </div>
        )}
        <div
          className={css`
            a {
              color: ${theme.colors.text['text/link']};
            }
            span {
              color: ${theme.colors.text['text/light']};
              font-style: italic;
            }
          `}
        >
          <div dangerouslySetInnerHTML={{ __html: doc.content || '' }} />
        </div>
        {/* {doc.resolved && (
          <Text.BodySM color="text/pink" marginBottom="4xs">
            Resolved
          </Text.BodySM>
        )} */}
      </Box>
    </Box>
  );
};
