import React, { useEffect } from 'react';
import { Page, Text } from 'components';
import { css } from '@emotion/css';
import { theme } from 'theme';
import { ERouteName } from 'pages';
import { Link, Outlet, useLocation } from 'react-router-dom';
import {
  ChecksIcon,
  MegaPhoneSimpleIcon,
  SpinnerGapIcon,
} from 'components/Icons';
import { useQuery } from '@apollo/client';
import pubsub from 'sweet-pubsub';
import { UNREAD_NOTIFICATIONS } from './gql';
import { ENotificationKey } from 'shared';

export const Notification = () => {
  const { data, refetch } = useQuery<{ unreadNotificationCount: number }>(
    UNREAD_NOTIFICATIONS,
    { fetchPolicy: 'network-only' }
  );
  const menu = [
    {
      label: 'Inbox',
      id: '1',
      link: ERouteName.NotificationsInbox,
      Icon: MegaPhoneSimpleIcon,
      hasUnread: true,
    },
    {
      label: 'To-do',
      id: '2',
      link: ERouteName.NotificationsTodo,
      Icon: SpinnerGapIcon,
    },
    {
      label: 'Done',
      id: '3',
      link: ERouteName.NotificationsDone,
      Icon: ChecksIcon,
    },
  ];

  const currentPage = useLocation();

  useEffect(() => {
    const callback = (id: string) => {
      refetch().then();
    };
    pubsub.on(ENotificationKey.NOTIFICATION_READ, callback);
    return () => {
      pubsub.off(ENotificationKey.NOTIFICATION_READ, callback);
    };
  }, [refetch]);

  return (
    <Page title="Home | Multiplayer">
      <div
        className={css`
          width: 100%;
          display: grid;
          grid-template-columns: 200px auto;
          margin-top: 30px;
        `}
      >
        <div
          className={css`
            width: 100%;
          `}
        >
          <Text.TextSmall1 color="text/100%">MAIN</Text.TextSmall1>
          {menu.map((item) => (
            <Link
              key={item.id}
              to={item.link}
              style={{
                textDecoration: 'none',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingRight: '16px',
              }}
            >
              <div
                onClick={() => {
                  refetch().then();
                }}
                className={css`
                  border-left: 1px solid
                    ${currentPage.pathname === item.link
                      ? theme.colors.accent['purple/main']
                      : 'transparent'};
                  padding-inline: 8px;
                  height: 32px;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  cursor: pointer;
                  margin-bottom: 16px;
                `}
              >
                <item.Icon
                  fill={
                    currentPage.pathname === item.link
                      ? theme.colors.text['text/100%']
                      : theme.colors.text['text/50%']
                  }
                  style={{
                    width: '16px',
                    height: '16px',
                    marginRight: '8px',
                  }}
                />
                <Text.Body
                  color={
                    currentPage.pathname === item.link
                      ? 'text/100%'
                      : 'text/50%'
                  }
                >
                  {item.label}
                </Text.Body>

                {Boolean(
                  item.hasUnread &&
                    data?.unreadNotificationCount &&
                    data?.unreadNotificationCount > 0
                ) && (
                  <div
                    className={css`
                      width: 18px;
                      height: 18px;
                      border-radius: 9px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      color: ${theme.colors.text['text/100%']};
                      background-color: ${theme.colors.accent['red/main']};
                      font-size: 10px;
                      margin-left: 80px;
                      /* margin-top: -10px; */
                    `}
                  >
                    {data?.unreadNotificationCount || 2}
                  </div>
                )}
              </div>
            </Link>
          ))}
        </div>
        <div
          className={css`
            width: 100%;
            padding-left: 50px;
            margin-top: 10px;
          `}
        >
          <Outlet />
        </div>
      </div>
    </Page>
  );
};
