import { Box, Text } from 'components';
import { HeartPixelIcon } from 'components/Icons';
import React from 'react';

type Props = {
  text: string;
};
export function NotificationEmptyState(props: Props) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="80vh"
      width="60%"
    >
      <Box
        noPadding
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        marginBottom="2xl"
      >
        <HeartPixelIcon style={{ marginRight: '28px' }} />
        <HeartPixelIcon style={{ marginRight: '28px' }} />
        <HeartPixelIcon />
      </Box>
      <Text.H2>Seems like there’s no activity here...yet.</Text.H2>
      <Text.Body style={{ textAlign: 'center' }}>{props.text}</Text.Body>
    </Box>
  );
}
