import { theme } from 'theme';

/* eslint-disable no-unused-vars */
export * from './user';
export * from './route';
export * from './github';

export enum EGraphQlErrorCode {
  GRAPHQL_PARSE_FAILED = 'GRAPHQL_PARSE_FAILED',
  GRAPHQL_VALIDATION_FAILED = 'GRAPHQL_VALIDATION_FAILED',
  BAD_USER_INPUT = 'BAD_USER_INPUT',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  FORBIDDEN = 'FORBIDDEN',
  PERSISTED_QUERY_NOT_FOUND = 'PERSISTED_QUERY_NOT_FOUND',
  PERSISTED_QUERY_NOT_SUPPORTED = 'PERSISTED_QUERY_NOT_SUPPORTED',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
}

export enum EScope {
  TOPIC_READ = 'topic:read',
  TOPIC_WRITE = 'topic:write',
  TOPIC_ADMIN = 'topic:admin',
  TOPIC_CREATE = 'topic:create',
}

export enum EActivityType {
  SLACK = 'slack',
  GOOGLE = 'google',
  GOOGLE_COLAB = 'googleColab',
  GOOGLE_COMMENT = 'googleComment',
  COMMENT = 'comment',
  REPLY = 'reply',
  UNKNOWN = 'unknown',
  DELETED = 'deleted',
  TOPIC = 'topic',
  NOTION = 'notion',
  LINEAR_ISSUE = 'linearIssue',
  LINEAR_ISSUE_COMMENT = 'linearIssueComment',
  MICROSOFT = 'microsoft',
  GITHUB = 'github',
}

export const labelColors = {
  Closed: theme.colors.primary['primary/03'],
  Active: theme.colors.accent['green/main'],
  Dormant: theme.colors.accent['red/main'],
};

export type TutorialProgress = {
  integrations: boolean;
  invitation: boolean;
  profile: boolean;
  tutorial: boolean;
  userOnboarded: boolean;
};

export enum EMomentumType {
  HIGHER = 'Higher',
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low',
  DORMANT = 'Dormant',
}

export type GoogleCommentModel = {
  id: string;
  modifiedDate: number;
  url?: string;
  lastVersionUrl: string;
  // thumbnail?: string;
  title?: string;
  lastModifyingUser: string;
  content: string | null | undefined;
  quotedFileContent: string | undefined;
  resolved: boolean | null | undefined;
  // replies:
  //   | {
  //       id: string;
  //       modifiedDate: number;
  //       lastModifyingUser: string;
  //       htmlContent: string | null | undefined;
  //     }[]
  //   | undefined;
};

export enum GoogleDocType {
  // eslint-disable-next-line no-unused-vars
  Document = 'Document',
  // eslint-disable-next-line no-unused-vars
  Presentation = 'Presentation',
  // eslint-disable-next-line no-unused-vars
  Sheets = 'Sheets',
  Colab = 'Colab',
  Folder = 'Folder',
}
