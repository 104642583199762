import { css } from '@emotion/css';
import React from 'react';
import { theme } from 'theme';

export type BadgeProps = {
  Icon: JSX.Element;
  className?: string;
  helper?: boolean;
};
export const Badge: React.FC<BadgeProps> = ({ Icon, helper, className }) => {
  return (
    <div className={className}>
      <div
        className={css`
          border: 2px solid ${theme.colors.accent['purple/light']};
          width: 40px;
          height: 40px;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        `}
      >
        {Icon}
        {helper && (
          <div
            className={css`
              width: 6px;
              height: 6px;
              border-radius: 3px;
              background-color: ${theme.colors.accent['red/main']};
              position: absolute;
              top: 5px;
              right: -1px;
            `}
          />
        )}
      </div>
    </div>
  );
};
