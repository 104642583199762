import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import './index.css';
import { Chart, ArcElement, Tooltip } from 'chart.js';
import { gql, useQuery } from '@apollo/client';
import { Text } from 'components/Text';
import { theme } from 'theme';
// import 'chart.js/auto';

Chart.register(ArcElement, Tooltip);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  cutout: '85%',
  spacing: 16, // Adjust this to add spacing between segments
  elements: {
    arc: {
      borderWidth: 0, // Remove borders between segments
      borderRadius: 20, // Add border-radius to each segment
    },
  },
  //   tooltips: {
  //     enabled: false,
  //   },
  plugins: {
    tooltip: {
      callbacks: {
        title: (xDatapoint: any) => {
          return xDatapoint.label;
        },
        label: (yDatapoint: any) => {
          return ` ${yDatapoint.formattedValue}%`;
        },
      },
    },
  },
  //   tooltips: {
  //     enabled: false,
  //     custom: function (tooltipModel: any) {
  //       // Create a custom tooltip element
  //       let tooltipEl = document.getElementById('custom-tooltip');

  //       // If the tooltip element doesn't exist, create it
  //       if (!tooltipEl) {
  //         tooltipEl = document.createElement('div');
  //         tooltipEl.id = 'custom-tooltip';
  //         tooltipEl.classList.add('custom-tooltip');
  //         document.body.appendChild(tooltipEl);
  //       }

  //       // If there's no tooltip, hide the custom tooltip
  //       if (tooltipModel.opacity === 0) {
  //         tooltipEl.style.opacity = '0';
  //         return;
  //       }

  //       // Set the tooltip content and position
  //       tooltipEl.innerHTML = tooltipModel.body[0].lines[0];
  //       tooltipEl.style.opacity = '1';
  //       tooltipEl.style.left = tooltipModel.caretX + 'px';
  //       tooltipEl.style.top = tooltipModel.caretY + 'px';
  //     },
  //   },
  animation: {
    animateRotate: true,
    animateScale: false,
  },
};

const GET_DATA = gql`
  query UserActivityRepartition($userId: ID!, $range: String) {
    userActivityRepartition(userId: $userId, range: $range) {
      id
      percentage
      topic
    }
  }
`;

type Data = {
  userActivityRepartition: {
    id: string;
    percentage: number;
    topic: string;
  }[];
};

type Props = {
  userId: string;
  range?: 'day' | 'week' | 'month';
};
export const DonutChart: React.FC<Props> = ({ userId, range = 'month' }) => {
  const { data: queryResult, loading } = useQuery<Data>(GET_DATA, {
    variables: {
      userId,
      range,
    },
    fetchPolicy: 'network-only',
  });
  return loading ? (
    <Text.Body>Loading...</Text.Body>
  ) : queryResult?.userActivityRepartition &&
    queryResult?.userActivityRepartition.length > 0 ? (
    <Doughnut
      data={{
        labels:
          queryResult?.userActivityRepartition?.map((item) => item.topic) || [],
        datasets: [
          {
            data:
              queryResult?.userActivityRepartition.map(
                (item) => item.percentage
              ) || [],
            backgroundColor: [
              '#3CAF88',
              '#FA4D35',
              '#FADD1C',
              '#6A53FE',
              '#E6B5CA',
              '#453E69',
              '#FFA220',
              '#FFFFFF',
              '#406AFF',
              '#7EC8E8',
              '#95EDF9',
            ],
            hoverBackgroundColor: [
              '#3CAF88',
              '#FA4D35',
              '#FADD1C',
              '#6A53FE',
              '#E6B5CA',
              '#453E69',
              '#FFA220',
              '#FFFFFF',
              '#406AFF',
              '#7EC8E8',
              '#95EDF9',
            ],
            borderWidth: 0,
          },
        ],
      }}
      options={{
        ...options,
        plugins: {
          tooltip: {
            enabled: false,
            external: function (context) {
              // Tooltip Element
              let tooltipEl = document.getElementById('chartjs-tooltip');

              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML =
                  '<div id="chartjs-custom-text-body" style="display: flex; flex-direction: row; justify-content: center; align-items: center;"></div>';
                document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              const tooltipModel = context.tooltip;
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = '0';
                return;
              }

              // Set caret Position
              tooltipEl.classList.remove('above', 'below', 'no-transform');
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add('no-transform');
              }

              function getBody(bodyItem: {
                before: string[];
                lines: string[];
                after: string[];
              }) {
                return bodyItem.lines;
              }

              // Set Text
              if (tooltipModel.body) {
                const titleLines = tooltipModel.title || [];
                const bodyLines = tooltipModel.body.map(getBody);

                let innerHtml = '';

                titleLines.forEach(function (title) {
                  innerHtml +=
                    '<div style="font-size: 14px; max-width: 150px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">' +
                    title +
                    '</div>';
                });
                // innerHtml += '</thead><tbody>';

                bodyLines.forEach(function (body, i) {
                  //   const colors = tooltipModel.labelColors[i];
                  let style = 'color: ' + theme.colors.text['Text/Secondary'];
                  //   style += '; border-color:' + colors.borderColor;
                  style += '; font-size: 14px';
                  style += '; margin-left: 8px';
                  style +=
                    '; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;';
                  innerHtml += '<div style="' + style + '">' + body + '%</div>';
                });
                // innerHtml += '</tbody>';

                //   let tableRoot = tooltipEl.querySelector('p');
                let tableRoot = document.getElementById(
                  'chartjs-custom-text-body'
                );
                tableRoot!.innerHTML = innerHtml;
              }

              const position = context.chart.canvas.getBoundingClientRect();

              // Display, position, and set styles for font
              tooltipEl.style.opacity = '1';
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.left =
                position.left + window.pageXOffset + tooltipModel.caretX + 'px';
              tooltipEl.style.top =
                position.top + window.pageYOffset + tooltipModel.caretY + 'px';
              tooltipEl.style.padding = '10px';
              tooltipEl.style.pointerEvents = 'none';
              tooltipEl.style.backgroundColor =
                theme.colors.primary['primary/03'];
              //   tooltipEl.style.maxWidth = '143px';
              tooltipEl.style.height = '40px';
              tooltipEl.style.borderRadius = '8px';
              tooltipEl.style.boxShadow = '0px 4px 8px rgba(0, 0, 0, 0.1)';
              tooltipEl.style.color = theme.colors.text['text/body'];
              tooltipEl.style.fontSize = '14px';
            },
          },
        },
        onClick: function (e, item) {
          if (item.length > 0) {
            const index = item[0].index;
            const topic = queryResult?.userActivityRepartition[index].id;
            if (topic) {
              window.location.href = `/topic/${topic}`;
              //   navigate(ERouteName.Topic + topic, { replace: true });
            }
          }
        },
      }}
      width={172}
      height={172}
    />
  ) : (
    <Text.Body>
      A visualization of a person's activity will appear here
    </Text.Body>
  );
};
