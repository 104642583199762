import React, { useMemo } from 'react';
import styled from '@emotion/styled';

import { theme, Theme } from 'theme';
import { css } from '@emotion/css';

type Props = {
  children: React.ReactNode;
  color?: keyof Theme['colors']['text'];
  style?: React.CSSProperties;
  marginTop?: keyof Theme['spacing'];
  marginBottom?: keyof Theme['spacing'];
  onClick?: VoidFunction;
};
export const H1: React.FC<Props> = ({
  children,
  color = 'text/main',
  marginBottom,
  marginTop,
  ...props
}) => {
  const TextComponent = useMemo(
    () => styled.h1`
      color: ${theme.colors.text[color]};
      font-weight: ${theme.textVariants.h1.fontWeight};
      font-family: ${theme.textVariants.h1.fontFamily};
      line-height: ${theme.textVariants.h1.lineHeight};
      font-size: ${theme.textVariants.h1.fontSize};
      text-align: left;
      margin-top: ${marginTop ? theme.spacing[marginTop] : 0}px;
      margin-bottom: ${marginBottom ? theme.spacing[marginBottom] : 0}px;
    `,
    [color, marginBottom, marginTop]
  );
  return <TextComponent {...props}>{children}</TextComponent>;
};

const H2: React.FC<Props> = ({
  children,
  color = 'text/main',
  marginBottom,
  marginTop,
  ...props
}) => {
  const TextComponent = useMemo(
    () => styled.h2`
      color: ${theme.colors.text[color]};
      font-weight: ${theme.textVariants.h2.fontWeight};
      font-family: ${theme.textVariants.h2.fontFamily};
      line-height: ${theme.textVariants.h2.lineHeight};
      font-size: ${theme.textVariants.h2.fontSize};
      margin-top: ${marginTop ? theme.spacing[marginTop] : 0}px;
      margin-bottom: ${marginBottom ? theme.spacing[marginBottom] : 0}px;
    `,
    [color, marginBottom, marginTop]
  );
  return <TextComponent {...props}>{children}</TextComponent>;
};

export const H3: React.FC<Props> = ({
  children,
  color = 'text/main',
  marginBottom,
  marginTop,
  ...props
}) => {
  const TextComponent = useMemo(
    () => styled.h3`
      color: ${theme.colors.text[color]};
      font-weight: ${theme.textVariants.h3.fontWeight};
      font-family: ${theme.textVariants.h3.fontFamily};
      line-height: ${theme.textVariants.h3.lineHeight};
      font-size: ${theme.textVariants.h3.fontSize};
      margin-top: ${marginTop ? theme.spacing[marginTop] : 0}px;
      margin-bottom: ${marginBottom ? theme.spacing[marginBottom] : 0}px;
    `,
    [color, marginBottom, marginTop]
  );
  return <TextComponent {...props}>{children}</TextComponent>;
};

const H4: React.FC<Props> = ({
  children,
  color = 'text/main',
  marginBottom,
  marginTop,
  ...props
}) => {
  const TextComponent = useMemo(
    () => styled.h4`
      color: ${theme.colors.text[color]};
      font-weight: ${theme.textVariants.h4.fontWeight};
      font-family: ${theme.textVariants.h4.fontFamily};
      line-height: ${theme.textVariants.h4.lineHeight};
      font-size: ${theme.textVariants.h4.fontSize};
      margin-top: ${marginTop ? theme.spacing[marginTop] : 0}px;
      margin-bottom: ${marginBottom ? theme.spacing[marginBottom] : 0}px;
    `,
    [color, marginBottom, marginTop]
  );
  return <TextComponent {...props}>{children}</TextComponent>;
};

const H5: React.FC<Props> = ({
  children,
  color = 'text/main',
  marginBottom,
  marginTop,
  ...props
}) => {
  const TextComponent = useMemo(
    () => styled.h5`
      color: ${theme.colors.text[color]};
      font-weight: ${theme.textVariants.h5.fontWeight};
      font-family: ${theme.textVariants.h5.fontFamily};
      line-height: ${theme.textVariants.h5.lineHeight};
      font-size: ${theme.textVariants.h5.fontSize};
      margin-top: ${marginTop ? theme.spacing[marginTop] : 0}px;
      margin-bottom: ${marginBottom ? theme.spacing[marginBottom] : 0}px;
    `,
    [color, marginBottom, marginTop]
  );
  return <TextComponent {...props}>{children}</TextComponent>;
};

const Subtitle: React.FC<Props> = ({
  children,
  color = 'text/main',
  marginBottom,
  marginTop,
  ...props
}) => {
  const TextComponent = useMemo(
    () => styled.h6`
      color: ${theme.colors.text[color]};
      font-weight: ${theme.textVariants.subtitle.fontWeight};
      font-family: ${theme.textVariants.subtitle.fontFamily};
      line-height: ${theme.textVariants.subtitle.lineHeight};
      font-size: ${theme.textVariants.subtitle.fontSize};
      margin-top: ${marginTop ? theme.spacing[marginTop] : 0}px;
      margin-bottom: ${marginBottom ? theme.spacing[marginBottom] : 0}px;
    `,
    [color, marginBottom, marginTop]
  );
  return <TextComponent {...props}>{children}</TextComponent>;
};

const Body: React.FC<Props> = ({
  children,
  color = 'text/main',
  marginBottom,
  marginTop,
  ...props
}) => {
  const TextComponent = useMemo(
    () => styled.p`
      color: ${theme.colors.text[color]};
      font-weight: ${theme.textVariants.body.fontWeight};
      font-family: ${theme.textVariants.body.fontFamily};
      line-height: ${theme.textVariants.body.lineHeight};
      font-size: ${theme.textVariants.body.fontSize};
      margin-top: ${marginTop ? theme.spacing[marginTop] : 10}px;
      margin-bottom: ${marginBottom ? theme.spacing[marginBottom] : 10}px;
      text-align: left;
      padding: 0px;
    `,
    [color, marginBottom, marginTop]
  );
  return <TextComponent {...props}>{children}</TextComponent>;
};

const BodySM: React.FC<Props> = ({
  children,
  color = 'text/main',
  marginBottom,
  marginTop,
  ...props
}) => {
  const TextComponent = useMemo(
    () => styled.p`
      color: ${theme.colors.text[color]};
      font-weight: ${theme.textVariants['body-sm'].fontWeight};
      font-family: ${theme.textVariants['body-sm'].fontFamily};
      line-height: ${theme.textVariants['body-sm'].lineHeight};
      font-size: ${theme.textVariants['body-sm'].fontSize};
      margin-top: ${marginTop ? theme.spacing[marginTop] : 10}px;
      margin-bottom: ${marginBottom ? theme.spacing[marginBottom] : 10}px;
    `,
    [color, marginBottom, marginTop]
  );
  return <TextComponent {...props}>{children}</TextComponent>;
};

const BodyXS: React.FC<Props> = ({
  children,
  color = 'text/main',
  marginBottom,
  marginTop,
  ...props
}) => {
  const TextComponent = useMemo(
    () => styled.p`
      color: ${theme.colors.text[color]};
      font-weight: ${theme.textVariants['body-xs'].fontWeight};
      font-family: ${theme.textVariants['body-xs'].fontFamily};
      line-height: ${theme.textVariants['body-xs'].lineHeight};
      font-size: ${theme.textVariants['body-xs'].fontSize};
      margin-top: ${marginTop ? theme.spacing[marginTop] : 10}px;
      margin-bottom: ${marginBottom ? theme.spacing[marginBottom] : 10}px;
    `,
    [color, marginBottom, marginTop]
  );
  return <TextComponent {...props}>{children}</TextComponent>;
};

const Button: React.FC<Props> = ({
  children,
  color = 'text/main',
  ...props
}) => {
  const TextComponent = useMemo(
    () => styled.span`
      color: ${theme.colors.text[color]};
      font-weight: ${theme.textVariants.button.fontWeight};
      font-family: ${theme.textVariants.button.fontFamily};
      line-height: ${theme.textVariants.button.lineHeight};
      font-size: ${theme.textVariants.button.fontSize};
    `,
    [color]
  );
  return <TextComponent {...props}>{children}</TextComponent>;
};

type AProps = {
  size?: keyof Theme['textVariants'];
  hoverColor?: keyof Theme['colors']['text'];
} & Props &
  React.AnchorHTMLAttributes<HTMLAnchorElement>;
export const A: React.FC<AProps> = ({
  children,
  size = 'body',
  marginBottom,
  color = 'text/link',
  hoverColor = 'text/link',
  marginTop,
  href,
  ...props
}) => {
  return (
    <a
      href={href || '#'}
      {...props}
      className={css`
        color: ${theme.colors.text[color]};
        font-weight: ${theme.textVariants[size].fontWeight};
        font-family: ${theme.textVariants[size].fontFamily};
        line-height: ${theme.textVariants[size].lineHeight};
        font-size: ${theme.textVariants[size].fontSize};
        text-decoration: none;
        margin-top: ${marginTop ? theme.spacing[marginTop] : 10}px;
        margin-bottom: ${marginBottom ? theme.spacing[marginBottom] : 10}px;
        cursor: pointer;
        &:hover {
          text-decoration: ${color === hoverColor ? 'underline' : 'none'};
          color: ${theme.colors.text[hoverColor]};
        }
      `}
    >
      {children}
    </a>
  );
};

type LabelProps = {
  size?: keyof Theme['textVariants'];
} & Props &
  React.LabelHTMLAttributes<HTMLLabelElement>;
export const Label: React.FC<LabelProps> = ({
  children,
  size = 'body',
  marginBottom,
  color = 'text/main',
  marginTop,
  ...props
}) => {
  return (
    <label
      {...props}
      className={css`
        color: ${theme.colors.text[color]};
        font-weight: ${theme.textVariants[size].fontWeight};
        font-family: ${theme.textVariants[size].fontFamily};
        line-height: ${theme.textVariants[size].lineHeight};
        font-size: ${theme.textVariants[size].fontSize};
        margin-top: ${marginTop ? theme.spacing[marginTop] : 10}px;
        margin-bottom: ${marginBottom ? theme.spacing[marginBottom] : 10}px;
        cursor: pointer;
      `}
    >
      {children}
    </label>
  );
};

const TextSmall1: React.FC<Props> = ({
  children,
  color = 'text/100%',
  marginBottom,
  marginTop,
  ...props
}) => {
  const TextComponent = useMemo(
    () => styled.p`
      color: ${theme.colors.text[color]};
      font-weight: ${theme.textVariants['text-small-1'].fontWeight};
      font-family: ${theme.textVariants['text-small-1'].fontFamily};
      line-height: ${theme.textVariants['text-small-1'].lineHeight};
      font-size: ${theme.textVariants['text-small-1'].fontSize};
      margin-top: ${marginTop ? theme.spacing[marginTop] : 10}px;
      margin-bottom: ${marginBottom ? theme.spacing[marginBottom] : 10}px;
    `,
    [color, marginBottom, marginTop]
  );
  return <TextComponent {...props}>{children}</TextComponent>;
};

const TextSmall2: React.FC<Props> = ({
  children,
  color = 'text/100%',
  marginBottom,
  marginTop,
  ...props
}) => {
  const TextComponent = useMemo(
    () => styled.p`
      color: ${theme.colors.text[color]};
      font-weight: ${theme.textVariants['text-small-2'].fontWeight};
      font-family: ${theme.textVariants['text-small-2'].fontFamily};
      line-height: ${theme.textVariants['text-small-2'].lineHeight};
      font-size: ${theme.textVariants['text-small-2'].fontSize};
      margin-top: ${marginTop ? theme.spacing[marginTop] : 10}px;
      margin-bottom: ${marginBottom ? theme.spacing[marginBottom] : 10}px;
    `,
    [color, marginBottom, marginTop]
  );
  return <TextComponent {...props}>{children}</TextComponent>;
};

export const Text = {
  H1,
  H2,
  H3,
  H4,
  H5,
  Subtitle,
  Body,
  BodySM,
  BodyXS,
  Button,
  A,
  Label,
  TextSmall2,
  TextSmall1,
};
