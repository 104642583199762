import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap';
import { Chart } from 'react-chartjs-2';
import { theme } from 'theme';
import { valueFromString } from 'utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TreemapController,
  TreemapElement
);

const colors = [
  '#887AE7',
  '#443E6D',
  '#383456',
  '#443E6D',
  '#655BA6',
  '#2C2943',
  '#443E6D',
  '#524B83',
  '#7266BD',
  '#655BA6',
];

const options = {
  plugins: {
    title: {
      display: false,
      text: 'dddd',
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
      displayColors: false,
      callbacks: {
        title(items: any) {
          return items[0].raw._data.name;
        },
        label(item: any) {
          const {
            _data: { capacityMW },
          } = item.raw;
          return [`${capacityMW}`];
        },
      },
      external: function (context: any) {
        // Tooltip Element
        let tooltipEl = document.getElementById('chartjs-tooltip');

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.innerHTML =
            '<div id="chartjs-custom-text-body" style="display: flex; flex-direction: row; justify-content: center; align-items: center;"></div>';
          document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        const tooltipModel = context.tooltip;
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = '0';
          return;
        }

        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }

        function getBody(bodyItem: {
          before: string[];
          lines: string[];
          after: string[];
        }) {
          return bodyItem.lines;
        }

        // Set Text
        if (tooltipModel.body) {
          const titleLines = tooltipModel.title || [];
          const bodyLines = tooltipModel.body.map(getBody);

          let innerHtml = '';

          titleLines.forEach(function (title: string) {
            innerHtml +=
              '<div style="font-size: 14px; max-width: 350px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">' +
              title +
              '</div>';
          });
          // innerHtml += '</thead><tbody>';

          bodyLines.forEach(function (body: any) {
            //   const colors = tooltipModel.labelColors[i];
            let style = 'color: ' + theme.colors.text['Text/Secondary'];
            //   style += '; border-color:' + colors.borderColor;
            style += '; font-size: 14px';
            style += '; margin-left: 8px';
            style +=
              '; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;';
            innerHtml += '<div style="' + style + '">' + body + '%</div>';
          });
          // innerHtml += '</tbody>';

          //   let tableRoot = tooltipEl.querySelector('p');
          let tableRoot = document.getElementById('chartjs-custom-text-body');
          tableRoot!.innerHTML = innerHtml;
        }

        const position = context.chart.canvas.getBoundingClientRect();

        // Display, position, and set styles for font
        tooltipEl.style.opacity = '1';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left =
          position.left + window.pageXOffset + tooltipModel.caretX + 'px';
        tooltipEl.style.top =
          position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        tooltipEl.style.padding = '10px';
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.backgroundColor = theme.colors.primary['primary/03'];
        //   tooltipEl.style.maxWidth = '143px';
        tooltipEl.style.height = '40px';
        tooltipEl.style.borderRadius = '8px';
        tooltipEl.style.boxShadow = '0px 4px 8px rgba(0, 0, 0, 0.1)';
        tooltipEl.style.color = theme.colors.text['text/body'];
        tooltipEl.style.fontSize = '14px';
      },
    },
  },
};

type Props = {
  data: {
    id: string;
    label: string;
    value: number;
  }[];
};

export const TreemapChart: React.FC<Props> = ({ data }) => {
  const config: any = {
    type: 'treemap',
    data: {
      datasets: [
        {
          tree: data?.map((d) => ({
            name: d.label,
            capacityMW: d.value,
            dataCoverage: valueFromString(d.label, colors.length),
          })),
          key: 'capacityMW',
          labels: {
            display: true,
            color: 'white',
            font: {
              size: 12,
              family: 'Roobert-Medium',
              weight: '500',
            },
            formatter: (context: any) => {
              const value = Number(context.raw._data.capacityMW);
              const label = String(context.raw._data.name);
              const stopValue = value >= 30 ? 40 : value >= 15 ? 30 : 10;
              return `${label.substring(0, stopValue)}${
                label.length > stopValue ? '...' : ''
              }`;
            },
          },
          spacing: 2,
          backgroundColor(context: any) {
            if (context.type !== 'data') return 'transparent';
            const { dataCoverage } = context.raw._data;
            return colors[dataCoverage];
          },
          //   hoverBackgroundColor: '',
          borderRadius: 0,
        },
      ],
    },
  };
  return (
    <Chart
      type="treemap"
      data={config.data}
      options={{
        ...options,
        onClick: function (e, item) {
          if (item.length > 0) {
            // const index = item[0].index;
          }
        },
      }}
    />
  );
};
