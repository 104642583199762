import * as React from 'react';
import * as Toast from '@radix-ui/react-toast';
import './index.css';
// import { CheckIcon, WarningIcon } from 'components/Icons';
// import { theme } from 'theme';
import { css } from '@emotion/css';
import { theme } from 'theme';

type Props = {
  show: boolean;
  handleShow: (state: boolean) => void;
  success?: boolean;
  text?: string;
};
export const Notification: React.FC<Props> = ({
  success,
  show,
  text,
  handleShow,
}) => {
  const [open, setOpen] = React.useState(false);
  const eventDateRef = React.useRef(new Date());
  const timerRef = React.useRef(0);

  React.useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  // const Icon = success ? CheckIcon : WarningIcon;
  // const color = success
  //   ? theme.colors.accent['green/main']
  //   : theme.colors.accent['red/main'];
  const title = success ? 'Success!' : 'Failed!';

  React.useEffect(() => {
    if (show) {
      setOpen(false);
      window.clearTimeout(timerRef.current);
      timerRef.current = window.setTimeout(() => {
        eventDateRef.current = oneWeekAway();
        setOpen(true);
        handleShow(false);
      }, 100);
    }
  }, [handleShow, show]);

  return (
    <Toast.Provider swipeDirection="right">
      {/* <button
        className="Button large violet"
        onClick={() => {
          setOpen(false);
          window.clearTimeout(timerRef.current);
          timerRef.current = window.setTimeout(() => {
            eventDateRef.current = oneWeekAway();
            setOpen(true);
          }, 100);
        }}
      >
        Add to calendar
      </button> */}

      <Toast.Root className="ToastRoot" open={open} onOpenChange={setOpen}>
        {/* <Toast.Description asChild>
          <Icon
            fill={color}
            style={{ width: '18px', height: '18px', marginRight: '16px' }}
          />
        </Toast.Description> */}
        <Toast.Title className="ToastTitle">
          {/* {title} */}
          {text ? (
            <div
              className={css`
                font-size: 14px;
                font-weight: 400;
                font-style: normal;
                background-color: ${theme.colors.accent['yellow/main']};
                color: black;
                padding: 8px 16px;
                border-radius: 8px;
              `}
            >
              {text}
            </div>
          ) : (
            <h1
              className={css`
                font-family: 'Pixel-Emulator';
                font-size: 32px;
                font-weight: 400;
                letter-spacing: 0.1em;
                background: linear-gradient(180deg, #8c83c3 0%, #6a53fe 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                font-style: normal;
              `}
            >
              {title}
            </h1>
          )}
        </Toast.Title>

        {/* <Toast.Action
          className="ToastAction"
          asChild
          altText="Goto schedule to undo"
        >
          <button className="Button small green">Undo</button>
        </Toast.Action> */}
      </Toast.Root>
      <Toast.Viewport className="ToastViewport" />
    </Toast.Provider>
  );
};

function oneWeekAway() {
  const now = new Date();
  const inOneWeek = now.setDate(now.getDate() + 7);
  return new Date(inOneWeek);
}

// function prettyDate(date: Date) {
//   return new Intl.DateTimeFormat('en-US', {
//     dateStyle: 'full',
//     timeStyle: 'short',
//   }).format(date);
// }
