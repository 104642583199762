import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface IDoc {
  id: string;
  url: string;
  created?: string;
  type: string;
  lastUpdated?: string;
  lastUpdatedBy?: string;
  thumbnail?: string;
  title?: string;
}

export interface ITopicLink {
  doc?: Partial<IDoc>;
  type: string;
  url?: string;
  important?: boolean;
  groups?: string[];
  isFake?: boolean;
  _id: string;
}

interface IUpdate {
  id: string;
  link: Partial<ITopicLink>;
}

const initialState = [] as ITopicLink[];

export const topicLinkSlice = createSlice({
  name: 'topicLink',
  initialState,
  reducers: {
    initializeTopicLink: (state, action: PayloadAction<ITopicLink[]>) => {
      return action.payload;
    },
    addNewTopicLink: (state, action: PayloadAction<ITopicLink[]>) => {
      state = [...state, ...action.payload];
      return state;
    },
    updateTopicLink: (state, action: PayloadAction<IUpdate>) => {
      const index = state.findIndex((link) => link._id === action.payload.id);
      if (index >= 0) {
        state[index] = {
          ...state[index],
          ...action.payload.link,
          doc: { ...state[index].doc, ...action.payload.link.doc },
        };
      }
      return state;
    },
    removeTopicLink: (state, action: PayloadAction<string>) => {
      state = state.filter((link) => link._id !== action.payload);
      return state;
    },
    resetTopicLink: (state) => {
      state = initialState;
      return initialState;
    },
  },
});

export const {
  initializeTopicLink,
  addNewTopicLink,
  updateTopicLink,
  removeTopicLink,
  resetTopicLink,
} = topicLinkSlice.actions;

export const topicLinkReducer = topicLinkSlice.reducer;
