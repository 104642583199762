import { css } from '@emotion/css';
import React from 'react';
import ReactQuill from 'react-quill';
import 'quill-mention';
// import 'react-quill/dist/quill.snow.css';
import './quill.css';
import { theme } from 'theme';
import { PaperClipIcon, PaperPlaneTilt, TaskListIcon } from 'components/Icons';
import {
  displayUser,
  formatURL,
  getFromLocalStorage,
  getNewToken,
  graphqlRequest,
} from 'utils';
import { Text } from 'components';
import { IUser } from 'model';
import { LinkDialog } from './components';
import { uploadLink } from './utils';

// const Block = Quill.import('blots/block');
// const Container = Quill.import('blots/container');

// class ListContainer extends Container {}
// ListContainer.blotName = 'list-container';
// ListContainer.tagName = 'ul';
// class TaskListBlot extends Block {
//   static create(value: string) {
//     const node = super.create() as Element;
//     node.setAttribute('data-checked', 'false');
//     return node;
//   }

//   static formats(domNode: any) {
//     return domNode.getAttribute('data-checked') || undefined;
//   }

//   static register() {
//     Quill.register(ListContainer);
//   }

//   constructor(domNode: any, scroll: any) {
//     super(domNode, scroll);

//     const ui = domNode.ownerDocument.createElement('span');
//     const listEventHandler = (e: any) => {
//       if (!scroll.isEnabled()) return;
//       const format = this.statics.formats(domNode, scroll);
//       if (format === 'checked') {
//         this.format('task-list', 'unchecked');
//         e.preventDefault();
//       } else if (format === 'unchecked') {
//         this.format('task-list', 'checked');
//         e.preventDefault();
//       }
//     };
//     ui.addEventListener('mousedown', listEventHandler);
//     ui.addEventListener('touchstart', listEventHandler);
//     this.attachUI(ui);
//   }

//   format(name: any, value: any) {
//     if (name === this.statics.blotName && value) {
//       this.domNode.setAttribute('data-checked', value);
//     } else {
//       super.format(name, value);
//     }
//   }

//   // static formats(node: any) {
//   //   // We will only be called with a node already
//   //   // determined to be a Link blot, so we do
//   //   // not need to check ourselves
//   //   return node.getAttribute('data-checked');
//   // }
// }
// class BoldBlot extends Inline {}
// TaskListBlot.register();
// TaskListBlot.blotName = 'task-list';
// TaskListBlot.tagName = 'li';
// ListContainer.allowedChildren = [TaskListBlot];
// TaskListBlot.requiredContainer = ListContainer;

// Quill.register(TaskListBlot);

// console.log(TaskListBlot);

type EditorProps = {
  placeholder?: string;
  value?: string;
  submit?: string;
  readonly?: boolean;
  loading?: boolean;
  compact?: boolean;
  /**
   * use this unique id property for the custom toolbar
   * to have multiple text editor in the same page
   */
  toolbarId?: string;
  noBackground?: boolean;
  dark?: boolean;
  label?: string;
  additionalContent?: JSX.Element;
  atValues?: { id: number; value: string; disabled?: boolean }[];
  hashValues?: { id: number; value: string; disabled?: boolean }[];
  height?: number;
  handleSubmit?(): void;
  handleChange?(value: string): void;
  handleChangeText?(value: string): void;
};

export class TextEditor extends React.Component<EditorProps, any> {
  constructor(props: EditorProps) {
    super(props);
    // this.state = { readOnly: false };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(
    html: string,
    delta: any,
    source: any,
    editor: ReactQuill.UnprivilegedEditor
  ) {
    this.props.handleChange?.(html);
    this.props.handleChangeText?.(editor.getText());
  }

  render() {
    (TextEditor as any).modules.toolbar.container = `#${
      this.props.toolbarId || 'toolbar'
    }`;
    return this.props.readonly ? (
      <div className="ql-editor">
        <div
          className={css`
            width: ${this.props.compact ? '100%' : '100%'};
            max-height: ${this.props.compact ? '100px' : 'auto'};
            overflow: auto;
            //   /* min-height: 50px; */
            border-radius: ${theme.borderRadius.m}px;
            background-color: ${this.props.noBackground
              ? 'transparent'
              : theme.colors.primary['primary/02']};
            img {
              max-width: 100%;
            }

            blockquote {
              margin: 0;
              padding: 0;
              counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7
                list-8 list-9;
              border-left: 4px solid #ccc;
              margin-bottom: 5px;
              margin-top: 5px;
              padding-left: 16px;
            }

            pre {
              white-space: pre-wrap;
              margin-bottom: 5px;
              margin-top: 5px;
              padding: 5px 10px;
              border-radius: 3px;
              font-size: 85%;
            }

            pre.ql-syntax {
              background-color: #172342;
              color: #f8f8f2;
              overflow: visible;
              font-family: Monaco, Courier New, monospace;
              font-size: 14px;
            }

            .link-mention {
              text-decoration: none !important;
            }
            .mention {
              color: ${theme.colors.text['text/link']};
              background-color: ${theme.colors.primary['primary/02']};
              padding: 2px 8px;
              border-radius: 0px 100px 100px 0px;
            }
            .mention:hover {
              background-color: ${theme.colors.primary['primary/01']};
            }
          `}
        >
          <div
            className="ql-snow"
            dangerouslySetInnerHTML={{
              __html: this.props.value || '',
            }}
          />
        </div>
      </div>
    ) : (
      <div
        className={css`
          width: 100%;
          background-color: ${this.props.dark
            ? '#272539'
            : theme.colors.primary['primary/02']};
          border-radius: ${theme.borderRadius.l}px;
        `}
      >
        <LinkDialog />

        <input type="file" name="" id="input_file" hidden />

        {this.props.label && (
          <div
            className={css`
              width: 100%;
              padding: 16px 16px 0px 17px;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Text.TextSmall2
              marginBottom="4xs"
              marginTop="4xs"
              color="text/light"
            >
              {this.props.label}
            </Text.TextSmall2>

            <div>{this.props.additionalContent}</div>
          </div>
        )}
        <ReactQuill
          // className={this.props.dark ? 'dark' : 'light'}
          theme="snow"
          onChange={this.handleChange}
          value={this.props.value}
          modules={(TextEditor as any).modules}
          formats={(TextEditor as any).formats}
          bounds={'.App'}
          readOnly={this.props.loading}
          placeholder={this.props.placeholder || 'Enter some text...'}
          style={{ height: this.props.height || '' }}
        />
        <div
          className={css`
            width: 97%;
            border-top: 1px solid ${theme.colors.accent['purple/light']};
            margin: auto;
          `}
        />
        <CustomToolbar
          dark={this.props.dark}
          submit={this.props.submit}
          handleSubmit={this.props.handleSubmit}
          toolbarId={this.props.toolbarId || 'toolbar'}
          loading={this.props.loading}
        />
      </div>
    );
  }
}

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
(TextEditor as any).modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      attachment: function () {
        const inputFile = document?.getElementById?.('input_file');

        const handleUpload = async (event: any) => {
          if (event.target.files?.[0]?.name) {
            const cursorPosition = (this as any).quill.getSelection().index;

            const loader = '...uploading...';

            (this as any).quill.disable();
            (this as any).quill.insertText(
              cursorPosition,
              loader,
              'color',
              'orange'
            );

            const url = await uploadLink(event);

            let error = false;
            try {
              JSON.parse(url);
              error = true;
            } catch (err) {
              error = false;
            }

            (this as any).quill.deleteText(cursorPosition, loader.length);

            if (error) {
              (this as any).quill.insertText(
                cursorPosition,
                'Failed! Max allowed size is 5MB',
                'color',
                'red'
              );
            } else {
              (this as any).quill.insertText(
                cursorPosition,
                event.target.files?.[0]?.name || event.target.value,
                'link',
                url
              );
            }

            (this as any).quill.enable();

            // (this as any).quill.setSelection(cursorPosition + 1);
          }
          inputFile?.removeEventListener('change', handleUpload);
        };
        inputFile?.click();
        inputFile?.addEventListener('change', handleUpload);
      },
      link: function (value: boolean) {
        let cursorPosition = (this as any).quill.getSelection().index;
        const range = (this as any).quill.getSelection();

        let selectedText = '';

        if (range.length !== 0) {
          selectedText = (this as any).quill.getText(range.index, range.length);
        }

        if (value) {
          const dialog = document.getElementById('dialog');
          const form = document.getElementById('form');
          const cancelButton = document.getElementById('cancel_button');
          const closeButton = document.getElementById('close_button');
          const input_text = document.getElementById('input_text');

          if (selectedText) {
            input_text?.setAttribute('value', selectedText);
          }

          (dialog as any)?.showModal();

          const handleSubmit = (event: any) => {
            event.preventDefault();
            const title = (form as any)?.elements?.['input_text']?.value;
            const url = (form as any)?.elements?.['input_url']?.value;
            form?.removeEventListener('submit', handleSubmit);
            (form as any)?.reset();

            if (!url) return;
            if (range.length !== 0) {
              (this as any).quill.deleteText(range.index, range.length);
              cursorPosition = range.index;
            }
            (this as any).quill.insertText(
              cursorPosition,
              title || url,
              'link',
              formatURL(url)
            );
            (this as any).quill.setSelection(
              cursorPosition + (title || url).length
            );
            (dialog as any)?.close();
          };

          form?.addEventListener('submit', handleSubmit);

          const handleClose = (event: any) => {
            (form as any)?.reset();
            cancelButton?.removeEventListener('click', handleClose);
            (dialog as any)?.close();
          };

          cancelButton?.addEventListener('click', handleClose);

          closeButton?.addEventListener('click', handleClose);
        } else {
          (this as any).quill.format('link', false);
        }
      },
    },
  },
  clipboard: {
    matchVisual: false,
  },
  mention: {
    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    mentionDenotationChars: ['@', '#', '+'],
    defaultMenuOrientation: 'bottom',
    isolateCharacter: true,
    linkTarget: '_blank',
    renderLoading: () => {
      return <h1>hello</h1>;
    },
    source: async (
      searchTerm: string,
      renderList: any,
      mentionChar: string
    ) => {
      renderList([{ id: 1, value: 'loading...', disabled: true }]);
      let values = [];
      const token = await getNewToken();
      try {
        if (mentionChar === '@') {
          const res = await graphqlRequest({
            query: `
            query Collaborators {
              collaborators {
                _id
                businessEmail
                profileUrl
                firstName
                lastName
              }
            }
          `,
            token,
          });
          const result = await res.json();
          values =
            result?.data?.collaborators?.map((user: IUser) => ({
              id: user._id,
              value: displayUser(user),
            })) || [];
        } else if (mentionChar === '#') {
          const token = await getNewToken();
          const res = await graphqlRequest({
            query: `
              query Topics($order: String, $filter: String) {
                topics(order: $order, filter: $filter) {
                  _id
                  name
                }
              }
          `,
            variables: {
              order: '-1',
              filter: 'opened',
            },
            token,
          });
          const result = await res.json();
          values =
            result?.data?.topics?.map((topic: any) => ({
              id: topic._id,
              value: topic.name,
            })) || [];
        } else {
          const res = await graphqlRequest({
            query: `
              query NotionPageList {
                notionPageList {
                  _id
                  name
                }
              }
            `,
            variables: {},
            token: getFromLocalStorage('accessToken') || '',
          });
          const result = await res.json();
          values =
            result?.data?.notionPageList
              ?.filter((page: any) => page.name !== '-')
              .map((page: any) => ({
                id: page._id,
                value: page.name,
              })) || [];
        }
      } catch (error) {
        values = [];
      }

      if (searchTerm.length === 0) {
        renderList(values, searchTerm);
      } else {
        const matches = [];
        for (let i = 0; i < values.length; i++)
          if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
            matches.push(values[i]);
        renderList(matches, searchTerm);
      }
    },
  },
  keyboard: {
    bindings: {
      strikeHotkey: {
        key: 'X',
        shortKey: true,
        shiftKey: true,
        handler: function (range: any, context: any) {
          if (context.format.strike) {
            (this as any).quill.formatText(range, 'strike', false);
          } else {
            (this as any).quill.formatText(range, 'strike', true);
          }
        },
      },
      strikeHotkeyWindows: {
        key: '5',
        altKey: true,
        shiftKey: true,
        handler: function (range: any, context: any) {
          if (context.format.strike) {
            (this as any).quill.formatText(range, 'strike', false);
          } else {
            (this as any).quill.formatText(range, 'strike', true);
          }
        },
      },
    },
  },
  //   keyboard.addBinding(
  //   {
  //     key: 'B',
  //     shortKey: true,
  //   },
  //   function (range: any, context: any) {
  //     (this as any).quill.formatText(range, 'bold', true);
  //   }
  // )
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
(TextEditor as any).formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'color',
  'background',
  'align',
  'code',
  'code-block',
  'mention',
  'table',
];

type CustomToolbarProps = {
  dark?: boolean;
  submit?: string;
  toolbarId: string;
  loading?: boolean;
  handleSubmit?: VoidFunction;
};
const CustomToolbar: React.FC<CustomToolbarProps> = ({
  submit,
  dark,
  toolbarId,
  loading,
  handleSubmit,
}) => (
  <div id={toolbarId} className={dark ? 'dark' : 'light'}>
    {/* <select
        className="ql-header"
        defaultValue={''}
        onChange={(e) => e.persist()}
      >
        <option value="1" />
        <option value="2" />
        <option value="" />
      </select> 
    */}

    {submit && (
      <button className="ql-submit" disabled={loading} onClick={handleSubmit}>
        <PaperPlaneTilt
          fill={theme.colors.text['text/blue/soft']}
          style={{ width: '14px', marginRight: '8px' }}
        />
        {loading ? 'sending...' : submit}
      </button>
    )}

    <select
      className="ql-align"
      defaultValue={''}
      onChange={(e) => e.persist()}
    >
      <option value="" />
      <option value="center" />
      <option value="right" />
      <option value="justify" />
    </select>
    <select
      className="ql-color"
      defaultValue={''}
      onChange={(e) => e.persist()}
    >
      <option value={theme.colors.text['text/main']} />
      <option value={theme.colors.accent['red/dark']} />
      <option value={theme.colors.accent['green/main']} />
      <option value={theme.colors.accent['blue/main']} />
      <option value="orange" />
      <option value={theme.colors.accent['purple/light']} />
      <option value={theme.colors.accent.black} />
    </select>
    <select
      className="ql-background"
      defaultValue={''}
      onChange={(e) => e.persist()}
    >
      <option value="" />
      <option value={theme.colors.text['text/main']} />
      <option value={theme.colors.accent['red/dark']} />
      <option value={theme.colors.accent['green/main']} />
      <option value={theme.colors.accent['blue/main']} />
      <option value="orange" />
      <option value={theme.colors.accent['purple/light']} />
      {/* <option value={theme.colors.accent.black} /> */}
    </select>

    <button className="ql-code-block" />
    <button className="ql-blockquote" />

    {/* <button className="ql-table" /> */}

    <button className="ql-video" />
    <button className="ql-image" />
    <button className="ql-attachment">
      <PaperClipIcon style={{ width: '16px' }} fill="#8C83C3" />
    </button>
    <button className="ql-link" />

    <button className="ql-list" value="unchecked">
      <TaskListIcon style={{ width: '16px' }} fill="#8C83C3" />
    </button>
    <button className="ql-list" value="bullet" />
    <button className="ql-list" value="ordered" />

    <button className="ql-strike" />
    <button className="ql-underline" />
    <button className="ql-italic" />
    <button className="ql-bold" />
  </div>
);
