import { gql } from '@apollo/client';

export const TOPIC_CREATE = gql`
  mutation CreateTopic(
    $name: String!
    $isPublic: Boolean
    $description: String
    $collaborators: [String!]
    $links: [AddLink!]
  ) {
    createTopic(
      name: $name
      isPublic: $isPublic
      description: $description
      collaborators: $collaborators
      links: $links
    ) {
      _id
      name
    }
  }
`;

export const CLOSE_TOPIC = gql`
  mutation closeTopic($topicId: ID!, $closed: Boolean!) {
    closeTopic(topicId: $topicId, closed: $closed)
  }
`;

export const DELETE_TOPIC = gql`
  mutation deleteTopic($id: ID!) {
    deleteTopic(id: $id)
  }
`;

export const EDIT_TOPIC = gql`
  mutation UpdateTopic(
    $topicId: ID!
    $name: String
    $description: String
    $isPublic: Boolean
    $collaborators: [String!]
    $links: [AddLink!]
  ) {
    updateTopic(
      topicId: $topicId
      name: $name
      description: $description
      isPublic: $isPublic
      collaborators: $collaborators
      links: $links
    ) {
      _id
      name
      description
      public
      links {
        doc {
          id
          url
          created
          type
          lastUpdated
          lastUpdatedBy
          thumbnail
          title
        }
        type
        url
        important
        groups
      }
      collaborators {
        _id
        businessEmail
        firstName
        lastName
        profileUrl
        role
      }
    }
  }
`;
