export async function uploadLink(event: any): Promise<string> {
  try {
    const bodyContent = new FormData();
    bodyContent.append('image', event.target.files?.[0]!);

    const headersList = {
      Accept: '*/*',
      'User-Agent': 'Thunder Client (https://www.thunderclient.com)',
    };

    const res = await fetch(
      `${process.env.REACT_APP_API_URL?.split('graphql').join(
        'api'
      )}/profile-upload`,
      {
        method: 'POST',
        body: bodyContent,
        headers: headersList,
      }
    );

    const result = await res
      .text()
      .then((response) => {
        return response;
      })
      .catch((e) => {
        return '';
      });
    return result;
  } catch (error) {
    return '';
  }
}
