import React from 'react';
import { Text } from 'components';

type Props = {
  text: string;
  color: string;
  Icon?: React.FC<any>;
};
export function CardHeader({ text, color, Icon }: Props) {
  return (
    <div
      style={{
        height: '26px',
        padding: '2px 14px',
        border: `1px solid ${color}`,
        borderRadius: '100px',
        marginRight: '8px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: 'fit-content',
        // marginTop: '20px',
        // marginBottom: '20px',
      }}
    >
      {Icon && (
        <Icon
          fill={color}
          style={{ width: '20px', height: '20px', marginRight: '8px' }}
        />
      )}
      <Text.TextSmall1 style={{ color }}>{text}</Text.TextSmall1>
    </div>
  );
}
