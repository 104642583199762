import { css } from '@emotion/css';
import { Text } from 'components';
import React from 'react';
import { extractFromQuery } from 'utils';

type Params = {
  audio: string;
};

export const Media: React.FC = () => {
  const { audio } = extractFromQuery<Params>(window.location.search);
  return (
    <div
      className={css`
        width: 80%;
        margin: auto;
        /* text-align: center; */
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      `}
    >
      <Text.H1 marginBottom="l" style={{ textAlign: 'center' }}>
        Your summary for today
      </Text.H1>

      <audio controls autoPlay>
        <source src={audio} type="audio/mpeg" />
        Your browser does not support the audio tag.
      </audio>
    </div>
  );
};
