import { gql } from '@apollo/client';

export const UPDATE_PROFILE = gql`
  mutation updateUser(
    $email: String
    $firstName: String
    $lastName: String
    $role: String
    $description: String
    $linkedinProfile: String
    $githubProfile: String
    $profileUrl: String
    $bannerUrl: String
  ) {
    updateUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      role: $role
      description: $description
      linkedinProfile: $linkedinProfile
      githubProfile: $githubProfile
      profileUrl: $profileUrl
      bannerUrl: $bannerUrl
    ) {
      email
      firstName
      lastName
      businessEmail
      description
      role
      profileUrl
      bannerUrl
      linkedinProfile
      githubProfile
    }
  }
`;

export const GOOGLE_AUTH = gql`
  query Query($redirect: String) {
    slackOAuthUrl(redirect: $redirect)
    notionOAuthUrl(redirect: $redirect)
    microsoftOAuthUrl(redirect: $redirect)
    linearOAuthUrl(redirect: $redirect)
    githubOAuthUrl(redirect: $redirect)
    # isSlackBotInstalled(domain: $domain)
    me {
      _id
      userHasSlackAuth
      userHasNotionAuth
      userHasMicrosoftAuth
      userHasLinearAuth
      userHasGithubAuth
      businessEmail
    }
  }
`;

export const REMOVE_INTEGRATION = gql`
  mutation RemoveIntegration($type: String!) {
    removeIntegration(type: $type)
  }
`;

export const ENABLE_NOTIFICATION = gql`
  mutation EnableNotification($type: String!) {
    enableNotification(type: $type)
  }
`;

export const DISABLE_NOTIFICATION = gql`
  mutation DisableNotification($type: String!) {
    disableNotification(type: $type)
  }
`;

export const ENABLE_TOPIC_BUILD = gql`
  mutation EnableTopicBuild {
    enableTopicBuild
  }
`;

export const DISABLE_TOPIC_BUILD = gql`
  mutation DisableTopicBuild {
    disableTopicBuild
  }
`;

export const GET_TOPIC_BUILD_STATUS = gql`
  query Workspace {
    currentWorkspace {
      disableTopicBuild
    }
  }
`;

export const GET_PROGRESS = gql`
  query TutorialProgress($userId: ID!) {
    tutorialProgress(userId: $userId) {
      integrations
      invitation
      profile
      tutorial
      userOnboarded
    }
  }
`;

export const VIDEO_WATCHED = gql`
  mutation OnboardingVideoWatched {
    onboardingVideoWatched
  }
`;

export const GET_SLACK_PROFILE = gql`
  query SlackProfileUrl($userId: ID!) {
    slackProfileUrl(userId: $userId)
  }
`;

export const NOTIFICATION_INFO = gql`
  query Query {
    me {
      _id
      hasEmailNotification
      hasGoogleCommentNotification
      hasSlackNotification
    }
  }
`;

export const GET_USER_INFO = gql`
  query Me {
    me {
      _id
      description
      email
      firstName
      lastName
      role
      githubProfile
      linkedinProfile
      profileUrl
    }
  }
`;

export const IS_SLACK_INSTALLED = gql`
  query IsSlackInstalled {
    isSlackInstalled
  }
`;
