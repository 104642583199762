import React, { useContext, useEffect } from 'react';
import { LogoFullIcon } from 'components/Icons';
import styled from '@emotion/styled';
import { LoadingBar, Text } from 'components';
import { useNavigate } from 'react-router-dom';
import { setToLocalStorage, extractFromQuery } from 'utils';
import { UserContext } from 'context';
import { EUserAction } from 'model';
import * as crypto from 'crypto-js';

const Container = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 132px;
`;

type Params = {
  data: string;
};

export const Continue = React.memo(() => {
  const navigate = useNavigate();
  const { dispatch } = useContext(UserContext);

  useEffect(() => {
    const { data } = extractFromQuery<Params>(window.location.search);
    if (data) {
      const decryptedData = crypto.AES.decrypt(
        data,
        process.env.REACT_APP_SECRET_KEY!
      ).toString(crypto.enc.Utf8);
      const userData = JSON.parse(decryptedData);
      if (userData?.token?.session) {
        setToLocalStorage('accessToken', userData.token.session);
        setToLocalStorage('refreshToken', userData.token.refresh);
        dispatch({ type: EUserAction.SET_USER, payload: userData.user });
        // setToLocalStorage('user', JSON.stringify(userData.user));
        setTimeout(() => {
          window.close();
        }, 2000);
      }
    }
  }, [dispatch, navigate]);

  return (
    <Container>
      <LogoFullIcon style={{ height: '36px', marginBottom: '175px' }} />
      <Text.H1 style={{ fontSize: '40px' }}>Welcome!</Text.H1>
      <Text.H2 style={{ fontSize: '24px' }}>
        Loading your Multiplayer experience.
      </Text.H2>
      <LoadingBar duration={2500} />
    </Container>
  );
});
