export enum ERoute {
  AuthBegin = '/auth/begin',
  AuthContinue = '/auth/continue',
  AuthEnsure = '/auth/ensure',
  CompleteAction = '/do/:actionId',
  CreateTopic = '/w/:workspaceId/:workspaceName/create-topic',
  CreateWorkspace = '/workspace/create',
  Done = '/done',
  Discover = '/discover',
  HandleAction = '/go/:actionId',
  Home = '/',
  People = '/people',
  Person = '/people/:id/:name',
  PriorityFeed = '/feed',
  Settings = '/settings',
  Topic = '/topic/:id/:name',
  Topics = '/workspace#topics',
  Workspace = '/workspace',
  Profile = '/people/:userId/:email',
}
