import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  ActivitySeparator,
  AvatarList,
  Box,
  Page,
  Text,
  TooltipContainer,
  TopicMomentum,
} from 'components';
import { theme } from 'theme';
import { Link, useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ERouteName } from 'pages';
import { capitalizeString, displayUser, shortId } from 'utils';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import {
  ESort,
  ETopicFilter,
  FilterStatusType,
  WorkspaceResult,
} from './types';
import { CHECK_TOPIC_CREATION_STATUS, GET_TOPICS } from './gql';
import { Header, TBody, THead, Table, Th, Tr, Td } from './components/primary';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  GearIcon,
  ListDashIcon,
  LockIcon,
  MotePencilIcon,
  SparkleIcon,
} from 'components/Icons';
import { FilterSection } from './FilterSection';
import { format } from 'date-fns';
import { EMomentumType, IUser } from 'model';
import { css } from '@emotion/css';
import { UserContext } from 'context';
import { CreateTopic } from '../../components/CreateTopic';
import { useAppDispatch, useAppSelector } from 'hooks';
import { RootState } from 'reducers';
import { addNewTopicsToList, initializeTopicList } from 'features/topics';
import { TopicTabItem } from './components/TopicTabItem';
import { TopicLoading } from './components/TopicLoading';
// import { MultiValue, SingleValue } from 'react-select';

export const GET_COLLABORATORS = gql`
  query Collaborators {
    collaborators {
      _id
      businessEmail
      role
      profileUrl
      firstName
      lastName
    }
  }
`;

type Collaborator = {
  collaborators: IUser[];
};

type TabKey = 'all' | 'auto' | 'manual';

let timer: NodeJS.Timer;

export const Workspace = () => {
  const navigate = useNavigate();

  const { state: userState } = useContext(UserContext);

  const topics = useAppSelector((state: RootState) => state.topicList);

  const dispatch = useAppDispatch();

  const [statusFilter, setStatusFilter] = useState<FilterStatusType>('opened');
  const [momentumFilter, setMomentumFilter] = useState<EMomentumType>();
  const [userFilter, setUserFilter] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [filterSelected, setFilterSelected] = useState<ETopicFilter>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [order, setOrder] = useState('-1');
  // const [topics, setTopics] = useState<WorkspaceResult['topics']>([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [sortBy, setSortBy] = useState<ESort>(ESort.UPDATED);
  const [momentumOrder, setMomentumOrder] = useState<'asc' | 'desc'>();
  const [searchQuery, setSearchQuery] = useState('');
  const [currentTab, setCurrentTab] = useState<TabKey>('all');

  const [fetchTopics, { data, loading }] = useLazyQuery<WorkspaceResult>(
    GET_TOPICS,
    {
      variables: {
        order,
        filter: statusFilter,
        type: currentTab,
        startDate,
        endDate,
        participants: userFilter,
        momentum: momentumFilter,
        searchQuery,
      },
      fetchPolicy: 'network-only',
    }
  );

  const { data: collabData } = useQuery<Collaborator>(GET_COLLABORATORS);

  const [getStateInfo, { data: topicCreateStatData }] = useLazyQuery<{
    topicCreationStatus: string;
  }>(CHECK_TOPIC_CREATION_STATUS, {
    fetchPolicy: 'network-only',
  });

  const handleTabSelected = (type: TabKey) => setCurrentTab(type);

  useEffect(() => {
    timer = setInterval(() => {
      getStateInfo().then();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, [getStateInfo]);

  const members = useMemo(() => {
    const result = collabData?.collaborators
      .map((item) => {
        return {
          userId: item._id,
          profilePicture: item.profileUrl,
          email: item.businessEmail!,
          role: item.role,
          name: displayUser(item)!,
        };
      })
      ?.filter((item) => item.userId !== userState._id);
    return result || [];
  }, [collabData?.collaborators, userState?._id]);

  const handleSortByChange = useCallback((newState: ESort) => {
    setSortBy(newState);
  }, []);

  const onTopicSelect = useCallback(
    (topicId: string) => () => {
      navigate(ERouteName.Topic + topicId);
    },
    [navigate]
  );

  const onCreateClick = useCallback(() => {
    setIsDrawerOpen(true);
    // navigate(ERouteName.TopicCreate);
  }, []);

  const onCancel = useCallback(() => {
    setIsDrawerOpen(false);
    // navigate(ERouteName.TopicCreate);
  }, []);

  const loadMore = useCallback(() => {
    if (topics.length >= 30 && !searchQuery) {
      fetchTopics({
        variables: {
          order,
          sortField: sortBy,
          filter: statusFilter,
          startDate,
          endDate,
          limit: 30,
          page,
          type: currentTab,
          participant: userFilter,
          momentum: momentumFilter,
          momentumOrder,
        },
      }).then((res) => {
        setPage((prev) => prev + 1);
        if (res.data?.topics && res.data.topics.length > 0) {
          dispatch(
            addNewTopicsToList({
              position: 'bottom',
              topics: res.data?.topics || [],
            })
          );
          // setTopics((prev) => [...prev, ...res.data?.topics!]);
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      });
    }
  }, [
    topics.length,
    searchQuery,
    fetchTopics,
    order,
    sortBy,
    statusFilter,
    startDate,
    endDate,
    page,
    currentTab,
    userFilter,
    momentumFilter,
    momentumOrder,
    dispatch,
  ]);

  useEffect(() => {
    setPage(1);
    setHasMore(searchQuery ? false : true);
    fetchTopics({
      variables: {
        order,
        sortField: sortBy,
        filter: statusFilter,
        type: currentTab,
        startDate,
        endDate,
        limit: 30,
        page: 0,
        participant: userFilter,
        momentum: momentumFilter,
        momentumOrder,
        searchQuery,
      },
      fetchPolicy: 'network-only',
    }).then((res) => {
      if (res.data?.topics && res.data.topics.length > 0) {
        dispatch(initializeTopicList(res.data.topics));
        // setTopics(res.data.topics);
      }
    });

    return () => {
      setPage(0);
      setHasMore(true);
      dispatch(initializeTopicList([]));
    };
  }, [
    dispatch,
    endDate,
    fetchTopics,
    momentumFilter,
    momentumOrder,
    order,
    sortBy,
    startDate,
    statusFilter,
    userFilter,
    searchQuery,
    currentTab,
  ]);

  const onStartDateChange = useCallback((date: Date) => {
    setStartDate(date);
  }, []);

  const onEndDateChange = useCallback((date: Date) => {
    setEndDate(date);
  }, []);

  const handleReset = useCallback((type?: ETopicFilter) => {
    switch (type) {
      case ETopicFilter.DATE:
        setStartDate(undefined);
        setEndDate(undefined);
        break;

      case ETopicFilter.PEOPLE:
        setUserFilter([]);
        break;

      case ETopicFilter.STATUS:
        setStatusFilter('opened');
        break;

      case ETopicFilter.MOMENTUM:
        setMomentumFilter(undefined);
        break;

      default:
        setStartDate(undefined);
        setEndDate(undefined);
        setUserFilter([]);
        setStatusFilter('opened');
        setMomentumFilter(undefined);
        break;
    }
  }, []);

  // const [menuOpen, setMenuOpen] = useState(false);
  // const [deleteOpen, setDeleteOpen] = useState(false);
  // const [closeOpen, setCloseOpen] = useState(false);
  // const [currentTopic, setCurrentTopic] = useState({} as any);

  // const handleTopicDelete = (topic: any) => {
  //   setCurrentTopic(topic);
  //   setDeleteOpen(true);
  // };

  // const handleTopicClose = useCallback((topic: any) => {
  //   setCurrentTopic(topic);
  //   setCloseOpen(true);
  // }, []);

  // const [changeScope] = useMutation<any>(CHANGE_SCOPE);

  // const handleTopicPublic = useCallback(
  //   (topic: any) => {
  //     setCurrentTopic(topic);
  //     changeScope({
  //       variables: {
  //         topicId: topic._id,
  //         scope: !topic.public,
  //       },
  //     }).then((res) => {});
  //   },
  //   [changeScope]
  // );

  const progress = topicCreateStatData?.topicCreationStatus?.split('/');
  const progressValue =
    (Number(progress?.[0] || 0) / Number(progress?.[1] || 1)) * 100;

  return (
    <Page title="Workspace | Multiplayer">
      <Box width="100%">
        <Box
          noPadding
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginTop="2xl"
          marginBottom="xl"
          position="relative"
        >
          <Text.H1 marginBottom="4xs" marginTop="4xs">
            Topics
          </Text.H1>
          {/* <Button
            text="Create topic"
            shape="rectangle"
            onClick={onCreateClick}
            Icon={AddCircle}
            size="large"
            // style={{ marginTop: theme.spacing.m }}
          /> */}
          <div
            className={css`
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              background-color: #6a53fe;
              border-radius: 16px;
              /* height: 80px; */
              padding: 16px 18px;
              cursor: pointer;
            `}
            onClick={onCreateClick}
          >
            <MotePencilIcon
              style={{
                width: '32px',
                height: '32px',
              }}
              fill="#fff"
            />
            <Text.TextSmall1
              marginBottom="4xs"
              marginTop="4xs"
              style={{ marginLeft: '8px' }}
            >
              Create new topic
            </Text.TextSmall1>
          </div>
        </Box>

        <div
          className={css`
            border: 1px solid #272539;
            height: 63px;
            width: 100%;
            border-radius: 24px 24px 0px 0px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <div
            className={css`
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              height: 100%;
            `}
          >
            <TopicTabItem
              title="All"
              Icon={ListDashIcon}
              selected={currentTab === 'all'}
              onSelect={() => handleTabSelected('all')}
            />

            <TopicTabItem
              title="AI Generated"
              buildProgress={Number(progressValue.toFixed(2))}
              Icon={ListDashIcon}
              selected={currentTab === 'auto'}
              onSelect={() => handleTabSelected('auto')}
            />

            <TopicTabItem
              title="Manually generated"
              Icon={ListDashIcon}
              selected={currentTab === 'manual'}
              onSelect={() => handleTabSelected('manual')}
            />
          </div>
          <div
            className={css`
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-end;
              padding-right: 24px;
            `}
          >
            <FilterSection
              filterSelected={filterSelected}
              setFilterSelected={setFilterSelected}
              setStatusFilter={setStatusFilter}
              statusFilter={statusFilter}
              momentumFilter={momentumFilter}
              setMomentumFilter={setMomentumFilter}
              members={[
                {
                  email: userState?.businessEmail!,
                  name: displayUser(userState) || '',
                  profilePicture: userState?.profileUrl,
                  role: userState?.role,
                  userId: userState?._id,
                },
                ...members,
              ]}
              userFilter={userFilter}
              setUserFilter={setUserFilter}
              startDate={startDate}
              endDate={endDate}
              onEndDateChange={onEndDateChange}
              onStartDateChange={onStartDateChange}
              onReset={handleReset}
              handleSortByChange={handleSortByChange}
              sortBy={sortBy}
              onSearQueySubmit={setSearchQuery}
              topicLoading={loading}
            />
            <TooltipContainer text="Disable auto generation">
              <Link to={ERouteName.WorkspaceSetting}>
                <GearIcon
                  style={{ width: '24px', height: '24px', marginLeft: '24px' }}
                  fill="#8C83C3"
                />
              </Link>
            </TooltipContainer>
          </div>
        </div>
        <div
          className={css`
            width: 100%;
            /* height: 80vh; */
            overflow: auto;
          `}
        >
          <InfiniteScroll
            dataLength={topics.length}
            next={loadMore}
            hasMore={hasMore}
            loader={<TopicLoading />}
            endMessage={
              <div>
                {(data?.topics?.length || 0) === 0 && !loading && (
                  <ActivitySeparator text="Yay! You have seen it all" big />
                )}
              </div>
            }
            className={css`
              overflow: hidden !important;
              /* padding-top: 20px; */
              width: 100%;
              height: 100%;
            `}
          >
            <Table>
              <THead>
                <Header>
                  <Th style={{ paddingLeft: '16px' }}>
                    <Text.TextSmall1 color="text/50%">ID</Text.TextSmall1>
                  </Th>
                  <Th style={{ width: '406px' }}>
                    <Text.TextSmall1 color="text/50%">Name</Text.TextSmall1>
                  </Th>
                  <Th></Th>
                  <Th>
                    <Text.TextSmall1 color="text/50%">
                      Participants
                    </Text.TextSmall1>
                  </Th>

                  <Th
                    onClick={() => {
                      setOrder((prev) => (prev === '-1' ? '1' : '-1'));
                      setMomentumOrder(undefined);
                    }}
                  >
                    <div
                      className={css`
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                      `}
                    >
                      <Text.TextSmall1 color="text/50%">
                        {loading
                          ? 'Date updated'
                          : sortBy === ESort.UPDATED
                          ? 'Date updated'
                          : 'Date created'}
                      </Text.TextSmall1>
                      {order === '-1' ? (
                        <ChevronDownIcon
                          fill={theme.colors.text['text/50%']}
                          style={{
                            width: '12px',
                            height: '12px',
                            marginLeft: '8px',
                          }}
                        />
                      ) : (
                        <ChevronUpIcon
                          fill={theme.colors.text['text/50%']}
                          style={{
                            width: '12px',
                            height: '12px',
                            marginLeft: '8px',
                          }}
                        />
                      )}
                    </div>
                  </Th>

                  <Th
                    onClick={() => {
                      setMomentumOrder((prev) =>
                        prev === 'desc' ? 'asc' : 'desc'
                      );
                      setOrder('-1');
                      // setMomentumOrder(undefined);
                    }}
                  >
                    <div
                      className={css`
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                      `}
                    >
                      <Text.TextSmall1 color="text/50%">
                        {loading ? 'Momentum' : 'Momentum'}
                      </Text.TextSmall1>
                      {momentumOrder === 'desc' ? (
                        <ChevronDownIcon
                          fill={theme.colors.text['text/50%']}
                          style={{
                            width: '12px',
                            height: '12px',
                            marginLeft: '8px',
                          }}
                        />
                      ) : (
                        <ChevronUpIcon
                          fill={theme.colors.text['text/50%']}
                          style={{
                            width: '12px',
                            height: '12px',
                            marginLeft: '8px',
                          }}
                        />
                      )}
                    </div>
                  </Th>
                </Header>
              </THead>
              <TBody>
                {topics.map((topic) => (
                  <Tr key={topic._id} onClick={onTopicSelect(topic._id)}>
                    <Td>
                      <Text.BodySM
                        color="text/blue/soft"
                        marginBottom="4xs"
                        marginTop="4xs"
                        style={{ marginLeft: '16px' }}
                      >
                        {shortId(topic._id)}
                      </Text.BodySM>
                    </Td>

                    <Td style={{ width: '406px' }}>
                      <Text.Body
                        marginBottom="4xs"
                        marginTop="4xs"
                        style={{
                          width: '390px',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        {capitalizeString(topic.name)}
                      </Text.Body>
                    </Td>

                    <Td
                      style={{
                        paddingRight: '16px',
                      }}
                    >
                      <div
                        className={css`
                          width: 100%;
                          height: 100%;
                          display: flex;
                          flex-direction: row;
                        `}
                      >
                        {topic.isAutoCreated && (
                          <div
                            className={css`
                              height: 18px;
                              padding: 0px 5px;
                              border-radius: 16px;
                              border: 1px solid #8d86f8;
                              font-size: 8px;
                              color: #8d86f8;
                              display: flex;
                              flex-direction: row;
                              align-items: center;
                              justify-content: center;
                            `}
                          >
                            <SparkleIcon
                              style={{
                                width: '8px',
                                height: '8px',
                                marginRight: '5px',
                              }}
                              fill="#8d86f8"
                            />
                            AI Generated
                          </div>
                        )}
                        {!topic.public && (
                          <TooltipContainer text="Private topic">
                            <div>
                              <LockIcon
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  marginLeft: '7px',
                                }}
                                fill="#8d86f8"
                              />
                            </div>
                          </TooltipContainer>
                        )}
                      </div>
                    </Td>

                    <Td>
                      <AvatarList
                        containerBackground={theme.colors.primary['primary/03']}
                        noInfo
                        maxUser={3}
                        users={
                          topic.collaborators.length > 0
                            ? [...topic.collaborators!].map((user) => ({
                                userId: user._id,
                                userName: displayUser(user) || '',
                                userRole: capitalizeString(
                                  user.role || 'No Role Defined'
                                ),
                                profilePicture: user.profileUrl,
                              }))
                            : [
                                {
                                  userId: topic.creatorId._id,
                                  userName: displayUser(topic.creatorId) || '',
                                  userRole: topic.creatorId.role,
                                  profilePicture: topic.creatorId.profileUrl,
                                },
                              ]
                        }
                      />
                    </Td>

                    <Td>
                      <Text.BodySM>
                        {sortBy === ESort.UPDATED
                          ? topic.updated
                            ? format(+topic.updated, 'MMMM d, yyyy')
                            : ''
                          : format(+topic.created, 'MMMM d, yyyy')}
                      </Text.BodySM>
                    </Td>

                    <Td>
                      <TopicMomentum
                        compacted
                        momentum={topic.closed ? undefined : topic.momentum}
                      />
                    </Td>
                  </Tr>
                ))}
              </TBody>
            </Table>
          </InfiniteScroll>
        </div>

        <CreateTopic
          isDrawerOpen={isDrawerOpen}
          members={members}
          onCancel={onCancel}
          setIsDrawerOpen={setIsDrawerOpen}
          isEdit={false}
        />

        {/* <DeleteDialog
          open={deleteOpen}
          onOpenChange={setDeleteOpen}
          topicId={currentTopic._id!}
          topicName={currentTopic?.name}
        /> */}

        {/* <CloseDialog
          open={closeOpen}
          closed={currentTopic?.closed || false}
          onOpenChange={setCloseOpen}
          topicId={currentTopic._id!}
          topicName={currentTopic?.name}
          reload
        /> */}
      </Box>
    </Page>
  );
};
