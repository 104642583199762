import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { GoogleSignInButton, Text } from 'components';
import { theme } from 'theme';

// import LoginIllustration from './static/LoginIllustration.png';
import LoginIllustration from './static/LoginIllustration.png';
import { ArrowRightAngleIcon, LogoIcon, SparkleIcon } from 'components/Icons';
import {
  getFromLocalStorage,
  removeLocalStorage,
  setToLocalStorage,
} from 'utils';
import { ERouteName } from 'pages';
import { gql, useQuery } from '@apollo/client';
import { css } from '@emotion/css';

const Container = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${theme.colors.accent.white};
  padding: 48px;
`;

const Illustration = styled.div`
  width: 50%;
  max-width: 680px;
  /* height: 100%;
  max-height: 850px; */
  padding-top: 60%;
  display: inline-block;
  position: relative;
  margin-right: 0px;
  background-image: url(${LoginIllustration});
  background-repeat: no-repeat;
  background-size: 100%;
  /* background-position: 20%; */
  vertical-align: top;
  @media (max-width: 1030px) {
    display: none;
  }
`;

const FormContainer = styled.div`
  width: 50%;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  @media (max-width: 1030px) {
    width: 100%;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  word-wrap: break-word;
  width: 65%;
  @media (max-width: 1100px) {
    width: 85%;
  }
`;

// const LogoIconContainer = styled.div`
//   /* padding: ${theme.spacing.xs}px ${theme.spacing.s}px; */
//   border-radius: ${theme.borderRadius.m}px;
//   /* background-color: ${theme.colors.primary['primary/01']}; */
//   margin-bottom: ${theme.spacing.s}px;
// `;

const GET_OAUTH = gql`
  query GoogleOAuthUrl($redirect: String) {
    googleOAuthUrl(redirect: $redirect)
  }
`;

const Login = () => {
  const { data } = useQuery(GET_OAUTH, {
    variables: { redirect: `${process.env.REACT_APP_WEB_URL}/auth/continue` },
  });

  useEffect(() => {
    // todo: remove this
    setToLocalStorage('djibril', 'hello');
  });

  const checkAuth = (authWindow: Window | null) => {
    const intervalId = setInterval(() => {
      const token = getFromLocalStorage('accessToken');
      if (token) {
        clearInterval(intervalId);
        if (authWindow) {
          authWindow.close();
        }
        window.location.href = ERouteName.Feed;
      }
    }, 1000);
  };

  const handleGoogleLogin = () => {
    removeLocalStorage('accessToken');
    removeLocalStorage('refreshToken');
    const url = data?.googleOAuthUrl || '#';
    const authWindow = window.open(url, '_blank');
    checkAuth(authWindow);
  };

  const goToMultiplayer = () => {
    let a = document.createElement('a');
    a.target = '_blank';
    a.href = 'https://multiplayer.work';
    a.click();
  };

  return (
    <Container>
      <FormContainer>
        <Right>
          <Content>
            <LogoIcon style={{ width: 48, height: 48 }} />

            <Text.H1 color="text/dark" marginBottom="4xs" marginTop="2xl">
              Welcome to Multiplayer
            </Text.H1>
            <Text.Body
              marginTop="xs"
              marginBottom="xl"
              style={{ color: 'rgba(23, 21, 34, 0.65)', fontSize: '21px' }}
            >
              Integrate, organize, and track work and communication across apps.
            </Text.Body>

            <div
              className={css`
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 40px;
              `}
            >
              <div>
                <SparkleIcon
                  style={{
                    width: '24px',
                    height: '24px',
                    marginRight: '12px',
                  }}
                  fill="#171425"
                />
              </div>
              <Text.Body style={{ color: '#171425' }}>
                We use AI to automatically build and maintain your workspace for
                you; we will ask you for the permissions needed to do this on
                the next page.
              </Text.Body>
            </div>
            <GoogleSignInButton
              disabled={!data?.googleOAuthUrl}
              onClick={handleGoogleLogin}
            />
          </Content>
        </Right>
      </FormContainer>
      <Illustration>
        <div
          className={css`
            border-radius: 12%;
            background: #f6f6f6;
            width: 28%;
            padding-top: 23%;
            position: absolute;
            top: 0px;
            right: 0;
            cursor: pointer;
          `}
          onClick={goToMultiplayer}
        >
          <div
            className={css`
              position: absolute;
              top: 5px;
              right: 10px;
              width: 28px;
              height: 28px;
              border-radius: 14px;
              background-color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
            `}
          >
            <ArrowRightAngleIcon style={{ width: '11px', height: '8px' }} />
          </div>
          <div
            className={css`
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              display: flex;
              flex-direction: row;
              align-items: flex-end;
              padding: 10px;
            `}
          >
            <Text.H5
              color="text/dark"
              style={{ fontSize: '19px', fontWeight: '500' }}
            >
              Learn more <br />
              about Multiplayer
            </Text.H5>
          </div>
        </div>
      </Illustration>
    </Container>
  );
};

export default Login;
