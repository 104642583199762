import { gql, useMutation } from '@apollo/client';
import { Dialog, Select } from 'components';
import { updateTopicLink } from 'features/topicLink';
import { useAppDispatch, useAppSelector } from 'hooks';
import React, { useCallback, useMemo, useState } from 'react';
import { MultiValue, SingleValue } from 'react-select';
import { RootState } from 'reducers';
import { theme } from 'theme';
import { capitalizeString } from 'utils';

interface Props {
  open?: boolean;
  linkId: string;
  groups: string[];
  onOpenChange?(open: boolean): void;
}

const ADD_TO_GROUP = gql`
  mutation addToGroup($id: ID!, $groups: [String]) {
    editLink(id: $id, groups: $groups)
  }
`;

export function AddLinkToGroup(props: Props) {
  // const [newGroup, setNewGroup] = useState('');
  const [category, setCategory] = useState('');

  const dispatch = useAppDispatch();

  const [addToGroup, { loading }] = useMutation(ADD_TO_GROUP);

  const group = useAppSelector((state: RootState) => state.activityFilter);

  const categories = useMemo(
    () =>
      group?.map((item: any) => ({
        label: capitalizeString(item.name, true),
        value: item.name,
      })) || [],
    [group]
  );

  const onSelectChange = useCallback(
    (
      values:
        | SingleValue<{
            value: string;
            label: string;
          }>
        | MultiValue<{
            value: string;
            label: string;
          }>
    ) => {
      const casted = values as { label: string; value: string };
      setCategory(casted.label.toLocaleLowerCase());
      // setNewGroup('');
    },
    []
  );

  return (
    <Dialog
      additionalContent={
        <div>
          <Select
            background={theme.colors.primary['primary/01']}
            data={categories}
            onChange={onSelectChange}
            placeholder="Select the group"
          />
        </div>
      }
      description={''}
      open={props.open}
      submitting={loading}
      submitDisabled={!category}
      title="Add to a group"
      onOpenChange={props.onOpenChange}
      onSubmit={async (onClose) => {
        addToGroup({
          variables: {
            id: props.linkId,
            groups: [...props.groups.filter((el) => el !== category), category],
          },
        }).then((re) => {
          onClose();
          // navigate(ERouteName.Workspace);
        });
        dispatch(
          updateTopicLink({
            id: props.linkId,
            link: {
              groups: [
                ...props.groups.filter((el) => el !== category),
                category,
              ],
            },
          })
        );
      }}
    />
  );
}
