import { gql, useQuery } from '@apollo/client';
import { css } from '@emotion/css';
import { Box, Text } from 'components';
import { TopicCreatedIcon } from 'components/Icons';
import React from 'react';
import { ToolTipHelper } from './ToolTipHelper';

const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

// function getReport(activities: { created: Date }[]) {
//   // const dayNames = ['sun', 'mon', 'tue', 'wed', 'thur', 'fri', 'sat'];

//   const lastMonth = Array.from({ length: 30 }, (_, i) => {
//     const date = new Date();
//     date.setDate(date.getDate() - i);
//     return {
//       day: days[date.getDay()],
//       date: date.toISOString().slice(0, 10), // format: "YYYY-MM-DD"
//       extraWork: null as any,
//     };
//   }).reverse();

//   function getDuration(date1: Date, date2: Date) {
//     if (!date1 || !date2) return 0;
//     const diffInMs = Math.abs(date2.getTime() - date1.getTime());
//     return Math.round(diffInMs / 60000);
//   }

//   activities.forEach((activity) => {
//     const activityDate = new Date(activity.created);
//     const activityHour = activityDate.getHours();
//     const formattedDate = activityDate.toISOString().slice(0, 10);

//     if (activityHour >= 19 || activityHour < 7) {
//       const day = lastMonth.find((day) => day.date === formattedDate);

//       if (day) {
//         if (!day.extraWork) {
//           day.extraWork = {
//             first: activity.created,
//             last: activity.created,
//             duration: 0,
//           };
//         } else {
//           day.extraWork.last = activity.created;
//           day.extraWork.duration = getDuration(
//             new Date(day.extraWork.last),
//             new Date(day.extraWork.first)
//           );
//         }
//       }
//     }
//   });

//   return lastMonth;
// }

const GET_ACTIVITIES = gql`
  query CalendarStats($userId: ID!) {
    workLifeBalance(userId: $userId) {
      day
      date
      extraWork {
        first
        last
        duration
      }
    }
  }
`;

type TWorkLifeBalance = {
  workLifeBalance: {
    day: string;
    date: string;
    extraWork?: {
      first: Date;
      last: Date;
      duration: number;
    };
  }[];
};

type Props = {
  userId: string;
};

export const WorkLifeBalance: React.FC<Props> = ({ userId }) => {
  const { data } = useQuery<TWorkLifeBalance>(GET_ACTIVITIES, {
    variables: { userId },
  });

  let workLifeBalance = data?.workLifeBalance || [];

  if (workLifeBalance?.length === 0) {
    // if there is no data, then we need to show the last 30 days
    // and the first day of the month is the first day of the week
    workLifeBalance = Array.from({ length: 30 }, (_, i) => {
      const date = new Date();
      date.setDate(date.getDate() - i);
      return {
        day: days[date.getDay()],
        date: `x/${date.getDate()}`, // we only need the date
        extraWork: null as any,
      };
    }).reverse();
  }

  let firstIndex = days.findIndex((day) => day === workLifeBalance[0]?.day);

  if (firstIndex === 6) {
    // in this case the last date is rendering outside the entire box
    // So removing the first date to fit
    // see issue: https://github.com/multiplayer-work/multiplayer-core/issues/656
    workLifeBalance = (data?.workLifeBalance || []).slice(1);
    firstIndex = days.findIndex((day) => day === workLifeBalance[0]?.day);
  }

  const getColor = (duration: number) => {
    if (duration <= 30) {
      return '#383456';
    } else if (duration <= 60) {
      return '#5D568E';
    } else {
      return '#8D86F8';
    }
  };

  return (
    <Box
      backgroundColor="primary/03"
      position="relative"
      width="856px"
      borderRadius="l"
      noPadding
    >
      <div
        className={css`
          position: relative;
          width: 100%;
          height: 100%;
          padding: 36px;
        `}
      >
        <div
          className={css`
            height: 26px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            border-radius: 13px;
            border: 1px solid #a99dcb;
            width: fit-content;
            padding-inline: 8px;
            margin-bottom: 14px;
          `}
        >
          <Text.BodySM
            style={{ color: '#A99DCB' }}
            marginBottom="4xs"
            marginTop="4xs"
          >
            Work
          </Text.BodySM>
          <TopicCreatedIcon
            fill="#A99DCB"
            style={{
              width: '16px',
              height: '16px',
              marginLeft: '8px',
              marginRight: '8px',
            }}
          />
          <Text.BodySM
            style={{ color: '#A99DCB' }}
            marginBottom="4xs"
            marginTop="4xs"
          >
            Life Balance
          </Text.BodySM>
        </div>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          height="290px"
          width="100%"
        >
          <div
            className={css`
              max-width: 400px;
              min-width: 300px;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
            `}
          >
            <div>
              <div
                className={css`
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: flex-start;
                `}
              >
                <div
                  className={css`
                    width: 16px;
                    height: 16px;
                    border-radius: 2px;
                    background: #8d86f8;
                    margin-right: 8px;
                  `}
                />
                <Text.BodySM
                  style={{ color: '#A99DCB' }}
                  marginBottom="4xs"
                  marginTop="4xs"
                >
                  😵
                </Text.BodySM>
              </div>

              <div
                className={css`
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: flex-start;
                  margin-top: 8px;
                `}
              >
                <div
                  className={css`
                    width: 16px;
                    height: 16px;
                    border-radius: 2px;
                    background: #5d568e;
                    margin-right: 8px;
                  `}
                />
                <Text.BodySM
                  style={{ color: '#A99DCB' }}
                  marginBottom="4xs"
                  marginTop="4xs"
                >
                  😥
                </Text.BodySM>
              </div>

              <div
                className={css`
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: flex-start;
                  margin-top: 8px;
                `}
              >
                <div
                  className={css`
                    width: 16px;
                    height: 16px;
                    border-radius: 2px;
                    background: #383456;
                    margin-right: 8px;
                  `}
                />
                <Text.BodySM
                  style={{ color: '#A99DCB' }}
                  marginBottom="4xs"
                  marginTop="4xs"
                >
                  😌
                </Text.BodySM>
              </div>
            </div>

            <div>
              <ToolTipHelper text="See if you’re overworking yourself by keeping your overtime work in check." />
              <Text.BodySM
                style={{ color: '#A99DCB', width: '200px' }}
                marginTop="s"
              >
                See if you’re overworking yourself by keeping your overtime work
                in check.
              </Text.BodySM>
            </div>
          </div>
          <div
            className={css`
              height: 100%;
              display: grid;
              grid-template-columns: repeat(7, 1fr);
              grid-auto-rows: 59px;
              gap: 0px;
              margin-left: 15px;
              margin-top: -160px;
              /* grid-column-gap: 0px;
            grid-row-gap: 0px; */
            `}
          >
            {days.map((day) => (
              <div
                key={day}
                className={css`
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: flex-end;
                  text-transform: uppercase;
                  font-size: 9px;
                  padding-bottom: 10px;
                  color: #8d86f8;
                `}
              >
                {day}
              </div>
            ))}
            {firstIndex > 0 &&
              Array.from({
                length: firstIndex,
              }).map((_, index) => (
                <div
                  key={index}
                  className={css`
                    width: 62px;
                    height: 59px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #453e69;
                    font-size: 16px;
                    color: #8d86f8;
                    font-weight: 600;
                  `}
                />
              ))}
            {workLifeBalance?.map((d) => (
              <div
                key={d.date}
                className={css`
                  width: 62px;
                  height: 59px;
                  background-color: ${d?.extraWork?.last
                    ? getColor(d?.extraWork?.duration)
                    : ''};
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border: 1px solid #453e69;
                  font-size: 16px;
                  color: ${d?.extraWork?.last ? '#000' : '#8d86f8'};
                  font-weight: 600;
                `}
              >
                {d?.date.split('/')[1]}
              </div>
            ))}
            {Array.from({
              length:
                35 -
                ((firstIndex > 0 ? firstIndex : 0) +
                  (workLifeBalance?.length || 0)),
            }).map((_, index) => (
              <div
                key={index}
                className={css`
                  width: 62px;
                  height: 59px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border: 1px solid #453e69;
                  font-size: 16px;
                  color: #8d86f8;
                  font-weight: 600;
                `}
              />
            ))}
          </div>
        </Box>
      </div>
    </Box>
  );
};
