import { gql, useQuery } from '@apollo/client';
import { css } from '@emotion/css';
import { Box, HorizontalBar, Text } from 'components';
import { TopicCreatedIcon } from 'components/Icons';
import React from 'react';
import { ToolTipHelper } from './ToolTipHelper';

const MEETING_REPARTITION = gql`
  query MeetingDurationPerDay($userId: ID!) {
    meetingDurationPerDay(userId: $userId) {
      fri
      mon
      sat
      thur
      sun
      tue
      wed
    }
  }
`;

type MeetingDurationPerDay = {
  meetingDurationPerDay: {
    sun?: number;
    mon?: number;
    tue?: number;
    wed?: number;
    thur?: number;
    fri?: number;
    sat?: number;
  };
};

type Props = {
  userId: string;
  placeholder?: string;
};
export const MeetingRepartition: React.FC<Props> = ({
  userId,
  placeholder,
}) => {
  const { data, loading } = useQuery<MeetingDurationPerDay>(
    MEETING_REPARTITION,
    {
      variables: { userId },
    }
  );

  const stats =
    loading || !data?.meetingDurationPerDay
      ? placeholder
        ? JSON.parse(placeholder)
        : {}
      : data?.meetingDurationPerDay;
  return (
    <Box
      backgroundColor="primary/11"
      position="relative"
      width="386px"
      height="402px"
      borderRadius="l"
      noPadding
      style={{ marginLeft: '15px' }}
    >
      <div
        className={css`
          width: 100%;
          height: 100%;
          position: relative;
          padding: 36px 28px;
        `}
      >
        <div>
          <div
            className={css`
              height: 26px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              width: fit-content;
              margin-bottom: 28px;
            `}
          >
            <div
              className={css`
                height: 26px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                border-radius: 13px;
                border: 1px solid #a99dcb;
                width: fit-content;
                padding-inline: 8px;
              `}
            >
              <Text.BodySM
                style={{ color: '#A99DCB' }}
                marginBottom="4xs"
                marginTop="4xs"
              >
                Meeting
              </Text.BodySM>
              <TopicCreatedIcon
                fill="#A99DCB"
                style={{
                  width: '16px',
                  height: '16px',
                  marginLeft: '8px',
                  marginRight: '8px',
                }}
              />
              <Text.BodySM
                style={{ color: '#A99DCB' }}
                marginBottom="4xs"
                marginTop="4xs"
              >
                Execution Time
              </Text.BodySM>
            </div>
            <div className={css``}>
              <ToolTipHelper
                text="What's your ratio of meeting time to time left for execution?"
                size={{ width: '26px', height: '26px' }}
              />
            </div>
          </div>
          <HorizontalBar
            value={(stats?.sun || 0) * 100}
            label="sun"
            style={{ marginBottom: '11px' }}
          />
          <HorizontalBar
            value={(stats?.mon || 0) * 100}
            label="mon"
            style={{ marginBottom: '11px' }}
          />
          <HorizontalBar
            value={(stats?.tue || 0) * 100}
            label="tue"
            style={{ marginBottom: '11px' }}
          />
          <HorizontalBar
            value={(stats?.wed || 0) * 100}
            label="wed"
            style={{ marginBottom: '11px' }}
          />
          <HorizontalBar
            value={(stats?.thur || 0) * 100}
            label="thu"
            style={{ marginBottom: '11px' }}
          />
          <HorizontalBar
            value={(stats?.fri || 0) * 100}
            label="fri"
            style={{ marginBottom: '11px' }}
          />
          <HorizontalBar
            value={(stats?.sat || 0) * 100}
            label="sat"
            style={{ marginBottom: '11px' }}
          />
        </div>

        <div
          className={css`
            width: 300px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-left: 38px;
          `}
        >
          <span
            className={css`
              color: #8d86f8;
              font-family: 'Roobert';
              font-size: 9px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              text-transform: uppercase;
            `}
          >
            Meeting
          </span>
          <span
            className={css`
              color: #211f30;
              font-family: 'Roobert';
              font-size: 9px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              text-transform: uppercase;
            `}
          >
            Execution
          </span>
        </div>
      </div>
    </Box>
  );
};
