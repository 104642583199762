import { css } from '@emotion/css';
import React from 'react';

type Props = {
  color: string;
};
export const ItemSeparator: React.FC<Props> = ({ color }) => {
  return (
    <div
      className={css`
        width: 100%;
        height: 1px;
        background-color: ${color};
        position: relative;
        margin-top: 8px;
        margin-bottom: 8px;
      `}
    >
      <div
        className={css`
          position: absolute;
          top: -2px;
          right: 0px;
          width: 4px;
          height: 4px;
          background-color: ${color};
          border-radius: 50%;
        `}
      />
    </div>
  );
};
