import React from 'react';
import { Table, Td, Tr } from './primary';
import { SkeletonLoading } from 'components';
import _ from 'lodash';

export const TopicLoading = () => (
  <Table>
    {_.range(10).map((item) => (
      <Tr key={item}>
        <Td>
          <SkeletonLoading
            type="rectangle"
            style={{ width: '300px', marginLeft: '20px' }}
          />
        </Td>
        <Td>
          <SkeletonLoading type="rectangle" style={{ width: '300px' }} />
        </Td>
        <Td>
          <SkeletonLoading type="rectangle" style={{ width: '100px' }} />
        </Td>
        <Td></Td>
        <Td></Td>
        <Td>
          <SkeletonLoading type="rectangle" style={{ width: '100px' }} />
        </Td>
      </Tr>
    ))}
  </Table>
);
