import React, { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';

import { theme, Theme } from 'theme';
import { Text } from 'components/Text';
import { css } from '@emotion/css';
import { Close } from 'components/Icons';

type CustomInputProps = {
  Icon?: React.FunctionComponent<any>;
  background?: string;
  color?: keyof Theme['colors']['text'];
  height?: number;
  width?: string;
  borderColor?: keyof Theme['colors']['text'];
  error?: string;
  small?: boolean;
  big?: boolean;
  children?: React.ReactNode;
  iconPosition?: 'right' | 'left';
  iconSize?: number;
};
type InputProps = React.InputHTMLAttributes<HTMLInputElement> &
  CustomInputProps;

// const iconSize = 16;

export const Input: React.FC<InputProps> = ({
  Icon,
  style,
  error,
  background,
  color,
  height,
  width,
  borderColor,
  small,
  big,
  children,
  iconPosition = 'left',
  iconSize = 16,
  onChange,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  const InputComponent = useMemo(
    () => styled.input<CustomInputProps>`
      border-radius: 8px;
      border: 1px solid
        ${borderColor ? theme.colors.text[borderColor] : 'transparent'};
      outline: none;
      box-sizing: border-box;
      transition: border-color 0.15s;
      width: ${width ? width : '100%'};
      height: ${small ? '27px' : height ? height + 'px' : '100%'};
      background-color: ${background
        ? background
        : theme.colors.primary['primary/03']};
      color: ${color
        ? theme.colors.text[color]
        : theme.colors.text['text/main']};
      font-size: ${big
        ? theme.textVariants.h1.fontSize
        : small
        ? theme.textVariants['text-small-2'].fontSize
        : theme.textVariants['text-small-1'].fontSize};
      font-weight: ${theme.textVariants['text-small-1'].fontWeight};
      font-family: ${theme.textVariants['text-small-1'].fontFamily};
      padding-right: ${theme.spacing.m}px;
      padding-left: ${Icon && iconPosition === 'left'
        ? iconSize + 2 * theme.spacing.m
        : theme.spacing.m}px;
      &::placeholder {
        color: ${theme.colors.text['text/menu']};
        font-size: ${big
          ? theme.textVariants.h1.fontSize
          : small
          ? theme.textVariants['text-small-2'].fontSize
          : theme.textVariants['text-small-1'].fontSize};
      }
      &:hover,
      &:focus {
        /* border-color: ${theme.colors.accent['blue/main']}; */
      }
    `,
    [
      Icon,
      background,
      big,
      borderColor,
      color,
      height,
      iconPosition,
      iconSize,
      small,
      width,
    ]
  );
  const InputContainer = useMemo(
    () => styled.div<CustomInputProps>`
      height: ${small ? 27 : height ? height : 40}px;
      position: relative;
      width: ${width || '100%'};
    `,
    [height, small, width]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event);
      if (children) {
        setOpen(true);
      }
    },
    [children, onChange]
  );

  const iconStyle: any = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: iconSize,
    height: iconSize,
  };

  if (iconPosition === 'left') {
    iconStyle.left = theme.spacing.m;
  } else {
    iconStyle.right = theme.spacing.m;
  }
  return (
    <InputContainer style={style}>
      {children && open && (
        <div
          className={css`
            width: 100%;
            height: 150px;
            background-color: ${theme.colors.primary['primary/02']};
            position: absolute;
            top: -160px;
            border-radius: ${theme.borderRadius.l}px ${theme.borderRadius.l}px
              0px 0px;
          `}
        >
          <div
            className={css`
              width: 100%;
              height: 150px;
              position: relative;
              overflow: auto;
            `}
          >
            <div
              className={css`
                width: 24px;
                height: 24px;
                position: absolute;
                right: 0px;
                top: 8px;
                cursor: pointer;
              `}
              onClick={() => setOpen(false)}
            >
              <Close
                fill={theme.colors.text['text/blue/soft']}
                style={{ width: '12px' }}
              />
            </div>
            {children}
          </div>
        </div>
      )}
      {Icon && (
        <Icon fill={theme.colors.text['text/blue/soft']} style={iconStyle} />
      )}
      <InputComponent Icon={Icon} onChange={handleChange} {...props} />
      {error && (
        <Text.BodySM
          color="text/error"
          style={{
            marginTop: -theme.spacing['2xs'],
            marginLeft: theme.spacing['2xs'],
          }}
        >
          {error}
        </Text.BodySM>
      )}
    </InputContainer>
  );
};
