import React from 'react';
import { theme } from 'theme';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';
import { RadialSeparators } from './RadialSeparators';

type Props = {
  value: number;
  className?: string;
  color?: string;
};
export const CircularProgress: React.FC<Props> = ({
  value,
  className,
  color,
}) => {
  return (
    <div style={{ width: 64, height: 64 }} className={className}>
      <CircularProgressbarWithChildren
        value={value}
        text={`${value}%`}
        strokeWidth={3}
        maxValue={100}
        minValue={0}
        styles={buildStyles({
          strokeLinecap: 'butt',
          textSize: '19px',
          pathTransitionDuration: 2,
          trailColor: theme.colors.primary['primary/02'],
          pathColor: color || theme.colors.accent['yellow/main'],
          textColor: theme.colors.text['text/100%'],
        })}
      >
        <RadialSeparators
          count={20}
          style={{
            background: theme.colors.primary['primary/02'],
            width: '3px',
            // This needs to be equal to props.strokeWidth
            height: `${3}%`,
          }}
        />
      </CircularProgressbarWithChildren>
    </div>
  );
};
