import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IUser } from 'model';

export type ITopic = {
  collaborators: IUser[];
  created: number;
  creatorId: IUser;
  _id: string;
  name: string;
  description?: string;
  public: boolean;
  closed: boolean;
};

type IUpdate = Partial<Omit<IUser, '_id' | 'created'>>;

const initialState = {} as IUser;

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    initializeUser: (state, action: PayloadAction<IUser>) => {
      return action.payload;
    },
    updateUserReducer: (state, action: PayloadAction<IUpdate>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { initializeUser, updateUserReducer } = userSlice.actions;

export const userReducer = userSlice.reducer;
