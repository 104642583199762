import React from 'react';
import { format } from 'date-fns';
import { ActivityHolder } from 'components';
import { LinearIssueModel } from '../types';
import { EActivityType } from 'model';
import LinearLogo from './assets/linear-app-icon.png';

type Props = {
  id: string;
  topicId: string;
  customRef: React.LegacyRef<HTMLDivElement>;
  focus?: boolean;
  stared: boolean;
  last?: boolean;
  change: LinearIssueModel['change'];
  title: string;
  url: string;
  modifiedDate: number;
  // isDrawerOpen?: boolean;
  // toggleDrawer?: VoidFunction;
};
export const LinearIssueUpdate: React.FC<Props> = ({
  topicId,
  id,
  customRef,
  stared,
  last,
  change,
  title,
  url,
  modifiedDate,
}) => {
  return (
    <ActivityHolder.Item
      topicId={topicId}
      type={EActivityType.LINEAR_ISSUE}
      id={id}
      customRef={customRef}
      link={`${process.env.REACT_APP_WEB_URL}/topic/${topicId}#${id}`}
      Icon={
        <img
          src={LinearLogo}
          alt="Linear icon"
          style={{ width: '20px', height: '20px' }}
        />
      }
      title={`*<a href="${url}" target="_blank" rel="noreferrer">${title}</a>** ${
        change?.type === 'state' ? 'status' : 'ownership'
      } changed ${change?.from ? `from *${change.from}**` : ''} to *${
        change?.to || ''
      }** `}
      subTitle={`${format(+modifiedDate, 'MMMM d, yyyy • h:mm aa')}`}
      // color={theme.colors.primary['primary/03']}
      stared={stared}
      last={last}
    />
  );
};
