import { css } from '@emotion/css';
import { Box, Text } from 'components';
import React from 'react';
import { CardHeader } from './CardHeader';
import { ItemSeparator } from './Separator';

type Props = {
  Icon: React.FC<any>;
  text: string;
  background?: 'primary/05' | 'primary/11';
  values: {
    value: string | number;
    label: string;
  }[];
};
export const StatsItem: React.FC<Props> = ({
  values,
  text,
  Icon,
  background = 'primary/05',
}) => {
  return (
    <Box
      backgroundColor={background}
      position="relative"
      width="486px"
      borderRadius="l"
      noPadding
      style={{ marginLeft: '15px' }}
    >
      <div
        className={css`
          width: 100%;
          position: relative;
          padding: 24px;
        `}
      >
        <CardHeader text={text} color="#000" Icon={Icon} />
        <Box noPadding width="80%" marginTop="2xl">
          {values.map((val, idx) => (
            <React.Fragment key={val.label}>
              <Item value={val.value} label={val.label} />
              {idx < values.length - 1 && <ItemSeparator color="#000" />}
            </React.Fragment>
          ))}
        </Box>
      </div>
    </Box>
  );
};

type ItemProps = {
  label: string;
  value: number | string;
};
const Item: React.FC<ItemProps> = ({ label, value }) => {
  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      `}
    >
      <Text.H4 color="text/dark" marginBottom="4xs" marginTop="4xs">
        {label}
      </Text.H4>
      <Text.H4 color="text/dark" marginBottom="4xs" marginTop="4xs">
        {value}
      </Text.H4>
    </div>
  );
};
