import React from 'react';
import { Box, PageLoading, Text, TextEditor } from 'components';
import {
  Comment,
  GoogleCommentIcon,
  GoogleLogo,
  NotionLogo,
  SlackLogo,
  TopicCreatedIcon,
} from 'components/Icons';

import { theme } from 'theme';
import { css } from '@emotion/css';
import { Header, Table, TBody, Td, Th, THead, Tr } from '.';
import { SearchResult } from '../type';
import { useNavigate } from 'react-router-dom';
import { ERouteName } from 'pages';
import { EActivityType } from 'model';

type Props = {
  searchResult: SearchResult[];
  loading?: boolean;
};
export const Right: React.FC<Props> = ({ searchResult, loading }) => {
  const navigate = useNavigate();

  const getIcon = (activityType?: EActivityType) => {
    switch (activityType) {
      case EActivityType.COMMENT:
        return <Comment style={{ width: '16px', height: '16px' }} />;

      case EActivityType.REPLY:
        return <Comment style={{ width: '16px', height: '16px' }} />;

      case EActivityType.GOOGLE:
        return <GoogleLogo style={{ width: '16px', height: '16px' }} />;

      case EActivityType.GOOGLE_COMMENT:
        return <GoogleCommentIcon style={{ width: '16px', height: '16px' }} />;

      case EActivityType.NOTION:
        return <NotionLogo style={{ width: '16px', height: '16px' }} />;

      case EActivityType.SLACK:
        return <SlackLogo style={{ width: '16px', height: '16px' }} />;

      default:
        return <TopicCreatedIcon />;
    }
  };
  const handleClick = (value: SearchResult) => {
    navigate(`${ERouteName.Topic}${value.topicId}#${value.activityId || ''}`);
  };
  return (
    <div>
      {loading ? (
        <PageLoading />
      ) : searchResult.length > 0 ? (
        <Table>
          <THead>
            <Header>
              <Th>{/* <Text.BodySM color="text/light"></Text.BodySM> */}</Th>
              <Th>
                <Text.BodySM color="text/light">Topic name</Text.BodySM>
              </Th>
              <Th>
                <Text.BodySM color="text/light">Details</Text.BodySM>
              </Th>
            </Header>
          </THead>
          <TBody>
            {searchResult.map((result) => (
              <Tr
                key={`${result.topicId}-${result.activityId || ''}`}
                onClick={() => handleClick(result)}
              >
                <Td>{getIcon(result.activityType)}</Td>

                <Td
                  className={css`
                    min-width: 400px;
                  `}
                >
                  <div
                    className={css`
                      color: ${theme.colors.text['text/main']};
                    `}
                    dangerouslySetInnerHTML={{
                      __html: `<p>${result.name}</p>`,
                    }}
                  />
                </Td>
                <Td
                  className={css`
                    max-width: 60%;
                  `}
                >
                  {result.comment && !result.message && (
                    <TextEditor readonly value={result.comment} compact />
                  )}
                  {result.title && (
                    <div
                      className={css`
                        color: ${theme.colors.text['text/main']};
                      `}
                      dangerouslySetInnerHTML={{
                        __html: `<p>${result.title}</p>`,
                      }}
                    />
                  )}
                  {result.message && (
                    <div
                      className={css`
                        width: 100%;
                        max-height: 100px;
                        word-wrap: break-word;
                        color: ${theme.colors.text['text/main']};
                        overflow: auto;
                        span {
                          color: ${theme.colors.text['text/yellow']};
                          /* background-color: ${theme.colors.accent[
                            'blue/light'
                          ]}; */
                        }
                        a {
                          color: ${theme.colors.text['text/pink']};
                        }
                      `}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            result.message || ''.split('\n').join('<br />'),
                        }}
                      />
                      {result.comment && (
                        <div
                          className={css`
                            color: ${theme.colors.text['text/light']};
                            /* color: #ffff0077; */
                          `}
                          dangerouslySetInnerHTML={{
                            __html: `<p>${result.name}</p>`,
                          }}
                        />
                      )}
                    </div>
                  )}
                </Td>
              </Tr>
            ))}
          </TBody>
        </Table>
      ) : (
        <Box>
          <Text.Body>
            Sorry we are not able to find anything that matches your research
            criteria 🙁
          </Text.Body>
        </Box>
      )}
    </div>
  );
};
