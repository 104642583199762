import { gql } from '@apollo/client';

export const GET_ALL_TOPICS = gql`
  query Topics($order: String, $filter: String) {
    topics(order: $order, filter: $filter) {
      _id
      name
    }
  }
`;

export const GET_SLACK_PROFILE = gql`
  query SlackProfileUrl($userId: ID!) {
    slackProfileUrl(userId: $userId)
  }
`;

export const GET_TOPICS = gql`
  query Topics($userId: ID!, $limit: Int, $page: Int) {
    mostInteractedTopics(userId: $userId, limit: $limit, page: $page) {
      _id
      name
      closed
      momentum {
        count
        label
        lastActivity
      }
    }
    # totalTopics(workspaceId: $workspaceId, userId: $userId, filter: $filter)
  }
`;

export const GET_PROFILE = gql`
  query getUserInfo($id: String!) {
    userById(id: $id) {
      email
      firstName
      lastName
      businessEmail
      description
      role
      profileUrl
      bannerUrl
      linkedinProfile
      githubProfile
      slackProfile
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation updateUser(
    $email: String
    $firstName: String
    $lastName: String
    $role: String
    $description: String
    $linkedinProfile: String
    $githubProfile: String
    $profileUrl: String
    $bannerUrl: String
  ) {
    updateUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      role: $role
      description: $description
      linkedinProfile: $linkedinProfile
      githubProfile: $githubProfile
      profileUrl: $profileUrl
      bannerUrl: $bannerUrl
    ) {
      email
      firstName
      lastName
      businessEmail
      description
      role
      profileUrl
      bannerUrl
      linkedinProfile
      githubProfile
    }
  }
`;

export const FOLLOW = gql`
  mutation followUser($userId: ID!) {
    followUser(userFollowed: $userId)
  }
`;

export const UN_FOLLOW = gql`
  mutation unFollowUser($userId: ID!) {
    unFollowUser(userFollowed: $userId)
  }
`;

export const GET_ACTIVITY = gql`
  query getActivity($workspaceId: ID!, $userId: ID!, $page: Int) {
    activityForUser(workspaceId: $workspaceId, userId: $userId, page: $page) {
      comment
      _id
      created
      topicId
      data
    }
    totalActivity(workspaceId: $workspaceId, userId: $userId)
  }
`;

export const ADD_PERSONAL_EMAIL = gql`
  mutation updateUser($email: String) {
    updateUser(email: $email) {
      email
    }
  }
`;

export const GET_COLLABORATORS = gql`
  query Collaborators {
    collaborators {
      role
      firstName
      lastName
      profileUrl
      businessEmail
      _id
    }
  }
`;

export const INVITE = gql`
  mutation InviteUserToTopic($topicId: ID!, $userEmails: [String!]!) {
    inviteUserToTopic(topicId: $topicId, userEmails: $userEmails) {
      _id
      profileUrl
      firstName
      lastName
      businessEmail
    }
  }
`;

export const GET_DATA_REP = gql`
  query UserActivityRepartition($userId: ID!, $range: String) {
    userActivityRepartition(userId: $userId, range: $range) {
      id
      percentage
      topic
    }
  }
`;

export const GET_TOP_DOC = gql`
  query TopKDocuments($userId: ID!) {
    topKDocuments(userId: $userId) {
      docId
      title
      type
      url
      revisions
      updated
    }
  }
`;
