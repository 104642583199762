import React from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import './styles.css';
import { theme } from 'theme';

type Props = {
  text: string;
  side?: 'bottom' | 'top' | 'right' | 'left';
  align?: 'start' | 'center' | 'end';
  children: React.ReactNode;
  additionalContent?: JSX.Element;
};
export const TooltipContainer: React.FC<Props> = ({
  text,
  children,
  side = 'top',
  align = 'center',
  additionalContent,
}) => {
  const renderText = () => {
    const textComp = text
      .split('**')
      .join('</span>')
      .split('*')
      .join('<span style="color: #838094;">');
    return <span dangerouslySetInnerHTML={{ __html: textComp }} />;
  };
  return (
    <Tooltip.Provider delayDuration={10}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            className="TooltipContent"
            side={side}
            align={align}
            // alignOffset={v2 ? 40 : undefined}
            style={{
              backgroundColor: theme.colors.primary['primary/03'],
              color: '#C5C4C8',
              // minHeight: '40px',
              display: 'flex',
              flexDirection: 'column',
            }}
            sideOffset={5}
          >
            <div
              style={{
                // backgroundColor: theme.colors.primary['primary/03'],
                color: '#C5C4C8',
                minHeight: '40px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {renderText()}

              <Tooltip.Arrow
                className="TooltipArrow"
                style={{
                  fill: theme.colors.primary['primary/03'],
                  height: '7px',
                  width: '14px',
                }}
              />
            </div>
            {additionalContent && <div>{additionalContent}</div>}
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};
