import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IUser } from 'model';
import { TTopicBasics } from 'pages/Topic/types';

export interface IDoc {
  id: string;
  url: string;
  created?: string;
  type: string;
  lastUpdated?: string;
  lastUpdatedBy?: string;
  thumbnail?: string;
  title?: string;
}

export interface ITopic extends Omit<TTopicBasics, 'links' | 'scope'> {}

type IUpdate = Partial<Omit<ITopic, '_id' | 'creatorId' | 'created'>>;

const initialState = {} as ITopic;

export const topicSlice = createSlice({
  name: 'topicLink',
  initialState,
  reducers: {
    initializeTopic: (state, action: PayloadAction<ITopic>) => {
      return action.payload;
    },
    updateTopicReducer: (state, action: PayloadAction<IUpdate>) => {
      return { ...state, ...action.payload };
    },
    addNewCollaboratorToTopic: (state, action: PayloadAction<IUser[]>) => {
      const collaborators = [...(state.collaborators || []), ...action.payload];
      return { ...state, collaborators };
    },
    addNewAllWatcherToTopic: (state, action: PayloadAction<IUser>) => {
      const allUpdatesWatchers = [
        ...(state.allUpdatesWatchers || []),
        action.payload,
      ];
      return { ...state, allUpdatesWatchers };
    },
    addNewSummaryWatcherToTopic: (state, action: PayloadAction<IUser>) => {
      const summaryWatchers = [
        ...(state.summaryWatchers || []),
        action.payload,
      ];
      return { ...state, summaryWatchers };
    },
    removeFromAllTopicWatcherInfo: (state, action: PayloadAction<string>) => {
      const allUpdatesWatchers = (state.allUpdatesWatchers || []).filter(
        (user) => user._id !== action.payload
      );
      return { ...state, allUpdatesWatchers };
    },
    removeFromTopicSummaryWatcherInfo: (
      state,
      action: PayloadAction<string>
    ) => {
      const summaryWatchers = (state.summaryWatchers || []).filter(
        (user) => user._id !== action.payload
      );
      return { ...state, summaryWatchers };
    },
    removeAllTopicInfo: (state) => {
      return initialState;
    },
  },
});

export const {
  initializeTopic,
  updateTopicReducer,
  addNewCollaboratorToTopic,
  addNewAllWatcherToTopic,
  addNewSummaryWatcherToTopic,
  removeFromAllTopicWatcherInfo,
  removeFromTopicSummaryWatcherInfo,
  removeAllTopicInfo,
} = topicSlice.actions;

export const topicReducer = topicSlice.reducer;
