export const spacing = {
  '4xs': 0,
  '3xs': 1,
  '2xs': 2,
  xs: 4,
  s: 8,
  m: 14,
  l: 16,
  xl: 22,
  '2xl': 32,
  '3xl': 48,
};

export const borderWidth = {
  '3xs': 0,
  '2xs': 0,
  xs: 0,
  s: 1,
  m: 2,
  l: 3,
  xl: 4,
  '2xl': 5,
  '3xl': 6,
};

export const borderRadius = {
  '3xs': 0,
  '2xs': 2,
  xs: 4,
  s: 5,
  m: 8,
  l: 16,
  xl: 24,
  '2xl': 30,
  '3xl': 40,
};

export type Spacing = typeof spacing;
export type BorderRadius = typeof borderRadius;
export type BorderWidth = typeof borderWidth;
