import styled from '@emotion/styled';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  width: 100%;
`;

/**
 * TODO -- handle the spacing using media queries
 */
export const Left = styled.div`
  /* padding-right: 40px; */
`;
export const Right = styled.div`
  padding: 0px 0px 0px 40px;
`;
