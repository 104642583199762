import { gql, useMutation } from '@apollo/client';
import { Dialog } from 'components';
import { UserContext } from 'context';
import { deleteCommentTopicActivity } from 'features/topicActivity';
import { useAppDispatch } from 'hooks';
import { IUser } from 'model';
import React, { useContext } from 'react';

interface Props {
  open?: boolean;
  itemId: string;
  onOpenChange?(open: boolean): void;
}

const DELETE_ITEM = gql`
  mutation deleteActivity($id: ID!) {
    deleteActivity(id: $id)
  }
`;

export function DeleteActivityDialog(props: Props) {
  const [deleteItem, { loading }] = useMutation(DELETE_ITEM);
  const { state: userState } = useContext(UserContext);

  const dispatch = useAppDispatch();

  return (
    <Dialog
      // additionalContent={}
      description={`
          Are you sure you want to delete this item?
        `}
      open={props.open}
      submitting={loading}
      submitDisabled={false}
      title="Delete comment"
      onOpenChange={props.onOpenChange}
      onSubmit={async (onClose) => {
        dispatch(
          deleteCommentTopicActivity({
            id: props.itemId,
            deletedBy: userState as IUser,
          })
        );
        deleteItem({
          variables: {
            id: props.itemId,
          },
        }).then(() => {
          // window.location.reload();
          // onClose();
        });
        onClose();
      }}
    />
  );
}
