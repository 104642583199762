import { useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/css';
import { Text } from 'components';
import { EmailLogo, SlackLogo } from 'components/Icons';
import React, { useCallback } from 'react';
import {
  DISABLE_NOTIFICATION,
  ENABLE_NOTIFICATION,
  NOTIFICATION_INFO,
} from './gql';
import { IntegrationItem, Separator } from './IntegrationItem';

type Result = {
  me: {
    _id: string;
    hasEmailNotification?: boolean;
    hasSlackNotification?: boolean;
    hasGoogleCommentNotification?: boolean;
  };
};

type NotificationType =
  | 'hasEmailNotification'
  | 'hasSlackNotification'
  | 'hasGoogleCommentNotification';

export const NotificationSetting: React.FC = () => {
  const { data, refetch } = useQuery<Result>(NOTIFICATION_INFO, {
    fetchPolicy: 'network-only',
  });

  const [disableIntegration] = useMutation(DISABLE_NOTIFICATION);
  const [enableIntegration] = useMutation(ENABLE_NOTIFICATION);

  const onEnableNotification = useCallback(
    (type: NotificationType) =>
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        enableIntegration({ variables: { type } }).then((res) => {
          refetch().then(() => {});
        });
      },
    [refetch, enableIntegration]
  );

  const onDisableNotification = useCallback(
    (type: NotificationType) => () => {
      disableIntegration({ variables: { type } }).then((res) => {
        refetch().then(() => {});
      });
    },
    [refetch, disableIntegration]
  );

  return (
    <div
      className={css`
        width: 80%;
      `}
    >
      <Text.H2 marginBottom="3xl">Notifications settings</Text.H2>

      <IntegrationItem
        title="Email"
        description="Receive email notifications from Multiplayer"
        Icon={EmailLogo}
        completed={!!data?.me.hasEmailNotification}
        onClick={onEnableNotification('hasEmailNotification')}
        onRemove={onDisableNotification('hasEmailNotification')}
      />
      <Separator />

      <IntegrationItem
        title="Slack"
        description="Receive Slack notifications from Multiplayer"
        Icon={SlackLogo}
        completed={!!data?.me.hasSlackNotification}
        onClick={onEnableNotification('hasSlackNotification')}
        onRemove={onDisableNotification('hasSlackNotification')}
      />
      {/* <Box
        width="50%"
        height="400px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <EggCrackIcon />
        <Text.H2>Coming soon!</Text.H2>
      </Box> */}
    </div>
  );
};
