import React from 'react';
import { format } from 'date-fns';
import { css } from '@emotion/css';
import { ActivityHolder, LinearCommentBox } from 'components';
// import { theme } from 'theme';
import { LinearCommentModel } from '../types';
import { EActivityType } from 'model';
import LinearLogo from './assets/linear-app-icon.png';
// import { extractUrlFromMarkdown } from 'utils';
// import { PaperClipIcon } from 'components/Icons';

type Props = {
  id: string;
  topicId: string;
  customRef: React.LegacyRef<HTMLDivElement>;
  focus?: boolean;
  stared: boolean;
  last?: boolean;
  change: LinearCommentModel['change'];
  title: string;
  url: string;
  modifiedDate: number;
  author: string;
  comment: string;
  // isDrawerOpen?: boolean;
  // toggleDrawer?: VoidFunction;
};
export const LinearIssueComment: React.FC<Props> = ({
  topicId,
  id,
  customRef,
  stared,
  last,
  focus,
  change,
  title,
  url,
  modifiedDate,
  author,
  comment,
}) => {
  // const files = extractUrlFromMarkdown(comment);
  // let commentWithoutFiles = comment;
  // if (files.length > 0) {
  //   files.forEach((file) => {
  //     commentWithoutFiles = commentWithoutFiles.replace(
  //       `![${file.name}](${file.url})`,
  //       ''
  //     );
  //     commentWithoutFiles = commentWithoutFiles.replace(
  //       `[${file.name}](${file.url})`,
  //       ''
  //     );
  //   });
  // }
  return (
    <ActivityHolder.Item
      topicId={topicId}
      type={EActivityType.LINEAR_ISSUE}
      id={id}
      customRef={customRef}
      link={`${process.env.REACT_APP_WEB_URL}/topic/${topicId}#${id}`}
      Icon={
        <img
          src={LinearLogo}
          alt="Linear icon"
          style={{ width: '20px', height: '20px' }}
        />
      }
      title={`*${author}** ${
        change === 'comment'
          ? 'added'
          : change === 'update'
          ? 'updated'
          : 'reply to'
      } a comment on ticket *<a href="${url}" target="_blank" rel="noreferrer">${title}</a>**`}
      subTitle={`${format(+modifiedDate, 'MMMM d, yyyy • h:mm aa')}`}
      // color={theme.colors.primary['primary/03']}
      stared={stared}
      last={last}
    >
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className={css`
          all: unset;
        `}
      >
        <LinearCommentBox comment={comment} focus={focus} />
      </a>
    </ActivityHolder.Item>
  );
};
