import React from 'react';
import { DottedProgressBar } from 'components';
import { formatDistance } from 'date-fns';
import { TopicList } from 'features/topics';

type Props = {
  momentum: TopicList[0]['momentum'];
  compacted?: boolean;
};
export const TopicMomentum: React.FC<Props> = ({ momentum, compacted }) => {
  return (
    <DottedProgressBar
      value={momentum?.count || 0}
      labelLeft={momentum?.label}
      small={compacted}
      labelRight={
        momentum?.lastActivity
          ? 'Last activity ' +
            formatDistance(momentum?.lastActivity, new Date(), {
              addSuffix: true,
            })
          : 'Last activity more than 30 days ago'
      }
    />
  );
};
