import { useLazyQuery, useMutation } from '@apollo/client';
import { css } from '@emotion/css';
import { Box, ReplyDrawer, Text } from 'components';
import {
  ChecksIcon,
  MegaPhoneSimpleIcon,
  SpinnerGapIcon,
} from 'components/Icons';
import { UserContext } from 'context';
import { EActivityType, IUser } from 'model';
import { ERouteName } from 'pages';
import { Activity } from 'pages/Topic/types';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { theme } from 'theme';
import { displayUser } from 'utils';
import { GET_COMMENT, MARK_AS_READ, REPLY_COMMENTS } from './gql';
import {
  ENotificationCategory,
  ENotificationType,
  INotification,
} from './type';
import pubsub from 'sweet-pubsub';
import { ENotificationKey } from 'shared';

interface Props {
  id: string;
  sender?: IUser;
  // topicId?: string;
  activityId?: string;
  topicBasics?: {
    closed?: boolean;
    isAdmin?: boolean;
    isWrite?: boolean;
    name?: string;
    id?: string;
  };
  // topicName?: string;
  type: ENotificationType;
  status: ENotificationCategory;
  isRead?: boolean;
  created: string;
  link?: string;
  google?: INotification['google'];
  onClick?(): void;
  onStatusChange(id: string, newStatus: ENotificationCategory): void;
}

export const NotificationItem: React.FC<Props> = ({
  sender,
  activityId,
  topicBasics,
  type,
  google,
  created,
  status,
  isRead,
  id,
  link,
  onClick,
  onStatusChange,
}) => {
  const { state: userState } = useContext(UserContext);

  const [hover, setHover] = useState(false);
  const [isNotificationRead, setIsNotificationRead] = useState(isRead);
  const [isOpen, setIsOpen] = useState(false);
  const [render, setRender] = useState(false);
  const [replyText, setReplyText] = useState('');
  const [reply, setReply] = useState('');
  const [replies, setReplies] = useState<Activity[]>([]);

  const navigate = useNavigate();

  const [markAsRead] = useMutation(MARK_AS_READ);

  const [fetchComments, { loading: getRepliesLoading }] = useLazyQuery<{
    commentWithReplies: Activity[];
  }>(GET_COMMENT, {
    variables: {
      commentId: activityId,
    },
  });

  const [replyComment, { loading: repliesLoading }] = useMutation<{
    replyToComment: Activity;
  }>(REPLY_COMMENTS);

  useEffect(() => {
    if (activityId) {
      fetchComments({
        variables: {
          commentId: activityId,
          page: 0,
        },
      })
        .then((res) => {
          if (res.data?.commentWithReplies) {
            setReplies(res.data.commentWithReplies);
          }
        })
        .catch((e) => {});
    }
  }, [fetchComments, activityId]);

  const renderLabels = () => {
    switch (type) {
      case ENotificationType.MULTIPLAYER_USER_MENTION:
        return {
          label1: sender ? displayUser(sender) : '',
          label2: 'mentioned you in',
          label3: topicBasics?.name,
        };

      case ENotificationType.ADDED_TO_TOPIC:
        return {
          label1: sender ? displayUser(sender) : '',
          label2: 'added you to',
          label3: topicBasics?.name,
        };

      case ENotificationType.SAVED_SLACK_MESSAGE:
        return {
          label1: 'You saved this from Slack',
          label2: '',
          label3: '',
        };

      case ENotificationType.GOOGLE_MENTION:
        return {
          label1: google?.sender || '',
          label2: 'mentioned you in a google doc comment ',
          label3: google?.title || '',
        };

      default:
        break;
    }
  };
  const { label1, label2, label3 } = renderLabels()!;

  const getIcon = () => {
    switch (status) {
      case ENotificationCategory.DONE:
        return (
          <Box
            noPadding
            width="16px"
            height="16px"
            borderRadius="l"
            backgroundColor="primary/03"
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop="2xs"
          >
            <ChecksIcon
              fill={theme.colors.text['text/100%']}
              style={{ width: '9px', height: '9px' }}
            />
          </Box>
        );

      case ENotificationCategory.TODO:
        return (
          <SpinnerGapIcon
            fill={theme.colors.accent['purple/main']}
            style={{ width: '16px', height: '16px', marginTop: '4px' }}
          />
        );

      case ENotificationCategory.INBOX:
        return (
          <div
            className={css`
              width: 16px;
              height: 16px;
              border-radius: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid ${theme.colors.primary['primary/03']};
              margin-top: 4px;
            `}
          >
            <div
              className={css`
                width: 3px;
                height: 3px;
                border-radius: 2px;
                background-color: ${isNotificationRead
                  ? theme.colors.primary['primary/03']
                  : theme.colors.accent['red/main']};
              `}
            />
          </div>
        );

      default:
        return (
          <div
            className={css`
              width: 16px;
              height: 16px;
              border-radius: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid ${theme.colors.primary['primary/03']};
            `}
          >
            <div
              className={css`
                width: 3px;
                height: 3px;
                border-radius: 2px;
                background-color: ${isNotificationRead
                  ? theme.colors.primary['primary/03']
                  : theme.colors.accent['red/main']};
              `}
            />
          </div>
        );
    }
  };
  const icon = getIcon();

  const handleStatusChange = useCallback(
    (newStatus: ENotificationCategory) => () => {
      markAsRead({
        variables: {
          id,
        },
      }).then(() => {
        pubsub.emit(ENotificationKey.NOTIFICATION_READ, id);
        setIsNotificationRead(true);
      });
      onStatusChange(id, newStatus);
    },
    [id, markAsRead, onStatusChange]
  );

  const handleClick = useCallback(() => {
    markAsRead({
      variables: {
        id,
      },
    }).then(() => {
      pubsub.emit(ENotificationKey.NOTIFICATION_READ, id);
      setIsNotificationRead(true);
    });
    if (link && type === ENotificationType.SAVED_SLACK_MESSAGE) {
      let a = document.createElement('a');
      a.target = '_blank';
      a.href = link;
      a.click();
    } else if (type === ENotificationType.MULTIPLAYER_USER_MENTION) {
      if (replies?.length > 0) {
        setIsOpen(true);
      } else {
        let a = document.createElement('a');
        // a.target = '_blank';
        a.href = `${ERouteName.Topic}${topicBasics?.id || 'notfound'}`;
        a.click();
      }
    } else if (type === ENotificationType.GOOGLE_MENTION) {
      let a = document.createElement('a');
      a.target = '_blank';
      a.href = google?.link?.split('#')?.join('') || '#';
      a.click();
    } else if (type === ENotificationType.ADDED_TO_TOPIC) {
      navigate(ERouteName.Topic + topicBasics?.id || 'notfound');
    }
  }, [
    google?.link,
    id,
    link,
    markAsRead,
    navigate,
    replies?.length,
    topicBasics?.id,
    type,
  ]);

  useEffect(() => {
    /**
     * Weird but Quill text Editor need to be unmounted before rendering
     * So we did this:
     */
    setTimeout(() => {
      setRender(true);
    }, 0);
  }, []);

  const handleReply = useCallback(() => {
    if (
      (!replyText.trim() || replyText.trim() === '') &&
      !(
        reply.includes('<img') ||
        reply.includes('<iframe') ||
        reply.includes('class="mention"')
      )
    )
      return;

    const theComment: any = {
      comment: reply,
      parentId: replies?.[0]?._id,
    };

    const data = {
      type: EActivityType.COMMENT,
      payload: theComment,
    };

    const toAdd: Activity = {
      _id: new Date().getTime().toString(),
      comment: 'fake',
      created: new Date().getTime(),
      data: JSON.stringify(data),
      topicId: topicBasics?.id!,
      workspaceId: '',
      stared: false,
      group: [],
      userId: {
        _id: userState._id,
        firstName: userState.firstName!,
        lastName: userState.lastName!,
        businessEmail: userState.businessEmail,
      },
    };
    setReplies((to) => [...to, toAdd]);
    replyComment({
      variables: {
        commentId: replies?.[0]?._id,
        comment: reply,
      },
    }).then((res) => {
      if (res.data?.replyToComment._id) {
        setReply('');
        setReplyText('');
        setReplies((to) => to.filter((el) => el.comment !== 'fake'));
        setReplies((el) => [...el, res.data?.replyToComment!]);
      }
    });
  }, [
    replies,
    reply,
    replyComment,
    replyText,
    topicBasics?.id,
    userState._id,
    userState.businessEmail,
    userState.firstName,
    userState.lastName,
  ]);

  return (
    <div
      className={css`
        width: 70%;
        display: grid;
        grid-template-columns: 16px auto;
        cursor: pointer;
        margin-bottom: 16px;
        position: relative;
      `}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {icon}
      <Box noPadding marginLeft="s" width="100%" onClick={handleClick}>
        <Box noPadding display="block" flexDirection="row" alignItems="center">
          <Text.Body
            marginTop="4xs"
            marginBottom="4xs"
            style={{ marginRight: '4px', display: 'inline' }}
          >
            {label1}
          </Text.Body>
          <Text.Body
            marginTop="4xs"
            marginBottom="4xs"
            style={{ color: '#B0BBD6', marginRight: '4px', display: 'inline' }}
          >
            {label2}
          </Text.Body>
          <Text.Body
            marginTop="4xs"
            marginBottom="4xs"
            style={{ marginRight: '4px', display: 'inline' }}
          >
            {label3}
          </Text.Body>
        </Box>
        <Text.TextSmall2 marginTop="xs" color="text/50%">
          {created}
        </Text.TextSmall2>
      </Box>

      {hover && (
        <div
          className={css`
            width: 76px;
            height: 24px;
            display: grid;
            grid-template-columns: auto auto;
            cursor: pointer;
            position: absolute;
            right: 0px;
            border-radius: 4px;
            background-color: ${theme.colors.primary['primary/02']};
          `}
        >
          <Box
            width="100%"
            height="100%"
            noPadding
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            className={css`
              &:hover {
                background-color: ${theme.colors.primary[
                  'primary/03'
                ]} !important;
              }
            `}
            onClick={handleStatusChange(
              status === ENotificationCategory.INBOX
                ? ENotificationCategory.TODO
                : ENotificationCategory.INBOX
            )}
          >
            {status === ENotificationCategory.INBOX ? (
              <SpinnerGapIcon
                fill={theme.colors.text['text/blue/soft']}
                style={{ width: '16px', height: '16px' }}
              />
            ) : (
              <MegaPhoneSimpleIcon
                fill={theme.colors.text['text/blue/soft']}
                style={{ width: '16px', height: '16px' }}
              />
            )}
          </Box>

          <Box
            width="100%"
            height="100%"
            noPadding
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            className={css`
              &:hover {
                background-color: ${theme.colors.primary[
                  'primary/03'
                ]} !important;
              }
            `}
            onClick={handleStatusChange(
              status === ENotificationCategory.DONE
                ? ENotificationCategory.TODO
                : ENotificationCategory.DONE
            )}
          >
            {status === ENotificationCategory.DONE ? (
              <SpinnerGapIcon
                fill={theme.colors.text['text/blue/soft']}
                style={{ width: '16px', height: '16px' }}
              />
            ) : (
              <ChecksIcon
                fill={theme.colors.text['text/blue/soft']}
                style={{ width: '16px', height: '16px' }}
              />
            )}
          </Box>
        </div>
      )}

      {isOpen && (
        <ReplyDrawer
          redirectTo={`${ERouteName.Topic}${topicBasics?.id || 'notfound'}#${
            replies?.[0]?._id
          }`}
          isDrawerOpen={isOpen}
          toggleDrawer={() => {
            setIsOpen(!isOpen);
          }}
          replies={replies?.slice(1) || []}
          readOnly={false}
          repliesLoading={repliesLoading}
          handleReply={handleReply}
          setReply={setReply}
          setReplyText={setReplyText}
          reply={reply}
          topicBasics={{
            closed: topicBasics?.closed || false,
            isAdmin: topicBasics?.isAdmin || false,
            isWrite: topicBasics?.isWrite || false,
          }}
          getRepliesLoading={getRepliesLoading}
          topComment={{
            id: replies?.[0]?._id || '',
            comment: replies?.[0]?.data
              ? JSON.parse(replies?.[0]?.data).payload.comment
              : '',
            createAt: replies?.[0]?.created || new Date().getTime(),
            createdBy: displayUser(replies?.[0]!.userId!) || '',
            render,
          }}
        />
      )}
    </div>
  );
};
