import React from 'react';
import { LabelBox, TooltipContainer } from 'components';
import { theme } from 'theme';
import { EMomentumType } from 'model';
import { css } from '@emotion/css';
import { TopicList } from 'features/topics';

type Props = {
  closed: boolean;
  momentum: TopicList[0]['momentum'];
  dotOnly?: boolean;
};
export const TopicLabel: React.FC<Props> = ({ closed, momentum, dotOnly }) => {
  return dotOnly ? (
    <TooltipContainer
      side="top"
      text={
        closed
          ? 'Closed'
          : momentum?.label === EMomentumType.DORMANT
          ? 'Dormant'
          : 'Active'
      }
    >
      <div
        className={css`
          width: 12px;
          height: 12px;
          border-radius: 6px;
          background-color: ${closed
            ? theme.colors.primary['primary/03']
            : momentum?.label === EMomentumType.DORMANT
            ? theme.colors.accent['red/main']
            : theme.colors.accent['green/main']};
        `}
      />
    </TooltipContainer>
  ) : (
    <LabelBox
      text={
        closed
          ? 'Closed'
          : momentum?.label === EMomentumType.DORMANT
          ? 'Dormant'
          : 'Active'
      }
      color={
        closed
          ? theme.colors.primary['primary/03']
          : momentum?.label === EMomentumType.DORMANT
          ? theme.colors.accent['red/main']
          : theme.colors.accent['green/main']
      }
    />
  );
};
