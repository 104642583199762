import { Text } from 'components';
import React, { Component } from 'react';

type Props = {
  children: React.ReactNode;
  componentName: string;
};
type State = {
  hasError: boolean;
};
export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // Log the error to an error reporting service
    /**
     * TODO: Log the error to an error reporting service (send by email maybe)
     */
    console.error({ component: this.props.componentName }, error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Text.TextSmall1>
          Something went wrong 🙁. Refreshing the page may fix the issue
        </Text.TextSmall1>
      );
    }

    return this.props.children;
  }
}
