import { ITopicLink } from 'features/topicLink';
import {
  EActivityType,
  EScope,
  GithubIssueActivity,
  GoogleCommentModel,
  IUser,
} from 'model';

export enum LinkedResourceType {
  GoogleDoc = 'GoogleDoc',
  GoogleFolder = 'GoogleFolder',
  Multiplayer = 'Multiplayer',
  Microsoft = 'Microsoft',
  GoogleColab = 'GoogleColab',
  File = 'File',
  Zoom = 'Zoom',
  Notion = 'Notion',
  Linear = 'Linear',
  Github = 'Github',
  Unknown = 'Unknown',
}
export interface ICommentModel {
  comment: string;
}
export interface IReplyModel {
  comment: string;
  parentId?: string; // the comment's Id
}
export type GoogleModel = {
  id: string;
  modifiedDate: number;
  url?: string;
  lastVersionUrl: string;
  title?: string;
  lastModifyingUser: {
    displayName: string;
    emailAddress: string;
  };
  type: string;
  delta?: {
    content: string;
    value: number;
  };
};

export type NotionModel = {
  id: string;
  modifiedDate: number;
  createdDate: number;
  url?: string;
  title?: string;
  lastModifyingUser: {
    displayName: string;
    emailAddress: string;
  };
  createdBy: {
    displayName: string;
    emailAddress: string;
    id: string;
  };
  content: string;
};

type SlackModel = {
  message: string;
  comment: string;
  channel: string;
  permalink: string;
  files?: string[];
  author: string;
};

export type LinearIssueModel = {
  id: string;
  modifiedDate: number;
  createdDate: number;
  url: string;
  title: string;
  description?: string;
  updatedBy?: {
    displayName: string;
    id: string;
  };
  createdBy?: {
    displayName: string;
    id: string;
  };
  // content: string;
  labels?: string[];
  state: {
    id: string;
    name: string;
    type: 'unstarted' | 'started' | 'completed' | 'archived';
  };
  // team: {
  //   id: string;
  //   name: string;
  //   key: string;
  // };
  assignee?: {
    id: string;
    name: string;
  };
  change?: {
    type: 'state' | 'assignee';
    from?: string;
    to: string;
  };
  action: 'remove' | 'update' | 'create';
};

export type LinearCommentModel = {
  id: string;
  modifiedDate?: number;
  createdDate: number;
  url: string;
  comment: string;
  commentedBy: {
    id: string;
    name: string;
  };
  issueId: string;
  title: string;
  parentId?: string;
  action: 'remove' | 'update' | 'create';
  change?: 'comment' | 'reply' | 'update';
};

type TopicModel = {
  type: 'created' | 'closed' | 'reopened';
};

export type MicrosoftModel = {
  id: string;
  modifiedDate: number;
  url: string;
  title: string;
  htmlContent?: string;
  delta?: {
    content?: string;
    value: number;
  };
  updatedBy: {
    displayName: string;
    id: string;
  };
};

export type ActivityData =
  | { type: EActivityType.COMMENT; payload: ICommentModel }
  | { type: EActivityType.REPLY; payload: IReplyModel }
  | { type: EActivityType.GOOGLE; payload: GoogleModel }
  | { type: EActivityType.GOOGLE_COLAB; payload: GoogleModel }
  | { type: EActivityType.NOTION; payload: NotionModel }
  | { type: EActivityType.SLACK; payload: SlackModel }
  | { type: EActivityType.TOPIC; payload: TopicModel }
  | { type: EActivityType.LINEAR_ISSUE; payload: LinearIssueModel }
  | { type: EActivityType.LINEAR_ISSUE_COMMENT; payload: LinearCommentModel }
  | { type: EActivityType.MICROSOFT; payload: MicrosoftModel }
  | { type: EActivityType.GOOGLE_COMMENT; payload: GoogleCommentModel }
  | { type: EActivityType.GITHUB; payload: GithubIssueActivity };

export interface Activity {
  _id: string;
  comment: string;
  topicId: string;
  workspaceId: string;
  userId?: IUser;
  deletedBy?: IUser;
  slackMessageAuthor?: {
    displayText: string;
  };
  data: string;
  created: number;
  stared: boolean;
  deleted?: number;
  updated?: number;
  replies?: number;
  updateMode?: boolean;
  group: {
    name: string;
    _id: string;
  }[];
  mentionWithNoAccess?: boolean;
}
export type ActivityList = {
  activityList: {
    result: Activity[];
    size: number;
  };
};

export type NewDocActivityList = {
  syncGoogleDoc: Activity[];
};

export type TTopicBasics = {
  collaborators: IUser[];
  summaryWatchers?: IUser[];
  allUpdatesWatchers?: IUser[];
  created: number;
  creatorId: IUser;
  _id: string;
  name: string;
  description?: string;
  public: boolean;
  closed: boolean;
  links?: ITopicLink[];
  scope: string;
  isAutoCreated?: boolean;
  shared?: {
    type: ETopicSharedType;
    collaboratorEmails?: string[];
    scope: EScope[];
  }[];
  momentum?: {
    label: string;
    count: number;
    lastActivity?: number;
  };
  fileAccess?: {
    id: string;
    access: boolean;
  }[];
};

export type EditResult = {
  updateTopic: {
    description?: string;
    _id: string;
    name: string;
  };
};

export type ChangeScopeResult = {
  changeTopicScope: {
    public: boolean;
  };
};

export type Params = {
  topicId: string;
};

export type ActivityRenderList = {
  date: number;
  component: React.ReactElement;
};

export type TMention = {
  label: string;
  value: string;
};

export type WorkspaceInfo = {
  myWorkspace: {
    _id: string;
    name: string;
    topics: {
      _id: string;
      name: string;
    }[];
  };
};

export enum ENotification {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}

export enum ELinkLabel {
  FILE = 'file',
  PDF = 'pdf',
  IMAGE = 'image',
  DOC = 'doc',
  NOTION = 'notion',
  SLIDE = 'slide',
  SHEET = 'sheet',
  FOLDER = 'folder',
  COLAB = 'colab',
  LINK = 'link',
  IMPORTANT = 'important',
  VIDEO = 'video',
  LINEAR = 'linear',
  MICROSOFT = 'microsoft',
  GITHUB = 'github',
}

export enum ETopicSharedType {
  PUBLIC = 'public',
  RESTRICTED = 'restricted',
}

export type ActivityPermission = {
  id: string;
  access: boolean;
};

export type TTopicViewMode = 'publicView' | 'privateView';
export type TTopicViewAccess = 'read' | 'write';
