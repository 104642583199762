import React, { useState } from 'react';
import { format } from 'date-fns';
import { ActivityHolder, Box, Text } from 'components';
import {
  LargeIcon,
  MediumIcon,
  MicrosoftLogo,
  SmallIcon,
  TeenyTinyIcon,
  TinyIcon,
} from 'components/Icons';
import { MicrosoftModel } from '../types';
import { EActivityType } from 'model';
import { theme } from 'theme';
import { css } from '@emotion/css';
import { DeltaDialog } from './ItemMenu/DeltaDialog';

type DocProps = {
  delta: MicrosoftModel['delta'];
  title: MicrosoftModel['title'];
  url: MicrosoftModel['url'];
  id: string;
  modifiedDate: MicrosoftModel['modifiedDate'];
  topicId: string;
  createdBy: string;
  creatorId?: string;
  customRef: React.LegacyRef<HTMLDivElement>;
  focus?: boolean;
  isFirst?: boolean;
  stared: boolean;
  last?: boolean;
  subComponents?: JSX.Element[];
  inCollapse?: boolean;
  // isDrawerOpen?: boolean;
  // toggleDrawer?: VoidFunction;
};
export const MicrosoftUpdate: React.FC<DocProps> = ({
  delta,
  topicId,
  title,
  url,
  id,
  modifiedDate,
  createdBy,
  customRef,
  creatorId,
  focus,
  isFirst,
  subComponents,
  stared,
  last,
  inCollapse,
}) => {
  const [openDelta, setOpenDelta] = useState(false);

  let updateType = '';
  let UpdateIcon;
  if (!delta?.value || delta?.value! <= 5) {
    updateType = 'Teeny tiny';
    UpdateIcon = TeenyTinyIcon;
  } else if (delta?.value! <= 28) {
    updateType = 'Tiny';
    UpdateIcon = TinyIcon;
  } else if (delta?.value! <= 400) {
    updateType = 'Small';
    UpdateIcon = SmallIcon;
  } else if (delta?.value! <= 800) {
    updateType = 'Medium';
    UpdateIcon = MediumIcon;
  } else {
    updateType = 'Large';
    UpdateIcon = LargeIcon;
  }
  return (
    <>
      <ActivityHolder.Item
        topicId={topicId}
        type={EActivityType.MICROSOFT}
        id={id}
        customRef={customRef}
        link={`${process.env.REACT_APP_WEB_URL}/topic/${topicId}#${id}`}
        Icon={<MicrosoftLogo style={{ width: '16px' }} />}
        title={`*<a href="${
          creatorId ? '/profile/' + creatorId : '#'
        }">${createdBy}</a>** added modification to *<a href="${url}" target="_blank" rel="noreferrer">${title}</a>**`}
        subTitle={`${format(+modifiedDate, 'MMMM d, yyyy • h:mm aa')}`}
        stared={stared}
        last={last}
        // hasCollapse={last && subComponents && subComponents.length > 0}
      >
        {/* {Boolean(delta?.value && delta?.value > 0) && ( */}
        <>
          <Box
            padding="m"
            borderRadius="m"
            width="100%"
            backgroundColor={inCollapse ? 'primary/03' : 'primary/02'}
            display="flex"
            flexDirection="row"
            alignItems="center"
            onClick={() => setOpenDelta(true)}
            className={css`
              cursor: pointer;
              border: 1px solid
                ${focus ? theme.colors.accent['blue/main'] : 'transparent'} !important;
              transition: border-color 0.15s;
              &:hover {
                border-color: ${theme.colors.accent['blue/main']} !important;
              }
            `}
          >
            <UpdateIcon />
            <Text.BodySM
              style={{ marginRight: '10px', marginLeft: '8px' }}
              marginBottom="4xs"
              marginTop="4xs"
            >
              {updateType} update
            </Text.BodySM>
          </Box>

          <DeltaDialog
            open={openDelta}
            itemId={id}
            onOpenChange={setOpenDelta}
            type={EActivityType.MICROSOFT}
          />
        </>
        {/* )} */}
      </ActivityHolder.Item>
    </>
  );
};
