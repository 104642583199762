import { useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/css';
import { Text } from 'components';
import { SparkleIcon } from 'components/Icons';
import React from 'react';
import {
  DISABLE_TOPIC_BUILD,
  ENABLE_TOPIC_BUILD,
  GET_TOPIC_BUILD_STATUS,
} from './gql';
import { IntegrationItem } from './IntegrationItem';

type Result = {
  currentWorkspace: {
    disableTopicBuild: boolean;
  };
};

export const WorkspaceSetting: React.FC = () => {
  const { data, refetch } = useQuery<Result>(GET_TOPIC_BUILD_STATUS, {
    fetchPolicy: 'network-only',
  });

  const [disableTopicBuild] = useMutation(DISABLE_TOPIC_BUILD);
  const [enableEnableTopicBuild] = useMutation(ENABLE_TOPIC_BUILD);

  const onEnable = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    enableEnableTopicBuild().then((res) => {
      refetch().then(() => {});
    });
  };

  const onDisable = () => {
    disableTopicBuild().then((res) => {
      refetch().then(() => {});
    });
  };

  return (
    <div
      className={css`
        width: 80%;
      `}
    >
      <Text.H2 marginBottom="3xl">Workspace settings</Text.H2>

      <IntegrationItem
        title="Topic AI auto generation"
        description="Topics will be built automatically using AI based on your activity"
        Icon={SparkleIcon}
        completed={!data?.currentWorkspace.disableTopicBuild}
        onClick={onEnable}
        onRemove={onDisable}
        helperText="Enabling this will start auto generating topics based on your activity"
        disableButtonHelperText="By disabling this, topics will no longer be auto generated unless you reactivated it"
      />
    </div>
  );
};
