import { css } from '@emotion/css';
import * as CDialog from '@radix-ui/react-dialog';
import React, { useEffect, useState } from 'react';
import { theme } from 'theme';
import pubsub from 'sweet-pubsub';
import { ENotificationKey } from 'shared';

type Props = {
  children?: JSX.Element;
  content?: React.ReactNode;
  open?: boolean;
  onOpenChange?(newState: boolean): void;
};

export function DialogBlank(props: Props) {
  const [open, setOpen] = useState(props.open || false);

  useEffect(() => {
    setOpen(props.open || false);
  }, [props.open]);

  return (
    <CDialog.Root
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        props.onOpenChange?.(open);
        if (!open) {
          pubsub.emit(ENotificationKey.SHOW_DROPDOWN, { value: false });
        }
      }}
    >
      {props.children && (
        <CDialog.Trigger asChild>
          {React.cloneElement(props.children, {
            // className: cx(props.children.props.className, props.className),
          })}
        </CDialog.Trigger>
      )}
      <CDialog.Portal>
        <CDialog.Overlay
          className={css`
            @keyframes fadeIn {
              from {
                backdrop-filter: blur(0px);
                opacity: 0;
              }
              to {
                backdrop-filter: blur(2px);
                opacity: 1;
              }
            }
            animation: fadeIn 0.15s;
            backdrop-filter: blur(2px);
            background-color: rgba(0, 0, 0, 0.3);
            inset: 0;
            position: fixed;
            z-index: 999;
          `}
        />
        <CDialog.Content asChild>
          <div
            className={css`
              @keyframes expand {
                0% {
                  opacity: 0;
                  transform: translateX(-50%) translateY(-50%) scale(0.9);
                }
                100% {
                  opacity: 1;
                  transform: translateX(-50%) translateY(-50%) scale(1);
                }
              }
              animation: expand 0.15s;
              background-color: ${theme.colors.primary['primary/03']};
              border-radius: 16px;
              box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.8);
              left: 50%;
              /* padding: 30px 19px; */
              position: fixed;
              top: 50%;
              transform: translateX(-50%) translateY(-50%);
              z-index: 1000;
              overflow: hidden;
            `}
          >
            {props.content}
          </div>
        </CDialog.Content>
      </CDialog.Portal>
    </CDialog.Root>
  );
}
