import { useEffect, useLayoutEffect, useRef } from 'react';

export const useKeyboardShortcut = (
  shortcuts: {
    key: string;
    type?: 'metaKey' | 'shiftKey' | 'ctrlKey' | 'altKey';
  }[],
  callback: (event: KeyboardEvent) => void,
  node?: Document
) => {
  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      // console.log(`Key pressed: ${event.key}`);
      if (
        shortcuts.some(
          (short) =>
            (!short.type || (short.type && event[short.type] === true)) &&
            short.key === event.key
        )
      ) {
        callbackRef.current(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [shortcuts]);
};
