import { useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import { UserContext } from 'context';
import {
  addMoreTopicActivity,
  addNewTopicActivity,
  deleteCommentTopicActivity,
  initializeTopicActivity,
  removeFakeTopicActivity,
  updateOrAddTopicActivity,
  updateTopicActivity,
} from 'features/topicActivity';
import { initializeTopicLink } from 'features/topicLink';
import { useAppDispatch, useAppSelector } from 'hooks';
import { EActivityType, IUser } from 'model';
import { useCallback, useContext, useEffect, useState } from 'react';
import { RootState } from 'reducers';
import { ENotificationKey } from 'shared';
import pubsub from 'sweet-pubsub';
import { isGoogleDoc, isLinearLink, isNotionUrl } from 'utils';
import {
  ADD_COMMENTS,
  EDIT_COMMENTS,
  GET_ACTIVITY,
  REPLY_COMMENTS,
  TOPIC_CHANGED,
} from '../gql';
import { Activity, ActivityList, ICommentModel } from '../types';

type Props = {
  topicId: string;
  page: number;
  stared: boolean;
  type: EActivityType[];
  groups: string[];
  commentText: string;
  currentComment: string;
  commentImportant: boolean;
  lastActivity: Activity;
  setCurrentComment: React.Dispatch<React.SetStateAction<string>>;
  setCommentImportant: React.Dispatch<React.SetStateAction<boolean>>;
  setEditFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setStared: React.Dispatch<React.SetStateAction<boolean>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setGroups: React.Dispatch<React.SetStateAction<string[]>>;
  setType: React.Dispatch<React.SetStateAction<EActivityType[]>>;
  syncTopicInfo: VoidFunction;
};
export function useTopicActivity({
  groups,
  page,
  stared,
  topicId,
  type,
  commentText,
  currentComment,
  commentImportant,
  setGroups,
  setPage,
  setStared,
  setType,
  setCurrentComment,
  setCommentImportant,
  syncTopicInfo,
}: Props) {
  const dispatch = useAppDispatch();
  const topicBasics = useAppSelector((state: RootState) => state.topic);
  const { state: userState } = useContext(UserContext);
  const topicActivity = useAppSelector(
    (state: RootState) => state.topicActivity
  );

  // const [limit, setLimit] = useState(10);
  const [showAlert, setShowAlert] = useState(false);

  /**
   * Fetch topic activity
   */
  const [fetchActivity, { data: poolData, loading: loadingActivity }] =
    useLazyQuery<ActivityList>(GET_ACTIVITY, {
      variables: {
        topicId,
        page,
        stared,
        type,
        groups,
      },
      fetchPolicy: 'cache-first',
      // pollInterval: 3000,
    });

  /**
   * Check for google doc update
   * In case push notifications from Google does not work
   */
  // const [syncDoc] = useMutation<NewDocActivityList>(SYNC_DOC);
  // const [syncMicrosoft] = useMutation<{
  //   syncMicrosoft: NewDocActivityList['syncGoogleDoc'];
  // }>(SYNC_MICROSOFT);

  /**
   * Subscribe for topic updates
   */
  useSubscription<{ topicUpdated: string }>(TOPIC_CHANGED, {
    variables: { topicId },
    onSubscriptionData: (res) => {
      if (res.subscriptionData.data?.topicUpdated) {
        try {
          const newData = JSON.parse(res.subscriptionData.data?.topicUpdated);
          if (newData.type === 'activity') {
            newData.data.created = new Date(newData.data.created).getTime();
            if (newData.data.deleted) {
              newData.data.deleted = new Date(newData.data.deleted).getTime();
              dispatch(
                deleteCommentTopicActivity({
                  id: newData.data._id,
                  deletedBy: newData.data.deletedBy as IUser,
                })
              );
              return;
            }
            if (newData.data.updated) {
              newData.data.updated = new Date(newData.data.updated).getTime();
            }
            // dispatch(removeFakeTopicActivity());
            dispatch(updateOrAddTopicActivity(newData.data));
          } else if (newData.type === 'topicInfo') {
            dispatch(initializeTopicLink(newData.data.links));
          }
        } catch (error: any) {
          // eslint-disable-next-line no-console
          console.log('log3:', error.message);
        }
      }
    },
  });

  const [addComment, { loading: addCommentLoading }] = useMutation<{
    addComment: Activity;
  }>(ADD_COMMENTS);

  const [replyComment] = useMutation<{
    replyToComment: Activity;
  }>(REPLY_COMMENTS);

  const [editComment] = useMutation<{
    updateComment: Activity;
  }>(EDIT_COMMENTS);

  // useEffect(() => {
  //   if (!topicBasics?.closed) {
  //     if (
  //       !stared &&
  //       (type.length === 0 ||
  //         type.includes(EActivityType.GOOGLE) ||
  //         type.includes(EActivityType.GOOGLE_COMMENT))
  //     ) {
  //       syncDoc({
  //         variables: {
  //           topicId,
  //         },
  //         fetchPolicy: 'network-only',
  //       }).then((result) => {
  //         if (
  //           result.data &&
  //           result.data?.syncGoogleDoc &&
  //           result.data?.syncGoogleDoc?.length > 0
  //         ) {
  //           // dispatch(addNewTopicActivity(result.data?.syncGoogleDoc));
  //           // dispatch(removeFakeTopicActivity());
  //           // dispatch(addNewTopicActivity(result.data?.syncGoogleDoc?.result));
  //         }
  //       });
  //     }

  //     if (
  //       !stared &&
  //       (type.length === 0 || type.includes(EActivityType.MICROSOFT))
  //     ) {
  //       syncMicrosoft({
  //         variables: {
  //           topicId,
  //         },
  //         fetchPolicy: 'network-only',
  //       }).then((result) => {
  //         if (
  //           result.data &&
  //           result.data?.syncMicrosoft &&
  //           result.data?.syncMicrosoft?.length > 0
  //         ) {
  //           // dispatch(addNewTopicActivity(result.data?.syncGoogleDoc));
  //           // dispatch(removeFakeTopicActivity());
  //           // dispatch(addNewTopicActivity(result.data?.syncGoogleDoc?.result));
  //         }
  //       });
  //     }
  //   }
  // }, [
  //   dispatch,
  //   stared,
  //   syncDoc,
  //   syncMicrosoft,
  //   topicBasics?.closed,
  //   topicId,
  //   type,
  // ]);

  useEffect(() => {
    fetchActivity({
      fetchPolicy: 'network-only',
      variables: {
        topicId,
        page: 0,
        stared: false,
        type: [],
        groups: [],
        activityId: window.location.hash.split('#')[1],
      },
    })
      .then((res) => {
        dispatch(
          initializeTopicActivity(res?.data?.activityList?.result || [])
        );
      })
      .catch((err) => {});
  }, [dispatch, fetchActivity, topicId]);

  const onCommentAdd = useCallback(() => {
    if (
      (!commentText.trim() || commentText.trim() === '') &&
      !(
        currentComment.includes('<img') ||
        currentComment.includes('<iframe') ||
        currentComment.includes('class="mention"')
      )
    )
      return;

    // return;

    // check if there is a google url
    if (isGoogleDoc(commentText)) {
      pubsub.emit(ENotificationKey.GOOGLE_LINK_ADDED, []);
    }

    if (isNotionUrl(commentText)) {
      pubsub.emit(ENotificationKey.NOTION_LINK_ADDED, []);
    }

    if (isLinearLink(commentText)) {
      pubsub.emit(ENotificationKey.LINEAR_LINK_ADDED, []);
    }

    const theComment: ICommentModel = {
      comment: currentComment,
    };

    const data = {
      type: EActivityType.COMMENT,
      payload: theComment,
    };

    const toAdd: Activity = {
      _id: new Date().getTime().toString(),
      comment: 'fake',
      created: new Date().getTime(),
      data: JSON.stringify(data),
      topicId: topicBasics?._id,
      workspaceId: '',
      stared: false,
      group: [],
      userId: {
        _id: userState?._id,
        firstName: userState?.firstName!,
        lastName: userState?.lastName!,
        businessEmail: userState?.businessEmail,
      },
    };
    dispatch(addNewTopicActivity([toAdd]));
    addComment({
      variables: {
        topicId: topicBasics._id,
        comment: currentComment,
        isImportant: commentImportant,
      },
    }).then((res) => {
      setCurrentComment('');
      setCommentImportant(false);
      dispatch(removeFakeTopicActivity());
      dispatch(addNewTopicActivity([res.data?.addComment!]));
      syncTopicInfo();
      if (res.data?.addComment?.mentionWithNoAccess) {
        setShowAlert(true);
      }
    });
  }, [
    commentText,
    currentComment,
    topicBasics?._id,
    userState?._id,
    userState?.firstName,
    userState?.lastName,
    userState?.businessEmail,
    dispatch,
    addComment,
    commentImportant,
    setCurrentComment,
    setCommentImportant,
    syncTopicInfo,
  ]);

  const onReplyComment = useCallback(
    (commentId: string) => (reply: string) => {
      if (!reply) return;

      replyComment({
        variables: {
          commentId,
          comment: JSON.stringify(reply),
        },
      }).then((res) => {
        if (res.data?.replyToComment?.mentionWithNoAccess) {
          setShowAlert(true);
        }
      });
    },
    [replyComment]
  );

  const onEdit = useCallback(
    (commentId: string) => (newComment: string, commentText: string) => {
      if (
        (!commentText.trim() || commentText.trim() === '') &&
        !(
          newComment.includes('<img') ||
          newComment.includes('<iframe') ||
          newComment.includes('class="mention"')
        )
      )
        return;
      dispatch(
        updateTopicActivity({
          id: commentId,
          data: {
            updateMode: false,
            updated: new Date().getTime(),
          },
        })
      );
      editComment({
        variables: {
          commentId,
          comment: newComment,
        },
      }).then((res) => {
        if (
          res?.data?.updateComment?.data &&
          res?.data?.updateComment?.data !== undefined
        ) {
          dispatch(
            updateTopicActivity({
              id: res?.data?.updateComment._id,
              data: {
                data: res?.data?.updateComment.data,
              },
            })
          );
          if (res.data?.updateComment?.mentionWithNoAccess) {
            setShowAlert(true);
          }
        }
      });
    },
    [dispatch, editComment]
  );

  const filterActivity = useCallback(
    (variables: {
      stared: boolean;
      type: EActivityType[];
      groups: string[];
    }) => {
      fetchActivity({
        variables: {
          topicId: topicBasics._id,
          page: 0,
          ...variables,
        },
        fetchPolicy: 'network-only',
      })
        .then((result) => {
          dispatch(
            initializeTopicActivity(result.data?.activityList?.result || [])
          );
          setPage(0);
        })
        .catch((err) => {});
    },
    [dispatch, fetchActivity, setPage, topicBasics._id]
  );

  const handleStaredClick = useCallback(() => {
    setStared(!stared);
    filterActivity({
      type,
      groups,
      stared: !stared,
    });
  }, [filterActivity, groups, setStared, stared, type]);

  const handleGroupClick = useCallback(
    (newGroup: string) => {
      if (groups.includes(newGroup)) {
        setGroups((value) => value.filter((el) => el !== newGroup));
        filterActivity({
          type,
          groups: groups.filter((el) => el !== newGroup),
          stared,
        });
      } else {
        setGroups((value) => [...value, newGroup]);
        filterActivity({
          type,
          groups: [...groups, newGroup],
          stared,
        });
      }
    },
    [filterActivity, groups, setGroups, stared, type]
  );

  const handleTypeClick = useCallback(
    (newType: EActivityType) => {
      if (type.includes(newType)) {
        setType((value) => value.filter((el) => el !== newType));
        filterActivity({
          type: type.filter((el) => el !== newType),
          groups,
          stared,
        });
      } else {
        setType((value) => [...value, newType]);
        filterActivity({
          type: [...type, newType],
          groups,
          stared,
        });
      }
    },
    [filterActivity, groups, setType, stared, type]
  );

  const clearFilter = useCallback(() => {
    setStared(false);
    setType([]);
    setGroups([]);
    filterActivity({
      type: [],
      groups: [],
      stared: false,
    });
  }, [filterActivity, setGroups, setStared, setType]);

  const loadMore = useCallback(() => {
    // let skip = 0;
    // for (const activity of topicActivity) {
    //   if (JSON.parse(activity.data).type !== EActivityType.GOOGLE) {
    //     skip++;
    //   }
    let theLimit = 10;
    if (
      [EActivityType.GOOGLE, EActivityType.NOTION].includes(
        JSON.parse(topicActivity[topicActivity.length - 1].data).type
      )
    ) {
      // setLimit(50);
      theLimit = 70;
    } else {
      theLimit = 10;
      // setLimit(10);
    }

    fetchActivity({
      fetchPolicy: 'cache-first',
      variables: {
        topicId: topicBasics._id,
        page,
        stared,
        type,
        groups,
        limit: theLimit, // TODO -- vary the limit depending if we have google collapse
        skip: topicActivity?.length,
      },
    }).then((res) => {
      setPage((prev) => prev + 1);
      const result = res.data?.activityList?.result || [];
      dispatch(addMoreTopicActivity(result));

      const last = result[result.length - 1];

      if (last?.data && JSON.parse(last.data)?.type === EActivityType.GOOGLE) {
        /**
         * TODO - prevent collapse problem
         * when first 10 for example are all collapsed
         */
      }
    });
  }, [
    dispatch,
    fetchActivity,
    groups,
    page,
    setPage,
    stared,
    topicActivity,
    topicBasics._id,
    type,
  ]);

  return {
    fetchActivity,
    addComment,
    replyComment,
    editComment,
    loadMore,
    clearFilter,
    handleTypeClick,
    handleGroupClick,
    handleStaredClick,
    onEdit,
    onReplyComment,
    onCommentAdd,
    setShowAlert,
    showAlert,
    poolData,
    loadingActivity,
    addCommentLoading,
  };
}
