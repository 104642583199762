import { css } from '@emotion/css';
import { theme } from 'theme';
import { Box, Badge, Text, TooltipContainer } from 'components';
import { CheckIcon, Close } from 'components/Icons';
import React from 'react';
import GoogleImage from './assets/google.png';

type Props = {
  title: string;
  description: string;
  Icon: React.FC<any>;
  completed: boolean;
  helperText?: string;
  disableButtonHelperText?: string;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onRemove: VoidFunction;
};
export const IntegrationItem: React.FC<Props> = ({
  title,
  description,
  Icon,
  completed,
  helperText,
  disableButtonHelperText,
  onClick,
  onRemove,
}) => {
  const addComponent = (
    <div
      className={css`
        border-radius: 8px;
        background-color: ${theme.colors.primary['primary/03']};
        padding: 6px 8px 6px 8px;
        cursor: pointer;
      `}
      onClick={onClick}
    >
      {title === 'Google' ? (
        <img src={GoogleImage} height={40} alt="google" />
      ) : (
        <Text.TextSmall2 marginBottom="4xs" marginTop="4xs">
          Add
        </Text.TextSmall2>
      )}
    </div>
  );

  const removeComponent = (
    <div
      className={css`
        display: flex;
        flex-direction: row;
      `}
    >
      <div
        className={css`
          width: 24px;
          height: 24px;
          border-radius: 12px;
          background-color: ${theme.colors.accent['green/main']};
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 8px;
        `}
      >
        <CheckIcon
          fill={theme.colors.text['text/100%']}
          style={{ width: '12px', height: '12px' }}
        />
      </div>
      <div
        className={css`
          width: 24px;
          height: 24px;
          border-radius: 12px;
          background-color: ${theme.colors.primary['primary/02']};
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        `}
        onClick={onRemove}
      >
        <Close
          fill={theme.colors.text['text/blue/soft']}
          style={{ width: '12px', height: '12px' }}
        />
      </div>
    </div>
  );
  return (
    <Box
      noPadding
      width="100%"
      borderRadius="l"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box
        noPadding
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        width="70%"
      >
        <Badge
          Icon={
            <Icon
              style={{
                width: '16px',
                height: '16px',
              }}
            />
          }
        />
        <Box noPadding marginLeft="m">
          <Text.Body marginBottom="4xs">{title}</Text.Body>
          <Text.TextSmall1 color="text/75%" marginTop="2xs">
            {description}
          </Text.TextSmall1>
        </Box>
      </Box>

      {completed ? (
        disableButtonHelperText ? (
          <TooltipContainer text={disableButtonHelperText}>
            {removeComponent}
          </TooltipContainer>
        ) : (
          removeComponent
        )
      ) : helperText ? (
        <TooltipContainer text={helperText}>{addComponent}</TooltipContainer>
      ) : (
        addComponent
      )}
    </Box>
  );
};

export const Separator = () => (
  <div
    className={css`
      width: 100%;
      border-bottom: 1px solid ${theme.colors.accent['purple/light']};
      margin-top: 16px;
    `}
  />
);
