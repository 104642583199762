import { css } from '@emotion/css';
import { Text } from 'components';
import { CheckIcon } from 'components/Icons';
import { Link } from 'react-router-dom';
import { theme } from 'theme';

type Props = {
  checked?: boolean;
  title: string;
  subtitle: string;
  link?: string;
  onClick?: VoidFunction;
};
export const TutorialItem: React.FC<Props> = ({
  checked,
  title,
  subtitle,
  link = '#',
  onClick,
}) => {
  return (
    <Link to={link} style={{ textDecoration: 'none' }} onClick={onClick}>
      <div
        className={css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}
      >
        <div
          className={css`
            width: 19px;
            height: 19px;
            border-radius: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-right: 16px;
            background-color: ${checked
              ? theme.colors.accent['green/main']
              : ''};
            border: ${checked
              ? ''
              : `2px solid ${theme.colors.primary['primary/02']}`};
          `}
        >
          {checked && (
            <CheckIcon
              fill={theme.colors.text['text/100%']}
              style={{ width: '12px' }}
            />
          )}
        </div>
        <div
          className={css`
            max-width: 80%;
          `}
        >
          <Text.Body>{title}</Text.Body>
          <Text.TextSmall1 color="text/75%">{subtitle}</Text.TextSmall1>
        </div>
      </div>
    </Link>
  );
};

export const TutorialItemSeparator: React.FC = () => (
  <div
    className={css`
      border: 1px solid ${theme.colors.accent['purple/light']};
      width: 100%;
      height: 1px;
      margin-top: 16px;
      margin-bottom: 16px;
    `}
  />
);
