import { getFromLocalStorage, setToLocalStorage } from 'utils';
import jwtDecode from 'jwt-decode';

type GraphQLRequest<T> = {
  query: string;
  variables?: T;
  token?: string;
  url?: string;
};
export const graphqlRequest = <T>({
  query,
  variables,
  token,
  url,
}: GraphQLRequest<T>) => {
  return fetch(process.env.REACT_APP_API_URL!, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token || ''}`,
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  });
};

export const getAccessToken: () => Promise<string> = async () => {
  // return new Promise<string>((resolve, reject) => { });
  if (getFromLocalStorage('refreshToken')) {
    const query = `
        mutation getAccessToken($refresh: String!) {
          refreshSession(refresh: $refresh) {
            session
            refresh
          }
        }
      `;
    type RefreshToken = {
      refresh: string;
    };
    const res = await graphqlRequest<RefreshToken>({
      query,
      variables: { refresh: getFromLocalStorage('refreshToken') || '' },
    });

    const result = await res.json().catch(() => {});

    if (result?.data) {
      setToLocalStorage('accessToken', result.data.refreshSession.session);
      setToLocalStorage('refreshToken', result.data.refreshSession.refresh);
      return result.data.refreshSession.session;
    } else {
      throw new Error('Unable to get new token');
    }
  } else {
    throw new Error('Unable to get new token');
  }
};

export const getNewToken: () => Promise<string> = async () => {
  try {
    if (getFromLocalStorage('accessToken')) {
      /**
       * TODO --- check if the access token is still valid, otherwise request a
       * new access token
       */
      const decodedToken: any = jwtDecode(getFromLocalStorage('accessToken')!);
      const currentTime = new Date().getTime() / 1000;
      if (decodedToken.exp > currentTime) {
        return getFromLocalStorage('accessToken')!;
      }
      const query = `
        mutation getAccessToken($refresh: String!) {
          refreshSession(refresh: $refresh) {
            session
            refresh
          }
        }
      `;
      type RefreshToken = {
        refresh: string;
      };
      const res = await graphqlRequest<RefreshToken>({
        query,
        variables: { refresh: getFromLocalStorage('refreshToken') || '' },
      });

      const result = await res.json().catch(() => {});

      if (result?.data) {
        setToLocalStorage('accessToken', result.data.refreshSession.session);
        setToLocalStorage('refreshToken', result.data.refreshSession.refresh);
        return result.data.refreshSession.session;
      }
    }
  } catch (error) {}

  return getFromLocalStorage('accessToken');
};
