import React from 'react';
// import { Bar } from 'react-chartjs-2';
// import './index.css';
// import {
//   Chart,
//   // ArcElement,
//   Tooltip,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Legend,
// } from 'chart.js';
import { css } from '@emotion/css';
import { Text, TooltipContainer } from 'components';

// Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// export const options = {
//   responsive: true,
//   plugins: {
//     legend: {
//       position: 'top' as const,
//       display: false,
//     },
//     title: {
//       display: false,
//       text: 'Chart.js Bar Chart',
//     },
//   },
// };

type Props = {
  labels: string[];
  values1: number[];
  values2: number[];
};
export const VerticalBarChart: React.FC<Props> = ({
  labels,
  values1,
  values2,
}) => {
  // const data = {
  //   labels,
  //   datasets: [
  //     {
  //       // label: 'Dataset 1',
  //       data: values1,
  //       backgroundColor: '#453E69',
  //     },
  //     {
  //       // label: 'Dataset 2',
  //       data: values2,
  //       backgroundColor: '#766DAA',
  //     },
  //   ],
  // };
  // <Bar options={options} data={data} height={240} />
  const max = Math.max(...values1, ...values2) + 2;
  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        align-items: flex-end;
      `}
    >
      {labels.map((label, idx) => (
        <div
          key={label}
          className={css`
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            margin-right: 28px;
            &:last-child {
              margin-right: 0px;
            }
          `}
        >
          <div
            className={css`
              height: 240px;
              display: flex;
              flex-direction: row;
              align-items: flex-end;
              justify-content: center;
            `}
          >
            {values1[idx] > 0 && (
              <TooltipContainer
                text={`Received *${values1[idx]}**`}
                side="right"
              >
                <div
                  className={css`
                    height: ${(values1[idx] / max) * 100}%;
                    width: 8px;
                    background-color: #453e69;
                    margin-right: 4px;
                    transition: height 2s ease;
                  `}
                />
              </TooltipContainer>
            )}
            {values2[idx] > 0 && (
              <TooltipContainer text={`Sent *${values2[idx]}**`} side="right">
                <div
                  className={css`
                    height: ${(values2[idx] / max) * 100}%;
                    width: 8px;
                    background-color: #766daa;
                    transition: height 2s ease;
                  `}
                />
              </TooltipContainer>
            )}
          </div>
          <Text.TextSmall2
            style={{ color: '#453E69', fontSize: '9px', fontWeight: '600' }}
            marginBottom="4xs"
            marginTop="xs"
          >
            {label}
          </Text.TextSmall2>
        </div>
      ))}
    </div>
  );
};
