import { Box, Text } from 'components';
import React from 'react';

type Props = {
  title: string;
  Icon: React.FC<any>;
};
export const ItemTitle: React.FC<Props> = ({ Icon, title }) => {
  return (
    <Box
      noPadding
      display="flex"
      flexDirection="row"
      alignItems="center"
      marginBottom="4xs"
    >
      <Icon
        style={{ width: '16px', height: '16px', marginRight: '8px' }}
        fill="#8D86F8"
      />
      <Text.TextSmall1 color="text/100%" marginTop="4xs" marginBottom="4xs">
        {title}
      </Text.TextSmall1>
    </Box>
  );
};
